<template>
  <svg
      height="22"
      viewBox="0 0 24 24"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="ic_duvida"
        transform="translate(-548 -521)"
    >
      <g
          :fill="color"
          data-name="Retângulo 46"
          id="Retângulo_46"
          opacity="0"
          stroke="#707070"
          stroke-width="1"
          transform="translate(548 521)"
      >
        <rect
            height="24"
            stroke="none"
            width="24"
        />
        <rect
            fill="none"
            height="23"
            width="23"
            x="0.5"
            y="0.5"
        />
      </g>
      <g
          id="noun_questions_1195076"
          transform="translate(547.25 520.5)"
      >
        <path
            :fill="color"
            d="M21.3,8.385H18.953v-4.1A2.786,2.786,0,0,0,16.17,1.5H4.543A2.786,2.786,0,0,0,1.75,4.284V13.7a2.786,2.786,0,0,0,2.793,2.779h1.4v3.192a.57.57,0,0,0,.973.4l3.579-3.6h.684v1.5a2.453,2.453,0,0,0,2.439,2.451H16.43l3.025,3.021a.57.57,0,0,0,.973-.4V20.427H21.3a2.453,2.453,0,0,0,2.451-2.451v-7.14A2.453,2.453,0,0,0,21.3,8.385ZM10.256,15.336a.57.57,0,0,0-.4.166L7.071,18.282V15.906a.57.57,0,0,0-.57-.57H4.543A1.644,1.644,0,0,1,2.89,13.7V4.284A1.644,1.644,0,0,1,4.543,2.64H16.17a1.644,1.644,0,0,1,1.644,1.644V13.7a1.644,1.644,0,0,1-1.644,1.639Zm12.354,2.64A1.311,1.311,0,0,1,21.3,19.287H19.858a.57.57,0,0,0-.57.57v1.824l-2.221-2.216a.57.57,0,0,0-.4-.166H13.616a1.311,1.311,0,0,1-1.322-1.322v-1.5H16.17A2.786,2.786,0,0,0,18.953,13.7V9.525H21.3a1.311,1.311,0,0,1,1.311,1.311Z"
            data-name="Caminho 8995"
            id="Caminho_8995"
            transform="translate(0 0)"
        />
        <path
            :fill="color"
            d="M31.048,14.462a2.49,2.49,0,0,0-2.558,2.49.566.566,0,0,0,1.132,0,1.345,1.345,0,0,1,.41-.971,1.326,1.326,0,0,1,.985-.383,1.358,1.358,0,0,1,.559,2.571,2.058,2.058,0,0,0-1.159,1.822s0,0,0,.014v.267a.566.566,0,0,0,1.132,0v-.258a.933.933,0,0,1,.532-.835,2.49,2.49,0,0,0-1.028-4.72Z"
            data-name="Caminho 8996"
            id="Caminho_8996"
            transform="translate(-20.631 -10.026)"
        />
        <path
            :fill="color"
            d="M37.546,48a.566.566,0,0,0-.566.566v.206a.566.566,0,0,0,1.132,0v-.206A.566.566,0,0,0,37.546,48Z"
            data-name="Caminho 8997"
            id="Caminho_8997"
            transform="translate(-27.195 -35.894)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'QuestionIcon',
  props: {
    color: {
      type: String,
      default: '#2d2d2d'
    },
  },
  }
</script>
<style scoped>

</style>