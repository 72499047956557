<template>
  <div
    :class="{ 'selected': selected }"
    class="header-category-item cursor-pointer">

    <div class="header-category-item__icon">
      <div v-if="selected" v-html="forceSize(category.iconSvgAtivo)"></div>
      <div v-else v-html="forceSize(category.iconSvgInativo)"></div>
    </div>

    <span class="header-category-item__title">
      {{ category.name }}
    </span>

  </div>
</template>
<script>
export default {
  name: 'HeaderCategory',
  props: {
    category: {
      type: Object
    },
    selected: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  },
    methods: {
      forceSize (svg) {
        if (!svg) return ''
        const clean = svg
            .replace(/width=["']\d*\.?\d*["']/, '')
            .replace(/height=["']\d*\.?\d*["']/, '')
            .replace(/[\n\r\t]/g, '')
        const rgx = /<svg.*>/

        const size = this.big ? 24 : 16
        return clean.replace(rgx, rgx.exec(clean)[0].replace('>', `width="${size}" height="${size}">`))
      },
    },
  }
</script>
<style lang="scss" scoped>

.header-category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 53px;
  height: 80%;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #5B621C;
  padding: 20px;
  transition: background-color 150ms ease-in;

  &.header-category-item.selected {
    background: rgba(205, 212, 63, 1) 0 0 no-repeat padding-box;
    opacity: 1;
  }

  &:hover {
    background: rgba(205, 212, 63, 1) 0 0 no-repeat padding-box;
    opacity: 1;
    transition-timing-function: ease-out;
  }

  .header-category-item__icon {
    height: 16px;
    width: 16px;
    margin-right: 12px;
  }

  .header-category-item__title {
    display: flex;
  }
}

</style>
