<template>
  <svg
      height="16.9"
      viewBox="0 0 24 16.9"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="ic_menu"
        transform="translate(-461 -42.5)"
    >
      <rect
          id="Retângulo_1"
          data-name="Retângulo 1"
          fill="#5a6222"
          height="2"
          rx="1"
          transform="translate(461 42.5)"
          width="24"
      />
      <rect
          id="Retângulo_2"
          data-name="Retângulo 2"
          fill="#5a6222"
          height="2"
          rx="1"
          transform="translate(461 50)"
          width="24"
      />
      <rect
          id="Retângulo_3"
          data-name="Retângulo 3"
          fill="#5a6222"
          height="2"
          rx="1"
          transform="translate(461 57.4)"
          width="24"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'BurgerIcon'
}
</script>
<style scoped>

a {
  opacity: 0;
}

</style>