const OfferApi = (vueResource, url) => {
  const resourceOffer = vueResource(`${url}/offer{/PATH}{/SUB}{/ID}{?Q*}`)
  const resourceLance = vueResource(`${url}/lance{/PATH}{/ID}{?Q*}`)
  const resourceBuyNow = vueResource(`${url}/compreja{/PATH}{/ID}{?Q*}`)
  const resourceVehicle = vueResource(`${url}/veicle{/PATH}{/ID}{?Q*}`)
  const resourceUsrio = vueResource(`${url}/usrio{/PATH}{/ID}{?Q*}`)
  const resourcePartner = vueResource(`${url}/partner{/PATH}{/ID}{?Q*}`)

  return {
    async getList (q) {
      try {
        return await resourceOffer.get({ Q: q })
      } catch (e) {
        return {}
      }
    },
    async getLimit (q) {
      try {
        return await resourceUsrio.get({ Q: q, PATH: 'limites' })
      } catch (e) {
        return {}
      }
    },
    async getOfferId (id) {
      try {
        return await resourceOffer.get({ ID: id })
      } catch (e) {
        return {}
      }
    },
    async getJustifys (q) {
      try {
        return await resourceOffer.get({ Q: q, PATH: 'justificativas' })
      } catch (e) {
        return {}
      }
    },
    async getPartnerDetails () {
      try {
        return await resourcePartner.get()
      } catch (e) {
        return { body: null }
      }
    },
    async getIncrements (id, q) {
      try {
        return await resourceOffer.get({ ID: id, Q: q, PATH: 'increments' })
      } catch (e) {
        return {}
      }
    },
    async getGallery (id, q) {
      try {
        return await resourceOffer.get({ ID: id, Q: q, PATH: 'gallery' })
      } catch (e) {
        return {}
      }
    },
    async getStat (id, q) {
      try {
        return await resourceOffer.get({ ID: id, Q: q, PATH: 'stat' })
      } catch (e) {
        return {}
      }
    },
    async getAllGallery (id, q) {
      try {
        return await resourceOffer.get({ ID: id, Q: q, PATH: 'gallery', SUB: 'all' })
      } catch (e) {
        return {}
      }
    },
    async getItem (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id })
      } catch (e) {
        return {}
      }
    },
    async getItemByToken (token, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: token, PATH: 'verify' })
      } catch (e) {
        return {}
      }
    },
    async getOptionalsStars (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'optionalsStars' })
      } catch (e) {
        return { body: null }
      }
    },
    async getOptionals (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'optionals' })
      } catch (e) {
        return { body: null }
      }
    },
    async getImplements (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'impl' })
      } catch (e) {
        return { body: null }
      }
    },
    async getTanks (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'tanq' })
      } catch (e) {
        return { body: null }
      }
    },
    async getChat (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'prgnt' })
      } catch (e) {
        return { body: null }
      }
    },
    async postCancelPurpouse (payload) {
      try {
        return await resourceLance.save({ PATH: 'cancelPurpose' }, payload)
      } catch (e) {
        return e
      }
    },
    async postCancelBuy (payload) {
      try {
        return await resourceOffer.save({ PATH: 'cancelCompra' }, payload)
      } catch (e) {
        return e
      }
    },
    async postChat (payload) {
      try {
        return await resourceOffer.save({ PATH: 'prgnt' }, payload)
      } catch (e) {
        return e
      }
    },
    async postFavorite (payload) {
      try {
        return await resourceOffer.save({ PATH: 'favorite' }, payload)
      } catch (e) {
        return e
      }
    },
    async getSpotlight (id, q) {
      try {
        return await resourceVehicle.get({ Q: q, ID: id, PATH: 'semelhantes' })
      } catch (e) {
        return { body: null }
      }
    },
    async getPrice (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'price' })
      } catch (e) {
        return { body: null }
      }
    },
    async getPriceList (id, q) {
      try {
        return await resourceOffer.get({ Q: q, ID: id, PATH: 'pricelist' })
      } catch (e) {
        return { body: null }
      }
    },
    async postLance (payload) {
      try {
        return await resourceLance.save(payload)
      } catch (e) {
        return e
      }
    },
    async postPurpose (payload) {
      try {
        return await resourceLance.save({ PATH: 'purpose' }, payload)
      } catch (e) {
        return e
      }
    },
    async postBuyNow (payload) {
      try {
        return await resourceBuyNow.save(payload)
      } catch (e) {
        return e
      }
    },

  }
}
export default OfferApi