<template>
  <v-row v-if="offers">
    <v-col
        class="pt-0 pb-0"
    >
      <div class="grid-row-aligner">
        <div class="grid-row-content">

          <OffersHeader
              :length="offers.length"
              :order-by="orderBy"
              :filter-by="filterBy"
              :title="title"
          />

          <v-row>
            <v-col
                class="pt-0 pb-0"
                style="margin-top: 24px;"
            >
              <div
                  style="display: flex; flex-wrap: nowrap;"
              >
                <div
                    class="align-content-center"
                    style="width: 10px; margin-right: 17px;"
                >
                  <ChevronLeft class="cursor-pointer"
                               @click="previous()"
                               :style="offers.length > onScreen ? '' : 'visibility: hidden;'" />
                </div>

                <div
                    ref="row"
                    :style="`min-width: ${width}; max-width: ${width};`"
                    style="display: flex; flex-wrap: nowrap;"
                >
                  <div
                      v-for="(offer, i) in offersInShow"
                      :key="offer.codigo"
                      :style="i === offersInShow.length - 1 ? '' : 'margin-right: auto;'"
                  >
                    <OfferCard
                        v-if="offer.codigo"
                        :offer="offer"
                    />
                    <div style="width: 16rem;"
                        v-else
                    />

                  </div>
                </div>

                <div
                    class="align-content-center"
                    style="width: 10px; margin-left: 17px;"
                >
                  <ChevronRight :style="offers.length > onScreen ? '' : 'visibility: hidden;'"
                                class="cursor-pointer"
                                @click="next()"/>
                </div>
              </div>
            </v-col>
          </v-row>

        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import OffersHeader from '@/shared/OfferHeader/OffersHeader'
import OfferCard from '@/shared/OfferCard/OfferCardIndex'
import ChevronLeft from "@/shared/Icons/ChevronLeft";
import ChevronRight from "@/shared/Icons/ChevronRight";

export default {
  name: 'OffersRow',
  components: {ChevronRight, ChevronLeft, OfferCard, OffersHeader },
  props: {
    offers: {
      type: Array
    },
    orderBy: {
      type: Boolean,
      default: false
    },
    filterBy: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'HomeIndex.OffersRow.title'
    },
    cardSizeDefault: {
      type: Number,
      default: 268
    },
    width: {
      type: String,
      default: '878px'
    }
  },
  data () {
    return {
      offset: 0,
      myRowWidth: 0,
      timeoutId: null
    }
  },
  watch: {
    width (newValue, oldValue) {
      setTimeout(this.updateRowWidth, 100)
    }
  },
  computed: {
    offersInShow () {
      if (!this.offers) return []
      if (this.offers.length < this.onScreen) {
        let length = this.onScreen - this.offers.length
        return this.offers.concat(Array.apply(null, { length: length }).map((v, i) => { return {}}))
      }
      return this.offers.slice(this.offset, this.offset + this.onScreen)
    },
    onScreen () {
      return this.myRowWidth ? Math.floor(this.myRowWidth / this.cardSizeDefault) : 3
    },
    canNext () {
      let validOffset = this.offset + this.onScreen
      const limit = this.offers.length > this.onScreen ? this.offers.length - this.onScreen : 0
      return validOffset >= limit
    },
    canPrevious () {
      let validOffset = this.offset - this.onScreen
      const limit = 0
      return validOffset <= limit
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateRowWidth)
  },
  mounted () {
    window.addEventListener('resize', this.updateRowWidth)
    this.updateRowWidth()
  },
  methods: {
    next () {
      let validOffset = this.offset + 1
      const limit = this.offers.length > this.onScreen ? this.offers.length - this.onScreen : 0
      this.offset = validOffset >= limit ? limit : validOffset
    },
    previous () {
      let validOffset = this.offset - 1
      const limit = 0
      this.offset = validOffset <= limit ? limit : validOffset
    },
    updateRowWidth () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
      if (!this.$refs.row) return this.timeoutId = setTimeout(this.updateRowWidth, 100)
      this.myRowWidth = this.$refs.row.offsetWidth
    }
  }
}
</script>

<style
    scoped
    type="text/css"
>

.row-grid {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
}

.grid-row-content {
  margin-top: 20px;
  margin-bottom: 20px;
  width: fit-content;
}

.box {
  width: 268px;
}

.columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.column {
  flex: 1;
  margin-left: 40px;
  width: 268px;
  height: 349px;
}

.column:first-child {
  max-width: 10px;
  margin-left: 0;
}

.column:last-child {
  max-width: 10px;
  margin-right: 0;
  margin-left: 0;
}

.home-fixer {
  margin-right: 41px;
}

.column:nth-child(2) {
  margin-left: 14px;
}

.column:nth-last-child(2) {
  margin-right: 16px;
}

@media screen and (max-width: 980px) {
  .columns .column {
    flex-basis: 40%;
  }

  .columns:nth-last-child(2) .column:nth-last-child(2) {
    margin-right: 0;
  }

  .columns:last-child .column:last-child {
    flex-basis: 100%;
    margin: 0;
  }

}

@media screen and (max-width: 680px) {
  .columns .column {
    flex-basis: 100%;
  }
}

/*.offer-card {*/
/*  margin-right: 0;*/
/*  width: 268px;*/
/*  height: 382px;*/
/*}*/

.grid-row-aligner {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
