<template>
  <v-container>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-row justify="space-between" align="baseline" class="mb-5">
      <div class="page-title">
        Perfil
      </div>
    </v-row>

    <template v-if="hasErrors">
      <v-alert
          color="red"
          dismissible
          type="error"
          v-for="error in errors"
          :key="error"
      >
        {{ error }}
      </v-alert>
    </template>

    <v-alert
      type="warning"
      v-if="isPartner"
    >
      Olá, Parceiro! Seus dados de perfil podem ser alterados na plataforma de Parceiros. Acesse <a target="_blank" :href="profilePartnerUrl" style="text-decoration: underline; color: white">aqui</a>
    </v-alert>

    <v-row class="row-split">
      <div class="form-group" :class="{ 'has-error': $v.form.nomeResponsavel.$anyError }">
        <label for="nomeResponsavel">{{ tipoUsuario === 'PESSOA_FISICA' ? 'Nome' : 'Nome do Responsável' }}*</label>
        <input id="nomeResponsavel" type="text" class="form-control" v-model.trim="$v.form.nomeResponsavel.$model" :disabled="isPartner">

        <span class="invalid-feedback" v-if="!$v.form.nomeResponsavel.required">Digite o nome</span>
      </div>

      <div class="form-group">
        <label for="sobrenomeResponsavel">{{ tipoUsuario === 'PESSOA_FISICA' ? 'Sobrenome' : 'Sobrenome do Responsável' }}*</label>
        <input id="sobrenomeResponsavel" type="text" class="form-control" v-model.trim="form.sobrenomeResponsavel" :disabled="isPartner">
      </div>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input id="email" type="email" class="form-control" v-model="form.email" disabled>
        </div>
      </v-col>

      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.telefone.$anyError }">
          <label for="telefone">Telefone*</label>
          <input id="telefone" type="text" class="form-control" v-model.trim="$v.form.telefone.$model" :disabled="isPartner">

          <span class="invalid-feedback" v-if="!$v.form.telefone.required">Digite seu telefone</span>
          <span class="invalid-feedback" v-if="!$v.form.telefone.numeric">Digite apenas números</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split" v-if="tipoUsuario !== 'PESSOA_FISICA'">
      <v-col>
        <div class="form-group">
          <label for="nomeUsuario">Nome de Usuário</label>
          <input id="nomeUsuario" type="text" class="form-control" v-model="form.nomeUsuario" disabled>
        </div>
      </v-col>

      <v-col>
        <div class="form-group">
          <label for="cnpj">CNPJ</label>
          <input id="cnpj" type="text" class="form-control" v-model="form.cnpj" disabled>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split" v-if="tipoUsuario !== 'PESSOA_FISICA'">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.nomeFantasia.$anyError }">
          <label for="nomeFantasia">Nome Fantasia*</label>
          <input id="nomeFantasia" type="text" class="form-control" v-model.trim="$v.form.nomeFantasia.$model" :disabled="isPartner">

          <span class="invalid-feedback" v-if="!$v.form.nomeFantasia.required">Digite o nome fantasia</span>
        </div>
      </v-col>

      <v-col>
        <div class="form-group">
          <label for="inscricaoEstadual">Inscrição Estadual</label>
          <input id="inscricaoEstadual" type="text" class="form-control" v-model="form.inscricaoEstadual" disabled>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.senhaAtual.$anyError }">
          <label for="senhaAtual">Senha atual</label>
          <input id="senhaAtual" type="password" class="form-control" v-model.trim="$v.form.senhaAtual.$model">

          <span class="invalid-feedback" v-if="!$v.form.senhaAtual.required">Digite a senha atual</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.senha.$anyError }">
          <label for="senha">{{ $t('Auth.password') }}*</label>
          <input id="senha" type="password" class="form-control" v-model.trim="$v.form.senha.$model">

          <ul class="password-requirements-list" role="list">
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.minLength && $v.form.senha.required"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.minLength || !$v.form.senha.required"/>
              No mínimo {{$v.form.senha.$params.minLength.min}} caracteres
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Low"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Low"/>
              Ao menos um caractere minúsculo
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Upp"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Upp"/>
              Ao menos um caractere maiúsculo
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Num"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Num"/>
              Ao menos um caractere numérico
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Esp"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Esp"/>
              Ao menos um caractere especial
            </li>
          </ul>
        </div>
      </v-col>
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.confirmacaoSenha.$anyError }">
          <label for="password_repeat">{{ $t('Auth.repeatPassword') }}*</label>
          <input id="password_repeat" type="password" class="form-control" v-model.trim="$v.form.confirmacaoSenha.$model">

          <span class="invalid-feedback" v-if="!$v.form.confirmacaoSenha.sameAsPassword">Senha não confere</span>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <Interests
        :show-button="false"
        ref="interests"
      />
    </v-row>

    <v-row class="actions-button">
      <button type="button" class="p-button p-button-primary" @click="save" :disabled="$v.form.$invalid">Salvar</button>
    </v-row>
  </v-container>
</template>

<script>
import TimesIcon from "@/shared/Icons/TimesIcon";
import CheckIcon from "@/shared/Icons/CheckIcon";
import {minLength, numeric, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import {ProfileResource} from "@/api";
import Interests from "@/screen/Interests/Interests";
import {mapState} from "vuex";
import {PARTNER_URL} from "@/main";
import goTo from 'vuetify/lib/services/goto';

export default {
  name: "PerfilIndex",
  components: { TimesIcon, CheckIcon, Interests },
  data: () => ({
    isLoading: false,
    errors: [],
    tipoUsuario: '',
    form: {
      nomeResponsavel: null,
      sobrenomeResponsavel: null,
      telefone: null,
      email: null,
      nomeUsuario: null,
      cnpj: null,
      nomeFantasia: null,
      inscricaoEstadual: null,
      senhaAtual: null,
      senha: null,
      confirmacaoSenha: null,
    },
  }),
  validations: {
    form: {
      nomeResponsavel: {
        required
      },
      nomeFantasia: {
        required: requiredIf(function() {
          return this.tipoUsuario !== 'PESSOA_FISICA'
        }),
      },
      telefone: {
        required,
        numeric
      },
      senhaAtual: {
        required
      },
      senha: {
        required,
        minLength: minLength(8),
        atLeast1Low: value => {
          if (!value) return false
          return /[a-z]/.test(value);
        },
        atLeast1Upp: value => /[A-Z]/.test(value),
        atLeast1Num: value => /\d/.test(value),
        atLeast1Esp: value => /[!@#$%*]/.test(value),
      },
      confirmacaoSenha: {
        sameAsPassword: sameAs('senha')
      },
    }
  },
  computed: {
    ...mapState('authStore', ['user']),
    hasErrors() {
      return this.errors.length
    },
    isPartner() {
      return this.user != null && this.user.isPartner
    },
    profilePartnerUrl() {
      return `${PARTNER_URL}/perfil`
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.isLoading = true
      const response = await ProfileResource.getUser()
      this.isLoading = false

      if (response.ok) {
        const form = this.form
        const data = response.data

        form.nomeResponsavel = data.nome
        form.sobrenomeResponsavel = data.sobrenome
        form.email = data.email
        form.nomeUsuario = data.login
        form.nomeFantasia = data.nomeFantasia
        form.inscricaoEstadual = data.inscricaoSocial
        form.cnpj = data.cpf
        form.telefone = data.telefone

        switch (data.tipoPerfil) {
          case 4:
            this.tipoUsuario = 'EMPRESA'
            break;
          case 5:
            this.tipoUsuario = 'LOJISTA'
            break;
          case 7:
            this.tipoUsuario = 'PESSOA_FISICA'
            break;
        }
      }
    },
    async save() {
      if (!this.$v.$invalid) {
        goTo(0)
        this.errors = []
        this.isLoading = true

        const form = this.form
        const payload = {
          nome: form.nomeResponsavel,
          sobrenome: form.sobrenomeResponsavel,
          telefone: form.telefone,
          password: form.senha,
          oldPassword: form.senhaAtual
        }

        const response = await ProfileResource.putUser(payload)

        if (response.ok) {
          await this.$refs.interests.saveInterests()
          await this.$router.push("/home")
        } else {
          this.errors.push(...response.data.erros)
        }

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  font: 30px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
}

.enter-here {
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;

  a {
    text-decoration: none;
    color: #60BED4;
  }
}

.row-split {
  gap: 10px;

  & > * {
    flex: 1
  }
}

@media (max-width: 768px) {
  .row-split {
    flex-direction: column;
  }
}

.password-requirements-list {
  list-style-type: none;
  padding-left: .5rem !important;
  margin-top: 1rem;

  & > li {
    font: 14px 'HelveticaNeueLTCom-Roman';
    display: flex;
    align-items: center;

    & .requirement-icon {
      margin-right: .5rem;
      flex: 0 0 4%;
    }
  }
}

.actions-button {
  justify-content: center;
  margin-block: 40px;
  gap: 20px;
}

@media (max-width:768px) {
  .actions-button {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

input[disabled]{
  background: #eee;
}
</style>