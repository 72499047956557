<template>
  <svg :fill="active ? '#ffffff' : '#a7a7a7'" class="car-dealership-icon" :class="{'small': small}" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px"
       viewBox="0 0 473.7 470.4" style="enable-background:new 0 0 473.7 470.4;" xml:space="preserve">
    <g>
      <g>
        <path d="M108.9,270.6c0,4.6,1.2,8.1,3.7,10.6c2.5,2.5,6.6,4.4,12.3,5.8v-32.8C114.3,255.7,108.9,261.1,108.9,270.6z"/>
        <path d="M140.6,311.9v35.4c5.6-1,9.8-3,12.5-6.1c2.7-3.1,4.1-7.2,4.1-12.3c0-5-1.3-8.7-3.9-11.3
          C150.7,315.1,146.4,313.2,140.6,311.9z"/>
        <path d="M238.7,105.8L137.2,20.6c-2.5-2.1-6-2.1-8.5,0L27.2,105.8c-3.5,2.9-5.5,7.2-5.5,11.8v323.7c0,3.7,3,6.6,6.6,6.6h209.2
          c3.7,0,6.6-3,6.6-6.6V117.6C244.2,113.1,242.2,108.7,238.7,105.8z M133,89.2c17,0,30.7,13.7,30.7,30.7c0,17-13.7,30.7-30.7,30.7
          s-30.7-13.7-30.7-30.7C102.3,103,116,89.2,133,89.2z M170.1,353.8c-6.5,7-15.9,11.5-28.2,13.3v10.6c0,5-4.1,9.1-9.1,9.1
          s-9.1-4.1-9.1-9.1v-9.9c-9.7-0.7-18-2.9-24.9-6.6c-3.2-1.7-6.1-3.6-8.8-5.8c-4.9-4-5.3-11.3-0.9-15.8l0.1-0.2
          c3.9-4,10.1-4.3,14.5-0.8c6.4,5,13.5,8,21.2,8.9V309l-4.3-0.7c-6.1-1.1-11.3-2.8-15.7-5c-4.4-2.2-8-5-10.8-8.2
          c-2.8-3.2-4.8-6.8-6.1-10.8c-1.3-4-2-8.3-2-12.9c0-10.6,3.3-19.1,9.8-25.5c6.5-6.5,15.8-10.4,27.9-11.7v-10.2c0-5,4.1-9.1,9.1-9.1
          s9.1,4.1,9.1,9.1v10.1c8.1,1,15,3,20.8,6.1c2.1,1.1,4,2.3,5.8,3.7c5.2,3.8,5.9,11.4,1.3,16.1c-3.7,3.8-9.6,4.3-14,1.2
          c-4.4-3.2-9.5-5.2-15.3-6.2v35.4l4.7,0.7c12.2,2.2,21,6.6,26.5,13c5.5,6.4,8.2,14.3,8.2,23.6C179.9,338,176.7,346.8,170.1,353.8z"
        />
      </g>
      <path d="M391,243.6L391,243.6l-129.7,0v-73.4h36.8C346.7,170.2,380.9,202.6,391,243.6z"/>
      <path d="M416.9,450.3h-55.3c-11.4,0-20.6-9.2-20.6-20.6l0,0h96.4l0,0C437.5,441.1,428.3,450.3,416.9,450.3z"/>
      <path d="M382.8,261.3H261.3V413h182.4c4.9,0,8.8-3.9,8.8-8.8v-73.3C452.5,292.5,421.3,261.3,382.8,261.3z M374.4,378.2
        c-20.9,0-37.9-17-37.9-37.9c0-20.9,17-37.9,37.9-37.9s37.9,17,37.9,37.9C412.3,361.2,395.3,378.2,374.4,378.2z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CarDealershipIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.car-dealership-icon {
  height: 80px;
  width: 90px;

  &.small {
    height: 40px !important;
    width: 40px !important;
  }
}
</style>
