<template>
  <div>
    <!--    ativo -->
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" v-if="active">
  <g id="ic_retirada_on" transform="translate(-52 -223)">
    <rect id="Retângulo_1004" data-name="Retângulo 1004" width="42" height="42" rx="6" transform="translate(52 223)" fill="#fff"/>
    <g id="Grupo_2229" data-name="Grupo 2229" transform="translate(59 231)">
      <g id="Grupo_2230" data-name="Grupo 2230" transform="translate(0 12.127)">
        <g id="Grupo_2229-2" data-name="Grupo 2229">
          <path id="Caminho_9121" data-name="Caminho 9121" d="M2.492,68.681a.916.916,0,0,0,.229-.527A6.227,6.227,0,0,1,4.3,64.786c.665-.894,1.375-1.879,2.5-2.039a36.283,36.283,0,0,1,8.341-.275,15.857,15.857,0,0,1,8.5,3.369,1.368,1.368,0,0,0,.527.229A11.508,11.508,0,0,1,28.226,67.6a7.222,7.222,0,0,1,.756.573h0a3.991,3.991,0,0,1,1.146,1.7l-.779.023v.321a.621.621,0,0,0,.665.573h.321a6.137,6.137,0,0,1-.023,1.444,1.471,1.471,0,0,1-1.558,1.4H26.072a2.652,2.652,0,0,1-1.879,1.6h-.825a4.155,4.155,0,0,1-.8-.367,2.714,2.714,0,0,1-.687-.665,2.637,2.637,0,0,1-.275-.573H10.65a2.652,2.652,0,0,1-1.879,1.6H7.946a4.155,4.155,0,0,1-.8-.367,3.153,3.153,0,0,1-.687-.665,2.637,2.637,0,0,1-.275-.573H2.9l-.069-.046C1.575,72.715,1.621,69.781,2.492,68.681Zm20.624,4.927a1.06,1.06,0,0,0,.733.3,1.019,1.019,0,0,0,.733-.3,1.135,1.135,0,0,0,.321-.71,1.054,1.054,0,1,0-2.108-.023A1.142,1.142,0,0,0,23.116,73.608ZM12.941,69.117h2.5a.54.54,0,1,0,0-1.077H12.964a.563.563,0,0,0-.573.55A.524.524,0,0,0,12.941,69.117Zm-.321-2.544s.115.527.5.55l9.052.39s.435-.046-.115-.573A8.831,8.831,0,0,0,18.12,64.4a19.6,19.6,0,0,0-5.064-.962s-.756-.16-.71.5Zm-4.9,7.035a1.06,1.06,0,0,0,.733.3,1.019,1.019,0,0,0,.733-.3,1,1,0,0,0,.3-.71,1.054,1.054,0,1,0-2.108-.023A1.058,1.058,0,0,0,7.717,73.608Zm-1.994-6.6,5.454.069s.458-.023.435-.412l-.206-2.8a.468.468,0,0,0-.619-.321,26.349,26.349,0,0,0-3.025.825,6.385,6.385,0,0,0-2.429,1.764s-.206.3-.252.39C5.013,66.619,4.829,67.009,5.723,67.009Z" transform="translate(-1.861 -62.344)" fill="#60bed4"/>
        </g>
      </g>
      <path id="Caminho_9122" data-name="Caminho 9122" d="M39.752,13.521l1.1,1.1a.139.139,0,0,1,0,.183l-1.146,1.054-1.123-.023v-.16a.4.4,0,0,0-.367-.39.561.561,0,0,0-.275.092.368.368,0,0,0-.115.252v.16l-.848-.023v-.16a.4.4,0,0,0-.367-.39h-.367a.362.362,0,0,0-.367.367v.16l-.94-.023v-.137a.4.4,0,0,0-.367-.39.561.561,0,0,0-.275.092.368.368,0,0,0-.115.252v.137l-1.054-.023v-.16a.4.4,0,0,0-.367-.39H32.4a.362.362,0,0,0-.367.367v.16l-1.512-.023v.527l-.894-.023L29.6,17.417a1.674,1.674,0,0,1-.137.687,2.1,2.1,0,0,1-1.9,1.4,2.491,2.491,0,0,1-.412-.023l-3.666-.527a2.418,2.418,0,0,1-.435-.115,2.507,2.507,0,0,1-1.7-2.429l.092-4.239A2.511,2.511,0,0,1,23.23,9.832a2.947,2.947,0,0,1,.435-.092l3.689-.3a1.246,1.246,0,0,1,.412,0,2.142,2.142,0,0,1,1.856,1.49,1.725,1.725,0,0,1,.092.687l-.023,1.215.894.023v.481ZM22.725,16.134a.5.5,0,0,0,.481.5h.39a.5.5,0,0,0,.5-.481l.069-3.414a.5.5,0,0,0-.481-.5H23.3a.5.5,0,0,0-.5.481Z" transform="translate(-16.847 -9.425)" fill="#60bed4"/>
    </g>
  </g>
</svg>

    <!--    inativo -->
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" v-else>
  <g id="ic_retirada_off" transform="translate(-52 -223)">
    <rect id="Retângulo_1004" data-name="Retângulo 1004" width="42" height="42" rx="6" transform="translate(52 223)" fill="#fff" opacity="0"/>
    <g id="Grupo_2229" data-name="Grupo 2229" transform="translate(59 231)">
      <g id="Grupo_2230" data-name="Grupo 2230" transform="translate(0 12.127)">
        <g id="Grupo_2229-2" data-name="Grupo 2229">
          <path id="Caminho_9121" data-name="Caminho 9121" d="M2.492,68.681a.916.916,0,0,0,.229-.527A6.227,6.227,0,0,1,4.3,64.786c.665-.894,1.375-1.879,2.5-2.039a36.283,36.283,0,0,1,8.341-.275,15.857,15.857,0,0,1,8.5,3.369,1.368,1.368,0,0,0,.527.229A11.508,11.508,0,0,1,28.226,67.6a7.222,7.222,0,0,1,.756.573h0a3.991,3.991,0,0,1,1.146,1.7l-.779.023v.321a.621.621,0,0,0,.665.573h.321a6.137,6.137,0,0,1-.023,1.444,1.471,1.471,0,0,1-1.558,1.4H26.072a2.652,2.652,0,0,1-1.879,1.6h-.825a4.155,4.155,0,0,1-.8-.367,2.714,2.714,0,0,1-.687-.665,2.637,2.637,0,0,1-.275-.573H10.65a2.652,2.652,0,0,1-1.879,1.6H7.946a4.155,4.155,0,0,1-.8-.367,3.153,3.153,0,0,1-.687-.665,2.637,2.637,0,0,1-.275-.573H2.9l-.069-.046C1.575,72.715,1.621,69.781,2.492,68.681Zm20.624,4.927a1.06,1.06,0,0,0,.733.3,1.019,1.019,0,0,0,.733-.3,1.135,1.135,0,0,0,.321-.71,1.054,1.054,0,1,0-2.108-.023A1.142,1.142,0,0,0,23.116,73.608ZM12.941,69.117h2.5a.54.54,0,1,0,0-1.077H12.964a.563.563,0,0,0-.573.55A.524.524,0,0,0,12.941,69.117Zm-.321-2.544s.115.527.5.55l9.052.39s.435-.046-.115-.573A8.831,8.831,0,0,0,18.12,64.4a19.6,19.6,0,0,0-5.064-.962s-.756-.16-.71.5Zm-4.9,7.035a1.06,1.06,0,0,0,.733.3,1.019,1.019,0,0,0,.733-.3,1,1,0,0,0,.3-.71,1.054,1.054,0,1,0-2.108-.023A1.058,1.058,0,0,0,7.717,73.608Zm-1.994-6.6,5.454.069s.458-.023.435-.412l-.206-2.8a.468.468,0,0,0-.619-.321,26.349,26.349,0,0,0-3.025.825,6.385,6.385,0,0,0-2.429,1.764s-.206.3-.252.39C5.013,66.619,4.829,67.009,5.723,67.009Z" transform="translate(-1.861 -62.344)" fill="#60bed4"/>
        </g>
      </g>
      <path id="Caminho_9122" data-name="Caminho 9122" d="M39.752,13.521l1.1,1.1a.139.139,0,0,1,0,.183l-1.146,1.054-1.123-.023v-.16a.4.4,0,0,0-.367-.39.561.561,0,0,0-.275.092.368.368,0,0,0-.115.252v.16l-.848-.023v-.16a.4.4,0,0,0-.367-.39h-.367a.362.362,0,0,0-.367.367v.16l-.94-.023v-.137a.4.4,0,0,0-.367-.39.561.561,0,0,0-.275.092.368.368,0,0,0-.115.252v.137l-1.054-.023v-.16a.4.4,0,0,0-.367-.39H32.4a.362.362,0,0,0-.367.367v.16l-1.512-.023v.527l-.894-.023L29.6,17.417a1.674,1.674,0,0,1-.137.687,2.1,2.1,0,0,1-1.9,1.4,2.491,2.491,0,0,1-.412-.023l-3.666-.527a2.418,2.418,0,0,1-.435-.115,2.507,2.507,0,0,1-1.7-2.429l.092-4.239A2.511,2.511,0,0,1,23.23,9.832a2.947,2.947,0,0,1,.435-.092l3.689-.3a1.246,1.246,0,0,1,.412,0,2.142,2.142,0,0,1,1.856,1.49,1.725,1.725,0,0,1,.092.687l-.023,1.215.894.023v.481ZM22.725,16.134a.5.5,0,0,0,.481.5h.39a.5.5,0,0,0,.5-.481l.069-3.414a.5.5,0,0,0-.481-.5H23.3a.5.5,0,0,0-.5.481Z" transform="translate(-16.847 -9.425)" fill="#60bed4"/>
    </g>
  </g>
</svg>

  </div>
</template>
<script>
  export default {
    name: 'TakingIcon',
    props: {
      active: {
        type: Boolean,
        default: false
      },
    },
  }
</script>
<style scoped>

</style>