const state = {
  ofertaInfo: {},
}

const mutations = {
  ['SET_OFERTA_INFO'] (state, payload) {
    state.ofertaInfo = payload
  },
}

const actions = {}

const getters = {}

const cancelamentoStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default cancelamentoStore
