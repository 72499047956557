<template>
  <div
      class="addon-container"
      :class="{ '--backdrop': backdrop }"
  >
    <div class="addon-icon">
      <slot>
        <v-img
            :src="getImageUrl(addon.icon)"
            height="42px"
            width="42px"
        />
      </slot>
    </div>

    <span class="offer-addon-title">
      {{ addon.title }}
    </span>

    <span class="offer-addon-value">
      {{ getValue(addon) }}
    </span>

  </div>
</template>
<script>
import { getImageUrl } from '@/api'

export default {
  name: 'AddonCard',
  props: {
    addon: {
      type: Object,
      default: () => { return {} }
    },
    backdrop: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    getImageUrl (endpoint) {
      return getImageUrl(endpoint)
    },
    getValue(obj){
      if(obj.type==='N' && Number.isInteger(parseFloat(obj.value))){
        return parseInt(obj.value, 10) 
      }
      if(obj.title === "Quantidade de Portas"){
        return (obj.value+"").split(".")[0];
      }
      return obj.value
    }
  },
}
</script>
<style lang="scss" type="text/css">

.addon-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 3px;

}

.addon-container.--backdrop {
  padding-inline: 2rem;
  padding-block: 1rem;
  background-color: #F5F5F7;
}

.addon-icon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-addon-title {
  width: 100%;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-addon-value {
  width: 100%;
  font: 17px 'HelveticaNeue-Bold';
  letter-spacing: 0;
  color: #2D2D2D;
  margin-bottom: 0;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
