<!--suppress HtmlUnknownTarget -->
<template>
  <div
      :style=" modalGallery ? 'z-index: 222;' : 'z-index: 22;'"
      class="relative cursor-pointer"
      style="width: 100%; max-width: 100%; height: 100%; background-color: white"
  >
    <div
        class="absolute carousel-arrow-button align-content-center"
        style="left: 30px; z-index: 10"
        @click="previous"
    >
      <ChevronLeft :size="16" />
    </div>

    <div
        class="absolute carousel-arrow-button align-content-center"
        style="z-index: 10;"
        :style="{ 'right': !isMobile ? '100px' : '30px' }"
        @click="next"
    >
      <ChevronRight :size="16" />
    </div>

    <v-img
        v-if="!isVideo(main)"
        :max-width="imageWidth"
        :src="getImage(main)"
        :width="imageWidth"
        :height="imageHeight"
        @click="setModalGallery(modalId, true)"
    />


    <div
        v-else-if="isVideo(main) && !isVimeo(main)"
        :style=" isMobile ? 'background: black; height: 339px; width: 100%' : 'background: black; height: fit-content!important; width: 100%'"
        @click="setModalGallery(modalId, true)"
    >
      <video
          autoplay
          controls
          height="fit-content"
          style="max-height: 100%;"
          width="100%"
      >
        <source
            :src="getImage(main)"
            type="video/mp4"
        >
      </video>
    </div>

    <div
        v-else-if="isVimeo(main)"
        :style=" isMobile ? 'background: black; height: 339px; width: 100%' : 'background: black; height: fit-content!important; width: 100%'"
        @click="setModalGallery(modalId, true)"
    >
      <iframe
          :src="main"
          allowfullscreen
          frameborder="0"
          height="100%"
          mozallowfullscreen
          webkitallowfullscreen
          width="100%"
      />
    </div>

    <!--    coluna de thumbs vv-->
    <div
        v-on:click="show = !show"
        @click="setShowGallery"
        v-if="isMobileGallery"
        class="absolute show-more-button align-content-center"
    >
      <ChevronRight :size="18" v-if="showGallery" />
      <ChevronLeft :size="18" v-else/>
    </div>
    <transition name="slide-fade">
        <div
            v-if="showGallery && isMobileGallery && show"
            class="thumb-column"
        >
          <div
              :key="i"
              v-for="(picture, i) in firstSix"
              style="margin-bottom: 16px;"
              @click="setModalGallery(i)"
          >
            <v-img
                :src="getImage(picture)"
                :height="50"
                v-if="!isVideo(picture)"
                :width="80"
            />

            <VideoThumb
                :picture="picture"
                v-else
            />

          </div>

      <div
          @click="setModalGallery(7, hasMore.size > 1)"
          class="relative"
          style="height: 50px; width: 80px;"
          v-if="hasMore"

      >
        <v-img
            :src="getImage(hasMore.picture)"
            height="50"
            v-if="!isVideo(hasMore.picture)"
            width="80"
        />

        <VideoThumb
            :picture="hasMore.picture"
            v-else
        />
        <div
            class="absolute has-more-pictures align-content-center"
            v-if="hasMore.size > 1"
        >
          +{{ hasMore.size }}
        </div>
      </div>
    </div>
    </transition>
    <!--    coluna de thumbs ^^-->

    <!--    linha de thumbs vv-->
<!--    <div class="horizontal-scroll-wrapper" v-if="!isMobileGallery">-->
<!--      <div-->
<!--          v-for="(picture, i) in gallery"-->
<!--          :key="i"-->
<!--          class="card"-->
<!--          @click="setModalGallery(i)"-->
<!--      >-->
<!--        <v-img-->
<!--            v-if="!isVideo(picture)"-->
<!--            :src="getImage(picture)"-->
<!--            height="68"-->
<!--            width="106"-->
<!--        />-->

<!--        <VideoThumb-->
<!--            v-else-->
<!--            :picture="picture"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
    <!--    linha de thumbs ^^-->

    <!--    galeria popup vv-->
    <div
        v-if="modalGallery && isMobileGallery"
        class="black-screen"
    >
      <div class="relative">
        <div
            @click="modalId = 0; modalGallery = false"
        >
          <XMarkIcon
              class="absolute"
              color="#FFF"
              height="12"
              style="top: 22px; right: 30px; height: 12px; width: 12px; margin: 5px"
              width="12"
          />
        </div>
        <div
            class="align-content-center"
            style="top: 54px; left: 10vw; right: 10vw; bottom: 128px; position: fixed;"
            :style="animationStyle"
        >

          <v-img
              :src="getImage(gallery[modalId])"
              :max-height="100*lightboxOpacity+'%'"
              :max-width="100*lightboxOpacity+'%'"
              v-if="!isVideo(gallery[modalId])"
          />

          <video
              autoplay
              controls
              v-else-if="isVideo(gallery[modalId]) && !isVimeo(gallery[modalId])"
              :height="100*lightboxOpacity+'%'"
              :width="100*lightboxOpacity+'%'"
          >
            <source
                :src="getImage(gallery[modalId])"
                type="video/mp4"
            >
          </video>

          <div
              v-else-if="isVimeo(gallery[modalId])"
              :style="`background: black; height:${100*lightboxOpacity}%; width:${100*lightboxOpacity}%`"
          >
            <iframe
                :src="gallery[modalId]"
                allowfullscreen
                frameborder="0"
                height="100%"
                mozallowfullscreen
                webkitallowfullscreen
                width="100%"
            />
          </div>
        </div>

        <div
            @click="previous"
            class="absolute align-content-center cursor-pointer"
            style="transform: scale(0.9,1.5); left: 30px;  top: 50vh; height: 30px; width: 12px; font-size: larger; color: #ffffff"
        >
          <ChevronLeft />
        </div>
        <div
            @click="next"
            class="absolute align-content-center cursor-pointer"
            style="transform: scale(0.9,1.5); right: 30px; top: 50vh; height: 30px; width: 12px; font-size: larger; color: #ffffff"
        >
          <ChevronRight />
        </div>

        <div
            class="align-content-center"
            style="position: fixed; right: 0; left: 36px; bottom: 24px;"
        >
          <!--  https://uxdesign.cc/creating-horizontal-scrolling-containers-the-right-way-css-grid-c256f64fc585        -->
          <div class="gallery-grid">
            <div
                :key="i"
                @click="setPicture(i)"
                v-for="(picture, i) in gallery"
            >
              <v-img
                  :class="{'selected': (i===modalId) }"
                  :src="getImage(picture)"
                  class="image-box"
                  v-if="!isVideo(picture)"
              />

              <VideoThumb
                  :picture="picture"
                  v-else
              />
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--    galeria popup ^^-->

  </div>
</template>
<script>

import XMarkIcon from '../../shared/Icons/XMarkIcon'
import VideoThumb from './VideoThumb'
import { getImageUrl } from '@/api'
import { mapMutations } from 'vuex'
import ChevronLeft from "@/shared/Icons/ChevronLeft";
import ChevronRight from "@/shared/Icons/ChevronRight";

export default {
  name: 'OfferGallery',
  components: {ChevronRight, ChevronLeft, VideoThumb, XMarkIcon },
  props: {
    gallery: {
      type: Array,
      default: () => []
    },
    imageWidth: {
      type: [String, Number],
      default: '100%'
    }
  },
  data () {
    return {
      showGallery: true,
      modalGallery: false,
      modalId: 0,
      lightboxOpacity: 1,
      show: true,
      timeoutId: null,
      isMobileGallery: true,
      openGallery: true
    }
  },
  mounted () {
    this.SET_GALLERY(false)
  },
  computed: {
    animationStyle() {
      return `opacity: ${this.lightboxOpacity};`
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    main() {
      if (this.isMobile) {
        return this.gallery[this.modalId]
      }
      if (this.modalGallery) {
        return this.gallery && this.gallery[0] ? this.gallery[0] : ''
      }
      return this.gallery && this.gallery[this.modalId] ? this.gallery[this.modalId] : ''
    },
    firstSix() {
      return this.gallery.slice(0, 5)
    },
    hasMore() {
      if (this.gallery.length > 7) {
        return {
          size: this.gallery.slice(7).length,
          picture: this.gallery[7]
        }
      }
      return false
    },
    imageHeight() {
      if (!this.isMobileGallery) {
        return '339px'
      } else {
        return '454px'
      }
    }
  },
  methods: {
    ...mapMutations('offerStore', ['SET_GALLERY']),
    setShowGallery () {
      this.showGallery = !this.showGallery
    },
    setModalGallery(id, openModal) {
      if (id === -1) {
        id = this.isMobile ? this.modalId : 0
      }
      this.modalId = id
      if (!openModal) return
      this.modalGallery = !this.modalGallery
      this.SET_GALLERY(this.modalGallery)
      this.lightboxOpacity = 0
      this.fadeIn()
    },
    async setPicture(id) {
      await this.fadeOut()
      this.modalId = id
      await this.fadeIn()
    },
    async next() {
      if (!this.gallery || this.gallery.length === 0) return

      await this.fadeOut()
      if (this.modalId === this.gallery.length - 1) {
        this.modalId = 0
      } else {
        this.modalId += 1
      }
      await this.fadeIn()
    },
    async previous() {
      if (!this.gallery || this.gallery.length === 0) return

      await this.fadeOut()
      if (this.modalId === 0) {
        this.modalId = this.gallery.length - 1
      } else {
        this.modalId -= 1
      }
      await this.fadeIn()
    },

    isVideo(endpoint) {
      return endpoint && endpoint.includes('.mp4')
    },

    isVimeo(endpoint) {
      return endpoint && endpoint.indexOf('vimeo.com') !== -1
    },

    getImage(endpoint) {
       if(window.outerWidth > 701 && window.outerHeight > 501) {
        this.isMobileGallery = true
      } else {
        this.isMobileGallery = false
      }
      return this.isVideo(endpoint) ? endpoint : getImageUrl(endpoint)
    },
    fadeIn() {
      return new Promise(resolve => {
        const looper = () => {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId)
            this.timeoutId = null
          }

          if (this.lightboxOpacity >= 1) {
            this.lightboxOpacity = 1
            return resolve()
          }
          this.lightboxOpacity += 0.04
          this.timeoutId = setTimeout(looper, 5)
        }
        looper()
      })
    },
    fadeOut() {
      return new Promise(resolve => {
        const looper = () => {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId)
            this.timeoutId = null
          }

          if (this.lightboxOpacity <= 0) {
            this.lightboxOpacity = 0
            return resolve()
          }
          this.lightboxOpacity -= 0.04
          this.timeoutId = setTimeout(looper, 4)
        }
        looper()
      })
    }
  },
}
</script>
<style
    scoped
    type="text/css"
>

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.show-more-button {
  height: 24px;
  width: 24px;
  right: 10px;
  top: 10px;

  background: rgba(0, 0, 0, 0.4) 0 0 no-repeat padding-box;
  border-radius: 4px;
  color: white;
}

.carousel-arrow-button {
  top: 50%;
  background: #FFFFFF;
  opacity: 0.7;
  border-radius: 50%;
  padding: 20px;
  color: #2D2D2D;
}

.has-more-pictures {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #00000099;
  font: 30px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #FFFFFF;
}

.black-screen {
  z-index: 222;
  background: rgba(0, 0, 0, 0.8) 0 0 no-repeat padding-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.gallery-grid {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-scrolling: touch;

}

.image-box {
  width: 106px;
  height: 68px;
  flex: 0 0 auto;
  margin-right: 16px;
  background: #000;
}

.thumb-column {
  position: absolute;
  height: 404px;
  width: 89px;
  top: 50px;
  right: 0px;
}

.horizontal-scroll-wrapper {
  width: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 17px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: none;
}

.card {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  box-shadow: 0 2px 4px #00000080;
}

@media screen and (max-width: 701px), screen and (max-height: 501px) {
  .image-box {
    margin-right: 0;
  }

  .horizontal-scroll-wrapper {
    display: block;
  }

}

</style>
