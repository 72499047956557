<template>
  <div class="page-content">
    <v-container>

      <resumo-compra :oferta="oferta"></resumo-compra>

      <VDivider></VDivider>

      <v-row justify="space-between" align="baseline" class="mt-5">
        <div class="page-title">
          Solicitar cancelamento
        </div>
      </v-row>

      <v-row class="row-split" >
        <v-col>
          <div class="form-group" :class="{ 'has-error': $v.form.motivo.$anyError }">
            <label>Motivo</label>
            <selector :options="justificativas" v-model="$v.form.motivo.$model" prop-key="codigo" filter-by="descricao">
              <template #option="{ option }">
                {{ option.descricao }}
              </template>
              <template #selected="{ option }">
                {{ option.descricao }}
              </template>
              <template #selection="{ option }">
                {{ option.descricao }}
              </template>
            </selector>
            <span class="invalid-feedback" v-if="!$v.form.motivo.required">Escolha o motivo</span>
          </div>
        </v-col>
        <v-col>
          <div class="form-group" :class="{ 'has-error': $v.form.justificativa.$anyError }">
            <label for="justificativa">Justificativa</label>
            <input id="justificativa" type="text" class="form-control" v-model.trim="$v.form.justificativa.$model">

            <span class="invalid-feedback" v-if="!$v.form.justificativa.required">Digite a justificativa</span>
          </div>
        </v-col>
      </v-row>

      <template v-if="dadosBancariosRequired">
        <v-row justify="space-between" align="baseline" class="mt-10">
          <div class="page-title">
            Dados bancários para estorno
          </div>
        </v-row>

        <v-row class="row-split">
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.banco.$anyError }">
              <label for="banco">Banco</label>
              <input id="banco" type="text" class="form-control" v-model.trim="$v.form.banco.$model">

              <span class="invalid-feedback" v-if="!$v.form.banco.required">Digite o banco</span>
            </div>
          </v-col>
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.agencia.$anyError }">
              <label for="agencia">Agência</label>
              <input id="agencia" type="text" class="form-control" v-model.trim="$v.form.agencia.$model">

              <span class="invalid-feedback" v-if="!$v.form.agencia.required">Digite a agência</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="row-split">
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.conta.$anyError }">
              <label for="conta">Conta</label>
              <input id="conta" type="text" class="form-control" v-model.trim="$v.form.conta.$model">

              <span class="invalid-feedback" v-if="!$v.form.conta.required">Digite a conta</span>
            </div>
          </v-col>
          <v-col>
            <div class="form-group">
              <label for="pix">PIX</label>
              <input id="pix" type="text" class="form-control" v-model.trim="form.pix">
            </div>
          </v-col>
        </v-row>

        <v-row justify="space-between" align="baseline" class="mt-10">
          <div class="page-title">
            Dados do responsável pela conta bancária
          </div>
        </v-row>

        <v-row class="row-split">
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.titular.$anyError }">
              <label>Titular da conta*</label>
              <selector :options="titularContaOpcoes" v-model="$v.form.responsavel.titular.$model" prop-key="value" filter-by="label">
                <template #option="{ option }">
                  {{ option.label }}
                </template>
                <template #selected="{ option }">
                  {{ option.label }}
                </template>
                <template #selection="{ option }">
                  {{ option.label }}
                </template>
              </selector>

              <span class="invalid-feedback" v-if="!$v.form.responsavel.titular.required">Digite o titular da conta</span>
            </div>
          </v-col>
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.cpf.$anyError }">
              <label for="responsavel_cpf">CPF/CNPJ</label>
              <input id="responsavel_cpf" type="text" class="form-control" v-model.trim="$v.form.responsavel.cpf.$model">

              <span class="invalid-feedback" v-if="!$v.form.responsavel.cpf.required">Digite o CPF/CNPJ</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="row-split">
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.nome.$anyError }">
              <label for="responsavel_nome">Nome do titular</label>
              <input id="responsavel_nome" type="text" class="form-control" v-model.trim="$v.form.responsavel.nome.$model">

              <span class="invalid-feedback" v-if="!$v.form.responsavel.nome.required">Digite o nome</span>
            </div>
          </v-col>
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.cep.$anyError }">
              <label for="responsavel_cep">CEP</label>
              <input id="responsavel_cep" type="text" class="form-control" v-model.trim="$v.form.responsavel.cep.$model">

              <span class="invalid-feedback" v-if="!$v.form.responsavel.cep.required">Digite o CEP</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="row-split">
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.estado.$anyError }">
              <label>Estado</label>

              <selector :options="estados" v-model="$v.form.responsavel.estado.$model" prop-key="codigoEstado" filter-by="estado" @select="onEstadoSelected">
                <template #option="{ option }">
                  {{ option.estado }}
                </template>
                <template #selected="{ option }">
                  {{ option.estado }}
                </template>
                <template #selection="{ option }">
                  {{ option.estado }}
                </template>
              </selector>

              <span class="invalid-feedback" v-if="!$v.form.responsavel.estado.required">Selecione o estado</span>
            </div>
          </v-col>
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.cidade.$anyError }">
              <label>Cidade</label>

              <selector :options="cidades" v-model="$v.form.responsavel.cidade.$model" prop-key="codigoCidade" filter-by="cidade">
                <template #option="{ option }">
                  {{ option.cidade }}
                </template>
                <template #selected="{ option }">
                  {{ option.cidade }}
                </template>
                <template #selection="{ option }">
                  {{ option.cidade }}
                </template>
              </selector>

              <span class="invalid-feedback" v-if="!$v.form.responsavel.cidade.required">Selecione a cidade</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="row-split">
          <v-col>
            <div class="form-group" :class="{ 'has-error': $v.form.responsavel.endereco.$anyError }">
              <label for="responsavel_endereco">Endereço</label>
              <input id="responsavel_endereco" type="text" class="form-control" v-model.trim="$v.form.responsavel.endereco.$model">

              <span class="invalid-feedback" v-if="!$v.form.responsavel.endereco.required">Digite o endereço</span>
            </div>
          </v-col>
        </v-row>
      </template>

      <v-row class="actions-button" style="margin-top: 2rem">
        <button type="button" class="p-button p-button-secondary">{{ $t('global.previous') }}</button>
        <button type="button" class="p-button p-button-primary" :disabled="$v.$invalid" @click="cancelar">{{ $t('global.continue') }}</button>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { required, requiredIf } from 'vuelidate/lib/validators'
import Selector from "@/shared/Selector/Selector";
import {FluxResource, OfferResource} from "@/api";
import ResumoCompra from "@/screen/Cancelamento/ResumoCompra";
import {mapState} from "vuex";

export default {
  name: "Cancelamento",
  components: {Selector, ResumoCompra},
  data: () => ({
    oferta: {},
    estados: [],
    cidades: [],
    justificativas: [],
    titularContaOpcoes: [
      { label: 'Sim', value: true },
      { label: 'Outro titular', value: false }
    ],
    form: {
      motivo: null,
      justificativa: null,
      banco: null,
      agencia: null,
      conta: null,
      pix: null,
      responsavel: {
        nome: null,
        cpf: null,
        titular: null,
        cep: null,
        estado: null,
        cidade: null,
        endereco: null
      }
    }
  }),
  validations: {
    form: {
      motivo: {required},
      justificativa: {required},
      banco: {
        required: requiredIf(function() {
          return this.dadosBancariosRequired
        })
      },
      agencia: {
        required: requiredIf(function() {
          return this.dadosBancariosRequired
        })
      },
      conta: {
        required: requiredIf(function() {
          return this.dadosBancariosRequired
        })
      },
      responsavel: {
        nome: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        },
        cpf: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        },
        titular: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        },
        cep: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        },
        estado: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        },
        cidade: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        },
        endereco: {
          required: requiredIf(function() {
            return this.dadosBancariosRequired
          })
        }
      }
    }
  },
  computed: {
    ...mapState('cancelamentoStore', ['ofertaInfo']),
    dadosBancariosRequired() {
      if (!this.ofertaInfo.pedidoCompra) return false
      return this.ofertaInfo.pedidoCompra.notaFiscalEmitida
        || (this.ofertaInfo.pedidoCompra.pagamentoAprovado
        && (this.ofertaInfo.pedidoCompra.formaPagamento === 'A' || this.ofertaInfo.pedidoCompra.formaPagamento === 'EF'))
    }
  },
  async mounted() {
    await this.getOferta()
    await this.getJustificativas()
    await this.getEstados()
  },
  methods: {
    async getOferta() {
      const response = await OfferResource.getOfferId(this.$route.params.id)
      this.oferta = response.body
    },
    async getJustificativas() {
      const response = await OfferResource.getJustifys()
      this.justificativas = response.body
    },
    async getEstados() {
      const response = await FluxResource.getEstados()
      this.estados = response.body
    },
    async onEstadoSelected({codigoEstado}) {
      if (!codigoEstado) return
      const response = await FluxResource.getCidades(codigoEstado)
      this.cidades = response.body
    },
    async cancelar() {
      if (!this.$v.$invalid) {
        let payload = {
          cdOfrta: this.oferta.codigo,
          cdJustf: this.form.motivo.codigo,
          obsJustf: this.form.justificativa,
        }
        if (this.dadosBancariosRequired) {
          const { banco, agencia, conta, pix } = this.form
          const { nome, cpf, titular, cep, cidade, endereco } = this.form.responsavel
          payload = {
            ...payload,
            banco,
            agencia,
            conta,
            pix,
            nome,
            cpf,
            titular: titular.value,
            cep,
            cidade,
            endereco
          }
        }
        const response = await OfferResource.postCancelBuy(payload)
        if (response.ok) {
          this.$router.push({
            path: '/home'
          }).catch((e) => {})
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  font: 24px 'HelveticaNeue-Light';
  letter-spacing: 0;
  color: #7D7D7D;
  opacity: 1;
}

.page-content {

  .row-split {
    gap: 10px;

    & > * {
      flex: 1
    }
  }

  @media (max-width: 768px) {
    .row-split {
      flex-direction: column;
    }
  }

  .checkbox {
    width: fit-content;
    font: 16px 'HelveticaNeueLTCom-Roman';
    color: #414141;
    letter-spacing: 0;
    margin-top: 30px;
    display: inline-flex;
    align-items: center;

    & > input[type=checkbox] {
      height: 18px;
      width: 18px;
      margin-right: 8px;
    }

    .text-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.col-xm-12 {
  @media (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.flex-row {
  display: flex;
}

.flex-col {
  flex: 1;
}

@media (max-width: 768px) {
  .flex-row {
    display: block;

    & > .flex-col {
      margin-left: 0;
    }
  }
}

.actions-button {
  justify-content: center;
  margin-block: 20px;
  gap: 20px;
}

@media (max-width:768px) {
  .actions-button {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
</style>
