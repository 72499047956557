<template>
  <v-container>
    <v-row justify="center">
      <v-col md="4" cols="12" class="page-title text-center">Seu cadastro foi criado com sucesso!</v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="4" cols="12" class="page-subtitle text-center">
        Tudo certo! agora você já pode acessar a platoforma
      </v-col>
    </v-row>

    <v-row class="actions-button mt-16">
      <a :href="partnerUrl" target="_blank" class="p-button p-button-primary" style="padding: 1rem 2rem; text-decoration: none">
        Ir para a plataforma
      </a>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {PARTNER_URL} from "@/main";

export default {
  name: "FinishPartnerRegister",
  computed: {
    ...mapState('authStore', ['interestToken']),
    partnerUrl() {
      return `${PARTNER_URL}/perfil`
    }
  },
}
</script>

<style scoped>
.page-title {
  font: 30px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
}

.page-subtitle {
  font: 20px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
}

.actions-button {
  justify-content: center;
  margin-block: 20px;
  gap: 20px;
}

@media (max-width:768px) {
  .actions-button {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
</style>