<template>
  <div>

    <OffersHeader
        :length="offers.length"
        :order-by="orderBy"
        :filter-by="filterBy"
        :title="title"
    />

    <div class="horizontal-scroll-wrapper">
      <div
          v-for="offer in offers"
          :key="offer.codigo"
          class="card"
      >
        <OfferCard :offer="offer"/>
      </div>
    </div>

  </div>
</template>
<script>
import { getImageUrl } from '@/api'
import OfferCard from '@/shared/OfferCard/OfferCardIndex'
import OffersHeader from '@/shared/OfferHeader/OffersHeader'

export default {
  components: {
    OfferCard,
    OffersHeader
  },
  props: {
    offers: {
      type: Array,
    },
    orderBy: {
      type: Boolean,
      default: false
    },
    filterBy: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'HomeIndex.OffersRow.title'
    }
  },
  methods: {
    getImage (endpoint) {
      return getImageUrl(endpoint)
    },
  },
}
</script>
<style scoped>
.horizontal-scroll-wrapper {
  width: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 17px;
  margin-top: 60px;
}

.card {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
</style>