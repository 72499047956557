import dataLayer from '../data-layer'
import { getLoggedUserId, getOfferProperties, getBidTypeFrom } from '../util'

class CheckoutEvents {

  async started(offer, fluxType, bid_value, offer_price) {
    const offerProps = await getOfferProperties(offer)
    const codigoUsuario = getLoggedUserId() || ''
    const url = window.location.origin
    const bid_type = getBidTypeFrom(fluxType)

    const bidValue = this.getBidValue(fluxType, offer_price, bid_value)

    dataLayer.event({
      event: 'Checkout Started',
      user_id: codigoUsuario,
      url: `${url}/#/oferta/${offerProps.offer_id}`,
      bid_type,
      ...offerProps,
      bid_value: bidValue,
    });
  }

  async completed(offer, bid_type, bid_value) {
    const offerProps = await getOfferProperties(offer)
    const codigoUsuario = getLoggedUserId() || ''
    const url = window.location.origin

    dataLayer.event({
      event: 'Checkout Completed',
      user_id: codigoUsuario,
      url: `${url}/#/oferta/${offerProps.offer_id}`,
      bid_type,
      ...offerProps,
      bid_value
    });
  }

  getBidValue(fluxType, offer_price, bid_value) {
    switch (fluxType) {
      case 'PURPOSE':
      case 'AUTO_BID':
      case 'BID':
        return bid_value.string2int().centavos2real()
      default:
        return (offer_price.valorCompreJa || 0).toFixed(2).string2int().centavos2real()
    }
  }

}

export default CheckoutEvents;
