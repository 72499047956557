/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueResource from "vue-resource";
import CookieControl from "./cookie-control";
import HomeApi from "./screen/Home/api";
import FilterApi from "./shared/FilterSidebar/api";
import OfferApi from "./screen/Offer/api";
import HeaderApi from "./shared/HeaderBar/api";
import AuthApi from "./screen/Auth/api";
import NotificationApi from "@/screen/Notification/api";
import InterestApi from "@/screen/Interests/api";
import NegotiationApi from "@/screen/Negotiation/api";
import ProfileApi from "@/screen/Profile/api";
import FavoritesApi from "@/screen/Favorites/api";
import FluxApi from "@/screen/Flux/api";
import DynamicApi from "@/screen/Dynamic/api";
import SubCategoryApi from "@/resources/subcategories/api";
import BrandApi from "@/resources/brands/api";
import ModelApi from "@/resources/models/api";
import SurveyApi from "@/screen/Survey/api";
import PartnerApi from "@/screen/RegisterPartner/api";

Vue.use(VueResource);
const cookieControl = new CookieControl();

// HTTP
// Vue.http.options.crossOrigin = true;
// Vue.http.options.credentials = true;

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};

  if (window.VUE_APP_MODE === "PRD" || window.VUE_APP_MODE === "HMG") {
    // SECURITY HEADERS
    request.headers.set(
      "Strict-Transport-Security",
      "max-age=31536000; includeSubDomains"
    );
    request.headers.set("x-content-type-options", "nosniff");
    if (window.VUE_APP_CSP) {
      request.headers.set("Content-Security-Policy", window.VUE_APP_CSP);
    }
    if (window.VUE_APP_FEATURE) {
      request.headers.set("Feature-Policy", window.VUE_APP_FEATURE);
    }
    if (window.VUE_APP_REFERRER) {
      request.headers.set("Referrer-Policy", window.VUE_APP_REFERRER);
    }
  }

  if (cookieControl.isLogin()) {
    request.headers.set(
      "security-token",
      cookieControl
        .getCookie(cookieControl.tokenCookie)
        .replace(/(^\\")|(\\"$)/g, "")
    );
  }

  // inject idiom
  let idiom = cookieControl.idiom();
  if (idiom) {
    if (request.params.Q) {
      request.params.Q.idiom = idiom;
    } else {
      request.params.Q = {
        idiom: idiom,
      };
    }
  }

  // inject idiom

  let partner = window.PARTNER
  if (partner) {
    if (request.params.Q) {
      request.params.Q.partner = partner
    } else {
      request.params.Q = {
        partner: partner
      }
    }
  }

  // custom headers
  if (request.body && request.body.headers) {
    for (let index in request.body.headers) {
      // noinspection JSUnfilteredForInLoop
      request.headers.set(index, request.body.headers[index]);
    }
    request.body = request.body.body;
  }
  request.headers.set("content-type", "application/json");
  next((response) => {
    if (response.status === 401) {
      cookieControl.signOut();
      if (!window.location.href.includes("auth")) {
        window.location = "/#/home";
      }
    }
  })
})


export const API_URL = window.API_URL || "https://hmgseminovos.aguiabranca.com.br";
export const getImageUrl = (endpoint) => endpoint;
export const HomeResource = HomeApi(Vue.resource, API_URL);
export const OfferResource = OfferApi(Vue.resource, API_URL);
export const HeaderResource = HeaderApi(Vue.resource, API_URL);
export const FilterResource = FilterApi(Vue.resource, API_URL);
export const AuthResource = AuthApi(Vue.resource, API_URL);
export const NotificationResource = NotificationApi(Vue.resource, API_URL);
export const NegotiationResource = NegotiationApi(Vue.resource, API_URL);
export const InterestsResource = InterestApi(Vue.resource, API_URL);
export const ProfileResource = ProfileApi(Vue.resource, API_URL);
export const FavoritesResource = FavoritesApi(Vue.resource, API_URL);
export const FluxResource = FluxApi(Vue.resource, API_URL);
export const DynamicResource = DynamicApi(Vue.resource, API_URL);
export const SubCategoriesResource = SubCategoryApi(Vue.resource, API_URL);
export const BrandResource = BrandApi(Vue.resource, API_URL);
export const ModelResource = ModelApi(Vue.resource, API_URL);
export const SurveyResource = SurveyApi(Vue.resource, API_URL);
export const PartnerResource = PartnerApi(Vue.resource, API_URL);

export default VueResource;
