<template>
  <svg
      height="256"
      viewBox="0 0 256 256"
      width="256"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="placeholder_car"
        transform="translate(-308.962 -800)"
    >
      <g
          id="Retângulo_1009"
          data-name="Retângulo 1009"
          opacity="0"
      >
        <rect
            id="Retângulo_1027"
            data-name="Retângulo 1027"
            fill="#fff"
            height="256"
            transform="translate(308.962 800)"
            width="256"
        />
        <rect
            id="Retângulo_1028"
            data-name="Retângulo 1028"
            fill="none"
            height="255"
            stroke="#707070"
            stroke-width="1"
            transform="translate(309.462 800.5)"
            width="255"
        />
      </g>
      <circle
          id="Elipse_45"
          cx="92"
          cy="92"
          data-name="Elipse 45"
          fill="#e8f1fe"
          r="92"
          transform="translate(344.963 833)"
      />
      <g
          id="Grupo_2234"
          data-name="Grupo 2234"
      >
        <path
            id="Caminho_9127"
            d="M564.954,931.619c-.139-1.831-3.931-12.829-18.592-20.893-13.4-7.368-36.715-6.121-41.68-5.757-4.13-2.439-34.986-20.516-49.183-24.8-14.892-4.5-23.633-4.969-40.03-4.969-2.01,0-4.2,0-6.537-.012-15.578-.04-36.9-.1-46.924,1.622-12.025,2.07-19.092,6.752-31.564,15.54-14.162,9.977-16.816,16.986-17.071,17.753-.469,1.4-3.313,20.435-4.173,26.228-.071.439-1.663,10.755,4.578,12.4,3.86,1.017,13.865,2.926,17.529,3.616a25.672,25.672,0,0,0,50.977,6.1q.087-.676.138-1.358h99.749a25.671,25.671,0,0,0,51.256-1.135,65.273,65.273,0,0,0,10.119-1.743,118.7,118.7,0,0,0,13.117-3.553c4.062-1.638,4.81-5.457,5.256-7.739.076-.39.148-.76.227-1.073.19-.762.623-2.091,1.092-3.534C564.492,934.452,565.033,932.655,564.954,931.619Zm-82.181,17.643a25.693,25.693,0,0,0-.606,3.947H382.422a25.54,25.54,0,0,0-.882-5.013Zm76.916-12.131-.118-.033-.234.721c-2.38.339-7.048,1.054-13.412,2.27a40.846,40.846,0,0,0-14.259,5.642,25.671,25.671,0,0,0-47.648-.32l-103.92-1.1a25.668,25.668,0,0,0-48.086,4.216c-3.65-.688-13.591-2.59-17.252-3.55-2.183-.574-2.114-5.78-1.747-8.072.344-2.313.745-4.991,1.159-7.726h10.434a4.644,4.644,0,0,0,4.376-6.2l-2.813-7.952a6.367,6.367,0,0,0-4.741-4.094l-3.87-.752c1.17-2.127,4.861-7.485,15.1-14.7,12.983-9.145,19.331-13.052,29.995-14.887,9.682-1.666,30.8-1.611,46.283-1.567,2.315.007,4.51.013,6.529.013,16,0,24.516.454,38.914,4.8,14.518,4.384,48.523,24.538,48.855,24.739l.534.317.62-.053c.22-.019,22.218-1.772,36.23,3.484-.595,3.1-1.75,11.594,2.218,16.176,1.922,2.221,5.355,3.347,10.209,3.347a43.06,43.06,0,0,0,7.825-.8,6.563,6.563,0,0,1,.219.8,44.219,44.219,0,0,1-1.52,5.233Zm-202.867-3.808a21.819,21.819,0,0,1,.483,43.633h-.483a21.816,21.816,0,1,1,0-43.633Zm-31.477-9.043a.785.785,0,0,1-.476,1,.755.755,0,0,1-.263.045h-9.848c.821-5.361,1.41-9.1,1.8-11.4l4.136.808a2.482,2.482,0,0,1,1.843,1.591ZM545.756,926c-2.15-2.483-2.2-7.716-1.509-12.025l.253.136c8.336,4.584,12.639,10.105,14.606,13.343C553.871,928.324,547.815,928.375,545.756,926Zm-37.985,50.959a21.576,21.576,0,1,1,.483,0Z"
            data-name="Caminho 9127"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9128"
            d="M487.5,908.807c-10.716-10.159-28.651-27.161-78.923-27.161-27.17,0-44.01,4.784-53.352,8.8-10.429,4.481-14.188,9-14.341,9.189l-.957,1.161.892,1.211c.655.891,3.584,3.163,16.614,5.026,11.512,1.64,32.836,2.158,44.291,2.437l.447.011c1.991.048,3.62.088,4.757.126,7.387.242,79.981,3.955,80.684,3.991l5.266.27-3.848-3.6Zm-4.92.666c-9.979-.512-32.291-1.643-50.215-2.535-.861-6.653-1.4-13.421-1.615-20.134C461.37,890.656,475.071,902.455,482.578,909.473Zm-101.153-22.1a89.014,89.014,0,0,0-3.27,17.447c-8.946-.427-15.548-.958-20.173-1.62-6.677-.953-10.342-1.966-12.346-2.737a44.4,44.4,0,0,1,11.391-6.6A102.193,102.193,0,0,1,381.425,887.376Zm47.022,19.372c-12.164-.6-19.36-.936-21.391-1-1.166-.04-2.851-.081-4.914-.133l-.324-.007c-6.228-.147-12.927-.322-19.8-.606.37-3.819,1.387-12.119,3.657-18.187a189.02,189.02,0,0,1,22.9-1.3c6.078,0,12.236.272,18.3.862A200.38,200.38,0,0,0,428.447,906.748Zm-26.641-.644Zm85.349,3.067Z"
            data-name="Caminho 9128"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9129"
            d="M356.823,966.577a11.433,11.433,0,0,0,0-22.865h0a11.433,11.433,0,0,0,0,22.865Zm-7.575-11.431a7.575,7.575,0,1,1,7.575,7.575A7.584,7.584,0,0,1,349.248,955.146Z"
            data-name="Caminho 9129"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9130"
            d="M519.2,955.144a11.467,11.467,0,0,0-11.433-11.432h0A11.433,11.433,0,1,0,519.2,955.144Zm-3.858,0a7.575,7.575,0,1,1-7.575-7.576A7.592,7.592,0,0,1,515.346,955.146Z"
            data-name="Caminho 9130"
            fill="#9fabbe"
        />
      </g>
      <path
          id="Retângulo_1008"
          d="M331.153,980.331H542.771a2.5,2.5,0,0,1,2.5,2.5h0a2.5,2.5,0,0,1-2.5,2.5H331.153a2.5,2.5,0,0,1-2.5-2.5h0A2.5,2.5,0,0,1,331.153,980.331Z"
          data-name="Retângulo 1008"
          fill="#9fabbe"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'PlaceholderCarIcon'
}
</script>
<style scoped>
</style>
