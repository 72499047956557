const _GoogleApi = () => {
  return {
    async login () {
      await window.gapi.auth2.getAuthInstance().signIn()
      const response = await window.gapi.client.request({
        'path': 'https://www.googleapis.com/oauth2/v1/userinfo',
      })
      const responseToken = await window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token
      try {
        return {
          email: response.result.email,
          id: response.result.id,
          name: response.result.name,
          accessToken: responseToken
        }
      } catch (e) {
        return { error: 'Falha ao conectar.' }
      }
    },
  }
}

const GoogleApi = _GoogleApi()

export default GoogleApi