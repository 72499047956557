<template>
  <div>
    <svg
        :height="height"
        id="svg8"
        viewBox="0 0 5.2916665 5.2916665"
        :width="width"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g
          id="g26"
          transform="matrix(0.26107863,-0.26113459,0.26107863,0.26113459,-2.6107864,2.6113458)"
      >
        <path
            d="M 0,10.132928 H 20"
            :style="`stroke: ${color};`"
            id="path18"
        />
        <path
            d="m 10,0.132928 v 20"
            :style="`stroke: ${color};`"
            id="path22"
        />
      </g>
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'XMarkIcon',
    props: {
      color: {
        type: String,
        default: '#666666'
      },
      height: {
        type: String,
        default: '20'
      },
      width: {
        type: String,
        default: '20'
      }
    },
  }
</script>
<style scoped>

  a {
    opacity: 0;
  }

  .b {
    fill: none;
  }

  .c, .d {
    stroke: none;
  }

  .d {
    fill: #c9cdd0;
  }

  #path18 {
    fill: none;
    stroke: white;
    stroke-width: 1.2px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1;
  }

  #path22 {
    fill: none;
    stroke: white;
    stroke-width: 1.2px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1;
  }
</style>