<template>
  <v-app
      :class="$vuetify.breakpoint.mdAndDown ? 'overflow-hidden' : ''"
  >
    <v-main>
      <router-view :key="$route.path"/>
    </v-main>
  </v-app>
</template>

<script>

  export default {
    name: 'App',

    components: {},

    data: () => ({
      //
    }),
  }
</script>

<style lang="scss">
  .fa, .far, .fas {
    font-family: "Font Awesome 5 Brands", "Font Awesome 5 Free";
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .align-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  .form-group {
    margin-top: 30px;
    width: 100%;

    label {
      display: block;
      margin-bottom: 10px;
      font: 18px 'HelveticaNeue-Medium';
      color: #414141;
      opacity: 1;
    }

    .form-control {
      background: #FFFFFF 0 0 no-repeat padding-box;
      border: 1px solid #D9D9D9;
      border-radius: 6px;
      opacity: 1;
      height: 48px;
      width: 100%;
      font: 16px 'HelveticaNeueLTPro-Roman';
    }

    & .invalid-feedback {
      display: none;
    }

    & .help-text {
      display: flex;
      justify-content: flex-start;
      font: 14px 'HelveticaNeueLTPro-Roman';
      margin-top: 8px;
    }

    &.has-error > .form-control {
      border: 1px solid #F64A14;
    }

    &.has-error > .invalid-feedback {
      display: flex;
      justify-content: flex-end;
      font: 14px 'HelveticaNeueLTPro-Roman';
      color: #F64A14;
      margin-top: 8px;
    }

    & input.form-control {
      padding-left: 17px;

      &::placeholder {
        letter-spacing: 0;
        color: #A7A7A7;
        opacity: 1;
        font: 18px 'HelveticaNeueLTPro-Roman';
      }
    }
  }

  .p-button {
    font: 16px 'HelveticaNeue-Medium';
    width: 211px;
    height: 45px;
    border-radius: 23px;
    opacity: 1;
    cursor: pointer;

    &.p-button-primary {
      background: #CDD43F 0 0 no-repeat padding-box;
      box-shadow: -1px -1px 3px #FFFFFF80;
    }

    &.p-button-secondary {
      background: #FFFFFF 0 0 no-repeat padding-box;
      box-shadow: none;
      border: 1px solid #C9C9C9;
    }

    &:disabled {
      background: #D3D3D3 0 0 no-repeat padding-box;
      cursor: default;
    }
  }

  .detail-container {
    font: 16px 'HelveticaNeue-Medium';
    color: #666666;

    & ul {
      list-style-type: none !important;
      padding-left: 10px !important;

      li {
        list-style-type: none;
        margin-bottom: 10px;
        background: url('assets/check-circle-solid.svg') no-repeat left center;
        padding: 0 0 3px 24px;
      }
    }
  }
</style>
