const state = {}

const mutations = {}

const actions = {}

const getters = {}

const interestsStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default interestsStore
