<template>
  <v-layout
      justify-space-around
      row
      wrap
  >
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card
        class="mx-12 my-12"
        color="transparent"
        elevation="0"
    >
      <v-card-text>
        <div class="login-header">
          <div class="login-title">{{ $t('Auth.login') }}</div>
          <div
              class="login-register"
          >
            {{ $t('Auth.notMember') }}
            <span
                class="login-go-register"
                @click="goToRegister"
            >
              {{ $t('Auth.signup') }}
            </span>
          </div>
        </div>

        <div
            :class="{'hide-error': !showError}"
            class="login-error-message align-content-center"
        >
          {{ showError }}
        </div>

        <v-alert
          v-if="showInputCode"
          text
          icon="$success"
          type="success"
        >
          Um token de acesso foi enviado ao seu e-mail. Por favor, use-o para prosseguir.
        </v-alert>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="signIn"
        >
          <label>
            <div class="label">
              {{ $t('global.mail') }}
            </div>
            <input
                v-model="username"
                class="input-login"
                required
                type="text"
                @keypress.enter="signIn"
                placeholder="Digite seu e-mail cadastrado"
            />

          </label>
          <label>

            <div class="label">
              {{ $t('Auth.password') }}
            </div>
            <input
                v-model="password"
                class="input-login"
                required
                type="password"
                @keypress.enter="signIn"
                placeholder="Digite a sua senha"
            />

          </label>

          <label v-if="showInputCode">
            <div class="label">
              Token
            </div>
            <input
              v-model.number="code"
              class="input-login"
              required
              type="text"
              @keypress.enter="signIn"
              placeholder="Digite o token"
            />
          </label>

          <div
              class="login-forget-password"
              @click="goToForgot"
          >
            {{ $t('Auth.forgotPass') }}
          </div>


          <div
              :class="{'login-button-disabled': !isValid}"
              class="login-button align-content-center"
              style="margin-left: auto; margin-right: auto;"
              @click="signIn"
          >
            <span>
              {{ $t('Auth.loginEnter') }}
            </span>
          </div>

        </v-form>

        <div
            class="columns-social-line"
        >
          <hr
              class="hr-social-line"
          >
          <div
              class="or-social-line"
          >
            {{ $t('global.or') }}
          </div>
          <hr
              class="hr-social-line"
          >
        </div>

        <div class="columns-social-buttons">
          <div
              class="column-social-button"
              @click="loginGoogle"
          >
            <GoogleIcon
                style="margin-right: 15px;"
            />
            {{ $t('Auth.loginGoogle') }}
          </div>
          <div
              class="social-spacer"
          />
          <div
              class="column-social-button"
              @click="loginFacebook"
          >
            <FacebookIcon
                style="margin-right: 15px;"
            />
            {{ $t('Auth.loginFacebook') }}
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
        v-model="error"
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="error"
      >
        <v-row align="center">
          <v-col class="grow">{{ errorMessage || $t('global.incorrectData') }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="error = false"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-dialog
        v-model="success"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="success"
      >
        <v-row align="center">
          <v-col class="grow">{{ $t('Auth.loginSuccess') }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="goToHome"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-layout>
</template>

<script>
import {AuthResource} from '@/api'
import {mapMutations} from 'vuex'
import GoogleIcon from '@/shared/Icons/GoogleIcon'
import FacebookIcon from '@/shared/Icons/FacebookIcon'
import FacebookApi from '@/lib/FacebookApi'
import GoogleApi from '@/lib/GoogleApi'
import { sessionEvents, signupEvents } from '@/analytics'

export default {
  name: 'Login',
  components: {FacebookIcon, GoogleIcon},
  data() {
    return {
      captchaResult: '',
      loginRules: [
        value => !!value || this.$t('global.required'),
        value => (value || '').length <= 40 || this.$t('global,maxChar', {count: 40}),
        value => (value || '').length >= 3 || this.$t('global,minChar', {count: 3}),
      ],
      passwordRules: [
        value => !!value || this.$t('global.required'),
      ],
      testCaptcha: [
        value => (this.captcha[0] + this.captcha[1]) === value.string2int() || this.$t('global.invalidResult')
      ],
      valid: false,
      username: '',
      password: '',
      code: null,
      error: false,
      errorMessage: '',
      success: false,
      divHeight: 450,
      captcha: [13, 13],
      showError: '',
      isLoading: false,
      redirectUrl: null,
      showInputCode: false,
    }
  },
  computed: {
    // captchaLabel () {
    //   return `${this.captcha[0]} + ${this.captcha[1]}`
    // },
    isValid() {
      return this.username && this.password
    }
  },
  mounted() {
    this.redirectUrl = this.$route.query['redirectFrom']
  },
  methods: {
    ...mapMutations('authStore',
        ['SET_USER', 'SIGN_OUT']
    ),
    async loginGoogle() {
      this.showError = ''
      const userData = await GoogleApi.login()

      if (userData.error) {
        return this.showError = this.$t('global.loginFail')
      }
      const response = await AuthResource.postLoginGoogle({
        idSocial: userData.id,
        tokenSocial: userData.accessToken
      })

      if (response.ok === true) {
        this.SET_USER(response.data)
        this.loginSuccessGA(response.data)
        this.goToHome()
      } else {
        this.showError = response.body ? response.body.erros[0] : this.$t('global.loginFail')
        this.loginFailureGA()
      }
    },
    async loginFacebook() {
      this.showError = ''
      const userData = await FacebookApi.login()

      if (userData.error) {
        return this.showError = this.$t('global.loginFail')
      }
      const response = await AuthResource.postLoginFacebook({
        idSocial: userData.id,
        tokenSocial: userData.accessToken
      })

      if (response.ok === true) {
        this.SET_USER(response.data)
        this.loginSuccessGA(response.data)
        this.goToHome()
      } else {
        this.showError = response.body ? response.body.erros[0] : this.$t('global.loginFail')
        this.loginFailureGA()
      }

    },

    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max))
    },

    async signIn() {
      await this.login()
      // if (this.showInputCode) {
      //   await this.login()
      // } else {
      //   await this.requestToken()
      // }
    },

    async requestToken() {
      if (!this.username || !this.password) {
        return this.showError = this.$t('Auth.requiredFieldsLogin')
      }
      this.showInputCode = false
      this.showError = ''

      this.isLoading = true
      const response = await AuthResource.requestAcessCode({
        login: this.username,
        password: this.password
      })
      this.isLoading = false

      if (response.ok) {
        this.showInputCode = true;
      } else {
        this.showError = response.body ? response.body.erros[0] : this.$t('global.loginFail')
      }
    },

    async login() {
      if (!this.username || !this.password) {
        return this.showError = this.$t('Auth.requiredFieldsLogin')
      }
      this.showError = ''

      this.isLoading = true
      const response = await AuthResource.postLogin({
        login: this.username,
        password: this.password,
      })
      this.isLoading = false

      if (response.ok) {
        this.SET_USER(response.data)

        this.loginSuccessGA(response.data)

        if (response.data.alteraPassword === 'S') {
          this.goToPassword()
        } else if (this.redirectUrl) {
          window.location = this.redirectUrl
        } else {
          this.goToHome()
        }

      } else {
        this.showError = response.body ? response.body.erros[0] : this.$t('global.loginFail')
        this.loginFailureGA()
      }
    },

    goToHome() {
      this.success = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/`
      })
    },

    goToPassword() {
      this.success = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/senha`
      })
    },

    goToForgot() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/esqueceu-senha`
      })
    },

    goToRegister() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/registrar`
      })
    },

    loginFailureGA() {
      sessionEvents.login({ user_id: '', status: 'Falha' })
    },

    loginSuccessGA({ codigoUsuario, firstSignIn }) {
      sessionEvents.login({ user_id: `${codigoUsuario}`, status: 'Sucesso' })

      if (firstSignIn) {
        signupEvents.verified({ user_id: `${codigoUsuario}`, status: 'Sucesso' })
      }
    },

  }
}
</script>

<style
    scoped
    type="text/css"
>
.label {
  font: 18px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 8px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #A7A7A7;
  opacity: 1;
  letter-spacing: 0;
  font: 18px 'HelveticaNeueLTPro-Roman';
}
::-moz-placeholder { /* Firefox 19+ */
  color: #A7A7A7;
  opacity: 1;
  letter-spacing: 0;
  font: 18px 'HelveticaNeueLTPro-Roman';
}
:-ms-input-placeholder { /* IE 10+ */
  color: #A7A7A7;
  opacity: 1;
  letter-spacing: 0;
  font: 18px 'HelveticaNeueLTPro-Roman';
}
:-moz-placeholder { /* Firefox 18- */
  color: #A7A7A7;
  opacity: 1;
  letter-spacing: 0;
  font: 18px 'HelveticaNeueLTPro-Roman';
}

.input-login {
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  height: 48px;
  width: 630px;
  padding-left: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 3px 3px 3px #FBFBFB;
  border: 1px solid #D9D9D9;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

.login-header {
  display: flex;
  margin-bottom: 20px;
  flex-flow: row wrap;
}

.login-title {
  font: 30px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #666666;
  flex: 1;
}

.login-register {
  flex: 0;
  width: fit-content;
  color: #666666;
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  white-space: nowrap;
  margin-top: 19px;
}

.login-go-register {
  color: #60BED4;
  cursor: pointer;
  font: 13px 'HelveticaNeue-Medium';
}

/*noinspection CssUnusedSymbol*/
.hide-error {
  visibility: hidden;
}

.login-forget-password {
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #60BED4;
  margin-bottom: 30px;
  cursor: pointer;
}

.login-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 3px #FFFFFF80;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 18px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-button > span {
  display: block;
  line-height: 1;
}

.login-button-disabled {
  color: #2D2D2D !important;
  background: #CDD43F 0 0 no-repeat padding-box !important;
  opacity: 0.3;
}

.columns-social-line {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  text-align: center;
  font: 14px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #C9CDD0;

  margin-bottom: 30px;
}

.hr-social-line {
  flex: 1;
  border: 1px solid #000000;
  opacity: 0.1;
}

.or-social-line {
  flex: 0;
  margin-left: 19px;
  margin-right: 19px;
  font: 14px 'HelveticaNeueLTPro-Roman';
  color: #8B8B8B;
}

.columns-social-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.column-social-button {
  flex: 1;
  border-radius: 3px;
  min-width: 195px;
  height: 46px;

  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;

  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 2px solid #8B8B8B4D;
  opacity: 1;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.login-error-message {
  min-height: 27px;
  background: #D85757 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #FFFFFF;
  padding: 6px;
}

.social-spacer {
  width: 20px;
}

@media screen and (max-width: 601px) {
  .input-login {
    width: 100%;
  }

  .login-header {
    margin-bottom: 20px;
  }

  /*noinspection CssUnusedSymbol*/
  .hide-error {
    visibility: unset;
    display: none;
  }

  .social-spacer {
    flex: 1;
    min-width: 100%;
    height: 20px;
  }
}

</style>
