import dataLayer from '../data-layer';

class SessionEvents {

  login({ user_id, status}) {
    dataLayer.event({
      event: 'Login',
      user_id: user_id || '',
      status: status,
    });
  }

  logout({ user_id, status}) {
    dataLayer.event({
      event: 'Logout',
      user_id: user_id || '',
      status: status,
    });
  }

}

export default SessionEvents;
