import { render, staticRenderFns } from "./OfferSideBar.vue?vue&type=template&id=45aa541a&scoped=true"
import script from "./OfferSideBar.vue?vue&type=script&lang=js"
export * from "./OfferSideBar.vue?vue&type=script&lang=js"
import style0 from "./OfferSideBar.vue?vue&type=style&index=0&id=45aa541a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45aa541a",
  null
  
)

export default component.exports