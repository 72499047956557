<template>
  

<svg xmlns="http://www.w3.org/2000/svg" width="30" height="41" viewBox="0 -5 30 41">
  <path id="car-chassis-svgrepo-com" d="M49.2,10.093h1.329A2.24,2.24,0,0,0,52.78,7.874V2.219A2.24,2.24,0,0,0,50.525,0H49.2A2.24,2.24,0,0,0,46.94,2.219V4.256H41.326a3.26,3.26,0,0,0-6.291,0H29.421V2.219A2.24,2.24,0,0,0,27.165,0H25.836A2.24,2.24,0,0,0,23.58,2.219V7.874a2.24,2.24,0,0,0,2.256,2.219h1.329a2.24,2.24,0,0,0,2.255-2.219V5.837h5.614a3.232,3.232,0,0,0,2.342,2.3V22.9a2.553,2.553,0,0,0-1.783,2.418v1.712H29.421V24.994a2.24,2.24,0,0,0-2.255-2.219H25.836a2.24,2.24,0,0,0-2.256,2.219v5.655a2.24,2.24,0,0,0,2.256,2.219h1.329a2.24,2.24,0,0,0,2.255-2.219V28.612h6.173v1.712a2.586,2.586,0,0,0,5.172,0V28.612h6.173v2.037A2.24,2.24,0,0,0,49.2,32.868h1.329a2.24,2.24,0,0,0,2.256-2.219V24.994a2.24,2.24,0,0,0-2.256-2.219H49.2a2.24,2.24,0,0,0-2.256,2.219v2.037H40.766V25.319A2.553,2.553,0,0,0,38.984,22.9V8.141a3.232,3.232,0,0,0,2.342-2.3h5.614V7.874A2.24,2.24,0,0,0,49.2,10.093ZM27.813,7.874a.643.643,0,0,1-.648.637H25.836a.644.644,0,0,1-.648-.637V2.219a.644.644,0,0,1,.648-.637h1.329a.643.643,0,0,1,.648.637V7.874Zm0,22.775a.643.643,0,0,1-.648.637H25.836a.644.644,0,0,1-.648-.637V24.994a.644.644,0,0,1,.648-.637h1.329a.643.643,0,0,1,.648.637v5.655Zm20.734-5.655a.644.644,0,0,1,.648-.637h1.329a.644.644,0,0,1,.648.637v5.655a.643.643,0,0,1-.648.637H49.2a.644.644,0,0,1-.648-.637Zm-9.388,5.33a.972.972,0,0,1-.978.962h0a.972.972,0,0,1-.979-.962v-5a.979.979,0,0,1,1.957,0ZM38.237,6.656l-.057,0-.057,0a1.639,1.639,0,1,1,.114,0Zm10.31-4.437a.644.644,0,0,1,.648-.637h1.329a.644.644,0,0,1,.648.637V7.874a.643.643,0,0,1-.648.637H49.2a.644.644,0,0,1-.648-.637Z" transform="translate(-23.58)" fill="#a7a7a7"/>
</svg>

</template>
<script>
export default {
  name: 'TractionIcon',
  props: {
    color: {
      type: String,
      default: '#4caf50'
    },
    height: {
      type: String,
      default: '42'
    },
    width: {
      type: String,
      default: '42'
    }
  },
}
</script>
<style scoped>


</style>