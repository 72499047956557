<template>
  <div v-if="content">
    <DynamicScreen
        :content="content"
    />
  </div>
</template>

<script>
import {DynamicResource} from "@/api";
import DynamicScreen from "@/screen/Dynamic/DynamicScreen";

export default {
  name: 'Dynamic',
  components: {DynamicScreen},
  data() {
    return {
      pages: {
        empresa: 'TELA_EMPRESA',
        vix: 'TELA_VIX',
        termos_app: 'TELA_TERMOS_APP',
        termos: 'TELA_TERMOS_SITE',
        pessoa: 'TELA_PESSOA_FISICA',
        lojista: 'TELA_LOJISTA',
        colaborador: 'TELA_COLABORADOR',
        condicaouso: 'TELA_TERMOS_CONDUSO',
        vendas: 'TELA_POLITICA_VENDAS',
        avisoslegais: 'TELA_AVISO_LEGAL',
        mapa: 'TELA_MAPA_DO_SITE',
      },
      pageId: '',
      content: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const page = this.$route.params.page
      this.pageId = this.pages[page]
      if (!this.pageId) return

      const response = await DynamicResource.getDynamic(this.pageId)
      this.content = response.body
    }
  },
}
</script>

<style scoped>

</style>
