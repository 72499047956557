<template>
  <label>
    <v-card
      class="pa-4 question-option"
      :class="{ 'is-selected': selected }"
      outlined
      tile
    >
      <v-row align-content="center" justify="start">
        <div class="input-container">
          <input type="radio" name="option" :value="value" @change="handleInput">
          <span class="checkmark"></span>
          <span class="question-option__label">{{ label }}</span>
        </div>
      </v-row>
    </v-card>
  </label>
</template>

<script>
export default {
  name: 'QuestionOption',
  props: ['value', 'label', 'selected'],
  model: {
    prop: 'checked',
    event: 'change'
  },
  methods: {
    handleInput (e) {
      const value = this.value
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.question-option {
  font: 16px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  background: #F5F5F7 0 0 no-repeat padding-box;
  border-radius: 10px;

&.is-selected {
   background: #60BED4 0 0 no-repeat padding-box;
   color: #FFF;
 }

&__label {
   margin-left: 40px;
 }

.input-container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.2), 1px 1px 1px #FFFFFF;
}

/* When the checkbox is checked, add a blue background */
.input-container input:checked ~ .checkmark {
  background-color: #60BED4;
  box-shadow: -1px -1px 1px #00000014, 1px 1px 1px rgba(255, 255, 255, 0.2);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
}
</style>