<template>
  <div>
    <vue-slider
        v-bind="options"
        @change="updateValue()"
        v-model="myValue"
    >
      <template v-slot:tooltip="{value, index}">
        <div
            :class="index === 0 ? 'vue-toolbar-left' : 'vue-toolbar-right' "
            class="vue-slider-dot-tooltip-inner"
        >
          <p v-if="tipo === 'km'">{{tooltipFormatter2(value)}}</p>
          <p v-else>{{tooltipFormatter(value)}}</p>
        </div>
      </template>

    </vue-slider>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'SliderTest',
  props: {
    tipo: {
      type: String,
      default: 'money'
    },
    min: {
      type: Number,
      default: 0
    },
      max: {
        type: Number,
        default: 999999
      },
      value: {
        type: Array,
        default: () => [0, 0]
      }
    },
    components: {
      VueSlider
    },
    data () {
      return {
        myValue: [0, 10]
      }
    },
    mounted () {
      this.myValue = this.value
    },
    computed: {
      options () {
        const leftTooltip = this.value[0] + (this.max * 0.7) >= this.value[1] ? 'bottom' : 'top'

        return {
          dotSize: 14,
          width: 'auto',
          height: 4,
          contained: false,
          direction: 'ltr',
          data: null,
          min: this.min,
          max: this.max,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: 'always',
          tooltipPlacement: ['top', leftTooltip],
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange: void 0,
          maxRange: void 0,
          order: true,
          marks: false,
          dotOptions: void 0,
          process: true,
          dotStyle: void 0,
          railStyle: void 0,
          processStyle: void 0,
          tooltipStyle: void 0,
          stepStyle: void 0,
          stepActiveStyle: void 0,
          labelStyle: void 0,
          labelActiveStyle: void 0,
        }
      }
    },
    watch: {
      value (newValue, oldValue) {
        if (this.myValue === newValue) return
        this.myValue = newValue
      }
    },
    methods: {
      updateValue () {
        this.$emit('update', this.myValue)
      },
      tooltipFormatter (v) {
        try{
         if(this.value[0] + (this.max * 0.7) >= this.value[1]){
          document.getElementsByClassName('vue-toolbar-right')[0].children[0].className='down'
          document.getElementsByClassName('vue-toolbar-right')[2].children[0].className='down'

         }else{
          document.getElementsByClassName('vue-toolbar-right')[0].children[0].className=''
          document.getElementsByClassName('vue-toolbar-right')[2].children[0].className=''

         }

        }catch (e) {
          // declarações para manipular quaisquer exceções
        }
        return v >= this.max ? this.$t('global.unlimited') : v.float2Money()
      },
      tooltipFormatter2 (v) {
        try{
          if(this.value[0] + (this.max * 0.7) >= this.value[1]){
          document.getElementsByClassName('vue-toolbar-right')[1].children[0].className='down'
          document.getElementsByClassName('vue-toolbar-right')[3].children[0].className='down'
        }else{
          document.getElementsByClassName('vue-toolbar-right')[1].children[0].className=''
          document.getElementsByClassName('vue-toolbar-right')[3].children[0].className=''
         }
        }catch (e) {
          // declarações para manipular quaisquer exceções
        }
        if (v >= this.max) return this.$t('global.unlimited')
        v = v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        v = v + ' km'
        return v
        // return v >= this.max ? 'Ilimitado' :  + ' km'
      }
    }
  }
</script>
<style>
  .vue-toolbar-left {
    transform: translate(0, 0);
  }

  .vue-toolbar-left > p {
    transform: translate(50%, 0);
    margin-bottom: 0;
  }

  .vue-toolbar-right > p {
    transform: translate(-50%, 0);
    margin-bottom: 0;
  }

  .down{
      transform: translate(50%, 0) !important
}

  .vue-slider-dot-tooltip-top::after {
    content: "•";
    margin-left: 50%;
    font: 11px 'HelveticaNeueLTPro-Md';
    letter-spacing: 0;
    color: #666666;
  }

  .vue-slider-rail {
    height: 3px;
    background: #D8DEE2 0 0 no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }

  .vue-slider-process {
    background: #60BED4 0 0 no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    height: 3px;
  }

  .vue-slider-dot-tooltip-bottom::before {
    content: "•";
    margin-left: 50%;
    font: 11px 'HelveticaNeueLTPro-Md';
    letter-spacing: 0;
    color: #666666;

  }

  .vue-slider-dot-handle {
    width: 14px;
    height: 14px;
    background: #F5F5F7 0 0 no-repeat padding-box;
    box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #A3B1C6;
    opacity: 1;
  }

  .vue-slider-dot-tooltip-inner {
    /*transform: translate(0, -75%);*/
    border-radius: 5px;
    border-color: transparent;
    background-color: transparent;
    font: 11px 'HelveticaNeueLTPro-Md';
    letter-spacing: 0;
    color: #666666;
    opacity: 1;
  }
</style>