<template>
  <svg class="building-icon" :class="{'small': small}" v-if="active" xmlns="http://www.w3.org/2000/svg" width="108" height="96" viewBox="0 0 108 96">
    <path id="Icon_material-store-mall-directory" data-name="Icon material-store-mall-directory" d="M106.5,6h-96V18h96Zm6,60V54l-6-30h-96l-6,30V66h6v36h60V66h24v36h12V66Zm-54,24h-36V66h36Z" transform="translate(-4.5 -6)" fill="#FFF"/>
  </svg>
  <svg class="building-icon" :class="{'small': small}" v-else xmlns="http://www.w3.org/2000/svg" width="108" height="96" viewBox="0 0 108 96">
    <path id="Icon_material-store-mall-directory" data-name="Icon material-store-mall-directory" d="M106.5,6h-96V18h96Zm6,60V54l-6-30h-96l-6,30V66h6v36h60V66h24v36h12V66Zm-54,24h-36V66h36Z" transform="translate(-4.5 -6)" fill="#a7a7a7"/>
  </svg>
</template>
<script>
export default {
  name: 'PartnerIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
  },
}
</script>
<style scoped>
  .building-icon {
    height: 80px;
    width: 90px;
  }

  .building-icon.small {
    height: 40px !important;
    width: 40px !important;;
  }
</style>