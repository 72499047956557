<template>
  <div>
    <div class="footer-container" v-if="!isMobileHideHeader" id="footer">

      <div class="footer-column">
        <div class="footer-column-title">{{ $t('footerBar.vehicles') }}</div>
        <ul class="footer-column-list footer-column-list__vehicles">
          <li v-for="(category, i) in categories" :key="i" @click="goToLink(category.codigo)">{{ category.name }}</li>
        </ul>
      </div>

      <div class="footer-column-container">
        <div class="footer-column">
          <div class="footer-column-title">{{ $t('footerBar.clientes') }}</div>
          <ul class="footer-column-list">
            <li @click="goToText('lojista')">{{ $t('footerBar.lojistas') }}</li>
            <li @click="goToText('empresa')">{{ $t('footerBar.empresa') }}</li>
            <li @click="goToText('colaborador')">{{ $t('footerBar.colaborador') }}</li>
            <li @click="goToText('pessoa')">{{ $t('footerBar.pessoaFisica') }}</li>
          </ul>
        </div>

        <div class="footer-column">
          <div class="footer-column-title">Vix</div>
          <ul class="footer-column-list">
            <li @click="goToText('vix')">{{ $t('footerBar.sobreVIX') }}</li>
            <li @click="openInNewTab('https://www.vix.com.br/')">{{ $t('footerBar.VIXLogistica') }}</li>
            <li @click="openInNewTab('https://vagas.vix.com.br/jobs')">{{ $t('footerBar.trabalheConosco') }}</li>
          </ul>
        </div>
      </div>

      <div class="footer-column">
        <div class="footer-column-title">{{ $t('footerBar.contato') }}</div>
        <ul class="footer-column-list">
          <li @click="phone1">{{ $t('HomeIndex.contact.phone1') }}</li>
          <li @click="phone2">{{ $t('HomeIndex.contact.phone2') }}</li>
          <li @click="phone3">{{ $t('HomeIndex.contact.phone3') }}</li>
          <li @click="mailto">{{ $t('HomeIndex.contact.mailto') }}</li>
        </ul>
      </div>

    </div>

    <div class="footer-copyright">
      <p>{{ $t('footerBar.textFooter') }}</p>
      <p class="footer-copyright-margin">Copyright © 2020 Vix Logística S/A. {{ $t('footerBar.rights') }}. CNPJ:
        {{ $t('footerBar.cnpj') }}</p>
    </div>

    <hr class="footer-line">

    <div class="footer-private-policy-columns">
      <div
        class="footer-private-policy-column"
        @click="goToText('condicaouso')"
      >
        <p>{{ $t('footerBar.privacidade') }}</p>
      </div>
      <p>|</p>
      <div
        class="footer-private-policy-column"       
        @click="redirect('https://portaldeprivacidade.vix.com.br/')" 
      >     
        <p>{{ $t('footerBar.portalPrivacidade') }}</p>  
      </div>
      <p>|</p>
      <div
        class="footer-private-policy-column"
        @click="goToPrivacyPolicy"
      >
        <p>{{ $t('footerBar.politicaPrivacidade') }}</p>
      </div>      
      <p>|</p>    
      <div
        class="footer-private-policy-column"
        @click="goToPartnerPrivacyPolicy"
      >   
      <p>{{ $t('footerBar.politicaPrivacidadeParceiro') }}</p>
      </div>   
     
        
     
    </div>
     
    <ContactSeller/>

  </div>

</template>
<script>
import { HeaderResource } from '@/api'
import { mapState } from 'vuex'
import ContactSeller from '@/shared/ContactSeller/ContactSeller'

export default {
  name: 'FooterBar',
  components: {ContactSeller},
  computed: {
    ...mapState('headerStore', ['hideHeader']),
    isMobileHideHeader() {
      return this.hideHeader && this.$vuetify.breakpoint.xsOnly
    },
  },
  data () {
    return {
      categories: [],
      homeBase: {
        name: 'Home',
        codigo: null
      },
      contacts: {
        phone1: '5527995041005',
        text1: 'Ol%C3%A1!%20Estava%20navegando%20na%20sessão%20de%20automóveis%20e%20utilitários%20no%20site%20e%20gostaria%20de%20atendimento',
        phone2: '5527995041005',
        text2: 'Ol%C3%A1!%20Estava%20navegando%20na%20sessão%20de%20máquinas%20e%20ônibus%20no%20site%20e%20gostaria%20de%20atendimento',
        phone3: '5527995041005',
        text3: 'Ol%C3%A1!%20Estava%20navegando%20na%20sessão%20de%20caminhões%20e%20implementos%20no%20site%20e%20gostaria%20de%20atendimento'
      },
      showPartnerPrivatePolicy: false    
    }
  },
  mounted () {
    this.init()
    this.showPartnerPrivatePolicy = window.PARTNER !== undefined
  },
  methods: {
    init () {
      this.getCategories()
    },
    async getCategories() {
      const responseHeaders = await HeaderResource.getList()
      this.categories = responseHeaders.body.slice(0, 7).reverse()
      this.categories.push(this.homeBase)
    },
    goToLink(link) {
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch(() => {
      })
      this.$router.go({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch(() => {
      })
    },
    goToText(link) {
      const path = `/texto/${link}`
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch(() => {
      })
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    phone1(e) {
      e.stopPropagation()
      e.preventDefault()
      window.open(this.whatsappUrl(this.contacts.phone1, this.contacts.text1 ))
    },
    phone2(e) {
      e.stopPropagation()
      e.preventDefault()
      window.open(this.whatsappUrl(this.contacts.phone2, this.contacts.text2))
    },
    phone3(e) {
      e.stopPropagation()
      e.preventDefault()
      window.open(this.whatsappUrl(this.contacts.phone3, this.contacts.text3))
    },
    whatsapp(e) {
      e.stopPropagation()
      e.preventDefault()
      let value = this.$t('HomeIndex.contact.whatsapp')
      value = value.onlyNumber().length === 13 ? value.onlyNumber() : `55${value.onlyNumber()}`
      window.open(`https://api.whatsapp.com/send?phone=${value}`)
    },
    whatsappUrl(number, text) {
      return `https://api.whatsapp.com/send?phone=${number}&text=${text}`
    },
    mailto(e) {
      e.stopPropagation()
      e.preventDefault()
      let value = this.$t('HomeIndex.contact.mailto')
      window.open(`mailto:${value}`)
    },
     redirect(link) {
      window.location.href = link
    },
    goToPrivacyPolicy() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/politica-privacidade`
      })
    },
    goToPartnerPrivacyPolicy() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/politica-privacidade-parceiro`
      })
    }
  },
}
</script>
<style
    scoped
    type="text/css"
    lang="scss"
>

.footer-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding-inline: 1rem;
  background: #F5F5F7 0 0 no-repeat padding-box;
}

.footer-column {

  .footer-column-title {
    font: 11px 'HelveticaNeue-Bold';
    margin-bottom: 15px;
  }

  .footer-column-list {
    list-style: none;
    padding-left: 0 !important;

    li {
      font: 11px HelveticaNeueLTPro-Lt;
      color: #8b8b8b;
      cursor: pointer;
      margin-bottom: 15px;
    }
  }
}

.footer-column-container {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 701px) {
  .footer-container {
    gap: 5rem;
  }

  .footer-column-list__vehicles {
    column-count: 2;
  }

  .footer-column-container {
    flex-direction: row;
    gap: 5rem;
  }
}

.footer {
  background: #F5F5F7 0 0 no-repeat padding-box;
  opacity: 1;
  padding-top: 40px;
}

.columns-container {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.footer-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-width: 653px;
}

/*.footer-column {*/
/*  flex: 1;*/
/*}*/

/*.footer-column:last-child {*/
/*  margin-right: 0;*/
/*}*/

.footer-group-title {
  text-align: left;
  font: 11px 'HelveticaNeueLTPro-Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  color: #2D2D2D;
  margin-bottom: 7px;
}

.footer-group-link {
  text-align: left;
  font: 11px 'HelveticaNeueLTPro-Lt';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 7px;
  cursor: pointer;
}

.footer-copyright {
  margin-top: 60px;
  font: 11px 'HelveticaNeueLTPro-Roman';
  text-align: center;
  letter-spacing: 0;
  color: #8B8B8B;
  opacity: 1;
  padding-left: 25px;
  padding-right: 25px;
}

.footer-copyright-margin {
  margin-top: 4px;
}

.footer-copyright > p {
  margin-bottom: 0;
}

.footer-line {
  border: 0.5px solid #cccccc;
  margin-top: 16px;
  width: 82vw;
  margin-bottom: 20px;
  margin-left: 9vw;
}

.footer-private-policy-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
  font: 11px 'HelveticaNeueLTPro-Lt';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  color: #8B8B8B;
  opacity: 1;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 60px;
}

.footer-private-policy-column {
  cursor: pointer;
  max-width: fit-content;
  margin-left: 5px;
  margin-right: 5px;
  flex: 1;
}

.footer-policy-column > p {
  margin-bottom: 0;
}

.footer-policy-column:last-child {
  margin-right: 0;
}

.float-icon > span {
  flex: 1;
  font: 11px 'HelveticaNeueLTPro-Lt';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  color: #FFFFFF;
  margin-left: 6px;
}

.footer-categories-list {
  display: flex;
  flex-flow: column-reverse;
  flex-wrap: wrap-reverse;
  max-height: 80px;
}

.footer-column-correction {
  margin-right: 37px;
}

.footer-group-title-correction {
  margin-left: -32px;
}

@media screen and (max-width: 701px) {
  .footer {
    padding-left: 5px;
    padding-right: 5px;
  }

  .footer-columns {
    flex-flow: column wrap;
    min-width: 100%;
    max-height: 300px;
  }

  .footer-column-space{
    margin-top: 20px;
  }

  .footer-categories-list {
    max-height: 100%;
  }

  .footer-column-correction {
    margin-right: 0;
  }

  .footer-group-title {
    margin-bottom: 15px;
  }

  .footer-group-link {
    margin-bottom: 15px;
  }

  .footer-copyright-margin {
    margin-top: 15px;
  }

  .footer-copyright {
    margin-top: 25px;
  }

  .footer-policy-column {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  .footer-group-title-correction {
    margin-left: 0;
  }
}

</style>