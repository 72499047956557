<template>
  <div class="page-content">
    <v-container>
      <v-row justify="space-between" align="baseline" class="mb-5">
        <div class="page-title">
          {{ title }}
        </div>
        <div class="enter-here">
          {{ $t('Auth.isMember') }} <a href="/#/auth">{{ $t('Auth.enterHere') }}</a>
        </div>
      </v-row>

      <v-row>
        <client-type ref="ClientType" v-if="activeScreen === 'ClientType'"></client-type>
        <register-user ref="RegisterUser" v-if="activeScreen === 'RegisterUser'"></register-user>
        <company-type ref="CompanyType" v-if="activeScreen === 'CompanyType'"></company-type>
        <register-company ref="RegisterCompany" v-if="activeScreen === 'RegisterCompany'"></register-company>
      </v-row>

      <v-row class="actions-button">
        <button type="button" class="p-button p-button-secondary" v-if="canPrevious" @click="previous">{{ $t('Auth.previous') }}</button>
        <button type="button" class="p-button p-button-primary" v-if="isRegisterScreen" @click="register">{{ $t('Auth.register') }}</button>
        <button type="button" class="p-button p-button-primary" v-else @click="next" :disabled="!canNext">{{ $t('Auth.next') }}</button>
      </v-row>

      <v-dialog
          persistent
          v-model="success"
          width="500"
      >
        <v-alert
            class="mb-0"
            prominent
            type="success"
        >
          <v-row align="center">
            <v-col class="grow">{{ $t('Auth.registerSuccess') }}</v-col>
            <v-col class="shrink">
              <v-btn
                  @click="goToInterests"
                  text
              >
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ClientType from '@/screen/Auth/ClientType'
import CompanyType from '@/screen/Auth/CompanyType'
import RegisterCompany from '@/screen/Auth/RegisterCompany'
import RegisterUser from '@/screen/Auth/RegisterUser'
import {mapMutations} from 'vuex'

export default {
  name: 'RegisterFrame',
  components: {RegisterUser, RegisterCompany, CompanyType, ClientType},
  data: () => ({
    screens: [
      'ClientType',
      'RegisterUser',
      'CompanyType',
      'RegisterCompany'
    ],
    activeScreen: 'ClientType',
    lastScreen: 'ClientType',
    step: 0,
    success: false
  }),
  computed: {
    canNext() {
      return this.step < this.screens.length
    },
    canPrevious() {
      return this.step > 0
    },
    isRegisterScreen() {
      return this.activeScreen === 'RegisterUser' || this.activeScreen === 'RegisterCompany'
    },
    title() {
      const activeScreen = this.activeScreen
      if (activeScreen === 'RegisterUser') {
        return this.$t('Auth.registerUser')
      } else if (activeScreen === 'RegisterCompany') {
        return this.$t('Auth.registerCompany')
      }
      return this.$t('global.register')
    },
  },
  methods: {
    ...mapMutations('authStore', ['SET_CLIENT_TYPE', 'SET_COMPANY_TYPE']),
    goToAuth() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/registrar`
      })
    },
    next() {
      const save = this.$refs[this.activeScreen].save()
      this.lastScreen = this.activeScreen

      if (this.activeScreen === 'ClientType') {
        if (save === 'PESSOA_FISICA') {
          this.SET_CLIENT_TYPE(7)
          this.activeScreen = 'RegisterUser'
          this.step += 1
        } else if (save === 'PESSOA_JURIDICA') {
          this.activeScreen = 'CompanyType'
          this.step += 1
        }
        return
      }

      if (this.activeScreen === 'CompanyType') {
        if (save === 'GERAL') {
          this.SET_COMPANY_TYPE(4)
        } else if (save === 'LOJISTA') {
          this.SET_COMPANY_TYPE(5)
        }
        this.step += 1
        this.activeScreen = 'RegisterCompany'
      }
    },
    async register() {
      if (this.activeScreen === 'RegisterUser' || this.activeScreen === 'RegisterCompany') {
        const result = await this.$refs[this.activeScreen].save()

        if (result === 'success') {
          this.success = true
        }
      }
    },
    previous() {
      this.activeScreen = this.lastScreen
      this.step -= 1
    },
    goToInterests () {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/interesses`
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .page-content {
    margin: 28px auto 0 auto;

    @media (min-width:768px) {
      margin: 30px auto 0 auto;
      width: 70%;
    }

    .actions-button {
      justify-content: center;
      margin-block: 20px;
      gap: 20px;
    }

    @media (max-width:768px) {
      .actions-button {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }

    .page-title {
      font: 30px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #666666;
      opacity: 1;
    }

    .enter-here {
      font: 13px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #666666;
      opacity: 1;

      a {
        text-decoration: none;
        color: #60BED4;
      }
    }

    //@media (min-width:320px) {
    //  width: 320px;
    //}
    //@media (min-width:480px) {
    //  width: 480px;
    //}
    //@media (min-width:600px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
    //@media (min-width:801px) { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
    //@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
    //@media (min-width:1281px) { /* hi-res laptops and desktops */ }

  }


</style>
