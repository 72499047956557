<template>
  <div
      class="item-category"
  >  
    <div v-if="selected" class="item-category-box-selected" >
      <p class="item-text">{{ category.name }}</p>
    </div>
    <div v-else class="item-category-box" >
      <p class="item-text">{{ category.name }}</p>
    </div>

  </div>
</template>
<script>
export default {
  name: 'ItemCategory',
  props: {
    category: {
      type: Object
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
    methods: {
      
    },
  }
</script>
<style scoped>

.item-category {
  /*grid-template-columns: auto auto;*/
  font: 16px 'HelveticaNeueLTPro-Roman';
  font-weight: bold;
  color: #2D2D2D;
  height: 40px;
  margin-left: 18px;
  column-gap: 8px;
}


.item-category-box {
  /*display: flex;*/
  align-items: center;
  justify-items: center;
  border-left: 20px;
  vertical-align: middle;
  padding-left: 41px; 
  padding-top: 10px;
  height: 100%;
}

.item-category-box-selected {
  /*display: flex;*/
  align-items: center;
  justify-items: center;
  border-left: 20px solid #CDD43F;
  vertical-align: middle;
  padding-left: 21px;
  padding-top: 10px;
  height: 100%;
  background: #FAFAFB 0 0 no-repeat padding-box;
}

.item-text{
  text-align: left;  
}

</style>
