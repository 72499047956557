<template>
  <article v-if="mobile" class="user-type-card" :class="{ 'selected': active }" @click="$emit('click', type)">
    <slot></slot>

    <div class="user-type-card__content">
      <span class="user-type-card__content_title">{{ title }}</span>
      <span class="user-type-card__content_subtitle" v-if="subtitle">{{ subtitle }}</span>
    </div>
  </article>

  <article v-else class="user-type-card user-type-card--desktop" :class="{ 'selected': active }" @click="$emit('click', type)">
    <span class="user-type-card__content_title">{{ title }}</span>
    <div style="display: flex; flex-grow: 2; align-items: center">
      <slot></slot>
    </div>
    <span class="user-type-card__content_subtitle" v-if="subtitle">{{ subtitle }}</span>
    <span v-else style="height: 16px; visibility: hidden"></span>
  </article>
</template>

<script>
export default {
  name: 'UserTypeCard',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    type: {
      type: String
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>

.user-type-card {
  display: flex;
  align-items: center;
  width: 283px;
  height: 74px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  padding: 15px;
  color: #A7A7A7;
  letter-spacing: 0;
  cursor: pointer;

  .user-type-card__content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;


    .user-type-card__content_title {
      font: 14px 'HelveticaNeue-Bold';
    }

    .user-type-card__content_subtitle {
      font: 10px 'HelveticaNeue-Medium';
    }
  }

  &.selected {
    color: #FFFFFF;
    background: #60BED4 0 0 no-repeat padding-box;
  }

  &.user-type-card--desktop {
    flex-direction: column;
    width: 216px;
    height: 222px;

    .user-type-card__content_title {
      font: 18px 'HelveticaNeue-Bold';
    }

    .user-type-card__content_subtitle {
      font: 16px 'HelveticaNeue-Medium';
    }
  }

}

//.user-type-card {
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: space-evenly;
//  font: 18px 'HelveticaNeue-Bold';
//  color: #A7A7A7;
//  //width: 125px;
//  //height: 128px;
//  background: #F5F5F7 0 0 no-repeat padding-box;
//  box-shadow: -1px -1px 1px #FFFFFF, 1px 1px 3px rgb(167, 167, 167, .5);
//  border-radius: 3px;
//  opacity: 1;
//  cursor: pointer;
//
//  width: 283px;
//  height: 74px;
//
//  & .card-title {
//    display: flex;
//    flex-grow: 1;
//    align-items: center;
//  }
//
//  & .subtitle {
//    display: flex;
//    flex-grow: 1;
//    font-size: 9px;
//  }
//
//  & .card-slot {
//    display: flex;
//    flex-grow: 2;
//    align-items: center;
//  }
//
//  &.active {
//    color: #FFFFFF;
//    background: #60BED4 0 0 no-repeat padding-box;
//  }
//}
//
///* Medium devices (landscape tablets, 768px and up) */
//@media only screen and (min-width: 768px) {
//  .user-type-card {
//    width: 216px;
//    height: 222px;
//    font: 18px 'HelveticaNeue-Bold';
//
//    .subtitle {
//      font-size: 16px;
//    }
//  }
//}
</style>
