const SurveyApi = (vueResource, url) => {
  const resource = vueResource(`${url}/survey{/ID}{/LANCE}{?Q*}`)
  const resourceAnswer = vueResource(`${url}/survey{/ID}{/LANCE}{?Q*}`)
  
  return {
    async getSurvey(guid, lance) {
      try {
        return await resource.get({ ID: guid, LANCE: lance })
      } catch (e) {
        return e
      }
    },
    async postSurveyAnswers(guid, lance, answers) {
      try {
        return await resourceAnswer.save({ ID: guid, LANCE: lance }, answers)
      } catch (e) {
        return e
      }
    },
  }
}

export default SurveyApi
