<template>
  <v-container>
    <v-row>
      <v-col md="4" cols="12" class="page-title">Verifique seu email</v-col>
    </v-row>
    <v-row>
      <v-col md="4" cols="12" class="page-subtitle">Para verificar seu email, entre com o código recebido</v-col>
    </v-row>

    <v-row>
      <v-col md="4" cols="12">
        <v-otp-input
          v-model="otp"
          @finish="onFinish"
        ></v-otp-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4" cols="12" class="page-subtitle">
        Não recebeu seu código? <a href="#" style="color: #60BED4">Reenviar</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'PartnerTokenVerifier',
  data: () => ({
    otp: '',
  }),
  methods: {
    ...mapActions('registerPartnerStore', ['VERIFY_TOKEN']),
    async onFinish(token) {
      await this.VERIFY_TOKEN(token)
    }
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  font: 32px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
}

.page-subtitle {
  font: 16px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #565656;
  opacity: 1;
}
</style>
