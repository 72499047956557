<template>
  <div class="wrapper" :class="{ 'active': active, 'disabled': disabled }">
    <div class="select-btn" @click="toggle">
      <span v-if="selected" style="color: initial;">
        <slot name="selected" v-bind:option="selected">
          <!-- Fallback content -->
          {{ selected }}
        </slot>
      </span>
      <span v-else> {{ placeholder }} </span>
      <i v-if="active" class="fa fa-angle-up"></i>
      <i v-else class="fa fa-angle-down"></i>
    </div>

    <div class="content">
      <div class="search">
        <i class="fa fa-search"></i>
        <input ref="searchInput" type="text" v-model="search" @keyup="event => search = event.target.value">
      </div>
      <ul class="options">
        <li v-if="isEmpty" class="message-item">Sem resultados...</li>
        <template v-else>
          <li v-for="option in filtered" :key="computeKey(option)" @click="pick(option)">
            <slot name="option" v-bind:option="option">
              <!-- Fallback content -->
              {{ option }}
            </slot>
          </li>
        </template>
      </ul>
    </div>

    <table class="table table-multiselect" v-if="multi">
      <thead>
      <tr>
        <th class="text-right">
          <i class="fa fa-sort" style="color: #7A80DD;" aria-hidden="true"></i>
        </th>
        <th style="width: 50px;"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!selections.length">
        <td class="text-center" colspan="2">
          Nenhum registro selecionado
        </td>
      </tr>
      <template v-else>
        <tr v-for="option in selections" :key="computeKey(option)">
          <td>
            <slot name="selection" v-bind:option="option">
              <!-- Fallback content -->
              {{ option }}
            </slot>
          </td>
          <td class="text-center">
            <i class="fa fa-times clickable" style="color: red;" @click="removeItem(option)"></i>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Selector',
  props: {
    value: {
      default: () => null
    },
    multi: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: () => 'Selecione...'
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => []
    },
    propKey: {
      type: String,
      default: () => null
    },
    filterBy: {
      type: String,
      default: () => null
    },
  },
  mounted() {
    const el = this.$el
    const vm = this
    window.addEventListener('mouseup', function (e) {
      if (e.target !== el && !el.contains(e.target)) {
        vm.active = false
      }
    });
  },
  data: () => ({
    active: false,
    selected: null,
    search: null,
    selections: [],
    refilter: 0,
  }),
  methods: {
    toggle() {
      this.active = !this.active
      if (this.active) {
        window.setTimeout(() => {
          this.$refs.searchInput.focus()
        }, 250);
      }
    },
    pick(option) {
      this.active = false
      this.search = null

      if (this.multi) {
        this.selections.push(option)
        this.$emit('input', this.selections)
        this.$emit('select', this.selections)
      } else {
        if (this.selected !== option) {
          this.selected = option
          this.$emit('input', this.selected)
          this.$emit('select', this.selected)
        }
      }
    },
    computeKey(option) {
      if (this.propKey) return option[this.propKey]
      return option
    },
    filterMulti(option) {
      if (!this.multi) return true

      return !this.selections.some((selection, _, __) => {
        if (this.propKey) {
          return selection[this.propKey] === option[this.propKey]
        }
        return selection === option
      })
    },
    filterSearch(option) {
      if (this.filterBy) {
        return option[this.filterBy].toLowerCase().includes(this.search.toLowerCase())
      }
      return option.toLowerCase().includes(this.search.toLowerCase())
    },
    removeItem(option) {
      const index = this.selections.findIndex(s => {
        if (this.propKey) {
          return s[this.propKey] === option[this.propKey]
        }
        return s === option
      })
      this.selections.splice(index, 1)
      this.$emit('input', this.selections)
    }
  },
  computed: {
    filtered() {
      this.selections;
      const vm = this;
      if (vm.search) {
        return vm.options
            .filter(this.filterSearch)
            .filter(this.filterMulti)
      } else {
        return vm.options.filter(this.filterMulti)
      }

    },
    isEmpty() {
      return this.options.length === 0
    }
  },
}
</script>

<style scoped>
.wrapper {
  max-width: 100%;
  position: relative;
  background: #fff;
}

.wrapper.disabled {
  cursor: not-allowed;
}

.wrapper.disabled .select-btn {
  pointer-events: none;
  background-color: #eee;
}

.select-btn {
  padding: .5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  height: 48px;
}

.select-btn span {
  color: #999999;
}

.content {
  display: none;
  background: #fff;
  width: 100%;
  border-right: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}

.wrapper.active .content {
  display: block;
  position: absolute;
  z-index: 300;
}

.wrapper.active .select-btn {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search input {
  border: 1px solid #e5e5e5;
  width: 100%;
  line-height: 20px;
  padding: 6px 6px !important;
  min-height: 26px;
  outline: 0;
  color: #333333;
  margin: 0;
  background-color: #fff !important;
}

.search {
  position: relative;
  padding: .5rem;
}
.search i {
  position: absolute;
  right: 0;
  top: 30%;
  padding-inline: 1rem;
  color: #999999;
}

.options {
  margin-block-start: 0;
  padding-inline-start: .5rem;
  margin-bottom: 0;
}

.options li {
  list-style-type: none;
  padding: .5rem;
}

.options li:hover {
  background: #eee;
  cursor: pointer;
}

.options {
  max-height: 200px;
  overflow: auto;
}

.options li.message-item:hover {
  background: #fff;
  cursor: initial;
}
</style>