<template>
  <div>
    <v-row class="question-text" align-content="center">
      <span>{{ question.enunciado }}</span>
    </v-row>

    <v-row justify="center" v-if="question.tipo === 'TEXTO_LIVRE'">
      <v-col
        cols="12"
        sm="6"
      >
        <v-textarea
          append-icon="mdi-comment"
          filled
          auto-grow
          counter
          v-model="form.text"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row justify="center" v-else>
      <v-col
        v-for="opcao in question.opcoes"
        :key="opcao.id"
        cols="12"
        sm="6"
      >
        <question-option
          :value="opcao"
          :label="opcao.descricao"
          v-model="form.selected"
          :selected="form.selected.valor === opcao.valor">
        </question-option>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuestionOption from "@/screen/Survey/QuestionOption";

const getDefaultForm = () => {
  return {
    selected: {
      valor: null
    },
    text: null,
  }
}

export default {
  name: 'Question',
  props: {
    question: {
      type: Object
    },
  },
  components: {
    QuestionOption
  },
  data: () => ({
    form: getDefaultForm(),
  }),
  methods: {
    save() {
      const isTextoLivre = this.question.tipo === 'TEXTO_LIVRE'
      const saved = {
        text: this.form.text,
        option: this.form.selected,
        question: this.question,
        isText: isTextoLivre
      }

      this.form = getDefaultForm()

      return saved
    },
  },
}
</script>

<style scoped>
  .question-text {
    font: 36px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
    margin-bottom: 50px;
    margin-top: 30px;
  }

  .question-option {
    font: 16px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
  }
</style>
