import AuthIndex from './AuthIndex'
import Login from './Login'
import Forgot from './Forgot'
import Confirm from './Confirm'
import Password from './Password'
import RegisterFrame from '@/screen/Auth/RegisterFrame'
import RegisterFinished from '@/screen/Auth/RegisterFinished'

const AuthRouter = {
  path: 'auth/',
  component: AuthIndex,
  // redirect: ''
  children: [
    {
      path: '',
      name: 'Login',
      component: Login
    },
    {
      path: 'registrar',
      name: 'Registrar',
      component: RegisterFrame
    },
    {
      path: 'cadastro-finalizado',
      name: 'CadastroFinalizado',
      component: RegisterFinished
    },
    {
      path: 'esqueceu-senha',
      name: 'Esqueceu-senha',
      component: Forgot
    },
    {
      path: 'confirmar/:type?',
      name: 'Confirmar',
      component: Confirm
    },
    {
      path: 'senha',
      name: 'Senha',
      component: Password
    },
  ]
}
export default AuthRouter