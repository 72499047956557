<template>
  <div
      class="image-box relative"
  >
    <video
        v-if="picture.indexOf('vimeo.com') === -1"
        height="68px"
        width="106px"
    >
      <source
          :src="picture"
          type="video/webm"
      >
    </video>
    <div
        v-else
        style="height: 68px; width: 106px; background: black"
    />
    <div
        class="absolute"
        style="top: 11px; left: 30px;"
    >
      <PlayIcon/>
    </div>
  </div>
</template>
<script>
import PlayIcon from '../../shared/Icons/PlayIcon'

export default {
  name: 'VideoThumb',
  components: { PlayIcon },
  props: {
    picture: {
      type: String,
      default: ''
    }
  }
}
</script>
<style
    scoped
    type="text/css"
>

.image-box {
  width: 106px;
  height: 68px;
  background: #000;
}


</style>