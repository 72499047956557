<template>
<v-container>

  <template v-if="hasError">
    <v-alert
      v-for="error in errors"
      :key="error"
      color="red"
      type="error"
      dense
    >
      {{ error }}
    </v-alert>
  </template>

  <v-row class="row-split">
    <v-col>
      <div class="form-group" :class="{ 'has-error': $v.form.nome.$anyError }">
        <label for="nome_responsavel">{{ $t('global.firstName') }}</label>
        <input id="nome_responsavel" type="text" class="form-control" v-model.trim="$v.form.nome.$model">

        <span class="invalid-feedback" v-if="!$v.form.nome.required">Digite seu nome</span>
      </div>
    </v-col>
    <v-col>
      <div class="form-group" :class="{ 'has-error': $v.form.cpf.$anyError }">
        <label for="cpf">{{ $t('Auth.cpf') }}</label>
        <input id="cpf" type="text" class="form-control" v-model.trim="$v.form.cpf.$model" maxlength="11">

        <span class="invalid-feedback" v-if="!$v.form.cpf.required">Digite seu CPF</span>
        <span class="invalid-feedback" v-if="!$v.form.cpf.numeric">Digite apenas números</span>
        <span class="invalid-feedback" v-if="!$v.form.cpf.minLength">Deve conter 11 dígitos</span>
      </div>
    </v-col>
  </v-row>

  <v-row class="row-split">
    <v-col>
      <div class="form-group" :class="{ 'has-error': $v.form.email.$anyError }">
        <label for="email">{{ $t('global.mail') }}</label>
        <input id="email" type="email" class="form-control" v-model.trim="$v.form.email.$model">

        <span class="invalid-feedback" v-if="!$v.form.email.required">Digite seu e-mail</span>
        <span class="invalid-feedback" v-if="!$v.form.email.email">Digite um e-mail válido</span>
      </div>
    </v-col>
    <v-col>
      <div class="form-group" :class="{ 'has-error': $v.form.telefone.$anyError }">
        <label for="telefone">{{ $t('Auth.phone') }}</label>
        <input id="telefone" type="tel" class="form-control" v-model.trim="$v.form.telefone.$model">

        <span class="invalid-feedback" v-if="!$v.form.telefone.required">Digite seu telefone</span>
        <span class="invalid-feedback" v-if="!$v.form.telefone.numeric">Digite apenas números</span>
      </div>
    </v-col>
  </v-row>

  <v-row class="row-split">
    <v-col>
      <div class="form-group" :class="{ 'has-error': $v.form.password.$anyError }">
        <label for="password">{{ $t('Auth.password') }}</label>
        <input id="password" type="password" class="form-control" v-model.trim="$v.form.password.$model">

        <ul class="password-requirements-list" role="list">
          <li class="password-requirement">
            <check-icon class="requirement-icon" :size="14" v-if="$v.form.password.minLength && $v.form.password.required"/>
            <times-icon class="requirement-icon" :size="14" v-if="!$v.form.password.minLength || !$v.form.password.required"/>
            No mínimo {{$v.form.password.$params.minLength.min}} caracteres
          </li>
          <li class="password-requirement">
            <check-icon class="requirement-icon" :size="14" v-if="$v.form.password.atLeast1Low"/>
            <times-icon class="requirement-icon" :size="14" v-if="!$v.form.password.atLeast1Low"/>
            Ao menos um caractere minúsculo
          </li>
          <li class="password-requirement">
            <check-icon class="requirement-icon" :size="14" v-if="$v.form.password.atLeast1Upp"/>
            <times-icon class="requirement-icon" :size="14" v-if="!$v.form.password.atLeast1Upp"/>
            Ao menos um caractere maiúsculo
          </li>
          <li class="password-requirement">
            <check-icon class="requirement-icon" :size="14" v-if="$v.form.password.atLeast1Num"/>
            <times-icon class="requirement-icon" :size="14" v-if="!$v.form.password.atLeast1Num"/>
            Ao menos um caractere numérico
          </li>
          <li class="password-requirement">
            <check-icon class="requirement-icon" :size="14" v-if="$v.form.password.atLeast1Esp"/>
            <times-icon class="requirement-icon" :size="14" v-if="!$v.form.password.atLeast1Esp"/>
            Ao menos um caractere especial
          </li>
        </ul>

      </div>
    </v-col>
    <v-col>
      <div class="form-group" :class="{ 'has-error': $v.form.passwordRepeat.$anyError }">
        <label for="password_repeat">{{ $t('Auth.repeatPassword') }}</label>
        <input id="password_repeat" type="password" class="form-control" v-model.trim="$v.form.passwordRepeat.$model">

        <span class="invalid-feedback" v-if="!$v.form.passwordRepeat.sameAsPassword">Senha não confere</span>
      </div>
    </v-col>
  </v-row>

  <v-row>
    <label class="checkbox">
      <input type="checkbox" value="true" v-model="$v.form.terms.$model">
      <span :style="{ color: !$v.form.terms.checked && $v.form.terms.$anyDirty ? '#F64A14' : 'inherit' }">Eu concordo com os <span class="text-link" @click.stop.prevent="goToTerm">termos de uso</span> e <span class="text-link" @click.stop.prevent="goToPrivacyPolicy">políticas de privacidade</span>.</span>
    </label>
  </v-row>
  
  <v-row>
    <label class="checkbox">
      <input type="checkbox" value="true" v-model="form.consentEmails">
      <span>Eu aceito receber e-mails com promoções e informações da Vix Seminovos</span>
    </label>
  </v-row>

  <v-dialog
    v-model="termDialog"
    width="80vw"
  >
    <div class="dialog-card-content">
      <div class="card-padding">
        <div class="dialog-info-header">
          <div class="dialog-info-title"/>
          <div
              class="dialog-info-close"
              @click="termDialog = false"
          >
            <XMarkIcon/>
          </div>
        </div>
        <div class="dialog-content">
          <div class="dialog-info-container">
            <DynamicScreen
                :content="termContent"
                style="padding: 0;"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>

  <v-dialog
    v-model="termDialog"
    width="80vw"
  >
    <div class="dialog-card-content">
      <div class="card-padding">
        <div class="dialog-info-header">
          <div class="dialog-info-title"/>
          <div
              class="dialog-info-close"
              @click="termDialog = false"
          >
            <XMarkIcon/>
          </div>
        </div>
        <div class="dialog-content">
          <div class="dialog-info-container">
            <DynamicScreen
              :content="termContent"
              style="padding: 0;"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>

  <v-dialog
    v-model="privacyPolicyDialog"
    width="80vw"
  >
    <div class="dialog-card-content">
      <div class="card-padding">
        <div class="dialog-info-header">
          <div class="dialog-info-title"/>
          <div
              class="dialog-info-close"
              @click="privacyPolicyDialog = false"
          >
            <XMarkIcon/>
          </div>
        </div>
        <div class="dialog-content">
          <div class="dialog-info-container">
            <DynamicScreen 
                :content="privacyPolicyContent"
                style="padding: 0;"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>

</v-container>
</template>

<script>
import DynamicScreen from '@/screen/Dynamic/DynamicScreen'
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import TimesIcon from '@/shared/Icons/TimesIcon'
import CheckIcon from '@/shared/Icons/CheckIcon'

import { required, email, sameAs, minLength, numeric } from 'vuelidate/lib/validators'
import {AuthResource, DynamicResource} from '@/api'
import {signupEvents} from '@/analytics'
import {mapMutations} from 'vuex'

export default {
  name: 'RegisterUser',
  components: { DynamicScreen, XMarkIcon, TimesIcon, CheckIcon},
  mounted() {
    this.init()
  },
  data: () => ({
    termDialog: false,
    termContent: undefined,
    privacyPolicyDialog: false,
    privacyPolicyContent: undefined,
    hasError: false,
    errors: null,
    social: null,
    userData: null,
    form: {
      nome: null,
      email: null,
      cpf: null,
      telefone: null,
      password: null,
      passwordRepeat: null,
      terms: false,
      consentEmails: false
    },
  }),
  validations: {
    form: {
      nome: {
        required
      },
      email: {
        required,
        email
      },
      cpf: {
        required,
        numeric,
        minLength: minLength(11)
      },
      telefone: {
        required,
        numeric
      },
      password: {
        required,
        minLength: minLength(8),
        atLeast1Low: value => {
          if (!value) return false
          return /[a-z]/.test(value);
        },
        atLeast1Upp: value => /[A-Z]/.test(value),
        atLeast1Num: value => /\d/.test(value),
        atLeast1Esp: value => /[!@#$%*]/.test(value),
      },
      passwordRepeat: {
        sameAsPassword: sameAs('password')
      },
      terms: {
        checked: value => value === true
      }
    }
  },
  methods: {
    ...mapMutations('authStore', ['SET_INTEREST_TOKEN']),
    async init() {
      const termResponse = await DynamicResource.getTermSite()
      this.termContent = termResponse.body
      const termPolicyResponse = await DynamicResource.getTermSitePoliticaPrivacidade()
      this.privacyPolicyContent = termPolicyResponse.body     
    },
    async save() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.hasError = true
        return 'error'
      } else {
        const response = await AuthResource.postRegisterPessoaFisica({
          'nome': this.form.nome,
          'email': this.form.email,
          'cpf': this.form.cpf.onlyNumber(),
          'telefone': this.form.telefone.onlyNumber(),
          'password': this.form.password,
          'aceitaEmails': this.form.consentEmails
        })

        if (response.ok) {
          this.hasError = false
          this.SET_INTEREST_TOKEN(response.data.data)
          signupEvents.completed({ user_id: '', status: 'Sucesso' })
          return 'success'
        } else {
          this.errors = response.status >= 400 && response.status < 500 ? response.body.erros : [this.$t('global.serverError')]
          this.hasError = true
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
          signupEvents.completed({ user_id: '', status: 'Falha' })
          return 'error'
        }
      }
    },
    goToTerm () {
      this.termDialog = true
    },
    goToPrivacyPolicy () {
      this.privacyPolicyDialog = true
    }
  },
}
</script>

<style lang="scss" scoped>
.page-content {

  .row-split {
    gap: 10px;

    & > * {
      flex: 1
    }
  }

  @media (max-width: 768px) {
    .row-split {
      flex-direction: column;
    }
  }
  
  .checkbox {
    width: fit-content;
    font: 16px 'HelveticaNeueLTCom-Roman';
    color: #414141;
    letter-spacing: 0;
    margin-top: 30px;
    display: inline-table;
    align-items: center;

    & > input[type=checkbox] {
      height: 18px;
      width: 18px;
      margin-right: 8px;
    }

    .text-link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.col-xm-12 {
  @media (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.flex-row {
  display: flex;
}

.flex-col {
  flex: 1;
}

@media (max-width: 768px) {
  .flex-row {
    display: block;

    & > .flex-col {
      margin-left: 0;
    }
  }
}

.password-requirements-list {
  list-style-type: none;
  padding-left: .5rem !important;
  margin-top: 1rem;

  & > li {
    font: 14px 'HelveticaNeueLTCom-Roman';
    display: flex;
    align-items: center;

    & .requirement-icon {
      margin-right: .5rem;
      flex: 0 0 4%;
    }
  }
}

.dialog-info-header {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: nowrap;
}

.dialog-info-title {
  text-align: left;
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  flex: 1;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
}

.dialog-card-content {
  background: #F5F5F7;
  box-shadow: 0 3px 15px #000000C7;
  border-radius: 4px;
  opacity: 1;
  min-height: 80vh;
  max-height: fit-content;
  padding: 20px;
}

.dialog-content {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
}

.dialog-info-container {
  display: flex;
  flex-flow: row nowrap;
}
</style>