<template>
  <div
      class="notification"
      :class="{'notification-mobile-background': !showNotification }"
  >

    <div
        :class="{'hide': !showNotification }"
        class="filter-header-back"
    >
      <div @click="close">
        <BackArrowIcon
            :show-circle="false"
        />
      </div>
    </div>

    <div
        :class="{'hide': showNotification }"
        class="notification-sidebar-container"
    >
      <div
          class="notification-sidebar"
      >
        <div
            class="notification-sidebar-title"
        >
          {{ $t('HeaderBar.notifications') }}
        </div>

        <div>
          <div
              :key="i"
              v-for="( notification, i ) in notifications"
          >
            <div
                :class="{'notification-sidebar-card-date-margin-top': i > 1}"
                class="notification-sidebar-card-date"
                v-if="i === 0 || getDate(notification) !== getDate(notifications[i-1])"
            >
              {{ getDate(notification) }}
            </div>
            <div
                :class="{'notification-sidebar-card-selected': i===selected}"
                :style="i < notifications ? '':'border-bottom: 0px'"
                @click="selectNotification(i)"
                class="notification-sidebar-card"
            >
              <div
                  :class="{'notification-sidebar-card-title-selected': i===selected}"
                  class="notification-sidebar-card-title"
              >
                {{ notification.tituloNotificacao }}
              </div>

              <div
                  :class="{'notification-sidebar-card-time-selected': i===selected}"
                  class="notification-sidebar-card-time"
              >
                {{ getHour(notification) }}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="notification-content"
        :class="{'hide': !showNotification }"
    >
      <div
          class="notification-box"
      >
        <div
            class="notification-content-title"
        >
          {{ selectedNotification.tituloNotificacao }}
        </div>
        <div
            class="notification-content-timestamp"
            v-html="timestamp"
        />

        <div
            class="notification-content-text"
        >
         <p v-html="description"></p>
        </div>

        <div
            @click="download(selectedNotification.guid, selectedNotification.nomeArquivo)"
            class="notification-content-attachment align-content-center"
            v-if="selectedNotification.nomeArquivo"
        >
          <Placeholder
              :text="selectedNotification.nomeArquivo.substr(selectedNotification.nomeArquivo.lastIndexOf('.')+1) "
              color="#FFF"
              height="35"
              only-title="true"
              style="margin-right: 12px;"
              width="31"
          />
          <div
              class="notification-content-attachment-name"
          >
            <div>{{ selectedNotification.nomeArquivo.length > 20 ? selectedNotification.nomeArquivo.substr(0,19)+"..."+selectedNotification.nomeArquivo.substr(selectedNotification.nomeArquivo.lastIndexOf('.')+1) : selectedNotification.nomeArquivo }}</div>
            <div
                class="notification-content-attachment-type"
            >
              {{ selectedNotification.nomeArquivo.substr(selectedNotification.nomeArquivo.lastIndexOf('.')+1) }} • {{ selectedSize }}
            </div>
          </div>

          <DownloadIcon
              class="cursor-pointer"
          />

        </div>

        <a
            ref="downloader"
            style="display: none;"
        >DOWNLOAD AREA</a>

      </div>

      <div class="notification-sender">
        <VixLogo
            style="margin-bottom: 24px;"
        />
        <div class="notification-sender-title">
          {{ $t('footerBar.VIXSeminovos') }}
        </div>
        <div class="notification-sender-phone">
          {{ $t('HomeIndex.contact.phone1') }}
        </div>
        <div class="notification-sender-phone">
          {{ $t('HomeIndex.contact.phone2') }}
        </div>
        <div class="notification-sender-phone">
          {{ $t('HomeIndex.contact.phone3') }}
        </div>
        <div class="notification-sender-email">
          {{ $t('HomeIndex.contact.mailto') }}
        </div>
        <div class="notification-sender-address">
          {{ $t('global.vixAddress') }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Placeholder from '@/shared/Placeholder/Placeholder'
import DownloadIcon from '@/shared/Icons/DownloadIcon'
import VixLogo from '@/shared/Icons/VixLogo'
import { getImageUrl, NotificationResource } from '@/api'
import moment from 'moment'
import 'moment/locale/pt-br'
import { mapMutations } from 'vuex'
import BackArrowIcon from '@/shared/Icons/BackArrowIcon'

export default {
  name: 'NotificationIndex',
  components: { BackArrowIcon, VixLogo, DownloadIcon, Placeholder },
  data () {
    return {
      selected: 0,
      notifications: [],
      showContent: false
    }
  },
  watch: {
    isMobile () {
      this.showContent = false
      this.SET_HIDE_HEADER(false)
    }
  },
  computed: {
    description () {
      if (this.selectedNotification.descricaoNotificacao) {
        return this.selectedNotification.descricaoNotificacao
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('`', '"')
            .replaceAll('&amp;', '&')
      }
      return ""
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    showNotification () {
      if (!this.$vuetify.breakpoint.xs) {
        return false
      }
      return this.showContent
    },
    selectedSize () {
      let size = this.selectedNotification.tamanho
      let times = 0
      while (size > 100) {
        size = size / 1028
        times++
      }
      return size.toFixed(1) + this.getSigla(times)
    },
    selectedNotification () {
      if (this.notifications.length === 0) return {}
      return this.notifications[this.selected]
    },
    timestamp () {
      const mDate = moment(this.selectedNotification.dataInicio)
      const date = mDate.format('DD/MM/YYYY')
      const time = mDate.format('HH:mm')
      return `${date}<span style="margin-left: 16px;">${time}</span>`
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('headerStore', ['SET_HIDE_HEADER']),
    async init () {
      this.SET_HIDE_HEADER(false)
      const response = await NotificationResource.getList()
      this.notifications = response.body
      if (!this.isMobile) {
        await this.selectNotification(0)
      }
    },
    close () {
      this.SET_HIDE_HEADER(false)
      this.showContent = false
    },
    async selectNotification (index) {
      this.selected = index

      this.showContent = true
      this.SET_HIDE_HEADER(true)

      if (this.notifications.length === 0) return
      await NotificationResource.postNotification({
        'codigoNotificacao': this.selectedNotification.codigoNotificacao
      })
    },
    async download (guid, name) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', getImageUrl(guid))
      xhr.responseType = 'arraybuffer'

      xhr.onload = () => {
        const blob = new Blob([xhr.response], { type: 'octet/stream' })
        const url = window.URL.createObjectURL(blob)
        this.$refs.downloader.href = url
        this.$refs.downloader.download = name
        this.$refs.downloader.click()
        window.URL.revokeObjectURL(url)

      }
      xhr.send()
    },
    getHour (notification) {
      let output = ''
      const notificationDate = moment(notification.dataInicio)
      if (notificationDate.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        const inp = moment().unix() - notificationDate.unix()
        if (inp < 3600) {
          output = Math.ceil(inp / 60) + ' min'
        } else {
          output = Math.ceil((inp / 60) / 60) + 'h'
        }
      } else {
        output = notificationDate.format('HH:mm')
      }

      return output
    },
    getDate (notification) {
      let output = ''
      const notificationDate = moment(notification.dataInicio)

      if (notificationDate.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        output = this.$t('global.today')
      } else if (notificationDate.format('DD/MM/YYYY') === moment().subtract(1, 'day').format('DD/MM/YYYY')) {
        output = this.$t('global.yesterday')
      } else {
        output = notificationDate.format('ddd, D')
        output += ' de '
        output += notificationDate.format('MMMM YYYY')
      }

      return output
    },
    getSigla (times) {
      switch (times) {
        case 0:
          return 'b'
        case 1:
          return 'Kb'
        case 2:
          return 'Mb'
        case 3:
          return 'Gb'
        case 4:
          return 'Tb'
      }
    }
  },
}
</script>

<style type="text/css">

.notification {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.notification-sidebar {
  width: 373px;
  height: 743px;
  background: #fbfbfb;
  word-break: break-all;
}

.notification-sidebar-title {
  text-align: left;
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 55px;
  padding-left: 36px;
  padding-top: 36px;
}

.notification-sidebar-card {
  padding: 17px 20px 16px 36px;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #c8cccf;
  cursor: pointer;
}

.notification-sidebar-card-selected {
  background: #60BED4 0 0 no-repeat padding-box;
}

.notification-sidebar-card-title {
  width: 251px;
  white-space: normal;
  font: 12px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #666666;
  margin-right: 40px;
}

.notification-sidebar-card-time {
  font: 11px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #C9CDD0;
  white-space: nowrap;
}

.notification-sidebar-card-title-selected {
  color: #FFFFFF;
}

.notification-sidebar-card-time-selected {
  color: #FFFFFF;
}

.notification-sidebar-card-date {
  margin-bottom: 11px;
  text-align: left;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #B4B4B4;
  margin-left: 36px;
}

.notification-sidebar-card-date-margin-top {
  margin-top: 18px;
}

.notification-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-evenly;
  background: #f5f5f7;
  padding-top: 36px;
}

.notification-content-title {
  text-align: left;
  font: 25px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 12px;
  word-break: break-all;
}

.notification-content-timestamp {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 36px;
}

.notification-content-text {
  font: 13px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #6F6F6F;
  margin-bottom: 17px;
  word-break: break-word;
}

.notification-content-attachment {
  width: 202px;
  height: 49px;
  padding: 7px 14px 7px 5px;
  display: flex;
  background: #f4f4f6 0 0 no-repeat padding-box;
  box-shadow: -2px -2px 2px #FFFFFF, 2px 2px 2px #A3B1C699;
  border-radius: 4px;
  align-content: center;

  cursor: pointer;

}

.notification-content-attachment-name {
  font: 11px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #666666;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notification-content-attachment-type {
  font: 10px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
}

.notification-sender {
  width: 272px;
  height: 260px;
  background: #F5F5F7 0 0 no-repeat padding-box;
  box-shadow: -2px -2px 2px #FFFFFF, 2px 2px 2px #00000014;;
  border-radius: 8px;
  padding: 24px 16px;
}

.notification-sender-title {
  font: 14px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #565656;
  margin-bottom: 10px;
}

.notification-sender-phone {
  text-align: left;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #60BED4;
  margin-bottom: 4px;
}

.notification-sender-email {
  text-align: left;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #60BED4;
  margin-bottom: 24px;
}

.notification-sender-address {
  text-align: left;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}


.notification-box {
  width: 498px;
}

.filter-header-back {
  display: none;
}

@media screen and (max-width: 1279px) {
  .notification-sender {
    margin: 0 20px 20px;
  }

  .notification-sidebar {
    width: 100%;
  }

  .notification-sidebar-container {
    width: 50vw;
  }

  .notification-box {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .notification-content {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-end;
  }

}

@media screen and (max-width: 601px) {
  .notification {
    padding-left: 25px;
    padding-right: 25px;
    flex-flow: column nowrap;
  }

  .filter-header-back {
    width: 100%;
    display: flex;
    min-height: 50px;
    align-items: center;
  }

  .notification-sidebar-container {
    width: 100%;
  }

  /*noinspection CssUnusedSymbol*/
  .hide {
    display: none;
  }

  /*noinspection CssUnusedSymbol*/
  .notification-mobile-background {
    background: #f5f5f7;
    min-height: 100vh;
  }
}

.btn-hiperlink {
  margin-top: 20px;
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 200px;
  flex: 0;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

</style>
