<template>
<!--  <div style="width: 100%;">-->
<!--    <div-->
<!--        style="position: fixed; top: 0;bottom: 0;left: 0;right: 0; background: transparent;"-->
<!--    />-->

<!--  </div>-->

  <div
      style="height: 40px; padding: 5px; width: 100%"
  >
    <div
        class="input-header-search-container"
    >
      <div
          class="input-header-search-icon align-content-center"
      >
        <SearchIcon/>
      </div>
      <input
          ref="searchInput"
          v-model="searchTerm"
          class="input-header-search"
          style="border: none;"
          @keypress.enter="onChangeTerm"
          placeholder="Burcar por"
      />
    </div>
  </div>
</template>
<script>
import SearchIcon from '@/shared/Icons/SearchIcon'
import { mapMutations, mapState } from 'vuex'
import { productEvents } from '@/analytics'

export default {
  name: 'SearchInput',
  components: { SearchIcon },
  computed: {
    ...mapState('headerStore', ['showSearch']),
  },
  data () {
    return {
      searchTerm: '',
    }
  },
  methods: {
    ...mapMutations('homeStore', ['SET_TERM', 'SET_ANCHOR', 'SET_SKIP_CLEAR_FILTER', 'CLEAR_FILTER']),
    ...mapMutations('headerStore', ['SET_SHOW_SEARCH']),
    openSearch() {
      this.searchTerm = ''
      this.SET_TERM(this.searchTerm)
    },
    async onChangeTerm() {
      this.SET_TERM(this.searchTerm)
      if (this.searchTerm) {
        productEvents.searched(this.searchTerm)

        await this.goToLink()
        this.SET_SKIP_CLEAR_FILTER(true)

        this.SET_ANCHOR(true)
      }
    },
    async goToLink(link) {
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      })
    },
  },
}
</script>
<style scoped>

a {
  opacity: 0;
}

.input-header-search-container {
  position: relative;
  padding: 0 0 0 0;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 3px 3px 3px #FBFBFB;
  direction: ltr;
  width: 216px;
  margin: 0 auto;
  border-radius: 6px;
}

.input-header-search-icon {
  position: absolute;
  bottom: 1px;
  left: 5px;
  width: 24px;
  height: 24px;
}

.input-header-search {
  padding-left: 35px;
  margin: 0;
  font: 13px 'HelveticaNeueLTPro-Roman';
  color: #414141;
  letter-spacing: 0;
  opacity: 1;
  border: 1px solid #d4e2f5;
  height: 28px;
  width: 100%;
}

.header-user-dropdown > * {
  cursor: pointer;
}

.input-header-search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  font: 12px 'HelveticaNeueLTPro-Roman';
}

.input-header-search::-moz-placeholder { /* Firefox 19+ */
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  font: 12px 'HelveticaNeueLTPro-Roman';
}
.input-header-search:-ms-input-placeholder { /* IE 10+ */
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  font: 12px 'HelveticaNeueLTPro-Roman';
}
.input-header-search:-moz-placeholder { /* Firefox 18- */
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  font: 12px 'HelveticaNeueLTPro-Roman';
}

</style>
