<!--suppress HtmlRequiredAltAttribute -->
<template>
  <div
      :style="(showSideMenu || showFilterMobile) ? 'background: #F5F5F7;  height: 100%;' : ''"
  >
    <HeaderBar/>
    <v-container>
      <v-row>
        <!--          :style="showSideMenu ? 'overflow:hidden; max-width: 100vh' : ''"-->
        <v-col
            :style="showSideMenu ? 'display: none;' : ''"
            class="pt-0 pb-0"
        >
          <router-view :key="$route.path"/>
        </v-col>
      </v-row>
    </v-container>
    <FooterBar
        :style="showSideMenu || showFilterMobile ? 'display: none;' : ''"
    />
  </div>
</template>

<script>
import HeaderBar from '@/shared/HeaderBar/HeaderBar'
import FooterBar from '@/shared/FooterBar/FooterBar'
import { mapState } from 'vuex'

export default {
  name: 'Dashboard-index',
  components: { FooterBar, HeaderBar },
  computed: {
    ...mapState('headerStore', ['showSideMenu']),
    ...mapState('homeStore', ['showFilterMobile'])
  }
}
</script>

<style>
.test {
  /*width: 1306px;*/
  /*margin-left: 50px;*/
  /*border: 1px solid greenyellow;*/
}

textarea:focus, input:focus {
  outline: none;
}

.row, .col {
  margin: 0;
  padding: 0;
}

.scroll-off {
  height: 100vh;
  overflow: hidden;
}

.v-application--wrap{
  background-color:#F5F5F7;
}
</style>
