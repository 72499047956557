class DataLayer {
  constructor() {
    window.dataLayer = window.dataLayer || [];
    this.dataLayer = window.dataLayer;
  }

  event(data) {
    this.dataLayer.push(data);
  }
}

const dataLayer = new DataLayer();

export default dataLayer;
