const ModelApi = (vueResource, url) => {
  const resource = vueResource(`${url}/model{/PATH}{/ID}{?Q*}`)

  return {
    async listByIds(ids) {
      try {
        return await resource.get({ids: ids.join(',')})
      } catch (e) {
        return {body: null}
      }
    }
  }
}

export default ModelApi
