<template>
  <div
      class="header-logo cursor-pointer"
  >
    <LogoVixIcon/>

  </div>
</template>
<script>
import LogoVixIcon from "@/shared/Icons/LogoVixIcon";
export default {
  name: 'VixLogo',
  components: {LogoVixIcon},
  props: {
    hideLabel: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: true
    },
  },
}
</script>
<style scoped>

.header-logo {
  display: flex;
  align-items: center;
  justify-items: center;
  font: 13px 'HelveticaNeueLTPro-Cn';
  font-weight: bold;
  letter-spacing: 1px;
  color: #4A5A11;
  height: 40px;
  width: fit-content;
}

.logo-label {
  padding-top: 4px;
  padding-right: 3px;
  margin-left: 10px;
  margin-right: 2px;
}

.expanded {
  height: 40px;
  width: 150px;
}
</style>
