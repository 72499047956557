<template>
  <div>
    <OffersRowMobile
        v-if="!isDesktop"
        :offers="offers"
        :order-by="orderBy"
        :filter-by="filterBy"
        :title="title"
    />

    <OffersRowDesktop
        v-if="isDesktop && offers.length"
        :offers="offers"
        :order-by="orderBy"
        :filter-by="filterBy"
        :width="width"
        :title="title"
        style=" background: #F5F5F7 0 0 no-repeat padding-box;"
    />
  </div>
</template>
<script>
import OffersRowDesktop from '@/shared/OffersRow/OffersRowDesktop'
import OffersRowMobile from '@/shared/OffersRow/OffersRowMobile'

export default {
  components: {
    OffersRowDesktop,
    OffersRowMobile,
  },
  props: {
    offers: {
      type: Array,
    },
    orderBy: {
      type: Boolean,
      default: false,
    },
    filterBy: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'HomeIndex.OffersRow.title',
    },
    cardSizeDefault: {
      type: Number,
      default: 268,
    },
    width: {
      type: String,
      default: '878px',
    },
  },
  computed: {
    isDesktop () {
      return this.$vuetify.breakpoint.lgAndUp
    },
  },
}
</script>