<template>
  <v-container>
    <v-row justify="space-between" align="baseline" class="mb-5">
      <div class="page-title">
        Cadastro de Parceiro
      </div>
      <div class="enter-here">
        {{ $t('Auth.isMember') }} <a href="/#/auth">{{ $t('Auth.enterHere') }}</a>
      </div>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.nomeResponsavel.$anyError }">
          <label for="nomeResponsavel">Nome do Responsável*</label>
          <input id="nomeResponsavel" type="text" class="form-control" v-model.trim="$v.form.nomeResponsavel.$model">

          <span class="invalid-feedback" v-if="!$v.form.nomeResponsavel.required">Digite o nome do responsável</span>
        </div>
      </v-col>

      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.cpfResponsavel.$anyError }">
          <label for="cpfResponsavel">CPF do Responsável*</label>
          <input id="cpfResponsavel" type="text" class="form-control" v-model.trim="$v.form.cpfResponsavel.$model">

          <span class="invalid-feedback" v-if="!$v.form.cpfResponsavel.required">Digite o CPF</span>
          <span class="invalid-feedback" v-if="!$v.form.cpfResponsavel.numeric">Digite apenas números</span>
          <span class="invalid-feedback" v-if="!$v.form.cpfResponsavel.minLength">Digite o CPF completo</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.nomeFantasia.$anyError }">
          <label for="nomeFantasia">Nome Fantasia*</label>
          <input id="nomeFantasia" type="text" class="form-control" v-model.trim="$v.form.nomeFantasia.$model">

          <span class="invalid-feedback" v-if="!$v.form.nomeFantasia.required">Digite o nome fantasia</span>
        </div>
      </v-col>

      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.razaoSocial.$anyError }">
          <label for="razaoSocial">Razão Social*</label>
          <input id="razaoSocial" type="text" class="form-control" v-model.trim="$v.form.razaoSocial.$model">

          <span class="invalid-feedback" v-if="!$v.form.razaoSocial.required">Digite a razão social</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.email.$anyError }">
          <label for="email">E-mail*</label>
          <input id="email" type="text" class="form-control" v-model.trim="$v.form.email.$model">

          <span class="invalid-feedback" v-if="!$v.form.email.required">Digite o e-mail</span>
          <span class="invalid-feedback" v-if="!$v.form.email.email">Digite um e-mail válido</span>
        </div>
      </v-col>

      <v-col>
        <div class="form-group">
          <label for="siteDaEmpresa">Site da Empresa (Opcional)</label>
          <input id="siteDaEmpresa" type="text" class="form-control" v-model.trim="form.siteDaEmpresa">
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.cnpj.$anyError }">
          <label for="cnpj">CNPJ*</label>
          <input id="cnpj" type="text" class="form-control" v-model.trim="$v.form.cnpj.$model">

          <span class="invalid-feedback" v-if="!$v.form.cnpj.required">Digite o CNPJ</span>
          <span class="invalid-feedback" v-if="!$v.form.cnpj.numeric">Digite apenas números</span>
          <span class="invalid-feedback" v-if="!$v.form.cnpj.minLength">Digite o CNPJ completo</span>
        </div>
      </v-col>

      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.telefone.$anyError }">
          <label for="telefone">Telefone*</label>
          <input id="telefone" type="text" class="form-control" v-model.trim="$v.form.telefone.$model">

          <span class="invalid-feedback" v-if="!$v.form.telefone.required">Digite seu telefone</span>
          <span class="invalid-feedback" v-if="!$v.form.telefone.numeric">Digite apenas números</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="row-split">
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.senha.$anyError }">
          <label for="senha">{{ $t('Auth.password') }}*</label>
          <input id="senha" type="password" class="form-control" v-model.trim="$v.form.senha.$model">

          <ul class="password-requirements-list" role="list">
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.minLength && $v.form.senha.required"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.minLength || !$v.form.senha.required"/>
              No mínimo {{$v.form.senha.$params.minLength.min}} caracteres
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Low"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Low"/>
              Ao menos um caractere minúsculo
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Upp"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Upp"/>
              Ao menos um caractere maiúsculo
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Num"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Num"/>
              Ao menos um caractere numérico
            </li>
            <li class="password-requirement">
              <check-icon class="requirement-icon" :size="14" v-if="$v.form.senha.atLeast1Esp"/>
              <times-icon class="requirement-icon" :size="14" v-if="!$v.form.senha.atLeast1Esp"/>
              Ao menos um caractere especial
            </li>
          </ul>
        </div>
      </v-col>
      <v-col>
        <div class="form-group" :class="{ 'has-error': $v.form.confirmacaoSenha.$anyError }">
          <label for="password_repeat">{{ $t('Auth.repeatPassword') }}*</label>
          <input id="password_repeat" type="password" class="form-control" v-model.trim="$v.form.confirmacaoSenha.$model">

          <span class="invalid-feedback" v-if="!$v.form.confirmacaoSenha.sameAsPassword">Senha não confere</span>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <label class="checkbox">
        <input type="checkbox" value="true" v-model="$v.form.termos.$model">
        <span :style="{ color: !$v.form.termos.checked && $v.form.termos.$anyDirty ? '#F64A14' : 'inherit' }">Eu concordo com os <span class="text-link" @click.stop.prevent="goToTerm">termos de uso</span> e <span class="text-link" @click.stop.prevent="goToPrivacyPolicy">políticas de privacidade</span>.</span>
      </label>
    </v-row>

    <v-row>
      <label class="checkbox">
        <input type="checkbox" value="true" v-model="form.marketing">
        <span>Eu aceito receber e-mails com promoções e informações da Vix Seminovos</span>
      </label>
    </v-row>

    <v-row class="actions-button">
      <button type="button" class="p-button p-button-secondary">Voltar</button>
      <button type="button" class="p-button p-button-primary" @click="submit">Próximo</button>
    </v-row>

    <v-dialog
    v-model="termDialog"
    width="80vw"
  >
    <div class="dialog-card-content">
      <div class="card-padding">
        <div class="dialog-info-header">
          <div class="dialog-info-title"/>
          <div
            class="dialog-info-close"
            @click="termDialog = false"
          >
            <XMarkIcon/>
          </div>
        </div>
        <div class="dialog-content">
          <div class="dialog-info-container">
            <DynamicScreen
              :content="termContent"
              style="padding: 0;"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>

  <v-dialog
    v-model="privacyPolicyDialog"
    width="80vw"
  >
    <div class="dialog-card-content">
      <div class="card-padding">
        <div class="dialog-info-header">
          <div class="dialog-info-title"/>
          <div
              class="dialog-info-close"
              @click="privacyPolicyDialog = false"
          >
            <XMarkIcon/>
          </div>
        </div>
        <div class="dialog-content">
          <div class="dialog-info-container">
            <DynamicScreen 
                :content="privacyPolicyContent"
                style="padding: 0;"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>

  </v-container>
</template>

<script>
import DynamicScreen from '@/screen/Dynamic/DynamicScreen'
import TimesIcon from "@/shared/Icons/TimesIcon";
import CheckIcon from "@/shared/Icons/CheckIcon";
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import {email, minLength, numeric, required, sameAs} from "vuelidate/lib/validators";
import {DynamicResource} from '@/api'
import {mapActions} from "vuex";

export default {
  name: "RegisterPartner",
  components: { TimesIcon, CheckIcon, XMarkIcon, DynamicScreen },
  mounted() {
    this.init()
  },
  data: () => ({
    termDialog: false,
    termContent: undefined,
    privacyPolicyDialog: false,
    privacyPolicyContent: undefined,
    form: {
      termos: false,
      marketing: false,
      nomeResponsavel: null,
      cpfResponsavel: null,
      nomeFantasia: null,
      email: null,
      telefone: null,
      razaoSocial: null,
      siteDaEmpresa: null,
      cnpj: null,
      senha: null,
      confirmacaoSenha: null,
    },
  }),
  validations: {
    form: {
      nomeResponsavel: {
        required
      },
      cpfResponsavel: {
        required,
        numeric,
        minLength: minLength(11)
      },
      nomeFantasia: {
        required
      },
      razaoSocial: {
        required
      },
      email: {
        required,
        email
      },
      cnpj: {
        required,
        numeric,
        minLength: minLength(14)
      },
      telefone: {
        required,
        numeric
      },
      senha: {
        required,
        minLength: minLength(8),
        atLeast1Low: value => {
          if (!value) return false
          return /[a-z]/.test(value);
        },
        atLeast1Upp: value => /[A-Z]/.test(value),
        atLeast1Num: value => /\d/.test(value),
        atLeast1Esp: value => /[!@#$%*]/.test(value),
      },
      confirmacaoSenha: {
        sameAsPassword: sameAs('senha')
      },
      termos: {
        checked: value => value === true
      },
      marketing: {
        checked: value => value === true
      },
    },
  },
  methods: {
    ...mapActions('registerPartnerStore', ['REGISTER_PARTNER']),
    async submit() {
      if (!this.$v.$invalid) {
        await this.REGISTER_PARTNER(this.form)
      }
    },
     goToTerm () {
      this.termos = false
      this.termDialog = true
    }
    ,
    goToPrivacyPolicy () {
      this.privacyPolicyDialog = true
    },
    async init() {
     
      const termResponse = await DynamicResource.getTermSiteParceiro()
      this.termContent = termResponse.body

      const termPolicyResponse = await DynamicResource.getTermSitePoliticaPrivacidadeParceiro()
      this.privacyPolicyContent = termPolicyResponse.body     
    }

  },
}
</script>

<style lang="scss" scoped>
.page-title {
  font: 30px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
}

.enter-here {
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;

  a {
    text-decoration: none;
    color: #60BED4;
  }
}

.row-split {
  gap: 10px;

  & > * {
    flex: 1
  }
}

@media (max-width: 768px) {
  .row-split {
    flex-direction: column;
  }
}

.password-requirements-list {
  list-style-type: none;
  padding-left: .5rem !important;
  margin-top: 1rem;

  & > li {
    font: 14px 'HelveticaNeueLTCom-Roman';
    display: flex;
    align-items: center;

    & .requirement-icon {
      margin-right: .5rem;
      flex: 0 0 4%;
    }
  }
}

.checkbox {
  width: fit-content;
  font: 16px 'HelveticaNeueLTCom-Roman';
  color: #414141;
  letter-spacing: 0;
  margin-top: 30px;
  display: inline-flex;
  align-items: center;

  & > input[type=checkbox] {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }

  .text-link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.actions-button {
  justify-content: center;
  margin-block: 20px;
  gap: 20px;
}

@media (max-width:768px) {
  .actions-button {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.dialog-info-header {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: nowrap;
}

.dialog-info-title {
  text-align: left;
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  flex: 1;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
}

.dialog-card-content {
  background: #F5F5F7;
  box-shadow: 0 3px 15px #000000C7;
  border-radius: 4px;
  opacity: 1;
  min-height: 80vh;
  max-height: fit-content;
  padding: 20px;
}

.dialog-content {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
}

.dialog-info-container {
  display: flex;
  flex-flow: row nowrap;
}
</style>