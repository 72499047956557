<template>

  <div
      class="offer-sidebar-container"
  >
    <div style='display: flex;'>
      <v-img
          :src="this.partnerDetails.urlFoto"
          height="30px"
          width="30px"
          style="border-radius: 50%; flex-grow: 0; margin-right: 10px"
      /> 
      <span style="line-height: 30px; font-weight: bold; font-size: 18px">
        {{this.partnerDetails.nome}}
      </span>
    </div>
    <div
        v-if="offer && offer.vehicles && offer.vehicles[0]"
        class="offer-sidebar-icons"
    >
      <!-- DISPLAY: COMPRA SIMPLES || Imagem da marca vv-->
      <v-img
          v-if="!isLote"
          :src="getImageUrl(offer.vehicles[0].ativo)"
          class="absolute"
          height="50px"
          style="top: 0;"
          width="50px"
          contain
      />
      <!-- DISPLAY: COMPRA SIMPLES || Imagem da marca ^^-->
      <!-- DISPLAY: COMPRA LOTE || Ícone de lote vv-->
      <div
          v-else
          style="top: 0; position: absolute; height:50px; width:50px;"
      >
        <VehicleLotIcon/>
      </div>
      <!-- DISPLAY: COMPRA LOTE || Ícone de lote ^^-->
      <div @click="goToQuestions()">
        <QuestionIcon
            class="absolute"
            style="top: 13px; right: 45px;cursor:pointer;"
        />
      </div>

      <div
          style="cursor:pointer;"
          @click="saveFavorites()"
      >
        <FavoriteIcon
            :active="favorite"
            class="absolute"
            style="top: 13px; right: 3px;"
        />
      </div>

    </div>

    <p class="offer-sidebar-title">
      {{ offer.title }}
    </p>

    
    <div
        class="relative"
    >
    
      
      <p
          class="offer-sidebar-buy-price-subtitle"
      >
        {{ $t('OfferBar.actualBid') }}
      </p>

    </div>
 <!-- DISPLAY: LEILAO || Botões para dar lance  vv-->
    <BidControl
        :increments="increments"
        :offer="offer"
        :price="price"
        
    />
    <!-- DISPLAY: LEILÃO || Lance atual vv-->
    <div
        class="relative faca_oferta"
    >
    

      <p
          class="offer-sidebar-buy-price-subtitle"
      >
      </p>
    <hr
        class="offer-sidebar-line"
        style="margin-bottom: 28px;"        
    > <!-- aqui -->
      <p
          class="offer-sidebar-buy-price"
          style="margin-bottom: 16px; text-align: center;"
      >
        {{ priceBuyNow }}
      </p>
       <!-- DISPLAY: COMPRA || Botões de compra e proposta vv-->
    <div
        class="buy-btn-container"
    >
      <div
          :class="{'big': offer.doOffer !== 'S'}"
          class="offer-sidebar-button-buy buy align-content-center"
          @click="compreJa()"
      >
        <span>{{ $t('OfferBar.buy') }}</span>
      </div>
      <div
          v-if="offer.doOffer === 'S' && !offer.hasBid"
          class="buy-btn-spacer-xs"
      />
      <div
          v-if="offer.doOffer === 'S' && !offer.hasBid"
          class="offer-sidebar-button-buy proposal-new align-content-center"
          @click="dialogPurpose = true"
      >
        <span>{{ $t('OfferBar.sendPurpose') }}</span>
      </div>
    </div>
      <div
          @click="dialogInfo = true"
      >
        
      </div>
    </div>
    <!-- DISPLAY: LEILÃO || Lance atual ^^-->

    <!-- DISPLAY: LEILÃO || Dialogo de informação -->
    <v-dialog
        v-model="dialogInfo"
        width="fit-content"
    >
      <v-card>
        <v-card-text style="padding: 24px;">
          <div class="dialog-info-header">
            <div class="dialog-info-title">
              {{ lanceInfoContent.description }}
            </div>
            <div
                class="dialog-info-close"
                @click="dialogInfo = false"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div
              class="dialog-info-text"
              v-html="lanceInfoContent.html"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DISPLAY: LEILÃO || Dialogo de informação ^^-->

    <!-- DISPLAY: COMPRA LOTE || Cartão de seleção de ativo vv-->
<!--    <div-->
<!--        v-if="display === 'buy' && isLote"-->
<!--        class="vehicles"-->
<!--    >-->
<!--      <VehiclesLot-->
<!--          :vehicles="offer.vehicles"-->
<!--      />-->
<!--    </div>-->
    <!-- DISPLAY: COMPRA LOTE || Cartão de seleção de ativo ^^-->

    <!-- DISPLAY: LEILÃO || Contador vv-->
    <div
        class="offer-sidebar-countdown-container"
    >
      <p
          class="offer-sidebar-buy-price-subtitle"
      >
      </p>


    </div>
    <!-- DISPLAY: LEILÃO || Contador ^^-->

    <!-- DISPLAY: COMPRA || Vendedores vv-->
    <div
        v-if="display === 'buy'"
        style="margin-bottom: 20px;"
    >
      <p
          :style="!isLote && offer.vehicles[0].codLaudo ? '' : 'visibility: hidden'"
          class="offer-seller"
          @click="download(offer.vehicles[0].codLaudo, offer.vehicles[0].nameLaudo)"
      >
        <span>{{ $t('OfferScreen.laudo') }}</span>
      </p>

      <a
          ref="downloader"
          style="display: none;"
      >DOWNLOAD AREA</a>
      <hr
          :style="!isLote && offer.vehicles[0].codLaudo ? '' : 'visibility: hidden'"
          class="offer-sidebar-line"
          style="margin-top: 14px; margin-bottom: 0;"
      >

      <p
          class="offer-seller"
          style="visibility: hidden"
      >
        Vendedor <span>Vix Seminovos</span>
      </p>

      <p
          v-if="!offer.phraseInsideOffer"
          class="offer-seller"
          style="visibility: hidden"
      >
        Entrega <span>Nome da Empresa</span>
      </p>
    </div>
    <!-- DISPLAY: COMPRA || Vendedores ^^-->

    <!-- DISPLAY: COMPRA || Linha de divisão vv-->
    
    
    <!-- DISPLAY: COMPRA || Linha de divisão ^^-->

   
    <!-- DISPLAY: LEILAO || Botões para dar lance  ^^-->

   
    <!-- DISPLAY: COMPRA || Botões de compra e proposta ^^-->

    <!-- DISPLAY: COMPRA || Link pra display: leilão vv-->
    
    <!-- DISPLAY: COMPRA || Link pra display: leilão ^^-->

    <!-- DISPLAY: LEILÃO || Link pra display: compra vv-->
    <p
        class="offer-sidebar-bid"
        @click="showBuy()"
    >
    </p>
    <!-- DISPLAY: LEILÃO || Link pra display: compra ^^-->


    <!-- dialogo de proposta -->
    <v-dialog
        v-model="dialogPurpose"
        width="fit-content"
    >
      <v-card>
        <v-card-text  class='vcard-text-content' style="width:fit-content;">
          <div
              class="dialog-info-header"
          >
            <div class="dialog-info-title">{{ $t('OfferBar.wantToNegotiate') }}</div>
            <div
                class="dialog-info-close"                 
                @click="dialogPurpose = false"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div class="dialog-bid">
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ $t('OfferBar.dialogPurposeText') }}
            </div>
            
              <div class="flux-address-label">
                <label>
                  {{ $t('OfferBar.dialogPurposeValue') }}
              </label>
              </div>
              <div>
                <input
                    v-model="purposeValue"
                    class="flux-address-input medium"
                    name="purposeValue"
                    placeholder="R$"
                    required
                    style="margin-bottom: 30px;"
                    type="text"
                    ref="purposeValue"
                    @blur="applyMaskMoney('purposeValue')"
                    @keypress="keyup($event, 'purposeValue')"
                    @keyup="magicMaskMoney('purposeValue', $event)"
                />
              </div>

            <div class="flux-button">

              <div
                  class="offer-sidebar-button-buy align-content-center"
                  @click="savePurpose"
              >
                <span>{{ $t('global.sendButton') }}</span>
              </div>
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialogo de proposta ^^-->

    <!-- dialogo de limite compra ^^-->
    <v-dialog
        v-model="dialogFake"
        width="fit-content"
    >
      <v-card>
        <v-card-text style="padding: 24px; width:635px">
          <div
              class="dialog-info-header"
          >
            <div class="dialog-info-title">{{ isBuy ? $t('OfferBar.buyLimit') : $t('OfferBar.bidLimit') }}</div>
            <div
                class="dialog-info-close"
                @click="dialogFake = false; isBid = false; isBuy = false;"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div class="dialog-bid">
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ isBuy ? $t('OfferBar.buyLimitText1') : $t('OfferBar.bidLimitText1') }}
            </div>
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ isBuy ? $t('OfferBar.buyLimitText2') : '' }}
            </div>

            <div class="flux-button-fake">

              <div
                  class="offer-sidebar-button-buy align-content-center"
                  style="width: 92px;"
                  @click="dialogFake = false; isBid = false; isBuy = false;"
              >
                <span>{{ $t('OfferBar.buyLimitButton') }}</span>
              </div>
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialogo de limite compra ^^-->

    <v-dialog
        v-model="success"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="info"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgSuccess }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="goToCadastrar"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import QuestionIcon from '../../shared/Icons/QuestionIcon'
import FavoriteIcon from '../../shared/Icons/FavoriteIcon'
import {mapMutations, mapState} from 'vuex'
import {DynamicResource, getImageUrl, OfferResource} from '@/api'
import CookieControl from '../../cookie-control'
import VehicleLotIcon from '@/shared/Icons/VehicleLotIcon'
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import BidControl from "@/screen/Offer/BidControl";
import { productEvents } from '@/analytics'

const cookieControl = new CookieControl()

export default {
  name: 'OfferSideBar',
  components: {
    BidControl,
    XMarkIcon,
    VehicleLotIcon,
    // VehiclesLot,
    FavoriteIcon,
    QuestionIcon,
    
  },
  props: {
    offer: {
      type: Object,
      default: () => {
        return {}
      }
    },
    price: {
      type: Object,
      default: () => {
        return {
          codigoOferta: 0,
          codigoPerfil: 0,
          valorCompreJa: 0,
          valorLeilao: 0,
        }
      }
    },
    increments: {
      type: Array,
      default: () => [100, 200, 300, 400]
    }
  },
  data() {
    return {
      dialogInfo: false,
      bidValue: '',
      maskMoneyTimeoutID: null,
      dialogPurpose: false,
      dialogFake: false,
      isBid: false,
      isBuy: false,
      dialogAutoBid: false,
      purposeValue: '',
      autoBidValue: '',
      msgSuccess: "",
      success: false,
      ofertaFinalizada: false,
      lanceInfoContent: {},
      parceiro: "",
      showParceiro: false,
      partnerDetails: {
        nome:"",
        urlFoto:"",
        dataMembro:"",
        descricao:"",
      }
    }
  },
  computed: {
    ...mapState('offerStore', [
      'display', 'favorite'
    ]),
    priceBuyNow() {
      if (!this.price.valorCompreJa) return 'R$ 0.000,00*'
      return this.price.valorCompreJa.number2Money()
    },
    priceBid() {
      if (!this.price.valorLeilao) return 'R$ 0.000,00*'
      return this.price.valorLeilao.number2Money()
    },
    isLote() {
      return this.offer.vehicles.length > 1
    },
    metric() {
      if (!this.offer.vehicles[0].km) return '-- ' + this.offer.vehicles[0].tipoContador
      return this.offer.vehicles[0].km.float2Money('', '', '.', '') + ' ' + this.offer.vehicles[0].tipoContador
    },
    locations() {
      let count = 0
      let cidadesEstados = []
      if(this.offer.vehicles.length>1) {
        for(let i=0;i<this.offer.vehicles.length;i++) {
          if(!cidadesEstados.includes(this.offer.vehicles[i].cidade + '-' + this.offer.vehicles[i].estado)) {
            cidadesEstados.push(this.offer.vehicles[i].cidade + '-' + this.offer.vehicles[i].estado)
            count++
          }
        }
        if(count === 1) {
          return count + ' localidade'
        } else {
          return count + ' localidades'
        }
      } else {
        return this.offer.vehicles[0].cidade + '-' + this.offer.vehicles[0].estado
      }
    }
  },
  mounted() {
    this.parceiro = window.PARTNER
    this.showParceiro = this.parceiro!==undefined;
    this.init()
  },
  methods: {
    ...mapMutations('offerStore', [
      'SET_DISPLAY', 'SET_FAVORITE', 'SET_SCROLL_QUESTION'
    ]),
    ...mapMutations('fluxStore', [
      'SET_FLUX_BUY', 'SET_FLUX_BID', 'SET_OFFER', 'SET_FLUX_PURPOSE', 'SET_FLUX_AUTO_BID'
    ]),
    init() {
      this.setDisplay(this.$route.query.view)
      this.setFavorite()
      this.getLenceInfo()
      if(this.showParceiro){
        this.getPartner()
      }
    },
    async getPartner () {
      const partner = await OfferResource.getPartnerDetails()
      this.partnerDetails = !partner ? {
        nome:"",
        urlFoto:"",
        dataMembro:"",
        descricao:"",
      } : partner.body
    },
    async getLenceInfo() {
      const response = await DynamicResource.getLanceInfo()
      if (response.body) {
        this.lanceInfoContent = response.body
      } else {
        this.lanceInfoContent = {
          description: this.$t('OfferBar.dialogBidInfo.title'),
          html: this.$t('OfferBar.dialogBidInfo.text')
        }
      }
    },
    emitClick() {
      this.$emit('click')
    },
    showAuction() {
      if (this.offer.status !== 'A') {
        this.msgSuccess = this.$t('global.invalidOffer')
        this.success = true
        this.ofertaFinalizada = true
        return
      }
      this.SET_DISPLAY('auction')
    },
    showBuy() {
      this.SET_DISPLAY('buy')
    },
    automaticBid() {
      this.dialogAutoBid = true
    },
    maskMoney(id) {
      if (this.maskMoneyTimeoutID) clearTimeout(this.maskMoneyTimeoutID)
      this.maskMoneyTimeoutID = setTimeout(() => {
        this.applyMaskMoney(id)
      }, 5000)
    },
    applyMaskMoney(id) {
      this[id] = this[id].number2Money(2, '')
      if (this[id] === '0,00') {
        this[id] = ''
      }
      return this[id]
    },
    magicMaskMoney(id, event) {
      if (event.key.length > 1 || event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
        if (event.key !== 'Backspace' && event.key !== 'Delete') {
          return
        }
      }

      let pos = this.$refs[id].selectionStart.toString()

      if (this[id].length === 1) {
        this[id] = '0,0' + this[id]
        pos = 4
      }
      this[id] = this[id].number2Money(2, '')
      if (this[id] === '0,00') {
        this[id] = ''
      }

      if (this[id].onlyNumber().length > 5 && (this[id].onlyNumber().length - 2) % 3 === 1) {
        pos++
      }

      setTimeout(() => {
        this.$refs[id].selectionStart = pos
        this.$refs[id].selectionEnd = pos

      }, 100)

      return this[id]
    },
    keyup(event, inputRef) {
      const key = event.key
      if (!key.onlyNumber()) {
        event.preventDefault()
      } else if (this[inputRef].length === 0) {
        this[inputRef] = '0,0' + key.onlyNumber();
        event.preventDefault()
      } else if (this[inputRef].onlyNumber().length === 1) {
        this[inputRef] = '0,' + this[inputRef].onlyNumber() + key.onlyNumber();
        event.preventDefault()
      }
    },
    async savePurpose() {
      if (!this.purposeValue) return '' // TODO: 21/08/2020 mensagem de erro
      if (!this.isUserLogin()) {
        this.msgSuccess = this.$t('global.needLogin')
        this.success = true
        return
      }
      const response = await OfferResource.getLimit()
      if (response.body) {
        for(var resp of response.body) {
          if(resp.tipo === 'PODE_DAR_LANCE' && !resp.valor) {
            this.dialogFake = !resp.valor
            this.isBid = true
            this.dialogPurpose = false
            return
          }
        }
      }
      this.SET_FLUX_PURPOSE(this.purposeValue)
      this.SET_OFFER(this.offer)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/fluxo/'
      }).catch(() => {
      })
    },

    async compreJa() {
      if (!this.isUserLogin()) {
        this.msgSuccess = this.$t('global.needLogin')
        this.success = true
        return
      }
      if (this.offer.status !== 'A') {
        this.msgSuccess = this.$t('global.invalidOffer')
        this.success = true
        this.ofertaFinalizada = true
        return
      }
      const response = await OfferResource.getLimit()
      if (response.body) {
        for(var resp of response.body) {
          if(resp.tipo === 'PODE_COMPRAR' && !resp.valor) {
            this.dialogFake = !resp.valor
            this.isBuy = true
            return
          }
        }
      }
      this.SET_FLUX_BUY()
      this.SET_OFFER(this.offer)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/fluxo/'
      }).catch(() => {
      })
    },
    setDisplay(display) {
      if (display && display === 'leilao') {
        this.SET_DISPLAY('auction')
      } else {
        this.SET_DISPLAY('buy')
        if (display === 'proposta') {
          this.dialogPurpose = true
        }
      }
    },
    setFavorite() {
      if (this.offer.favorite) {
        this.SET_FAVORITE(true)
      } else {
        this.SET_FAVORITE(false)
      }
    },

    getImageUrl(endpoint) {
      return getImageUrl(endpoint)
    },

    isUserLogin() {
      return cookieControl.isLogin()
    },

    async saveFavorites() {
      if (this.isUserLogin()) {
        await OfferResource.postFavorite({
          'codigoOferta': this.offer.codigo,
        })
        this.SET_FAVORITE(!this.favorite)
        if (!this.favorite) {
          productEvents.favorited(this.offer)
        }
      }
    },
    goToQuestions() {
      this.SET_SCROLL_QUESTION(true)
    },
    goToCadastrar() {
      if (this.ofertaFinalizada) {
        this.success = false;
        return;
      }
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/auth/'
      }).catch(() => {
      })
    },
    async download(guid, name) {
      const xhr = new XMLHttpRequest()
      const endpoint = '/offer/laudo/' + guid
      xhr.open('GET', getImageUrl(endpoint))
      xhr.responseType = 'arraybuffer'

      xhr.onload = () => {
        const blob = new Blob([xhr.response], {type: 'octet/stream'})
        const url = window.URL.createObjectURL(blob)
        this.$refs.downloader.href = url
        this.$refs.downloader.download = name
        this.$refs.downloader.click()
        window.URL.revokeObjectURL(url)

      }
      xhr.send()
    },
  },
}
</script>

<style
    scoped
    type="text/css"
>

.vcard{
  width:583px;
  background: yellow;
}

.vcard-text-content {
    padding: 24px; 
    width:85%;
  }
  /*style="padding: 24px; width:635px">*/


.offer-sidebar-container {
  height: 100%;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 22px;
  background: #F5F5F7;
}

.offer-sidebar-icons {
  height: 50px;
  width: 100%;
  margin-bottom: 12px;
  position: relative;
}

.offer-sidebar-line {
  width: 100%;
  margin-bottom: 20px;
  height: 1px;
  background-color: RGBA(139, 139, 139, 0.4);
  border: none;
}

p.offer-sidebar-title {
  font: 27px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #2d2d2d;
  margin-bottom: 35px;
  margin-top:20px;
}

p.offer-sidebar-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 20px;
}

p.offer-sidebar-year {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 8px;
}

p.offer-sidebar-metric {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 0px;
}

p.offer-sidebar-buy-price {
  font: 40px 'HelveticaNeue-Bold';
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  margin-bottom: 12px;
}

p.offer-sidebar-buy-price-subtitle {
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color:  #000000;
  margin-bottom: 0;
}

.is-my-bid {
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 20px;
  margin-left: -10px;
}

.is-my-bid > span {
  background-color: #CDD43F66;
  border-radius: 5px;
  padding: 6px 10px;
}

.addon-grid {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(2, 89px);
  grid-template-columns: repeat(2, 110px);
  column-gap: 40px;
  row-gap: 24px;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
}

p.offer-seller {
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  margin-bottom: 8px;
  color: #666666;
  cursor: pointer;
}

p.offer-seller > span {
  color: #60BED4;
}

p.offer-sidebar-location {
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #27BADC;
  padding-top: 15px;
}

.icon-location {
  font-size:20px;
  color:#27BADC;
  margin-left: -5px;
}

.divLocation {
  display:flex;
}

.offer-sidebar-button-buy {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 18px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 211px;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.offer-sidebar-button-buy.big {
  width: 212px;
}
.offer-sidebar-button-buy.proposal-new {
  top: 632px;
  left: 1150px;
  width: 179px;
  height: 45px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #CDD43F;
  border-radius: 23px;
  opacity: 1;
  font-size: 16px;
}
.offer-sidebar-button-buy.proposal {
  background: #60BED4 0 0 no-repeat padding-box;
  color: #FFFFFF;
  white-space: nowrap;
}


.offer-sidebar-button-buy.buy {
  background: #60BED4 0 0 no-repeat padding-box;
  color: #FFFFFF;
  white-space: nowrap;
}

.offer-sidebar-button-buy > span {
  display: block;
  line-height: 1;
}

.flux-button-save {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 140px;
  flex: 0;
  height: 46px;
  cursor: pointer;
}

.offer-sidebar-bid {
  width: 100%;
  text-align: center;
  font: 15px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #27BADC;
  cursor: pointer;
}

.offer-sidebar-card-bid > span {
  display: block;
  line-height: 1;
  white-space: nowrap;
}


.vehicles {
  width: 295px;
  margin-bottom: 15px;
}

.dialog-info-header {
  margin-bottom: 10px;
  display: flex;
  width: 95%;
  flex-flow: row;
  flex-wrap: nowrap;
}

.dialog-info-title {
  text-align: center;
  letter-spacing: 0;
  color: #2D2D2D;
  flex: 1;
  font: normal normal normal 28px/32px 'HelveticaNeueLTPro-Bd';
  opacity: 1;
  margin-top:25px;
  margin-inline: 3%;
  margin-bottom: 25px;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
  margin-top:35px;
}

.dialog-info-text {
  width:fit-content;
  text-align: left;
  font: 20px/26px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
}

.flux-address-label {
  font: 18px/24px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 8px;
  width:90%;
}

.flux-address-input {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 26px/33px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 48px;
  width: 95%;
  padding-left: 11px;
  margin-bottom: 20px;
  flex: 0;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}

.flux-address-input:read-only {
  opacity: 0.3;
}

textarea.chat-textarea {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 100%;
  height: 63px;
  background: #F5F6F7 0 0 no-repeat padding-box;
  box-shadow: -2px -2px 2px #00000014;
  border: 1px solid #ECECEC;
  border-radius: 6px;
  resize: none;
  padding: 10px;
}

textarea.chat-textarea::placeholder {
  color: #8B8B8B;
}

.flux-button {
  flex: 0;
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top: 15px;
}

.flux-button-fake {
  flex: 0;
  min-width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
}

.buy-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buy-btn-spacer-xs {
  width: 10px;
}

.dialog-bid {
  width: 496px;
  margin-inline: 3%;
}

.v-dialog{
  overflow-y: none;
}

@media screen and (min-width: 1360px) {
  .faca_oferta{
    max-height: 210px;
    width: 435px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 701px), screen and (max-height: 501px) {

  .offer-sidebar-container {
    padding-left: 20px;
    padding-right: 20px;
    height: fit-content;
    padding-bottom: 24px;
  }

  .v-dialog{
    overflow-y: none;
  }
  
  .vcard-text-content {
    width:100%;
  }
  /*style="padding: 24px; width:635px">*/



  .vehicles {
    padding-left: 29px;
    padding-right: 29px;
  }

  .dialog-info-text {
    width: 100%;
    text-align: left;
    font: 14px/16px 'HelveticaNeueLTPro-Roman';
    line-height: 20px;
  }

  .addon-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .dialog-bid {
    width: 100%;
    margin-inline: auto;
  }

  .medium {
    width: 100%;
  }

  .dialog-info-title {
    text-align: center;
    font: 22px/24px 'HelveticaNeueLTPro-Bd';
    width: 100%;
    margin-inline: 0;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .flux-address-label{
    font: 14px/18px 'HelveticaNeueLTPro-Roman';
    width: 100%;
  }

  .flux-address-input{
    width: 100%;
    font-size:18px;
  }

  .flux-button {
    flex: 0;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 15px;
  }

  .dialog-info-header{
    width:95%;
    padding-top:15px;
  }

  .dialog-info-close{
    margin-top:25px;
  }
}

</style>
