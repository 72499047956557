<template>
  <svg
      :height="height"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="img_combustivel"
        transform="translate(0 0)"
    >
      <g
          data-name="Grupo 2167"
          id="Grupo_2167"
          transform="translate(0 0)"
      >
        <g
            data-name="Retângulo 379"
            fill="#A7A7A7"
            id="Retângulo_379"
            opacity="0"
            stroke="#707070"
            stroke-width="1"
        >
          <rect
              height="40"
              stroke="none"
              width="40"
          />
          <rect
              fill="none"
              height="39"
              width="39"
              x="0.5"
              y="0.5"
          />
        </g>
        <path
            d="M3.488,1.922H17.6a.928.928,0,0,1,.984.936V9.387a.928.928,0,0,1-.984.936H3.488a.943.943,0,0,1-1-.936V2.858a.944.944,0,0,1,1-.936Zm24.779,22.3L24.621,4.586s0-1.639-2.738-1.482V4.887h.688L26.2,24.264a1.651,1.651,0,0,1-1.734,1.551,1.67,1.67,0,0,1-1.754-1.551l.018-6.812c0-1.871.336-4.36-3.644-4.36v-.581h1.989V0H0V12.58H1.933V27.528H19.042V14.946c1.4-.088,1.6.158,1.6,1.325v7.994a3.642,3.642,0,0,0,3.824,3.4,3.628,3.628,0,0,0,3.8-3.442"
            data-name="noun_Gas_157 (1)"
            fill="#A7A7A7"
            fill-rule="evenodd"
            id="noun_Gas_157_1_"
            transform="translate(6.793 6.559)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'FuelIcon',
  props: {
    height: {
      type: String,
      default: '42'
    },
  }
}
</script>
<style scoped>


</style>