<template>
  <div>

    <div v-if="hasOffers">
      <div style="display: flex; justify-content: center; width: 100%">
        <OffersGrid
            :offers="filteredOffers"
            :order-by="true"
            :filter-by="true"
            :title="this.$t('favorites.favorites')"
            style="background: #f5f5f7 0 0 no-repeat padding-box; flex:0;"
            :width="$vuetify.breakpoint.mdAndDown ? '100vw' : '1123px'"
        />
      </div>
    </div>

    <div
        v-else
        style="background: #f5f5f7 0 0 no-repeat padding-box; flex:0;"
    >

      <div
          class="notfound"
      >
        <PlaceHolderHearthIcon/>
        <div class="notfound-title">{{ $t('favorites.noFavorite') }}</div>
        <div class="notfound-text">{{ $t('favorites.haventAdd') }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import { FavoritesResource } from '@/api'
import { mapMutations, mapState } from 'vuex'
import OffersGrid from '../../shared/OffersGrid/OffersGrid'
import PlaceHolderHearthIcon from '../../shared/Icons/PlaceHolderHearthIcon'

export default {
  name: 'FavoritesIndex',
  components: { OffersGrid, PlaceHolderHearthIcon },
  data () {
    return {
      offers: [],
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState('homeStore', [
      'filteredOffers',
      'filteredSpotlight',
    ]),
    hasOffers () {
      return this.offers.length > 0
    }
  },
  methods: {
    ...mapMutations('homeStore', [
      'SET_OFFERS'
    ]),
    async init () {
      let offerResponse
      offerResponse = await FavoritesResource.getList()

      this.offers = !offerResponse ? [] : offerResponse.body.data
      this.SET_OFFERS(this.offers)
    },
  },
}
</script>

<style
    scoped
    type="text/css"
>

.label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
}

.input-register {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 28px;
  width: 442px;
  padding-left: 11px;
  margin-bottom: 20px;
}


.register-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
}

.register-header {
  position: relative;
  margin-bottom: 16px;
}

.register-register {
  width: fit-content;
  position: absolute;
  right: 3px;
  top: 14px;
  color: #666666;
  font: 13px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
}

.register-go-register {
  color: #27badc;
  cursor: pointer;
}

.register-term {
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  margin-bottom: 30px;
  color: #666666;
}

.register-term > span > span {
  color: #27BADC;
  cursor: pointer;
}

.register-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 78px;
}

.register-button-disabled {
  color: #2D2D2D !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
}

.columns-social-line {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  text-align: center;
  font: 14px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #C9CDD0;

  margin-bottom: 30px;
}

.hr-social-line {
  flex: 1;
  border: 0;
  border-bottom: 2px solid #EEEEEE;
}

.or-social-line {
  flex: 0;
  margin-left: 19px;
  margin-right: 19px;
}

.columns-social-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

}

.column-social-button {
  flex: 1;
  border: 2px solid #EEEEEE;
  border-radius: 3px;
  width: 195px;
  height: 46px;
  background: #FCFCFC 0 0 no-repeat padding-box;

  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.input-register.email-input-register {
  width: 269px;
  margin-right: 24px;
}

.input-register.phone-input-register {
  width: 149px;
}

.register-form-container {
  display: flex;
  flex-wrap: wrap;
  width: 442px;
}

.radio-button-register {
  margin-right: 30px;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}

.radio-button-register > input {
  margin-right: 8px;
}

.custom-radio {

}

input[type=radio] {
  background: black;
}

.custom-radio {

}

input:checked {
  background: #000;
}

.register-error-message {
  height: 27px;
  background: #D85757 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #FFFFFF;
}

.has-error {
  border-color: #e09e9e;
}

.notfound {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px 20px 80px;
}

.notfound-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 20px;
}

.notfound-text {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
  text-align: center;
}
</style>
