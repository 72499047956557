<template>
  <div class="body">
    <h1 align="center" class="title"> Política de Privacidade do Parceiro</h1>
    <section>
      <p class="text" align="center"><strong class="topic">Atualiza&ccedil;&atilde;o:</strong>&nbsp; 01 de janeiro de 2023&nbsp;</p>
      <p class="text" align="justify">Esta Pol&iacute;tica de Privacidade (&ldquo;Pol&iacute;tica&rdquo;) &eacute; o documento que demonstra o compromisso da&nbsp;</p>
      <table class="table-vix" border="1" cellspacing="0" style="width:100%;">
        <tbody> 
          <tr>
            <td style="padding:20px;"> 
              <p class="text" align="justify"><strong class="sub-topic">VIX LOG&Iacute;STICA S/A&nbsp;</strong>(&ldquo;VIX&rdquo; / &ldquo;n&oacute;s&rdquo; / &ldquo;nossa&rdquo;)&nbsp;</p>
              <p class="text" align="justify"><strong class="sub-topic">CNPJ:&nbsp;</strong>32.681.371/0001-72&nbsp;</p>
              <p class="text" align="justify"><strong class="sub-topic">Endere&ccedil;o:&nbsp;</strong>Avenida Jer&ocirc;nimo&nbsp;Vervloet, n&ordm; 345, Goiabeiras, Vit&oacute;ria &ndash; ES&nbsp;/ Rod. Gov. M&aacute;rio Covas, s/n - Planalto de Carapina, Serra - ES, 29161-160&nbsp;</p>
            </td>     
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p class="text" align="justify">com a privacidade e seguran&ccedil;a das informa&ccedil;&otilde;es, quando tratamos dados pessoais ao prestar regularmente nossos servi&ccedil;os, inclusive&nbsp;<em>on-line</em>.&nbsp;Nesta Pol&iacute;tica, descrevemos como os seus dados pessoais s&atilde;o coletados, utilizados, compartilhados e armazenados.&nbsp;</p>
      <p>&nbsp;</p>
      <h1 class="topic">GLOSS&Aacute;RIO</h1>
      <p>&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Anonimiza&ccedil;&atilde;o:</strong>&nbsp;Utiliza&ccedil;&atilde;o de meios t&eacute;cnicos razo&aacute;veis e dispon&iacute;veis no momento do Tratamento, por meio dos quais um dado perde a possibilidade de associa&ccedil;&atilde;o, direta ou indireta, a um indiv&iacute;duo.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>:</strong>&nbsp;Pequenos arquivos enviados pelos sites, salvos no seu dispositivo, que armazenam as prefer&ecirc;ncias e outras pequenas informa&ccedil;&otilde;es, com a finalidade de personalizar sua navega&ccedil;&atilde;o de acordo com o seu perfil.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Dado Pessoal:</strong>&nbsp;&Eacute; toda informa&ccedil;&atilde;o relacionada a uma pessoa natural identificada ou identific&aacute;vel. Por exemplo: nome, endere&ccedil;o, e-mail, endere&ccedil;o IP,&nbsp;geolocaliza&ccedil;&atilde;o, n&uacute;mero do protocolo, n&uacute;mero da conta, etc.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Encarregado:</strong>&nbsp;Pessoa indicada por n&oacute;s para atuar como canal de comunica&ccedil;&atilde;o entre a&nbsp;<strong class="sub-topic">VIX</strong>, voc&ecirc; e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados (ANPD).&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Endere&ccedil;o IP:</strong>&nbsp;Abreviatura de&nbsp;<em>Internet&nbsp;Protocol</em>.&nbsp;&Eacute; o conjunto alfanum&eacute;rico que identifica os dispositivos dos Usu&aacute;rios na Internet.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">LGPD:</strong>&nbsp;Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais &ndash; Lei n&ordm;. 13.709/18, que disp&otilde;e sobre o tratamento de dados pessoais em meios digitais ou n&atilde;o.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Site: </strong>&eacute; o endere&ccedil;o&nbsp;<a class="link"  @click="goToPartnerLandingPage" ><u>{{ this.parceiroLandingPage }}</u></a>.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Titular dos dados pessoais:</strong>&nbsp;&Eacute; a pessoa f&iacute;sica a quem os dados pessoais se referem, no caso desta Pol&iacute;tica, o titular &eacute; voc&ecirc;.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Tratamento: </strong>Toda opera&ccedil;&atilde;o realizada com dados pessoais, como a coleta, produ&ccedil;&atilde;o,&nbsp;recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o.&nbsp;</p>
      <p class="text" align="justify"><strong class="sub-topic">Usu&aacute;rio: </strong>Qualquer indiv&iacute;duo que se cadastra na &aacute;rea de Parceiros a fim de utilizar qualquer um dos servi&ccedil;os dispon&iacute;veis dentro da plataforma.&nbsp;</p>
      <p>&nbsp;</p>
      <h1 class="topic">SOBRE OS&nbsp;DADOS PESSOAIS COLETADOS&nbsp;</h1>
      <p>&nbsp;</p>
      <p class="text" align="justify">Os Dados poder&atilde;o ser coletados quando voc&ecirc; interage em nosso Site.&nbsp;</p>
      <p class="text" align="justify">N&oacute;s podemos utilizar seus Dados Pessoais da&nbsp;seguinte maneira:&nbsp;&nbsp;</p>
      <table class="table-dados-pessoais" border="1" cellspacing="0" style="width:100%; border:3px solid #2abad4;">
        <tbody> 
          <tr>
			<td style="background-color:#cdd625; padding:10px;">
			<p align="center" style="margin:0; color:#fff;"><strong>Finalidade</strong></p>
			</td>
			<td style="background-color:#cdd625; padding:10px;">
			<p align="center" style="margin:0; color:#fff;"><strong>Dados coletados</strong>&nbsp;</p>
			</td>
		</tr>
     <tr>
			<td style="padding:10px;width:70%;">
			<p align="center"><u>Cadastro do Parceiro&nbsp;</u></p>
			<p align="center">Para se cadastrar na &aacute;rea de parceiros.&nbsp;</p>
			</td>
			<td style="padding:10px;width:70%;">
			<ul>
      <li align="justify">Nome representante da empresa&nbsp;</li>
      <li align="justify">CPF representante da empresa&nbsp;</li>
			<li align="justify">E-mail&nbsp;</li>
      <li align="justify">Telefone&nbsp;</li>
			<li align="justify">RG do s&oacute;cio (se houver)&nbsp;</li>
			<li align="justify">CPF do s&oacute;cio (se houver)&nbsp;</li>   
			</ul>
      </td>			
		</tr>
    <tr>
			<td style="padding:10px;width:70%;">
			<p align="center"><u>Login</u></p>
			<p align="center">Para acessar a &aacute;rea de parceiros.&nbsp;</p>
			</td>
			<td style="padding:10px;width:70%;">
			<ul>
			<li align="justify">E-mail&nbsp;</li>
      </ul>
      </td>			
		</tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Atualiza&ccedil;&atilde;o e veracidade dos Dados.</strong>&nbsp;Voc&ecirc; &eacute; o &uacute;nico respons&aacute;vel pela precis&atilde;o, veracidade ou atualiza&ccedil;&atilde;o dos Dados que nos fornece. N&oacute;s n&atilde;o somos obrigados a tratar os seus Dados se houver raz&otilde;es para crer que tal Tratamento possa nos imputar infra&ccedil;&atilde;o de qualquer lei aplic&aacute;vel, ou se voc&ecirc; estiver utilizando nossos Sites para quaisquer fins ilegais ou il&iacute;citos.</p>
    <p class="text" align="justify"><strong class="sub-topic">Base de Dados.</strong>A base de dados formada por meio da coleta de Dados &eacute; de nossa propriedade e est&aacute; sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, ser&atilde;o feitos dentro dos limites e prop&oacute;sitos descritos nesta Pol&iacute;tica.&nbsp;&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Tecnologias empregadas.</strong>&nbsp;N&oacute;s utilizamos Cookies em Nossos Ambientes, caso voc&ecirc; n&atilde;o tenha interesse em mant&ecirc;-los, dever&aacute; configurar seu navegador de internet para bloque&aacute;-los; no entanto, desde j&aacute; destacamos que, nesta hip&oacute;tese, algumas funcionalidades que oferecemos poder&atilde;o ser limitadas.&nbsp;
      Todas as tecnologias utilizadas respeitar&atilde;o sempre a legisla&ccedil;&atilde;o vigente e os termos desta Pol&iacute;tica. N&oacute;s n&atilde;o utilizamos nenhum tipo de decis&atilde;o unicamente automatizada que impacte o Usu&aacute;rio.
    </p>
    <p>&nbsp;</p>
    <h1 class="topic">COMO UTILIZAMOS COOKIES</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><em>Cookies</em>&nbsp;s&atilde;o pequenos arquivos digitais em formato de texto que s&atilde;o armazenados no seu dispositivo (computador,&nbsp;<em>smartphone</em>,&nbsp;<em>tablet</em>&nbsp;etc.) pelo navegador de internet e que guardam informa&ccedil;&otilde;es relacionadas &agrave;s suas prefer&ecirc;ncias, como idioma preferido, localiza&ccedil;&atilde;o, recorr&ecirc;ncia das suas sess&otilde;es e outras vari&aacute;veis para tornar a sua experi&ecirc;ncia muito mais eficiente.&nbsp;</p>
    <p class="text" align="justify">Os&nbsp;<em>Cookies</em>&nbsp;servem para aprimorar a sua experi&ecirc;ncia, tanto em termos de performance, como em termos de usabilidade, uma vez que os conte&uacute;dos disponibilizados ser&atilde;o direcionados &agrave;s suas necessidades e expectativas.</p>
    <p class="text" align="justify">Eles tamb&eacute;m podem ser utilizados para realizar estat&iacute;sticas an&ocirc;nimas e agregadas que permitem entender como o usu&aacute;rio utiliza o site, bem como para aprimorar suas estruturas e conte&uacute;do. Por serem estat&iacute;sticas an&ocirc;nimas, n&atilde;o &eacute; poss&iacute;vel identific&aacute;-lo pessoalmente por meio desses dados.</p>
    <p>&nbsp;</p>  
    <div style="padding:10px;width:90%;">
      <ul>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;Necess&aacute;rios:</strong>&nbsp;Permitem a navega&ccedil;&atilde;o e utiliza&ccedil;&atilde;o das aplica&ccedil;&otilde;es, bem como acessar a &aacute;reas seguras do site. Sem estes<em>Cookies</em>&nbsp;, o site n&atilde;o funciona corretamente.</li>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;Desempenho/Anal&iacute;ticos:</strong>&nbsp;Coletam dados estat&iacute;sticos an&ocirc;nimos com a finalidade de analisar a utiliza&ccedil;&atilde;o do site e seu respectivo desempenho.</li>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;Funcionalidade:</strong>&nbsp;S&atilde;o utilizados para assegurar a disponibiliza&ccedil;&atilde;o de funcionalidades adicionais do site ou para guardar as prefer&ecirc;ncias definidas pelo Usu&aacute;rio ao navegar na internet, sempre que utilizar o mesmo dispositivo.</li>
       <li class="text" align="justify"><strong class="sub-topic"><em>Cookies</em>&nbsp;Publicidade:</strong>&nbsp;Direcionam&nbsp;a publicidade em fun&ccedil;&atilde;o dos interesses do Usu&aacute;rio, ajudando a medir o sucesso de aplica&ccedil;&otilde;es e a efic&aacute;cia da publicidade de parceiros.</li>
      </ul>
    </div>
    <p class="text" align="justify">A maioria dos navegadores de Internet &eacute; configurada para aceitar automaticamente os <em>Cookies</em>, mas voc&ecirc; pode alterar as configura&ccedil;&otilde;es para bloquear o uso de <em>Cookies</em>.&nbsp;Os navegadores que possuem navega&ccedil;&atilde;o an&ocirc;nima coletam apenas <em>cookies</em> estritamente necess&aacute;rios e de funcionalidade.&nbsp;</p>
    <p class="text" align="justify">Existem v&aacute;rias formas de gerenciar <em>Cookies</em>.&nbsp;Consulte as instru&ccedil;&otilde;es de ou a se&ccedil;&atilde;o de ajuda,&nbsp;<em>tools</em>&nbsp;ou&nbsp;<em>edit</em>&nbsp;do seu navegador. Disponibilizamos abaixo alguns&nbsp;<em>links</em>&nbsp;para os navegadores mais conhecidos:</p>
    <div style="padding:10px;width:70%;">
      <ul>
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Firefox,<a class="link" style="text-decoration:none"  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">&nbsp;clique aqui</a></span></li>
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Chrome,<a class="link" style="text-decoration:none"  href="https://support.google.com/chrome/answer/95647?hl=pt-BR&amp;co=GENIE.Platform%3DDesktop">&nbsp;clique aqui</a></span></li>   
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Internet Explorer,<a class="link" style="text-decoration:none"  href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-manage-cookies">&nbsp;clique aqui</a></span></li>   
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Safari,<a class="link" style="text-decoration:none"  href="https://support.apple.com/pt-br/guide/safari/sfri11471/">&nbsp;clique aqui</a></span></li>   
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Opera,<a class="link" style="text-decoration:none"  href="https://help.opera.com/en/latest/security-and-privacy/">&nbsp;clique aqui</a></span></li>       
       <li class="text" align="justify">&nbsp;Para mais informa&ccedil;&otilde;es sobre gest&atilde;o de <em>Cookies</em>&nbsp;no <span class="link">Microsoft Edge,<a class="link" style="text-decoration:none"  href="https://support.microsoft.com/pt-br/help/4026200/microsoft-edge-browse">&nbsp;clique aqui</a></span></li>           
      </ul>
    </div>
    <p>&nbsp;</p>
    <h1 class="topic">COMO COMPARTILHAMOS&nbsp;SEUS DADOS PESSOAIS</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">&Eacute; importante que voc&ecirc; saiba que, ressalvadas as hip&oacute;teses abaixo elencadas, a&nbsp;<strong class="sub-topic">VIX</strong>&nbsp;n&atilde;o compartilha seus Dados Pessoais e, quando o faz, somente informa aqueles que forem estritamente necess&aacute;rios para cumprimento das atividades a que s&atilde;o destinados e, sempre que poss&iacute;vel, adota medidas para salvaguardar as informa&ccedil;&otilde;es e adotar boas pr&aacute;ticas.</p>
    <div style="padding:10px;width:90%;">
      <ul>
       <li class="text" align="justify">&nbsp;Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determina&ccedil;&atilde;o legal, requerimento, requisi&ccedil;&atilde;o ou ordem judicial;&nbsp;</li>           
       <li class="text" align="justify">&nbsp;Com&nbsp;nossos acionistas, como a&nbsp;&Aacute;guia Branca Participa&ccedil;&otilde;es&nbsp;S.A;&nbsp;</li>           
       <li class="text" align="justify">&nbsp;Com as empresas do&nbsp;<strong>Grupo &Aacute;guia Branca</strong>&nbsp;por motivos jur&iacute;dicos ou em conex&atilde;o&nbsp;com&nbsp;&nbsp;reclama&ccedil;&otilde;es, conflitos, processos judiciais e administrativos;</li>           
       <li class="text" align="justify">&nbsp;Com as empresas parceiras, quando necess&aacute;rio para viabilizar, facilitar ou executar atividade espec&iacute;fica; e</li>           
       <li class="text" align="justify">&nbsp;De forma autom&aacute;tica, em caso de movimenta&ccedil;&otilde;es societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e incorpora&ccedil;&atilde;o.</li>              
      </ul>
    </div>
    <p class="text" align="justify">Para eventuais atividades de intelig&ecirc;ncia de mercado, divulga&ccedil;&atilde;o de dados &agrave; imprensa e realiza&ccedil;&atilde;o de propagandas, os Dados Pessoais fornecidos por voc&ecirc; ser&atilde;o compartilhados de forma anonimizada, ou seja, de forma que n&atilde;o possibilite sua identifica&ccedil;&atilde;o.</p>
    <p class="text" align="justify">Caso voc&ecirc; tenha qualquer d&uacute;vida sobre com quem compartilhamos seus Dados Pessoais, por favor, entre em contato&nbsp;conosco&nbsp;por meio dos <a class="link" href="https://portaldeprivacidade.vix.com.br"><u>Canais de Atendimento</u></a>&nbsp;disponibilizados nesta Pol&iacute;tica e lhe auxiliaremos a esclarecer os pontos.</p>
    <p class="text" align="justify">Para mais informa&ccedil;&otilde;es sobre como&nbsp;tratamos e compartilhamos seus Dados Pessoais com as empresas do Grupo, por gentileza,&nbsp;acesse&nbsp;nosso <a class="link" href="https://portaldeprivacidade.vix.com.br/"><u>Portal de Privacidade</u></a>.</p>
    <div style="align:center; padding:10px;width:60%;" ref="divImg">
      <img :style="style" src="@/assets/privacy-policy/image1.png">
    </div>
    <h1 class="topic">COMO PROTEGEMOS SEUS DADOS</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Pr&aacute;ticas de Seguran&ccedil;a e Governan&ccedil;a.</strong>&nbsp;Para resguardar a sua privacidade e proteger os seus Dados, estamos implementando um programa de governan&ccedil;a que cont&eacute;m regras de boas pr&aacute;ticas, pol&iacute;ticas e procedimentos internos, os quais estabelecem condi&ccedil;&otilde;es de organiza&ccedil;&atilde;o, treinamentos, a&ccedil;&otilde;es educativas e mecanismos de supervis&atilde;o e mitiga&ccedil;&atilde;o de riscos relacionados ao Tratamento de Dados Pessoais.&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Acesso aos Dados, proporcionalidade e relev&acirc;ncia.</strong>&nbsp;Internamente, os Dados coletados s&atilde;o acessados somente por profissionais devidamente autorizados, respeitando os princ&iacute;pios de proporcionalidade, necessidade e relev&acirc;ncia para os objetivos do nosso neg&oacute;cio, al&eacute;m do compromisso de confidencialidade e preserva&ccedil;&atilde;o da sua privacidade nos termos desta Pol&iacute;tica.&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Ado&ccedil;&atilde;o de boas pr&aacute;ticas.</strong>&nbsp;Você também é responsável pelo sigilo de seus Dados e deve ter sempre ciência de que o compartilhamento de senhas e dados de acesso viola esta Política e pode comprometer a segurança dos seus Dados e do nosso Site. Caso você identifique ou tome conhecimento sobre o comprometimento da segurança dos seus Dados, entre em contato com o nosso Encarregado por meio dos Canais de Atendimento disponibilizados no final desta Política.</p>
    <p class="text" align="justify"><strong class="sub-topic">Tratamento por terceiros sob nossa diretriz.</strong>&nbsp;N&oacute;s buscamos avaliar cuidadosamente nossos parceiros e prestadores de servi&ccedil;os e firmamos com eles obriga&ccedil;&otilde;es contratuais de confidencialidade, seguran&ccedil;a da informa&ccedil;&atilde;o e prote&ccedil;&atilde;o de Dados, com objetivo de proteger voc&ecirc;.&nbsp;</p>
    
    <h1 class="topic">COMO ARMAZENAMOS&nbsp;SEUS DADOS PESSOAIS E O REGISTRO DE&nbsp;ATIVIDADES</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Local de armazenamento:</strong>&nbsp;Os Dados Pessoais coletados s&atilde;o armazenados em ambiente seguro e controlado, podendo estar em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (<em>cloud&nbsp;computing</em>), o que poder&aacute; exigir transfer&ecirc;ncia e/ou processamento dos seus Dados Pessoais fora do Brasil. Tomamos o cuidado necess&aacute;rio para garantir que as transfer&ecirc;ncias envolvam apenas empresas que demonstrem estar em conformidade com as legisla&ccedil;&otilde;es aplic&aacute;veis, mantendo um n&iacute;vel de conformidade semelhante ou mais rigoroso que o previsto na legisla&ccedil;&atilde;o brasileira.</p>
    <p class="text" align="justify"><strong class="sub-topic">Prazo de armazenamento:</strong>&nbsp;N&oacute;s armazenamos os Dados Pessoais somente pelo tempo que for necess&aacute;rio para cumprir com as finalidades para as quais foram coletados ou para cumprimento de quaisquer obriga&ccedil;&otilde;es legais, regulat&oacute;rias ou para preserva&ccedil;&atilde;o de direitos. Lembre-se, este prazo pode superar o de sua rela&ccedil;&atilde;o direta com a&nbsp;<strong class="sub-topic">&nbsp;VIX</strong>,&nbsp;j&aacute; que determinadas obriga&ccedil;&otilde;es se estendem no tempo.</p>
    <p class="text" align="justify"><strong class="sub-topic">Descarte dos Dados:</strong>&nbsp;Decorrido&nbsp;o prazo de manuten&ccedil;&atilde;o e a necessidade legal, os Dados Pessoais s&atilde;o exclu&iacute;dos com uso de m&eacute;todos de descarte seguro ou utilizados de forma anonimizada para fins estat&iacute;sticos.&nbsp;</p>
    
    <h1 class="topic">DIREITOS DO&nbsp;TITULAR</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify"><strong class="sub-topic">Local de armazenamento:</strong>&nbsp;O usu&aacute;rio &eacute; o titular dos Dados Pessoais que o identifica e, por isso, tem direitos, respaldados em Lei, referentes ao uso desses Dados Pessoais pelo Controlador, tais como:</p>
    <div style="padding:10px;width:90%;">
      <ul>
       <li class="text" align="justify">&nbsp;Pedir a&nbsp;<strong class="sub-topic">&nbsp;confirma&ccedil;&atilde;o</strong>&nbsp;do tratamento de dados e informa&ccedil;&otilde;es sobre o seu uso;</li>           
       <li class="text" align="justify">&nbsp;Voc&ecirc; pode solicitar uma&nbsp;<strong class="sub-topic">c&oacute;pia</strong>&nbsp;dos Dados Pessoais que n&oacute;s temos a seu respeito;</li>           
       <li class="text" align="justify">&nbsp;Corrigir ou&nbsp;<strong class="sub-topic">solicitar a corre&ccedil;&atilde;o</strong>&nbsp;de dados incompletos, inexatos ou desatualizados: o usu&aacute;rio deve atualizar seus dados pessoais cadastrais sempre que houver uma altera&ccedil;&atilde;o, isso garantir&aacute; a maior seguran&ccedil;a e melhora na presta&ccedil;&atilde;o dos servi&ccedil;os;</li>           
       <li class="text" align="justify">&nbsp;Solicitar&nbsp;a&nbsp;<strong class="sub-topic">exclus&atilde;o</strong>&nbsp;dos dados pessoais dos sistemas da&nbsp;<strong class="sub-topic">&nbsp;VIX</strong>. Todos os dados coletados ser&atilde;o exclu&iacute;dos dos servidores da&nbsp;<strong class="sub-topic">&nbsp;VIX</strong>&nbsp;quando o usu&aacute;rio assim requisitar ou quando estes n&atilde;o forem mais necess&aacute;rios ou relevantes para o oferecimento dos Servi&ccedil;os, salvo se houver qualquer outra raz&atilde;o para a sua manuten&ccedil;&atilde;o, como eventual obriga&ccedil;&atilde;o legal de reten&ccedil;&atilde;o de dados ou necessidade de preserva&ccedil;&atilde;o destes para resguardo de direitos da&nbsp;<strong class="sub-topic">&nbsp;VIX</strong>.</li>           
       <li class="text" align="justify">&nbsp;Solicitar a&nbsp;<strong class="sub-topic">anonimiza&ccedil;&atilde;o</strong>, o&nbsp;<strong class="sub-topic">bloqueio</strong>&nbsp;ou a&nbsp;<strong class="sub-topic">elimina&ccedil;&atilde;o</strong>&nbsp;de seus dados: se o usu&aacute;rio considerar o uso dos seus dados pessoais como desnecess&aacute;rio e/ou excessivo;</li>           
       <li class="text" align="justify">&nbsp;Solicitar a&nbsp;<strong class="sub-topic">revis&atilde;o</strong>&nbsp;de a&ccedil;&otilde;es que tenham sido tomadas de forma automatizadas com base no uso de dados pessoais;&nbsp;</li>           
       <li class="text" align="justify">&nbsp;Voc&ecirc; pode solicitar&nbsp;<strong class="sub-topic">informa&ccedil;&otilde;es sobre o compartilhamento</strong>&nbsp;de seus Dados Pessoais. &Eacute; direito seu saber com quais entidades p&uacute;blicas e privadas&nbsp;a&nbsp;<strong class="sub-topic">VIX</strong>&nbsp;compartilha seus dados;&nbsp;</li>           
       <li class="text" align="justify">&nbsp;Voc&ecirc; pode se&nbsp;<strong class="sub-topic">opor</strong>&nbsp;&agrave;s atividades de tratamento que n&atilde;o dependam do consentimento. Neste caso, &eacute; poss&iacute;vel solicitar sua interrup&ccedil;&atilde;o;</li>           
       <li class="text" align="justify">&nbsp;&Eacute; seu direito solicitar que&nbsp;a&nbsp;<strong class="sub-topic">VIX</strong>&nbsp;forne&ccedil;a os seus Dados Pessoais para&nbsp;<strong class="sub-topic">transfer&ecirc;ncia &agrave; outra empresa</strong>&nbsp;do mesmo ramo de atua&ccedil;&atilde;o que o nosso.&nbsp;</li>           
       <li class="text" align="justify">&nbsp;Por fim, voc&ecirc; tem o direito de&nbsp;<strong class="sub-topic">n&atilde;o dar ou retirar seu consentimento</strong>&nbsp;e obter informa&ccedil;&otilde;es sobre as consequ&ecirc;ncias dessa escolha. Nesse caso, alguns dos servi&ccedil;os n&atilde;o poder&atilde;o ser prestados.&nbsp;</li>                
      </ul>
    </div>
    <p class="text" align="justify">&Eacute; importante que voc&ecirc; saiba que o exerc&iacute;cio de seus direitos n&atilde;o &eacute; ilimitado, a&nbsp;<strong class="sub-topic">VIX</strong>&nbsp;pode, em casos espec&iacute;ficos e autorizados por lei, deixar de atender alguma requisi&ccedil;&atilde;o. Caso isto ocorra, voc&ecirc; ser&aacute; informado sobre o motivo de nossa recusa.&nbsp;
      Voc&ecirc; tamb&eacute;m est&aacute; ciente de que, caso a solicita&ccedil;&atilde;o de acesso aos seus Dados Pessoais recaia sobre documentos espec&iacute;ficos, principalmente que envolvam Dados Pessoais de outras pessoas, somente seus Dados Pessoais ser&atilde;o fornecidos e n&atilde;o a integralidade dos documentos.&nbsp;
      A&nbsp;<strong class="sub-topic">VIX</strong>&nbsp;se compromete a responder todas as requisi&ccedil;&otilde;es em um prazo razo&aacute;vel e sempre em conformidade com a legisla&ccedil;&atilde;o aplic&aacute;vel.
    </p>
    <p class="text" align="justify">Para exercer seus direitos, por gentileza, acesse o nosso&nbsp;<a class="link" href="https://portaldeprivacidade.vix.com.br/"><u>Portal de Privacidade</u></a>.&nbsp;</p>
    <h1 class="topic">ALTERA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PRIVACIDADE</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">A&nbsp;<strong class="sub-topic">VIX</strong>&nbsp;poder&aacute;&nbsp;alterar o teor desta Pol&iacute;tica a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente, cabendo a voc&ecirc; verific&aacute;-la sempre que efetuar o acesso em Nossos Ambientes. Ocorrendo atualiza&ccedil;&otilde;es neste documento e que demandem nova coleta de consentimento, voc&ecirc; ser&aacute; notificado por meio dos canais de contatos que nos informar.&nbsp;
      <br>Caso algum ponto desta Pol&iacute;tica seja considerado inaplic&aacute;vel por Autoridade de Dados ou judicial, as demais condi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.
      <br><strong class="sub-topic">Lei aplic&aacute;vel e foro</strong>.&nbsp;Esta Pol&iacute;tica ser&aacute; interpretada segundo a legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo eleito o foro do seu domic&iacute;lio para dirimir qualquer controv&eacute;rsia que envolva este documento, salvo ressalva espec&iacute;fica de compet&ecirc;ncia pessoal, territorial ou funcional pela legisla&ccedil;&atilde;o aplic&aacute;vel.
    </p>
    <h1 class="topic">CONTATO&nbsp;</h1>
    <p>&nbsp;</p>
    <p class="text" align="justify">Em caso de qualquer d&uacute;vida com rela&ccedil;&atilde;o &agrave;s disposi&ccedil;&otilde;es constantes desta Pol&iacute;tica, inclusive para exerc&iacute;cio dos seus direitos, voc&ecirc; poder&aacute; entrar em contato com nosso Encarregado, que est&aacute; &agrave; disposi&ccedil;&atilde;o nos seguintes endere&ccedil;os:&nbsp;</p>   
    <div style="padding:10px;width:70%;">
      <ul>
        <li class="text" align="justify">&nbsp;<strong>Encarregado:</strong>&nbsp;Peck Advogados, CNPJ 42.797.473/0001-01, endere&ccedil;o Rua Henrique&nbsp;Schaumann, 270 - 4o andar, Edif&iacute;cio Pinheiros Corporate, Jardim Paulista, S&atilde;o Paulo, SP, CEP 05413-909.</li>
        <li class="text" align="justify">&nbsp;<strong>Endereço para correspondências:</strong>&nbsp;Avenida Jeronimo Vervloet, 345, Maria Ortiz, Vitoria/Espírito Santo, CEP: 29070- 350.</li>              
        <li class="text" align="justify">&nbsp;<strong>E-mail para contato:</strong>&nbsp;<a class="link" href="mailto:privacidade@vix.com.br">privacidade@vix.com.br</a>.</li>       
      </ul>
    </div>
    </section>
     
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data () {
    return {
      parceiro: "",
      parceiroLandingPage: "",
      window: {
        width: 600,
        height: 150
      }
    }
  },
  computed: {
    style () {
      return `height: ${this.getSize(this.window.height)}; width: ${this.getSize(this.window.width)};`
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted () {
      this.parceiro = window.PARTNER !== undefined ? window.PARTNER : ""
      this.parceiroLandingPage = window.location.origin + "/" + this.parceiro
    },
    methods: {
      handleResize() {
        const oldHeight = this.window.height
        const oldWidth = this.window.width
        //const height = this.$refs.divImg.offsetHeight
        const width = this.$refs.divImg.offsetWidth
        this.window.width = width
        this.window.height = width * oldHeight / oldWidth
      },
      getSize (size) {
        return size.toString()
          .replace(/\d/g, '') ? size : `${size}px`
      },
      goToPartnerLandingPage(){
        this.$router.push({
            path: (window.PARTNER === undefined ? "" : "/" + window.PARTNER)
        })
      }
    }
}
</script>

<style scoped lang="scss">
.body { 
  font-family: "HelveticaNeueLTPro-Roman";
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  padding-top: 30px;
  font: 24px 'HelveticaNeueLTPro-Roman';
  font-size: 24px;  
  text-transform: uppercase;
  color: #cdd625;
}

.topic {
  color: #60BED4;
  font-size: 18px;  
}

.link {
  color: #60BED4; 
}

.sub-topic {
  color: #CDD43F;
}

.text {
  font-family: "HelveticaNeueLTPro-Roman";
  color: #939393;  
}

.table-vix {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 40px;
  color: #939393;
}

.table-dados-pessoais {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 40px;
  color: #939393;
}

.holder {   
  width: auto;
  display: inline-block;  
}


</style>