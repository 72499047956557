<template>
  <div
      class="relative"
  >

   

    <div>
      
      <div
          class="bid-row-3"
      >
        <div
            class="align-content-center"
            style="margin-left: auto; margin-right: auto; height: 100%; width: 100%;"
        >
        
          <input
              ref="bidValue"
              v-model="bidValue"
              :min="price && price.valorLeilao"
              :placeholder="bidValue"
              class="offer-sidebar-input"
              type="text"
              @load="applyMaskMoney('5858')"
              @blur="applyMaskMoney('bidValue')"
              @keypress="keyup($event, 'bidValue')"
              @keyup="magicMaskMoney('bidValue', $event)"
          />

        </div>
        <div
            class="offer-sidebar-card-bid align-content-center offer-sidebar-card-bid-no-mobile"
            style="margin-left: auto; margin-right: auto; margin-bottom: 0;"
            @click="sendBid(0)"
        >
          <span>{{ $t('OfferBar.sendBid') }}</span>
        </div>
      </div>

      <div
          class="bid-row-1"
      >
        <div
            v-for="(increment, i) in finalIncrements"
            :key="i"
            class="offer-sidebar-card-bid-increments align-content-center"
            @click="sendBid(increment)"
        >
          <span style="width: 100%; text-align: center; white-space: nowrap;">{{ increment.number2Money() }}</span>
        </div>
        <!-- DISPLAY: LEILÃO || Contador vv-->
    <div
        class="offer-sidebar-countdown-container"
    >


      <Countdown
          :small="$vuetify.breakpoint.xs"
          :time="offer.end"
          style="margin-bottom: 20px; font-size:12px;"
      />
    </div>

      </div>

      <v-row justify="center" align="center">
        <div
            class="offer-sidebar-card-bid align-content-center offer-sidebar-card-bid-mobile"
            style="margin-left: auto; margin-right: auto;"
            @click="sendBid(0)"
        >
          <span>{{ $t('OfferBar.sendBid') }}</span>
        </div>
      </v-row>
      <v-row justify="center" align="center">
        <div
            class="offer-sidebar-card-bid auto-bid align-content-center span-column"
            @click="automaticBid"
        >
          <span class="offer-sidebar-card-bid-auto-bid-span">{{ $t('OfferBar.autoBid') }}</span>
        </div>
      </v-row>
      
    </div>

    <!-- Dialogo de autolance -->
    <v-dialog
        v-model="dialogAutoBid"
        width="fit-content"
    >
      <v-card>
        <v-card-text style="padding: 24px;">
          <div
              class="dialog-info-header"
          >
            <div class="dialog-info-title">{{ $t('OfferBar.autoBidBar') }}</div>
            <div
                class="dialog-info-close"
                @click="dialogAutoBid = false"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div class="dialog-bid">
            <div
                class="dialog-info-text"
            >
              <ul>
                <li><span>{{ $t('OfferBar.dialogAutoBIdText01', {count: increments[0]}) }}</span></li>
                <li><span>{{ $t('OfferBar.dialogAutoBIdText02', {count: increments[0]}) }}</span></li>
                <li><span>{{ $t('OfferBar.dialogAutoBIdText03', {count: increments[0]}) }}</span></li>
              </ul>            
            </div>
            <label style="width: 100%">
              <div class="flux-address-label">
                {{ $t('OfferBar.dialogAutoBidMaxValue') }}
              </div>
              <input
                  ref="autoBidValue"
                  v-model="autoBidValue"
                  class="flux-address-input medium"
                  name="purposeValue"
                  placeholder="R$"
                  required
                  style="margin-bottom: 30px;"
                  type="text"
                  @blur="applyMaskMoney('autoBidValue')"
                  @keypress="keyup($event, 'autoBidValue')"
                  @keyup="magicMaskMoney('autoBidValue', $event)"
              />
            </label>

            <div class="flux-button align-content-center">

              <div
                  class="offer-sidebar-button-buy align-content-center"
                  @click="sendAutoBid"
              >
                <span>{{ $t('global.sendButton') }}</span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogo de autolance ^^-->

   <!-- dialogo de limite compra ^^-->
    <v-dialog
        v-model="dialogFake"
        width="fit-content"
    >
      <v-card>
        <v-card-text style="padding: 24px;">
          <div
              class="dialog-info-header"
          >
            <div class="dialog-info-title">{{ isBuy ? $t('OfferBar.buyLimit') : $t('OfferBar.bidLimit') }}</div>
            <div
                class="dialog-info-close"
                @click="dialogFake = false; isBid = false; isBuy = false;"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div class="dialog-bid">
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ isBuy ? $t('OfferBar.buyLimitText1') : $t('OfferBar.bidLimitText1') }}
            </div>
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ isBuy ? $t('OfferBar.buyLimitText2') : '' }}
            </div>

            <div class="flux-button-fake">

              <div
                  class="offer-sidebar-button-buy align-content-center"
                  style="width: 92px;"
                  @click="dialogFake = false; isBid = false; isBuy = false;"
              >
                <span>{{ $t('OfferBar.buyLimitButton') }}</span>
              </div>
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialogo de limite compra ^^-->

   <v-dialog
        v-model="success"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="info"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgSuccess }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="goToCadastrar"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

     <!-- dialogo de recusa de valor ^^-->

   <v-dialog
        v-model="dialogBidMinimumValue"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="info"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgBidMinimumValue }}</v-col>
          <v-col class="shrink">
            <v-btn
                text  
                @click="dialogBidMinimumValue = false"             
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>



  </div>
</template>
<script>
import {mapMutations} from "vuex";
import XMarkIcon from "@/shared/Icons/XMarkIcon";
import CookieControl from "@/cookie-control";
import {OfferResource} from '@/api';
import Countdown from '@/shared/Countdown/Countdown'

const cookieControl = new CookieControl()

export default {
  name: 'BidControl',
  components: {XMarkIcon, Countdown},
  props: {
    price: {
      type: Object,
      default: () => {
        return {
          codigoOferta: 0,
          codigoPerfil: 0,
          valorCompreJa: 0,
          valorLeilao: 0,
        }
      }
    },
    offer: {
      type: Object,
      default: () => {
        return {}
      }
    },
    increments: {
      type: Array,
      default: () => [100, 200, 300, 400]
    }
  },
  computed: {
    finalIncrements() {
      const bidValue = Math.ceil((this.price && this.price.valorLeilao) || 0)
      return this.increments
          .map(v => (bidValue + v).number2Money(2, ''))
          .splice(0, 4)
    },
    priceBid() {
      if (!this.price.valorLeilao) return 'R$ 0.000,00*'
      return this.price.valorLeilao.number2Money()
    },
    
  },
  data() {
    return {
      bidValue: this.price.valorLeilao.number2Money(),
      dialogAutoBid: false,
      dialogFake: false,
      isBid: false,
      isBuy: false,
      autoBidValue: '',
      msgSuccess: "",
      success: false,
      bidBaseValue: this.price.valorLeilao.number2Money(), // guarda valor inicial do lance
      dialogBidMinimumValue: false,
      msgBidMinimumValue: ""
    }
  },
  methods: {
    ...mapMutations('fluxStore', [
      'SET_FLUX_BID', 'SET_OFFER', 'SET_FLUX_AUTO_BID'
    ]),
    async sendBid(bid) {

      const isBidValueLower = this.bidValue < this.bidBaseValue
      if (isBidValueLower) {
        this.msgBidMinimumValue = this.$t('negotiation.bidMinimumAmount') + ' ' + this.applyMaskMoney('bidBaseValue')
        this.dialogBidMinimumValue = true
        return
      }

      if (!this.isUserLogin()) {
        this.msgSuccess = this.$t('global.needLogin')
        this.success = true
        return
      }

      bid = bid || this.applyMaskMoney('bidValue')
      if (!bid) return ''
      if (!bid.includes(',')) {
        bid = bid.onlyNumber().number2Money(2, '')
      }
      if (!this.isUserLogin()) {
        this.msgSuccess = this.$t('global.needLogin')
        this.success = true
        return
      }
      const response = await OfferResource.getLimit()
      if (response.body) {
        for(var resp of response.body) {
          if(resp.tipo === 'PODE_DAR_LANCE' && !resp.valor) {
            this.dialogFake = !resp.valor
            this.isBid = true
            this.dialogAutoBid = false
            return
          }
        }
      }
      this.SET_FLUX_BID(bid)
      this.SET_OFFER(this.offer)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/fluxo/'
      }).catch(() => {
      })
    },
    isUserLogin() {
      return cookieControl.isLogin()
    },
    async sendAutoBid() {
      if (!this.autoBidValue) return '' // TODO: 21/08/2020 mensagem de erro
      if (!this.isUserLogin()) {
        this.msgSuccess = this.$t('global.needLogin')
        this.success = true
        return
      }
      const response = await OfferResource.getLimit()
      if (response.body) {
        for(var resp of response.body) {
          if(resp.tipo === 'PODE_DAR_LANCE' && !resp.valor) {
            this.dialogFake = !resp.valor
            this.isBid = true
            this.dialogAutoBid = false
            return
          }
        }
      }
      this.SET_FLUX_AUTO_BID(this.autoBidValue)
      this.SET_OFFER(this.offer)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/fluxo/'
      }).catch(() => {
      })
    },
    automaticBid() {
      if (!this.isUserLogin()) {
        this.msgSuccess = this.$t('global.needLogin')
        this.success = true
        return
      }
      this.dialogAutoBid = true
    },
    maskMoney(id) {
      if (this.maskMoneyTimeoutID) clearTimeout(this.maskMoneyTimeoutID)
      this.maskMoneyTimeoutID = setTimeout(() => {
        this.applyMaskMoney(id)
      }, 5000)
    },
    applyMaskMoney(id) {
      if (this[id] === '0,00') {
        this[id] = ''
      }
      return this[id]
    },
    goToCadastrar() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/auth/'
      }).catch(() => {
      })
    },
    magicMaskMoney(id, event) {
      if (event.key.length > 1 || event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
        if (event.key !== 'Backspace' && event.key !== 'Delete') {
          return
        }
      }

      let pos = this.$refs[id].selectionStart.toString()

      if (this[id].length === 1) {
        this[id] = '0,0' + this[id]
        pos = 4
      }
      this[id] = this[id].number2Money(2, '')
      if (this[id] === '0,00') {
        this[id] = ''
      }

      if (this[id].onlyNumber().length > 5 && (this[id].onlyNumber().length - 2) % 3 === 1) {
        pos++
      }

      setTimeout(() => {
        this.$refs[id].selectionStart = pos
        this.$refs[id].selectionEnd = pos

      }, 100)
      this[id] = 'R$ ' + this[id]
      return this[id]
    },
    keyup(event, inputRef) {
      const key = event.key
      if (!key.onlyNumber()) {
        event.preventDefault()
      } else if (this[inputRef].length === 0) {
        this[inputRef] = '0,0' + key.onlyNumber();
        event.preventDefault()
      } else if (this[inputRef].onlyNumber().length === 1) {
        this[inputRef] = '0,' + this[inputRef].onlyNumber() + key.onlyNumber();
        event.preventDefault()
      }
    },
  },
}
</script>
<style
    scoped
    type="text/css"
>


p.offer-sidebar-buy-price-subtitle {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 20px;
}

p.offer-seller > span {
  color: #60BED4;
}

.offer-sidebar-countdown-container{
width: 100%;
font-size: 12px;
}

.offer-sidebar-countdown-container span{
  font-size: 14px;
  text-indent: 0px;
}

.offer-sidebar-button-buy {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 3px #FFFFFF80, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 211px;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.offer-sidebar-button-buy.big {
  width: 212px;
}

.offer-sidebar-button-buy > span {
  display: block;
  line-height: 1;
}


.offer-sidebar-card-bid.auto-bid {
  background: #60BED4 0 0 no-repeat padding-box;
  box-shadow: 0 2px 3px #00000033;
  padding-left: 28px;
  padding-right: 28px;
  width: 100%;
  height: 45px;
  font: 18px 'HelveticaNeue-Medium';
  color: #ffffff;
}


.offer-sidebar-card-bid-auto-bid-span {
  width: 260px;
  color: #ffffff;
}
.offer-sidebar-card-bid {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 18px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #000000;
  height: 45px;
  margin-bottom: 20px;
  cursor: pointer;
  padding-left: 22px;
  padding-right: 22px;
  flex: 1;
  width: 100%;
}


.offer-sidebar-card-bid-increments {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  opacity: 1;
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  height: 30px;
  width: 200px;
  margin-bottom: 15px;
  cursor: pointer;
  padding-left: 22px;
  padding-right: 22px;
  flex: 0;
}

.offer-sidebar-card-bid > span {
  display: block;
  line-height: 1;
  white-space: nowrap;
}

.bid-row-3 {
  display: grid;
  width: 100%;
  grid-template-columns: auto 110px;
  column-gap: 9px;
  align-items: center;
  justify-items: center;
  margin-bottom: 15px;
}

.bid-row-1 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}


.offer-sidebar-input {
  background: #FFFFFF;
  font: normal normal bold 26px/33px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  border: 1px solid #d4e2f5;
  max-height: 40px;
  width: 100%;
  text-indent: 11px;
  text-align: right;
  margin-right: 10px;
}

.offer-sidebar-input::placeholder {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #C9CDD0;
  opacity: 1;
}

.dialog-info-header {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  width: 100%;
}

.dialog-info-title {
  text-align: left;
  font: normal normal normal 30px/39px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #2D2D2D;
  flex: 1;
  opacity: 1;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
  margin-top:10px;
  width:18%;
}

.dialog-info-text {
  width: 583px;
  text-align: left;
  letter-spacing: 0;
  font: normal normal normal 18px/24px 'HelveticaNeueLTPro-Roman';
  color: #414141;
  opacity: 1;
  text-align: left;
  margin-bottom: 30px;
}

.dialog-info-text ul{
  width: 100%;
}

.dialog-info-text ul li{
  width: 97%;
  color: #CDD43F; 
  font-weight:bold; 
  font-size:22px;
}

.dialog-info-text ul li span{
  font-weight:normal; 
  color:#000; 
  font-size:16px;
}

.dialog-info-text ul li{
  margin-top:10px;
}

.flux-address-label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #1d1c1c;
  margin-bottom: 15px;
}

.flux-address-input {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 26px/33px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 48px;
  width: 583px;
  padding-left: 11px;
  margin-bottom: 20px;
  flex: 0;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}

.flux-address-input:read-only {
  opacity: 0.3;
}

.flux-button {
  flex: 0;
  width: 583px;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.flux-button-fake {
  flex: 0;
  min-width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
}

.dialog-bid {
  width: 583px;
}

.offer-sidebar-card-bid-no-mobile{
  display: block;
}

.offer-sidebar-card-bid-no-mobile > span{
  position: relative;
  height: 100%;
  width: 110px;
  left: -22px;
  line-height: 45px;
}

.offer-sidebar-card-bid-mobile{
  display: none;
}

@media screen and (max-width: 601px) {



  .offer-sidebar-card-bid-no-mobile{
    display: none;
  }

  .offer-sidebar-card-bid-mobile{
    display: block;
    margin-bottom: 10px;
  }

  .offer-sidebar-card-bid-mobile > span{
    height: 100%;
    line-height: 45px;
  }

  .dialog-info-text {
    width: 100%;
  }

  .dialog-bid {
    width: 100%;
  }

  .medium {
    width: 100%;
  }

  .dialog-info-title{
    font: normal normal normal large 'HelveticaNeueLTPro-Bd';
  }

  .dialog-info-text{
    margin-bottom: 25px;
  }

  .dialog-info-text ul{
    width: 100%;
  }

  .dialog-info-text ul li{
    width: 95%;
    color: #CDD43F; 
    font-weight:bold; 
    font-size:small;
    line-height:18px
  }

  .dialog-info-text ul li span{
    font-weight:normal; 
    color:#1d1c1c; 
    font-size:small;
  }
  .flux-address-label {
    font: 13px 'HelveticaNeueLTPro-Roman';
  }

  .flux-address-input {
    font: medium 'HelveticaNeueLTPro-Md';
    color: #000000;
    border: 1px solid #c5bfbf;
    background:#FFFFFF;
    padding-left: 11px;
    margin-bottom: 20px;
    flex: 0;
    box-shadow: none;
    border-radius: 6px;
  }
  .dialog-info-close {
   margin-top:auto;
  }

  .flux-button {
    width: 100%;
  }

  .bid-row-3 {
    display: flex;
    flex-wrap: wrap;
  }

  .bid-row-3 .offer-sidebar-input {
    margin: 0;
    margin-bottom: 10px;
  }

}

</style>
