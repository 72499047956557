<template>
  <div>

    <div
        :class="{'hide': !showNegotiationContent }"
        class="filter-header-back"
    >
      <div
          :class="{'selected': sidebarSelected === 'negotiation'}"
          class="negotiation-sidebar-item negotiation-sidebar-item-tab"
          style="margin-bottom: 0;"
          @click="showNegotiation()"
      >
        {{ $t('HeaderBar.negotiations') }}
      </div>
      <div
          :class="{'selected': sidebarSelected === 'purchased'}"
          class="negotiation-sidebar-item negotiation-sidebar-item-tab"
          style="margin-bottom: 0;"
          @click="showPurchased()"
      >
        {{ $t('HeaderBar.myPurchase') }}
      </div>
    </div>

    <div
        class="negotiation-screen"
    >
      <div
          :class="{'hide': showNegotiationContent && $vuetify.breakpoint.xs }"
          class="negotiation-sidebar"
      >
        <div
            :class="{'selected': sidebarSelected === 'negotiation'}"
            class="negotiation-sidebar-item"
            @click="showNegotiation()"
        >
          {{ $t('HeaderBar.negotiations') }}
        </div>
        <div
            :class="{'selected': sidebarSelected === 'purchased'}"
            class="negotiation-sidebar-item"
            @click="showPurchased()"
        >
          {{ $t('HeaderBar.myPurchase') }}
        </div>
      </div>

      <div
          :class="{'hide': !showNegotiationContent }"
          class="negotiation-content"
      >
        <div
            v-if="myOffers.length > 0"
            class="negotiation-content-column"
        >
          <NegotiationRow
              v-for="(offer, i) in myOffers"
              :key="i"
              :offer="offer"
          />
        </div>
        <div
            v-else
            class="negotiation-content-column"
        >
          <div
              v-if="sidebarSelected === 'negotiation'"
              class="notfound"
          >
            <PlaceholderCarIcon/>
            <div class="notfound-title">{{ $t('negotiation.negotiationsNotFound.title') }}</div>
            <div class="notfound-text">{{ $t('negotiation.negotiationsNotFound.text') }}</div>
          </div>
          <div
              v-else
              class="notfound"
          >
            <PlaceholderCreditCardIcon/>
            <div class="notfound-title">{{ $t('negotiation.myPurchaseNotFound.title') }}</div>
            <div class="notfound-text">{{ $t('negotiation.myPurchaseNotFound.text') }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import NegotiationRow from '@/screen/Negotiation/NegotiationRow'
import {mapMutations, mapState} from 'vuex'
import {NegotiationResource} from '@/api'
import PlaceholderCarIcon from "@/shared/Icons/PlaceholderCarIcon";
import PlaceholderCreditCardIcon from "@/shared/Icons/PlaceholderCreditCardIcon";

export default {
  name: 'NegotiationIndex',
  components: {PlaceholderCreditCardIcon, PlaceholderCarIcon, NegotiationRow},
  computed: {
    ...mapState('negotiationStore', [
      'offers'
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xs
    },
    showNegotiationContent() {
      if (!this.isMobile) {
        return true
      }
      return this.showContent
    },

    sidebarSelected() {
      return this.$route.query.view === 'compra' ? 'purchased' : 'negotiation'
    },
    myOffers() {
      if(this.componentLoaded) {
        if (this.sidebarSelected === 'purchased') {
          return this.offers.filter(v => v.winner === 'W')
        }
        return this.offers.filter(v => v.winner !== 'W')
      } else {
        return []
      }
    }
  },
  data() {
    return {
      showContent: true,
      componentLoaded: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations('negotiationStore', ['SET_MY_OFFERS']),
    async init() {
      const response = await NegotiationResource.getList()
      this.SET_MY_OFFERS(response.body.data)
      this.componentLoaded = true
    },
    showNegotiation() {
      this.showContent = true
      let path = '/negociacao?view=negociacao'
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {
      })
    },
    showPurchased() {
      this.showContent = true
      let path = '/negociacao?view=compra'
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {
      })
    },
    close() {
      this.showContent = false
    },
  },
}
</script>

<style type="text/css">
.negotiation-screen {
  min-height: 797px;
  display: flex;
  flex-wrap: nowrap;
}

.negotiation-content {
  background: #FCFCFC 0 0 no-repeat padding-box;
  height: auto;
  width: 100%;
  display: flex;
  align-content: center;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 76px;
  flex-wrap: nowrap;
  justify-content: center;
}

.negotiation-content-column {
  height: auto;
  width: 999px;
  display: flex;
  align-content: center;
  flex-flow: column;
  flex-wrap: nowrap;
}

.negotiation-sidebar {
  height: auto;
  width: 209px;
  padding-top: 77px;
  display: flex;
  flex-flow: column;
  background: #F5F5F7 0 0 no-repeat padding-box;
}

.negotiation-sidebar-item {
  width: 209px;
  height: 49px;
  opacity: 1;
  margin-bottom: 13px;
  text-align: left;
  font: 16px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #8B8B8B;
  padding-top: 15px;
  padding-left: 36px;
  padding-bottom: 15px;
  cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.negotiation-sidebar-item.selected {
  background: #60BED4 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 13px;
  text-align: left;
  font: 16px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #FFFFFF;
}


/*noinspection CssUnusedSymbol*/
.hide {
}

@media screen and (max-width: 1279px) {
  .negotiation-content-column {
    width: fit-content;
  }

  .negotiation-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 601px) {
  .negotiation-sidebar {
    /*display: flex;*/
    /*align-items: center;*/
    width: 100%;
  }

  .negotiation-sidebar-item {
    width: 100%;
  }

  .negotiation-content {
    padding: 25px;
  }

  /*noinspection CssUnusedSymbol*/
  .hide {
    display: none;
  }
}

.negotiation-sidebar-item-tab {
  margin-bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px 20px 80px;
}

.notfound-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 20px;
}

.notfound-text {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
  text-align: center;
}

</style>
