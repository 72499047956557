<template>
  <div style="background: #ffffff 0 0 no-repeat padding-box; flex:0;">

    <v-layout
      justify-space-around
      row
      wrap
    >

      <v-card
        class="mx-12 d-flex justify-center flex-column align-center"
        color="transparent"
        style="margin-top:104px;margin-bottom:164px;width:450px;text-align:justify;"
        elevation="0"
      >

        <div class="title mb-10" style="font: 36px 'HelveticaNeueLTPro-Roman'; font-weight: 600">
          Muito obrigado
        </div>

        <div class="body-2 mb-10 text-center">
          Suas respostas irão ajudar para que nossos serviços fiquem cada vez melhores e mais eficientes.
        </div>

        <a href="#" class="body-2 mb-10" style="color: #60BED4" @click="goToHome">
          Retornar a home
        </a>

      </v-card>

    </v-layout>

  </div>
</template>

<script>
export default {
  name: 'SurveyFinalization',
  methods: {
    goToHome() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/`
      })
    }
  }
}
</script>

<style scoped>

</style>
