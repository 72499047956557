<template>
  <div
      class="brand-btn"
  >
    <div
        style="height: 57.5px; width: 43.25px"
        class="align-content-center"
    >
      <v-img
          :src="getImageUrl(obj.ativo, obj.inativo, selected)"
          height="50px"
          width="50px"
          contain
      />
    </div>
    <FilterBtn
        :height="height"
        :selected="selected"
        :title="obj.name"
        :width="width"
        @click="emitClick"
        style="margin-left: 10px;"
    />
  </div>
</template>
<script>
import FilterBtn from './FilterBtn'
import { getImageUrl } from '@/api'

export default {
  name: 'BrandBtn',
  components: { FilterBtn },
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    height: {
      type: String || Number,
      default: '30px'
      },
      width: {
        type: String || Number,
        default: '100px'
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      forceSize (svg) {
        if (!svg) return ''
        const clean = svg
            .replace(/width=["']\d*\.?\d*["']/, '')
            .replace(/height=["']\d*\.?\d*["']/, '')
            .replace(/[\n\r\t]/g, '')
        const rgx = /<svg.*>/

        return clean.replace(rgx, rgx.exec(clean)[0].replace('>', 'width="50" height="35">'))
      },
      emitClick() {
        this.$emit('click')
      },
      getImageUrl(endpointAtivo, endpointInativo, active) {
        return getImageUrl(active ? endpointAtivo : endpointInativo)
      }
    },
  }
</script>
<style
    scoped
    type="text/css"
>

  .brand-btn {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-items: center;
  }
</style>
