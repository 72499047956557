<template>
  <v-layout
      justify-space-around
      row
      wrap
  >
    <v-card
        class="mx-12"
        color="transparent"
        elevation="0"
        style="margin-top:103px;"
    >

      <div
          :style="showError ? '' : 'visibility: hidden;'"
          class="password-error-message align-content-center"
      >
        {{ showError }}
      </div>


      <v-card-text style=" padding-left: 25px; padding-right: 25px;">
        <div class="password-header">
          <div class="password-title">
            {{ $t('Auth.createNewPass') }}
          </div>
        </div>

        <v-form
            ref="form"
            class="password-form-container"
        >
        <div style="margin-top:37px;">

            <label>
              <div class="label">
                {{ $t('Auth.passwordRequirements') }}
              </div>
              <ul id="example-1">
              <li class="label">
                {{ $t('Auth.min8characters') }}
              </li>
              <li class="label">
                {{ $t('Auth.atLeast1Low') }}
              </li>
              <li class="label">
                {{ $t('Auth.atLeast1Upp') }}
              </li>
              <li class="label">
                {{ $t('Auth.atLeast1Num') }}
              </li>
              <li class="label">
               {{ $t('Auth.atLeast1Esp') }}
              </li>
              </ul>
            </label>

            <label>
              <div class="label">
                {{ $t('Auth.createPass') }}
              </div>
              <input
                  v-model="password"
                  class="input-password"
                  name="password"
                  required
                  type="password"
              />

            </label>
            <label>
              <div class="label">
                {{ $t('Auth.repeatPass') }}
              </div>
              <input
                  v-model="password_repeat"
                  class="input-password"
                  name="password_repeat"
                  required
                  type="password"
              />

            </label>
          </div>

          <div
              :class="{'password-button-disabled': !isValid}"
              class="password-button align-content-center"
              style="margin-left: auto; margin-right: auto;margin-top:40px;"
              @click="savePassword"

          >
            <span>{{ $t('Auth.changePassword') }}</span>
          </div>

        </v-form>

      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import {AuthResource} from '@/api'
import {mapMutations} from "vuex";

export default {
  name: 'Senha',
  components: '',
  data () {
    return {
      valid: false,
      password: '',
      password_repeat: '',
      error: false,
      errorMessage: '',
      success: false,
      divHeight: 450,
      showError: ''
    }
  },
  computed: {
    isValid () {
      const basic = this.password &&
          this.password_repeat

      if (!basic) return false

      return basic
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations('authStore',
        ['SIGN_OUT']
    ),
    async savePassword() {
      this.showError = false
      if (!this.isValid) {
        return this.showError = this.$t('Auth.requiredFieldsPass')
      }
      if (this.password !== this.password_repeat) {
        return this.showError = this.$t('Auth.requiredSamePass')
      }
      if (this.testPassword()) {
        return this.showError = this.testPassword()
      }

      const response = await AuthResource.postPassword({
        'senha': this.password
      })

      if (response.status === 204) {
        this.success = true
        this.goToHome()
      } else if (response.status === 404) {
        this.showError = this.$t('Auth.userNotFound')
      } else {
        this.showError = response.body ? response.body.erros[0] : this.$t('global.fail')
      }
    },
    testPassword () {
      let retorno = ''
      if (!(/[a-z]/.exec(this.password))) {
        retorno = this.$t('Auth.requiredASmallCase')
      } else if (!/[A-Z]/.exec(this.password)) {
        retorno = this.$t('Auth.requiredUppercase')
      } else if (!(/\d/.exec(this.password))) {
        retorno = this.$t('Auth.requiredNumber')
      } else if (!(/[!@#$%]/.exec(this.password))) {
        retorno = this.$t('Auth.requiredSpecialChar')
      } else if (!((this.password || '').length >= 8)) {
        retorno = this.$t('Auth.requiredMinChar')
      }
      return retorno
    },
    goToHome() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/confirmar/password`
      })
    },
  },
  beforeDestroy() {
    if (!this.success) {
      this.SIGN_OUT()
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/home`
      })
    }
  }
}
</script>

<style
    scoped
    type="text/css"
>
.label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
}

.input-password {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 28px;
  width: 442px;
  padding-left: 11px;
  margin-bottom: 20px;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}


.password-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}

.password-header {
  position: relative;
  margin-bottom: 16px;
}

.password-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 78px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.password-button > span {
  display: block;
  line-height: 1;
}

/*noinspection CssUnusedSymbol*/
.password-button-disabled {
  color: #2D2D2D !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
}

.password-form-container {
  display: flex;
  flex-wrap: wrap;
  width: 442px;
}

.radio-button-password > input {
  margin-right: 8px;
}

input[type=radio] {
  background: black;
}

input:checked {
  background: #000;
}

.password-error-message {
  height: 27px;
  background: #D85757 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #FFFFFF;
}

/*noinspection CssUnusedSymbol*/
.has-error {
  border-color: #e09e9e;
}


@media screen and (max-width: 601px) {
  .password-form-container {
    width: 100%;
    display: block;
  }

  .input-password {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
  }

  .password-header {
    margin-bottom: 20px;
  }

  /*noinspection CssUnusedSymbol*/
  .hide-error {
    visibility: unset;
    display: none;
  }

  .full-width {
    width: 100%;
  }

}
</style>
