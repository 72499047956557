<template>
  <v-layout
      justify-space-around
      row
      wrap
  >
    <v-card
        color="transparent"
        elevation="0"
        style="margin-top:102px;"
    >

      <div
          :style="showError ? '' : 'visibility: hidden;'"
          class="forgot-error-message align-content-center"
      >
        {{ showError }}
      </div>


      <v-card-text style="padding-left: 25px; padding-right: 25px;">
        <div class="forgot-header">
          <div class="forgot-title">{{ $t('Auth.forgotPass')}}</div>
        </div>

        <v-form
            ref="form"
            class="forgot-form-container"
        >

          <v-row>
            <div class="form-group" :class="{ 'has-error': $v.form.email.$anyError }">
              <label for="email"> {{ $t('global.mail') }}</label>
              <input id="email" type="text" class="form-control" v-model.trim="$v.form.email.$model" inputmode="email">
              <div class="help-text">{{ $t('Auth.informMail') }}</div>

              <span class="invalid-feedback justify-start" v-if="!$v.form.email.required">Digite seu e-mail</span>
              <span class="invalid-feedback justify-start" v-if="!$v.form.email.email">Digite um e-mail válido</span>
            </div>
          </v-row>

          <v-row justify="center">
            <button type="button" class="p-button p-button-primary" style="margin-top: 40px; color: #2D2D2D" @click="forgot">{{ $t('global.sendButton') }}</button>
          </v-row>

        </v-form>

      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import { AuthResource } from '@/api'
import {email, required} from 'vuelidate/lib/validators'

export default {
  name: 'EsqueceuSenha',
  components: '',
  data: () => ({
    success: false,
    divHeight: 450,
    showError: '',
    form: {
      email: null,
    },
  }),
  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },
  mounted () {
  },
  methods: {
    async forgot () {
      this.$v.$touch()

      this.showError = false
      if (!this.$v.$invalid) {
        const response = await AuthResource.postForgot({
          'email': this.form.email
        })

        if (response.status === 204) {
          this.success = true
          this.goToConfirmar()
        } else if (response.status === 404) {
          this.showError = this.$t('global.mailNotFound')
        } else {
          this.showError = response.body ? response.body.erros[0] : this.$t('global.fail')
        }
      }
    },
    goToConfirmar () {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/confirmar/forgot`
      })
    },

  }
}
</script>

<style
    scoped
    type="text/css"
>
.label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
}

.input-forgot {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 28px;
  width: 442px;
  padding-left: 11px;
  margin-bottom: 20px;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}


.forgot-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}

.forgot-header {
  position: relative;
  margin-bottom: 16px;
}

.forgot-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 78px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.forgot-button > span {
  display: block;
  line-height: 1;
}

.forgot-form-container {
  display: flex;
  flex-wrap: wrap;
  width: 442px;
}

.forgot-error-message {
  height: 27px;
  background: #D85757 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #FFFFFF;
}

/*noinspection CssUnusedSymbol*/
.has-error {
  border-color: #e09e9e;
}

@media screen and (max-width: 601px) {
  .forgot-form-container {
    width: 100%;
  }

  .input-forgot {
    width: 100%;
  }

  .forgot-header {
    margin-bottom: 20px;
  }

  /*noinspection CssUnusedSymbol*/
  .hide-error {
    visibility: unset;
    display: none;
  }

  .full-width {
    width: 100%;
  }

  .social-spacer {
    flex: 1;
    min-width: 100%;
    height: 20px;
  }
}

</style>
