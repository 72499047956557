const NotificationApi = (vueResource, url) => {
  const resource = vueResource(`${url}/usrio/notif/{/PATH}{/ID}{?Q*}`)

  return {
    async getList () {
      try {
        return await resource.get()
      } catch (e) {
        return { body: [] }
      }
    },
    async postNotification (payload) {
      try {
        return await resource.save(payload)
      } catch (e) {
        return e
      }
    },
  }
}
export default NotificationApi