const state = {}

const mutations = {}

const actions = {}

const getters = {}

const notificationStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default notificationStore
