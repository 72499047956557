import dataLayer from '../data-layer';
import { SubCategoriesResource, BrandResource, ModelResource } from '@/api'
import { getLoggedUserId, getOfferProperties } from '../util'

class ProductEvents {

  searched(term) {
    dataLayer.event({
      event: 'Products Searched',
      text_searched: term,
    });
  }

  async filtered({ price_range, mileage_range, brand, model, category }) {
    const codigoUsuario = getLoggedUserId() || ''
    let categories = [];
    let brands = [];
    let models = []

    if (category.length) {
      const response = await SubCategoriesResource.listByIds(category)

      if (response.ok) {
        categories = response.data.data.map(c => c.name)
      }
    }

    if (brand.length) {
      const response = await BrandResource.listByIds(brand)

      if (response.ok) {
        brands = response.data.data.map(b => b.name)
      }
    }

    if (model.length) {
      const response = await ModelResource.listByIds(model)

      if (response.ok) {
        models = response.data.data.map(b => b.name)
      }
    }

    dataLayer.event({
      event: 'Products Filtered',
      user_id: codigoUsuario,
      price_range,
      mileage_range,
      brand: brands.join(', '),
      model: models.join(', '),
      category: categories.join(', '),
    });
  }

  sorted({ sorting_by }) {
    const codigoUsuario = getLoggedUserId() || ''

    dataLayer.event({
      event: 'Products Sorted',
      user_id: codigoUsuario,
      sorting_by,
    });
  }

  async detailsViewed(offer) {
    const offerProps = await getOfferProperties(offer)
    const codigoUsuario = getLoggedUserId() || ''
    const url = window.location.origin

    dataLayer.event({
      event: 'Product Details Viewed',
      user_id: codigoUsuario,
      url: `${url}/#/oferta/${offerProps.offer_id}`,
      ...offerProps
    });
  }

  async favorited(offer) {
    const offerProps = await getOfferProperties(offer)
    const codigoUsuario = getLoggedUserId() || ''
    const url = window.location.origin

    dataLayer.event({
      event: 'Product Favorited',
      user_id: codigoUsuario,
      url: `${url}/#/oferta/${offerProps.offer_id}`,
      ...offerProps
    })
  }

}

export default ProductEvents;
