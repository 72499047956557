<template>
  <div
      class="step-row"
      @click="$emit('click')"
  >
    <div
        :class="{'selected': selectedStep, 'cursor-pointer': selectedLine}"
        class="circle-outside align-content-center relative"
    >
      <div
          class="circle-inside"
          v-if="selectedStep"
      />
      <div class="label">
        {{ $t(stepText) }}
      </div>
    </div>
    <div
        class="align-content-center"
        v-if="!isLast"
    >
      <div
          :class="{'selected': selectedLine}"
          class="step-line"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepPoint',
  props: {
    selectedStep: {
      type: Boolean,
      default: false
    },
    selectedLine: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    stepText: {
      type: String,
      default: ''
    }
  }
}
</script>
<style
    scoped
    type="text/css"
>
.circle-outside {
  background: #F5F6F7 0 0 no-repeat padding-box;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  box-shadow: -1px -1px 1px #00000014;
  border: 1px solid #CCCCCC;
}

.circle-outside.selected {
  box-shadow: 2px 2px 2px #FFFFFF;
  border: 1px solid #60BED4;
}

.circle-inside {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #60BED4 0 0 no-repeat padding-box;
}

.step-line {
  flex: 0;
  min-width: 129px;
  border: 1.5px solid #C9CDD0;
}

/*noinspection CssUnusedSymbol*/
.step-line.selected {
  border-color: #60BED4;
}

.label {
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-top: 14px;
  position: absolute;
  bottom: -30px;
}

.step-row {
  display: flex;
  flex-flow: row;
  align-content: center;
}

/*@media screen and (max-width: 1279px){*/
/*  .step-line {*/
/*    min-width: 110px;*/
/*  }*/
/*}*/

@media screen and (max-width: 601px) {

  .step-line {
    min-width: 100px;
  }
}
</style>
