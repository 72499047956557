const FluxApi = (vueResource, url) => {
  const resourceUser = vueResource(`${url}/usrio/edit{/PATH}{/ID}{?Q*}`)
  const resourceAddress = vueResource(`${url}/usrio/address{/PATH}{/ID}{?Q*}`)
  const resourceEndereco = vueResource(`${url}/endereco{/PATH}{/ID}{?Q*}`)

  return {
    async getUser () {
      try {
        return await resourceUser.get()
      } catch (e) {
        return e
      }
    },
    async putUser (payload) {
      try {
        return await resourceUser.update(payload)
      } catch (e) {
        return e
      }
    },
    async getCep (id) {
      try {
        return await resourceEndereco.get({ PATH: 'cep', ID: id })
      } catch (e) {
        return e
      }
    },
    async getEstados () {
      try {
        return await resourceEndereco.get({ PATH: 'estados'})
      } catch (e) {
        return e
      }
    },
    async getCidades (id) {
      try {
        return await resourceEndereco.get({ PATH: 'cidades', ID: id})
      } catch (e) {
        return e
      }
    },
    async getAddress () {
      try {
        return await resourceAddress.get()
      } catch (e) {
        return e
      }
    },
    async putAddress (payload) {
      try {
        return await resourceAddress.update(payload)
      } catch (e) {
        return e
      }
    },
    async postAddress (payload) {
      try {
        return await resourceAddress.save(payload)
      } catch (e) {
        return e
      }
    },
    async deleteAddress (id) {
      try {
        return await resourceAddress.delete({ ID: id })
      } catch (e) {
        return e
      }
    },
  }
}

export default FluxApi