import CookieControl from '../../cookie-control'

const state = {
  user: {},
  interestToken: '',
  clientType: null,
  companyType: null
}

const cookieControl = new CookieControl()

const mutations = {
  ['SET_USER'] (state, payload) {
    state.user = payload
    cookieControl.saveCookie(cookieControl.tokenCookie, payload.token)
    localStorage.setItem('VIXToken', payload.token)
  },
  ['SET_INTEREST_TOKEN'] (state, payload) {
    state.interestToken = payload
    localStorage.setItem('VIXToken', payload)
  },
  ['SIGN_OUT'] (state) {
    state.user = {}
    cookieControl.signOut()
    localStorage.removeItem('VIXToken')
  },
  ['SET_CLIENT_TYPE'] (state, payload) {
    state.clientType = payload
  },
  ['SET_COMPANY_TYPE'] (state, payload) {
    state.companyType = payload
  },
}

const actions = {
  isLogin () {
    return cookieControl.isLogin()
  }
}

const getters = {}

const authStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default authStore
