<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="145" height="36.048" viewBox="0 0 145 36.048">
    <g id="logo" transform="translate(-2109.999 -1695)">
      <g id="Grupo_339" data-name="Grupo 339" transform="translate(2109.994 1694.953)">
        <path id="Caminho_4728" data-name="Caminho 4728" d="M318.149,644.9H303.4v13.868h-1.631V644.9H286.853a2.375,2.375,0,0,0-2.375,2.375v31.3a2.375,2.375,0,0,0,2.375,2.375h31.3a2.375,2.375,0,0,0,2.375-2.375v-31.3a2.375,2.375,0,0,0-2.375-2.375ZM303.4,678.339h-1.631v-11.1H303.4Z" transform="translate(-284.473 -644.853)" fill="#cdd43f"/>
        <path id="Caminho_4729" data-name="Caminho 4729" d="M312.437,666.177c-.69-.908-1.16-1.936-1.918-2.837l-1.829,2.814h-2.106l2.934-4.417-2.691-4.009H309l1.555,2.506c.247.007.285-.175.366-.3.39-.608.788-1.211,1.159-1.833a.707.707,0,0,1,.721-.417c.5.031,1,.009,1.6.009l-2.707,4.061,2.925,4.392C313.848,666.214,313.141,666.164,312.437,666.177Z" transform="translate(-282.634 -643.833)" fill="#39411a"/>
        <path id="Caminho_4730" data-name="Caminho 4730" d="M288.947,657.717h1.843c.594,1.995.95,4.026,1.5,6.025.694-1.963,1.072-4,1.645-6.011a14.025,14.025,0,0,1,1.894-.024c-.281,1.079-.538,2.084-.8,3.087-.348,1.318-.683,2.639-1.055,3.949-.327,1.149-.887,1.5-1.823,1.438-.823-.052-1.276-.471-1.531-1.511-.338-1.381-.662-2.766-.993-4.15C289.4,659.592,289.178,658.668,288.947,657.717Z" transform="translate(-284.099 -643.835)" fill="#39411a"/>
        <rect id="Retângulo_1216" data-name="Retângulo 1216" width="1.631" height="8.482" transform="translate(17.293 13.864)" fill="#39411a"/>
      </g>
      <text id="Seminovos" transform="translate(2154 1719)" fill="#5a6222" font-size="16" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">{{ $t('HeaderBar.logoTitle') }}</tspan></text>
    </g>
  </svg>

</template>
<script>
export default {
  name: 'LogoVixIcon',
  props: {
    showCircle: {
      type: Boolean,
      default: true
    },
  },
}
</script>
<style scoped>
</style>
