<template>
  <svg
      height="16"
      viewBox="0 0 11.455 16"
      width="11.455"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="ic_phone"
        transform="translate(-17.682 -4.998)"
    >
      <path
          d="M26.315,20.951c1.355.43,2.814-2.17,2.823-2.605.015-.743-2.273-3.043-2.608-2.863a7.507,7.507,0,0,1-2.345.727c-.993-.278-3.573-4.746-3.317-5.746a7.505,7.505,0,0,1,1.8-1.667c.324-.2-.524-3.332-1.175-3.691-.381-.21-3.363-.246-3.667,1.142-1.051,4.784,3.819,13.22,8.488,14.7Z"
          data-name="Caminho 7074"
          fill="#eeefe8"
          fill-rule="evenodd"
          id="Caminho_7074"
          transform="translate(0 0)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'PhoneIcon'
}
</script>
<style scoped>

</style>