const NegotiationApi = (vueResource, url) => {
  const resource = vueResource(`${url}/offer/myoffers{/PATH}{/ID}{?Q*}`)

  return {
    async getList () {
      try {
        return await resource.get()
      } catch (e) {
        return { body: [] }
      }
    },
  }
}
export default NegotiationApi