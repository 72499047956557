<template>
  <svg class="person-icon" :class="{'small': small}" v-if="active" xmlns="http://www.w3.org/2000/svg" width="99.996" height="104.999" viewBox="0 0 99.996 104.999">
    <g id="Grupo_537" data-name="Grupo 537" transform="translate(-322.486 -562.396)">
      <g id="Grupo_62" data-name="Grupo 62" transform="translate(344.977 562.396)">
        <path id="Caminho_16" data-name="Caminho 16" d="M496.74,396.86a29.541,29.541,0,0,0,2.976,2.595A27.605,27.605,0,0,1,496.74,396.86Z" transform="translate(-488.671 -349.857)" fill="#FFFFFF"/>
        <path id="Caminho_17" data-name="Caminho 17" d="M498.88,398.62a28.68,28.68,0,0,0,5.894,3.033A26.5,26.5,0,0,1,498.88,398.62Z" transform="translate(-486.729 -348.259)" fill="#FFFFFF"/>
        <path id="Caminho_7" data-name="Caminho 7" d="M517.394,372.22A27.445,27.445,0,0,0,495.38,383.3,27.511,27.511,0,0,1,544.9,399.747v.038a27.505,27.505,0,0,1-14.345,24.15,27.521,27.521,0,0,0-13.162-51.715Z" transform="translate(-489.905 -372.22)" fill="#FFFFFF"/>
        <path id="Caminho_18" data-name="Caminho 18" d="M547.505,399.71v.038A27.507,27.507,0,0,1,533.161,423.9a12.719,12.719,0,0,1-1.24.629c-.4.191-.8.381-1.221.553-.4.191-.82.343-1.24.5-.82.286-1.66.553-2.518.782a17.155,17.155,0,0,1-1.774.381,8.276,8.276,0,0,1-.992.172c-.419.076-.858.133-1.3.172a8.656,8.656,0,0,1-1.011.076c-.591.057-1.182.076-1.793.076a28.117,28.117,0,0,1-9.519-1.66,28.685,28.685,0,0,1-5.895-3.033c-.382-.248-.744-.515-1.106-.763a29.612,29.612,0,0,1-2.976-2.594c-.61-.61-1.2-1.259-1.774-1.927-.553-.687-1.087-1.393-1.583-2.137-.5-.725-.954-1.488-1.373-2.251a27.426,27.426,0,0,1-3.338-13.124v-.038q0-.8.057-1.6a20.959,20.959,0,0,1,.229-2.193,6.276,6.276,0,0,1,.115-.84c.057-.3.114-.61.172-.9.114-.591.248-1.183.419-1.755q.258-.944.572-1.831a24.062,24.062,0,0,1,1.164-2.8c.381-.782.8-1.545,1.24-2.289a.066.066,0,0,1,.019-.038c.458-.744.953-1.45,1.469-2.156,0-.019,0-.019.019-.038a27.511,27.511,0,0,1,49.521,16.443Z" transform="translate(-492.51 -372.184)" fill="#FFFFFF"/>
      </g>
      <g id="Grupo_63" data-name="Grupo 63" transform="translate(322.486 624.026)">
        <path id="Caminho_19" data-name="Caminho 19" d="M501.685,404.169c-.74-.227-1.49-.382-2.238-.544C500.2,403.78,500.948,403.955,501.685,404.169Z" transform="translate(-463.724 -402.939)" fill="#FFFFFF"/>
        <path id="Caminho_20" data-name="Caminho 20" d="M504.464,403.933a29.568,29.568,0,0,0-5.908-.6l-.038.019a28.347,28.347,0,0,0-4.6.325A27.56,27.56,0,0,1,504.464,403.933Z" transform="translate(-468.74 -403.247)" fill="#FFFFFF"/>
        <path id="Caminho_21" data-name="Caminho 21" d="M530.083,404a28.065,28.065,0,0,0-6.2-.648,29.607,29.607,0,0,0-9.29,1.507,33.835,33.835,0,0,1-4.082,1.068,32.95,32.95,0,0,0,4.025-1.068v-.038A27.945,27.945,0,0,1,530.083,404Z" transform="translate(-453.683 -403.265)" fill="#FFFFFF"/>
        <path id="Caminho_9" data-name="Caminho 9" d="M579.791,440.483a9.327,9.327,0,0,0,.725-3.586,37.854,37.854,0,0,0-1.259-9.748,38.6,38.6,0,0,1,1.24,9.71v.038A9.315,9.315,0,0,1,579.791,440.483Zm-.019.019a9.4,9.4,0,0,1-1.983,2.938A8.691,8.691,0,0,0,579.772,440.5Zm-3.357,4.063a9.365,9.365,0,0,1-4.178,1.526A8.856,8.856,0,0,0,576.414,444.565Zm-86.661,1.564h-.038a9.173,9.173,0,0,1-2.728-.42,8.849,8.849,0,0,0,2.766.439H571.3a6.832,6.832,0,0,0,.9-.057c-.324.019-.629.038-.954.038Zm73.8-40.174a28.362,28.362,0,0,1,5.036,3.377A25.27,25.27,0,0,0,563.557,405.955Zm-2.728-1.24a29.222,29.222,0,0,1,2.728,1.24A20.653,20.653,0,0,0,560.829,404.716Zm-57.838-.916a26.964,26.964,0,0,0-13.849,8.832A27.852,27.852,0,0,1,502.992,403.8Zm-16,41.91a9.457,9.457,0,0,1-3.777-2.289A8.934,8.934,0,0,0,486.987,445.71Zm-4.9-3.663a9.5,9.5,0,0,1-1.144-2.384A8.879,8.879,0,0,0,482.084,442.047Z" transform="translate(-480.52 -402.78)" fill="#FFFFFF"/>
        <path id="Caminho_22" data-name="Caminho 22" d="M580.7,437.3v.038a9.32,9.32,0,0,1-.706,3.586.019.019,0,0,1-.019.019,9.4,9.4,0,0,1-1.984,2.938,6.963,6.963,0,0,1-1.374,1.125,9.365,9.365,0,0,1-4.177,1.526c0,.019-.019.019-.038,0-.324.019-.629.038-.953.038h-81.53a9.178,9.178,0,0,1-2.728-.42,9.456,9.456,0,0,1-3.777-2.289,7.726,7.726,0,0,1-1.125-1.373,9.5,9.5,0,0,1-1.145-2.385,9.173,9.173,0,0,1-.42-2.728v-.038a38.514,38.514,0,0,1,1.717-11.35q.229-.772.515-1.545a38.539,38.539,0,0,1,6.391-11.369,27.85,27.85,0,0,1,13.849-8.832c.9-.229,1.793-.439,2.709-.592a28.358,28.358,0,0,1,4.6-.324l.038-.019a29.291,29.291,0,0,1,8.146,1.144c.4.115.782.229,1.183.362a34.594,34.594,0,0,0,17.683,1.068,33.758,33.758,0,0,0,4.082-1.068,29.609,29.609,0,0,1,9.29-1.507,28.052,28.052,0,0,1,6.2.648c.477.115.954.229,1.431.362.839.248,1.679.515,2.48.84a29.271,29.271,0,0,1,2.728,1.24,28.356,28.356,0,0,1,5.036,3.376c.382.324.763.667,1.145,1.011.362.362.744.725,1.088,1.088.362.382.706.763,1.049,1.164l.019.019a38.34,38.34,0,0,1,6.886,12.933c.172.515.306,1.03.458,1.564a.066.066,0,0,1,.019.038A38.589,38.589,0,0,1,580.7,437.3Z" transform="translate(-480.72 -403.226)" fill="#FFFFFF"/>
      </g>
    </g>
  </svg>
  <svg class="person-icon" :class="{'small': small}" v-else xmlns="http://www.w3.org/2000/svg" width="99.996" height="104.999" viewBox="0 0 99.996 104.999">
    <g id="Grupo_537" data-name="Grupo 537" transform="translate(-322.486 -562.396)">
      <g id="Grupo_62" data-name="Grupo 62" transform="translate(344.977 562.396)">
        <path id="Caminho_16" data-name="Caminho 16" d="M496.74,396.86a29.541,29.541,0,0,0,2.976,2.595A27.605,27.605,0,0,1,496.74,396.86Z" transform="translate(-488.671 -349.857)" fill="#a7a7a7"/>
        <path id="Caminho_17" data-name="Caminho 17" d="M498.88,398.62a28.68,28.68,0,0,0,5.894,3.033A26.5,26.5,0,0,1,498.88,398.62Z" transform="translate(-486.729 -348.259)" fill="#a7a7a7"/>
        <path id="Caminho_7" data-name="Caminho 7" d="M517.394,372.22A27.445,27.445,0,0,0,495.38,383.3,27.511,27.511,0,0,1,544.9,399.747v.038a27.505,27.505,0,0,1-14.345,24.15,27.521,27.521,0,0,0-13.162-51.715Z" transform="translate(-489.905 -372.22)" fill="#a7a7a7"/>
        <path id="Caminho_18" data-name="Caminho 18" d="M547.505,399.71v.038A27.507,27.507,0,0,1,533.161,423.9a12.719,12.719,0,0,1-1.24.629c-.4.191-.8.381-1.221.553-.4.191-.82.343-1.24.5-.82.286-1.66.553-2.518.782a17.155,17.155,0,0,1-1.774.381,8.276,8.276,0,0,1-.992.172c-.419.076-.858.133-1.3.172a8.656,8.656,0,0,1-1.011.076c-.591.057-1.182.076-1.793.076a28.117,28.117,0,0,1-9.519-1.66,28.685,28.685,0,0,1-5.895-3.033c-.382-.248-.744-.515-1.106-.763a29.612,29.612,0,0,1-2.976-2.594c-.61-.61-1.2-1.259-1.774-1.927-.553-.687-1.087-1.393-1.583-2.137-.5-.725-.954-1.488-1.373-2.251a27.426,27.426,0,0,1-3.338-13.124v-.038q0-.8.057-1.6a20.959,20.959,0,0,1,.229-2.193,6.276,6.276,0,0,1,.115-.84c.057-.3.114-.61.172-.9.114-.591.248-1.183.419-1.755q.258-.944.572-1.831a24.062,24.062,0,0,1,1.164-2.8c.381-.782.8-1.545,1.24-2.289a.066.066,0,0,1,.019-.038c.458-.744.953-1.45,1.469-2.156,0-.019,0-.019.019-.038a27.511,27.511,0,0,1,49.521,16.443Z" transform="translate(-492.51 -372.184)" fill="#a7a7a7"/>
      </g>
      <g id="Grupo_63" data-name="Grupo 63" transform="translate(322.486 624.026)">
        <path id="Caminho_19" data-name="Caminho 19" d="M501.685,404.169c-.74-.227-1.49-.382-2.238-.544C500.2,403.78,500.948,403.955,501.685,404.169Z" transform="translate(-463.724 -402.939)" fill="#a7a7a7"/>
        <path id="Caminho_20" data-name="Caminho 20" d="M504.464,403.933a29.568,29.568,0,0,0-5.908-.6l-.038.019a28.347,28.347,0,0,0-4.6.325A27.56,27.56,0,0,1,504.464,403.933Z" transform="translate(-468.74 -403.247)" fill="#a7a7a7"/>
        <path id="Caminho_21" data-name="Caminho 21" d="M530.083,404a28.065,28.065,0,0,0-6.2-.648,29.607,29.607,0,0,0-9.29,1.507,33.835,33.835,0,0,1-4.082,1.068,32.95,32.95,0,0,0,4.025-1.068v-.038A27.945,27.945,0,0,1,530.083,404Z" transform="translate(-453.683 -403.265)" fill="#a7a7a7"/>
        <path id="Caminho_9" data-name="Caminho 9" d="M579.791,440.483a9.327,9.327,0,0,0,.725-3.586,37.854,37.854,0,0,0-1.259-9.748,38.6,38.6,0,0,1,1.24,9.71v.038A9.315,9.315,0,0,1,579.791,440.483Zm-.019.019a9.4,9.4,0,0,1-1.983,2.938A8.691,8.691,0,0,0,579.772,440.5Zm-3.357,4.063a9.365,9.365,0,0,1-4.178,1.526A8.856,8.856,0,0,0,576.414,444.565Zm-86.661,1.564h-.038a9.173,9.173,0,0,1-2.728-.42,8.849,8.849,0,0,0,2.766.439H571.3a6.832,6.832,0,0,0,.9-.057c-.324.019-.629.038-.954.038Zm73.8-40.174a28.362,28.362,0,0,1,5.036,3.377A25.27,25.27,0,0,0,563.557,405.955Zm-2.728-1.24a29.222,29.222,0,0,1,2.728,1.24A20.653,20.653,0,0,0,560.829,404.716Zm-57.838-.916a26.964,26.964,0,0,0-13.849,8.832A27.852,27.852,0,0,1,502.992,403.8Zm-16,41.91a9.457,9.457,0,0,1-3.777-2.289A8.934,8.934,0,0,0,486.987,445.71Zm-4.9-3.663a9.5,9.5,0,0,1-1.144-2.384A8.879,8.879,0,0,0,482.084,442.047Z" transform="translate(-480.52 -402.78)" fill="#a7a7a7"/>
        <path id="Caminho_22" data-name="Caminho 22" d="M580.7,437.3v.038a9.32,9.32,0,0,1-.706,3.586.019.019,0,0,1-.019.019,9.4,9.4,0,0,1-1.984,2.938,6.963,6.963,0,0,1-1.374,1.125,9.365,9.365,0,0,1-4.177,1.526c0,.019-.019.019-.038,0-.324.019-.629.038-.953.038h-81.53a9.178,9.178,0,0,1-2.728-.42,9.456,9.456,0,0,1-3.777-2.289,7.726,7.726,0,0,1-1.125-1.373,9.5,9.5,0,0,1-1.145-2.385,9.173,9.173,0,0,1-.42-2.728v-.038a38.514,38.514,0,0,1,1.717-11.35q.229-.772.515-1.545a38.539,38.539,0,0,1,6.391-11.369,27.85,27.85,0,0,1,13.849-8.832c.9-.229,1.793-.439,2.709-.592a28.358,28.358,0,0,1,4.6-.324l.038-.019a29.291,29.291,0,0,1,8.146,1.144c.4.115.782.229,1.183.362a34.594,34.594,0,0,0,17.683,1.068,33.758,33.758,0,0,0,4.082-1.068,29.609,29.609,0,0,1,9.29-1.507,28.052,28.052,0,0,1,6.2.648c.477.115.954.229,1.431.362.839.248,1.679.515,2.48.84a29.271,29.271,0,0,1,2.728,1.24,28.356,28.356,0,0,1,5.036,3.376c.382.324.763.667,1.145,1.011.362.362.744.725,1.088,1.088.362.382.706.763,1.049,1.164l.019.019a38.34,38.34,0,0,1,6.886,12.933c.172.515.306,1.03.458,1.564a.066.066,0,0,1,.019.038A38.589,38.589,0,0,1,580.7,437.3Z" transform="translate(-480.72 -403.226)" fill="#a7a7a7"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PersonIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
  .person-icon {
    height: 80px;
    width: 90px;

    &.small {
      height: 40px !important;
      width: 40px !important;;
    }
  }
</style>
