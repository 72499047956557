<template>
  <div
      style="width: fit-content;"
  >
    <div
        v-if="offer"
        class="negotiation-content-row"
    >

      <div class="negotiation-content-row-image">
        <v-img
            :src="getImage(offer.vehicles[0].photo)"
            height="68"
            width="106"
        />
      </div>

      <div class="negotiation-content-row-logo">
        <v-img
            :src="getIcon(offer.vehicles[0].ativo)"
            height="48"
            width="50"
            v-if="!isLote"
            contain
        />
        <div
          class="offer-header-column"
          style=" height:48px; width:50px;"
          v-else
      >
        <VehicleLotIcon/>
      </div>
      </div>
      <div class="row-detail-container">
        <div class="negotiation-content-row-description">
          <div
              class="negotiation-content-row-description-title"
              style="cursor:pointer;"
              @click="goToOffer(offer.codigo)"
          >
            {{ offer.title }}
          </div>
          <div class="negotiation-content-row-description-subtitle">
            {{ offer.vehicles.length > 1 ? '' : offer.vehicles[0].version }}
          </div>
          <div class="negotiation-content-row-description-info">
            {{ offer.vehicles.length > 1 ? '' : offer.vehicles[0].yearManufacture + '/' + offer.vehicles[0].yearModel }}
            <span v-if="offer.vehicles.length > 1">
            {{ offer.vehicles.length }} {{ $t('global.units') }}
        </span>
            <span
                v-else
                style="margin-left: 20px;"
            >
            {{ metric }}
        </span>
          </div>
        </div>

        <div class="negotiation-content-row-value-box">
          <div class="negotiation-content-row-value-title">
            {{ statusLance }}
          </div>
          <div class="negotiation-content-row-value">
            {{ price }}
          </div>
        </div>

        <div v-if="offer.statusOferta === 'A' && !isOvercome">
          <div class="negotiation-content-row-stat-box">
            <div class="negotiation-content-row-value-title">
              {{ $t('negotiation.timeLeft') }}
            </div>
            <Countdown
                :small="true"
                :time="offer.date"
            />
            <div
                class="negotiation-content-row-stat-link"
                @click="dialogInfo = true"
            >
              {{ $t('negotiation.seeBids') }}
            </div>
          </div>

        </div>

        <div v-else>

          <div class="negotiation-content-row-stat-box">
            <div class="negotiation-content-row-stat-text">
              Status atual
            </div>
            <div
                v-if="statusOferta !== this.$t('negotiation.missedOffer')"
                class="negotiation-content-row-stat-link"
                style="margin-top:10px;cursor:pointer;"
                @click="dialogInfo = true"
            >
              {{ statusOferta }}
            </div>
            <div
                v-else
                class="negotiation-content-row-stat-link"
                style="margin-top:10px; color:#000000; cursor:default"
            >
              {{ statusOferta }}
            </div>
            <div
                class="negotiation-content-row-stat-text"
                style="margin-top:10px;"
            >
              {{ getDateStatus(dataUltimoStatus) }}
            </div>
          </div>
        </div>


        <div
            v-if="offer.statusOferta === 'A' && isOvercome"
            class="negotiation-content-row-button"
        >
          <div
              class="negotiation-content-row-button-bid align-content-center"
              @click="dialogBid = true"
          >
            <span>{{ $t('negotiation.doBid') }}</span>
          </div>
        </div>

        <div
            v-if="(offer.statusOferta !== 'A' && !isOvercome && offer.winner !== 'W' && offer.tipoUltimoLance !== 'O' && offer.dataCancelada === null && offer.dataSolicitacaoCancelamento === null) || (offer.statusOferta === 'A' && offer.tipoUltimoLance === 'O' && !isOvercome) || (offer.vendido10dias && offer.dataSolicitacaoCancelamentoVenda === null)"
            class="negotiation-content-icon"
        >
      <div v-if="this.$vuetify.breakpoint.xs">
        <v-menu right offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
          <v-icon style="font-size:20px;">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item style="cursor:pointer;"
            @click="goToCancelScreen(offer)"
          >
            <v-list-item-title style="font: 16px 'HelveticaNeueLTPro-Roman';color:#8B8B8B">{{ $t('negotiation.cancelBuy') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
      <div v-else>
        <v-menu left offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
          <v-icon style="font-size:20px;">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="cursor-pointer"
             @click="goToCancelScreen(offer)"
          >
            <v-list-item-title style="font: 16px 'HelveticaNeueLTPro-Roman';color:#8B8B8B">{{ $t('negotiation.cancelBuy') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
        </div>
      </div>

      <!-- Dialogo de informação -->
      <v-dialog
          v-model="dialogInfo"
          width="fit-content"
      >
        <div class="dialog-card-content">
          <div class="card-padding">
            <div class="dialog-info-header">
              <div class="dialog-info-title"></div>
              <div
                  class="dialog-info-close"
                  @click="dialogInfo = false"
              >
                <XMarkIcon/>
              </div>
            </div>
            <div class="dialog-content">
              <div class="dialog-info-container">
                <div
                    class="negotiation-content-row-image"
                >
                  <v-img
                      :src="getImage(offer.vehicles[0].photo)"
                      height="68"
                      width="106"
                  />
                </div>

                <div class="dialog-offer-info">
                  <v-img
                      :src="getIcon(offer.vehicles[0].ativo)"
                      height="48"
                      style="margin-bottom: 12px;"
                      width="50"
                      contain
                  />

                  <div class="negotiation-dialog-description-title">
                    {{ offer.title }}
                  </div>
                  <div
                      class="negotiation-content-row-description-subtitle"
                  >
                    {{ offer.vehicles[0].version }}
                  </div>

                  <div
                      v-if="offer.statusOferta === 'A'"
                      class="dialog-offer-countdown"
                  >
                    <div
                        class="negotiation-content-row-value-title"
                    >
                      {{ $t('negotiation.timeLeft') }}
                    </div>
                    <Countdown
                        :small="true"
                        :time="offer.date"
                    />
                  </div>

                </div>
              </div>
              <div
                  v-if="offer.statusOferta === 'A'"
                  class="dialog-row-card-container"
              >


                <div
                    v-for="(lance,i) in offer.histLances"
                    :key="i"
                    class="dialog-row-card"
                    v-show="lance.status !== '.'"
                >

                  <div class="dialog-card-time">
                    {{ getDate(lance) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHour(lance) }}
                  </div>
                  <div
                      v-if="lance.codigoUsr === userLogged.codigoUsuario && lance.status === null"
                      class="dialog-card-text"
                  >
                    {{ $t('negotiation.bidOf') }} {{ lance.valor.number2Money() }} {{ $t('negotiation.done') }}
                  </div>
                  <div
                      v-else-if="lance.codigoUsr === userLogged.codigoUsuario && lance.status === 'R'"
                      class="dialog-card-text"
                  >
                    {{ $t('negotiation.bidOf') }} {{ lance.valor.number2Money() }} {{ $t('negotiation.reject') }}
                  </div>
                  <div
                      v-else-if="lance.codigoUsr === userLogged.codigoUsuario && lance.status === 'K'"
                      class="dialog-card-text"
                  >
                    {{ $t('negotiation.bidOf') }} {{ lance.valor.number2Money() }} {{ $t('negotiation.withdrawal') }}
                  </div>
                  <div
                      v-else-if="lance.codigoUsr === userLogged.codigoUsuario && lance.status === 'Z'"
                      class="dialog-card-text"
                  >
                    {{ $t('negotiation.bidOf') }} {{ lance.valor.number2Money() }} {{ $t('negotiation.cancell') }}
                  </div>
                  <div
                      v-else-if="offer.lanceVencedor"
                      class="dialog-card-text"
                  >
                    {{ $t('negotiation.offerDone') }}
                  </div>
                  <div
                      v-else
                      class="dialog-card-text"
                  >
                    {{ $t('negotiation.bidExc') }}
                  </div>
                </div>


              </div>
              <div
                  v-else
                  class="dialog-row-card-container"
              >

                <div
                    v-if="offer.dataCancelada"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataCancelada) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataCancelada) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.cancel') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataRejeicaoCancelamentoVenda"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataRejeicaoCancelamentoVenda) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataRejeicaoCancelamentoVenda) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.cancelRejectCardBuy') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataSolicitacaoCancelamentoVenda"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataSolicitacaoCancelamentoVenda) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataSolicitacaoCancelamentoVenda) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.cancelAnalyze') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataCompraFinalizada"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataCompraFinalizada) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataCompraFinalizada) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.checkout') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataRejeicaoCancelamento"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataRejeicaoCancelamento) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataRejeicaoCancelamento) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.cancelRejectCard') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataSolicitacaoCancelamento"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataSolicitacaoCancelamento) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataSolicitacaoCancelamento) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.cancelAnalyze') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataAguardandoRetirada"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataAguardandoRetirada) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataAguardandoRetirada) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.waitingWithdrawal') }}
                  </div>
                </div>


                <div
                    v-if="offer.dataAguardandoCredito"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataAguardandoCredito) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataAguardandoCredito) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.waitingLiberation') }}
                  </div>
                </div>


                <div
                    v-if="offer.dataNegociacao"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataNegociacao) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataNegociacao) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.negotiation') }}
                  </div>
                </div>

                <div
                    v-if="offer.dataAguardandoContato"
                    class="dialog-row-card"
                >
                  <div class="dialog-card-time">
                    {{ getDateStatus(offer.dataAguardandoContato) }}
                  </div>
                  <div class="dialog-card-time">
                    {{ getHourStatus(offer.dataAguardandoContato) }}
                  </div>
                  <div class="dialog-card-text">
                    {{ $t('negotiation.waitingContact') }}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </v-dialog>
      <!-- Dialogo de informação ^^-->

      <!-- Dialogo de Lance -->
      <v-dialog
          v-model="dialogBid"
          width="fit-content"
      >
        <div class="dialog-card-content">
          <div class="card-padding">
            <div class="dialog-info-header">
              <div class="dialog-info-title"></div>
              <div
                  class="dialog-info-close"
                  @click="dialogBid = false"
              >
                <XMarkIcon/>
              </div>
            </div>
            <div class="dialog-content bid">
              <div class="dialog-content-box">
                <div class="negotiation-content-row-image">
                  <v-img
                      :src="getImage(offer.vehicles[0].photo)"
                      height="68"
                      width="106"
                  />
                </div>

                <div
                    class="negotiation-content-row-logo"
                    style="margin-left: 0;"
                >
                  <v-img
                      v-if="!isLote"
                      :src="getIcon(offer.vehicles[0].ativo)"
                      contain
                      height="48"
                      width="50"
                  />
                  <div
                      v-else
                      class="offer-header-column"
                      style=" height:48px; width:50px;"
                  >
                    <VehicleLotIcon/>
                  </div>
                </div>

                <div class="row-detail-container">
                  <div
                      class="negotiation-content-row-description"
                      style="margin-left: 0;"
                  >
                    <div
                        class="negotiation-content-row-description-title"
                        style="cursor:pointer;"
                        @click="goToOffer(offer.codigo)"
                    >
                      {{ offer.title }}
                    </div>
                    <div class="negotiation-content-row-description-subtitle">
                      {{ offer.vehicles.length > 1 ? '' : offer.vehicles[0].version }}
                    </div>
                    <div class="negotiation-content-row-description-info">
                      {{
                        offer.vehicles.length > 1 ? '' : offer.vehicles[0].yearManufacture + '/' + offer.vehicles[0].yearModel
                      }}
                      <span v-if="offer.vehicles.length > 1">
            {{ offer.vehicles.length }} {{ $t('global.units') }}
        </span>
                      <span
                          v-else
                          style="margin-left: 20px;"
                      >
            {{ metric }}
        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-content-box">
                <BidControl
                    :increments="increments"
                    :offer="offer"
                    :price="offerPrice"
                />
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
      <!-- Dialogo de Lance ^^-->


      <!-- Dialogo de informação -->
      <v-dialog
          v-model="dialogCancelar"
          width="fit-content"
      >
        <div class="dialog-card-content">
          <div class="card-padding">
            <div class="dialog-info-header">
              <div class="dialog-info-title"></div>
              <div
                  class="dialog-info-close"
                  @click="dialogCancelar = false"
              >
                <XMarkIcon/>
              </div>
            </div>
            <div class="dialog-content">
              <div class="dialog-info-container">
                <div
                    class="negotiation-content-row-image"
                >
                  <v-img
                      :src="getImage(offer.vehicles[0].photo)"
                      height="68"
                      width="106"
                  />
                </div>

                <div class="dialog-offer-info">
                  <v-img
                      :src="getIcon(offer.vehicles[0].ativo)"
                      height="48"
                      style="margin-bottom: 12px;"
                      width="50"
                      contain
                  />

                  <div class="negotiation-dialog-description-title">
                    {{ offer.title }}
                  </div>
                  <div
                      class="negotiation-content-row-description-subtitle"
                  >
                    {{ offer.vehicles[0].version }}
                  </div>
                </div>
                </div>
                  <div class="dialog-row-card-container">
                     <div class="dialog-row-card">
                          <div style="font: 14px 'HelveticaNeueLTPro-Roman';color:#666666;">
                            Selecione a Justificativa
                          <label
                              :key="justify.codigo"
                              @click="selectedJustifyId = i"
                              class="radio-button-register cursor-pointer"
                              v-for="(justify, i) in this.justifys"
                          >
                            <input
                                :checked="i === selectedJustifyId"
                                :id="justify.descricao"
                                :name="justify.descricao"
                                :value="i"
                                class="custom-radio cursor-pointer"
                                type="radio"
                            >
                            <span>{{ justify.descricao }}</span>
                          </label>

                          <textarea 
                                  :style=" selectedJustify.observacao === 'S' ? 'display:block;' : 'display:none;' "
                                  class="input-textarea"
                                  placeholder="Digite sua justificativa aqui..."
                                  v-model="observacao"
                                  maxlength="500"
                            ></textarea>


                        <div
                        :class="{'register-button-disabled': !isValid}"
                        class='register-button align-content-center'
                        :style=" selectedJustify.observacao === 'S' ? 'margin-left: auto; margin-right: auto;' : 'margin-left: 138px;' "
                        @click=" offer.tipoUltimoLance === 'O' ? cancelPurpouse() : cancelBuy() "
                        >
                        <span>{{ $t('global.sendButton') }}</span>
                      </div>
                        </div>
                       
                        
                     </div>
                  </div>
            </div>
          </div>
          </div>
          </v-dialog>


    <v-dialog
        v-model="showConfirm"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="info"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgSuccess }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="close()"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-dialog
        v-model="error"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="error"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgError }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="error=false"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

    </div>
    <!--    <hr class="offer-line">-->
  </div>
</template>
<script>
import {getImageUrl, OfferResource} from '@/api'
import Countdown from '@/shared/Countdown/Countdown'
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import VehicleLotIcon from '@/shared/Icons/VehicleLotIcon'
import {mapMutations, mapState} from 'vuex'
import moment from 'moment'
import 'moment/locale/pt-br'
import BidControl from "@/screen/Offer/BidControl";

export default {
  name: 'NegotiationRow',
  components: {BidControl, VehicleLotIcon, Countdown, XMarkIcon},
  props: {
    offer: {
      type: Object,
      default: () => {
        return null
      }
    },
  },
  data() {
    return {
      bidButton: false,
      dialogInfo: false,
      dialogBid: false,
      dialogCancelar: false,
      showConfirm: false,
      error: false,
      msgSuccess: '',
      msgError: '',
      increments: [],
      justifys: [],
      selectedJustifyId: 0,
      observacao: '',
      offerPrice: {
        codigoOferta: 0,
        codigoPerfil: 0,
        valorCompreJa: 0,
        valorLeilao: 0
      },
    }
  },
  computed: {
    ...mapState('authStore', ['user']),
    price() {
      if (!this.offer.valorUltimoLance) return 'R$ 0.000,00*'
      return this.offer.valorUltimoLance.number2Money()
    },
    isValid () {
      if (this.selectedJustify.observacao === 'S') {
        if(this.observacao === '') {
          return false
        } else {
          return true
        }
      } else {
        return true
      } 
    },
    selectedJustify () {
      if (this.justifys.length < 1) return {}
      return this.justifys[this.selectedJustifyId]
    },
    statusLance() {
      if(this.offer.tipoUltimoLance === 'B') {
        return this.$t('negotiation.cancel')
      } else if (this.offer.tipoUltimoLance === 'C') {
        return this.$t('OfferBar.buy')
      } else if (this.offer.tipoUltimoLance === 'P' || this.offer.tipoUltimoLance === 'L') {
        return this.$t('negotiation.bid')
      } else if(this.offer.tipoUltimoLance === 'R') {
        return this.$t('negotiation.bidReject')
      } else if(this.offer.tipoUltimoLance === 'Z') {
        return this.$t('negotiation.bidCancel')
      } else if(this.offer.tipoUltimoLance === 'K') {
        return this.$t('negotiation.bidWithdrawal')
      } else {
        return this.$t('negotiation.purpose')
      }
    },
    isOvercome() {
      return this.user.codigoUsuario !== this.offer.codigoUsuarioUltimoLance;
    },
    statusOferta() {
      if (this.offer.winner !== 'N') {
        if(this.offer.dataRejeicaoCancelamentoVenda && this.offer.statusOferta === 'X') {
          return this.$t('negotiation.cancelRejectBuy')
        }
        if(this.offer.dataRejeicaoCancelamento && this.offer.statusOferta === 'L') {
          return this.$t('negotiation.cancelReject')
        } else if(this.offer.idofrtacancl === 'Q') {
          return this.$t('negotiation.cancelAnalyze')
        } else if (this.offer.dataCancelada) {
          return this.$t('negotiation.cancel')
        } else if (this.offer.statusOferta === 'V' && this.offer.dataNegociacao) {
          return this.$t('negotiation.negotiation')
        } else if (this.offer.statusOferta === 'V' && !this.offer.dataNegociacao) {
          return this.$t('negotiation.waitingContact')
        } else if (this.offer.statusOferta === 'C') {
          return this.$t('negotiation.waitingLiberation')
        } else if (this.offer.statusOferta === 'L') {
          return this.$t('negotiation.waitingWithdrawal')
        } else if (this.offer.statusOferta === 'X') {
          return this.$t('negotiation.checkout')
        } else {
          return ''
        }
      } else {
        if (this.offer.statusOferta === 'A' && this.isOvercome) {
          return this.$t('negotiation.overcome')
        } else {
          return this.$t('negotiation.missedOffer')
        }
      }
    },
    dataUltimoStatus () {
      if (this.offer.winner !== 'N') {
        if(this.offer.dataRejeicaoCancelamento && this.offer.statusOferta === 'L') {
          return this.offer.dataRejeicaoCancelamento
        } else if(this.offer.dataSolicitacaoCancelamento && this.offer.statusOferta === 'L') {
          return this.offer.dataSolicitacaoCancelamento
        } else if(this.offer.dataRejeicaoCancelamentoVenda && this.offer.statusOferta === 'X') {
          return this.offer.dataRejeicaoCancelamentoVenda
        } else if(this.offer.dataSolicitacaoCancelamentoVenda && this.offer.statusOferta === 'X') {
          return this.offer.dataSolicitacaoCancelamentoVenda
        } else if (this.offer.statusOferta === 'V' && this.offer.dataNegociacao) {
          return this.offer.dataNegociacao
        } else if (this.offer.statusOferta === 'V' && !this.offer.dataNegociacao) {
          return this.offer.dataAguardandoContato
        } else if (this.offer.statusOferta === 'C') {
          return this.offer.dataAguardandoCredito
        } else if (this.offer.statusOferta === 'L') {
          return this.offer.dataAguardandoRetirada
        } else if (this.offer.statusOferta === 'X') {
          return this.offer.dataCompraFinalizada
        } else if (this.offer.dataCancelada) {
          return this.offer.dataCancelada
        } else {
          return ''
        }
      } else {
        if(this.offer.lanceVencedor != null) {
          return this.offer.dataLanceVencedor
        } else {
          return this.offer.date
        }
      }
    },
    userLogged () {
      return this.user
    },
    isLote() {
      return this.offer.vehicles.length > 1
    },
    metric() {
      if (!this.offer.vehicles[0].km) return '-- ' + this.offer.vehicles[0].tipoContador
      return this.offer.vehicles[0].km.float2Money('', '', '.', '') + ' ' + this.offer.vehicles[0].tipoContador
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    'selectedJustifyId': function (newValue) {
      this.observacao = ''
    }
  },
  methods: {
    ...mapMutations('cancelamentoStore', ['SET_OFERTA_INFO']),
    async init() {
      if (this.isOvercome) {
        await this.getOfferPrice()
        await this.getIncrements()
      }
      await this.getJustifys()
    },
    async getJustifys() {
      const response = await OfferResource.getJustifys()
      this.justifys = response.body
    },
    async getOfferPrice() {
      const response = await OfferResource.getPrice(this.offer.codigo)
      this.offerPrice = response.body || {
        codigoOferta: 0,
        codigoPerfil: 0,
        valorCompreJa: 0,
        valorLeilao: 0
      }
    },
    async getIncrements() {
      const response = await OfferResource.getIncrements(this.offer.codigo)

      this.increments = response.body.incremento.sort((a, b) => a - b)
    },
    async cancelPurpouse () {
        if(!this.isValid) {return}
        const response = await OfferResource.postCancelPurpouse({
        'cdlance': this.offer.codigoUltimoLance,
        'cdJustf': this.selectedJustify.codigo,
        'obsJustf': this.observacao === '' ? null : this.observacao
      })
      if (response.ok === true) {
        this.showConfirm = true
        this.dialogCancelar = false
        this.msgSuccess = this.$t('negotiation.msgPurpouseCancel')
      } else {
        this.dialogCancelar = false
        this.error = true
        this.msgError = this.$t('negotiation.msgErrorCancel')
      }
    },
    async cancelBuy () {
        if(!this.isValid) {return}
        const response = await OfferResource.postCancelBuy({
        'cdOfrta': this.offer.codigo,
        'cdJustf': this.selectedJustify.codigo,
        'obsJustf': this.observacao === '' ? null : this.observacao
      })
      if (response.ok === true && response.body.data === 'AGUARDANDO') {
        this.showConfirm = true
        this.dialogCancelar = false
        this.msgSuccess = this.$t('negotiation.msgSolCancel')
      } else if(response.ok === true && response.body.data === 'CANCELADO') {
        this.showConfirm = true
        this.dialogCancelar = false
        this.msgSuccess = this.$t('negotiation.msgCancel')
      } else {
        this.dialogCancelar = false
        this.error = true
        this.msgError = this.$t('negotiation.msgErrorCancel')
      }
    },
    getImage(endpoint) {
      return getImageUrl(endpoint)
    },
    getIcon(endpoint) {
      return getImageUrl(endpoint)
    },
    getHour(lance) {
      let output = ''
      const lanceDate = moment(lance.data)
      output = lanceDate.format('HH:mm')
      return output
    },
    getDate (lance) {
      let output = ''
      const lanceDate = moment(lance.data)
      output = lanceDate.format('DD/MM/YYYY')

      return output
    },
    getDateStatus (date) {
      let output = ''
      const statusDate = moment(date)
      output = statusDate.format('DD/MM/YYYY')

      return output
    },
    getHourStatus (date) {
      let output = ''
      const statusDate = moment(date)
      output = statusDate.format('HH:mm')

      return output
    },
    close() {
      this.showConfirm = false
      this.$router.go(0)
    },
    goToOffer (codOffer) {
      const path = '/oferta/' + codOffer
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {})
      this.$router.go({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {})
    },
    goToCancelScreen(offer) {
      this.SET_OFERTA_INFO(offer)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/cancelamento/' + offer.codigo
      }).catch((e) => {})
    }
  }
}
</script>
<style
    scoped
    type="text/css"
>

.negotiation-content-row {
  width: 999px;
  height: 109px;
  margin-bottom: 50px;
  display: flex;
  border-bottom: 1px solid #C9CDD0;
}

.negotiation-content-icon {
  height: 46px;
  margin-left: 10px;
}

.negotiation-content-row-image {
  width: 106px;
  height: 68px;
  margin-right: 19px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px #00000080;
}

.radio-button-register {
  margin-right: 30px;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  display: block;
  margin-bottom: 26px;
  margin-top: 26px;
}

.input-textarea {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 121px;
  width: 406px;
  padding-left: 11px;
  margin-bottom: 20px;
  display: block;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}

.input-textarea::placeholder { 
  color: #8B8B8B;  
  opacity: 1;
  font: 14px 'HelveticaNeueLTPro-Roman';
}

.radio-button-register > input {
  margin-right: 8px;
}

input[type=radio] {
  background: black;
}

.custom-radio > span {
  white-space: nowrap;
}

.register-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 130px;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.register-button > span {
  display: block;
  line-height: 1;
}

.register-button-disabled {
  color: #2D2D2D !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
}

.negotiation-content-row-logo {
  width: 50px;
  height: 48px;
  margin-left: 21px;
}

.negotiation-content-row-description {
  display: flex;
  flex-flow: column;
  min-width: 295px;
  margin-left: 24px;
}

.negotiation-content-row-description-title {
  font: 18px 'HelveticaNeueLTPro-Bd';
  white-space: nowrap;
  min-height: fit-content;

  letter-spacing: 0;
  color: #60BED4;
  opacity: 1;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.negotiation-content-row-description-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  min-height: fit-content;
  color: #666666;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 295px;
  word-break: break-word;
  white-space: normal;
}

.negotiation-dialog-description-title {
  font: 18px 'HelveticaNeueLTPro-Bd';
  white-space: nowrap;
  min-height: fit-content;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  text-overflow: ellipsis;
  margin-bottom: 6px;
}

.negotiation-dialog-description-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  white-space: nowrap;
  min-height: fit-content;
  color: #666666;
  margin-bottom: 8px;
  text-overflow: ellipsis;
}

.negotiation-content-row-description-info {
  display: flex;
  font: 14px 'HelveticaNeueLTPro-Lt';
  white-space: nowrap;
  min-height: fit-content;
  letter-spacing: 0;
  color: #666666;
}

.negotiation-content-row-value-box {
  display: flex;
  flex-flow: column;
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0;
}

.negotiation-content-row-value-title {
  text-align: left;
  font: 14px 'HelveticaNeueLTPro-Lt';
  white-space: nowrap;
  color: #666666;
  margin-bottom: 10px;
}

.negotiation-content-row-value {
  font: 14px 'HelveticaNeueLTPro-Bd';
  white-space: nowrap;
  color: #414141;
}

.negotiation-content-row-stat-box {
  margin-left: 16px;
  width: 235px;
}

.negotiation-content-row-stat-text {
  font: 14px 'HelveticaNeueLTPro-Lt';
  white-space: nowrap;

  letter-spacing: 0;
  color: #666666
}

.negotiation-content-row-stat-link {
  font: 16px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #60BED4;
  cursor: pointer;
}


.negotiation-content-row-button {
  width: 108px;
  height: 46px;
}

.negotiation-content-row-button-bid {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  white-space: nowrap;

  letter-spacing: 0;
  color: #2D2D2D;
  width: 108px;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

.negotiation-content-row-button-bid > span {
  display: block;
  line-height: 1;
}

.dialog-info-header {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: nowrap;
}

.dialog-info-title {
  text-align: left;
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  flex: 1;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
}

.dialog-info-text {
  width: 496px;
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #2D2D2D;
}

.dialog-row-card {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  width: 410px;
  margin-bottom: 16px;
  padding-top: 20px;
  border-top: 1px solid;
  border-top-color: #C9CDD099;
}

.offer-line {
  width: 100%;
  margin-bottom: 50px;
  height: 0.1px;
  background-color: #C9CDD0;
  border: none;
}

.row-detail-container {
  display: flex;
  flex-flow: row nowrap;
}

.dialog-card-content {
  background: #F5F5F7;
  box-shadow: 0 3px 15px #000000C7;
  border-radius: 4px;
  opacity: 1;
}

.dialog-content {
  width: fit-content;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.dialog-content.bid {
  width: 735px;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.dialog-row-card-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.dialog-card-time {
  flex: 0;
  margin-right: 24px;
  font: 14px 'HelveticaNeueLTPro-Roman';
  color: #666666;
}

.dialog-card-text {
  flex: 1;
  color: #2D2D2D;
  font: 14px 'HelveticaNeueLTPro-Md';
}

.dialog-offer-info {
  width: fit-content;
  height: fit-content;
  margin-bottom: 30px;
}

.dialog-offer-countdown {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: fit-content;
  margin-top: 30px;
}

.dialog-info-container {
  display: flex;
  flex-flow: row nowrap;
}

.card-padding {
  padding: 24px;
}


.dialog-content-box {
  width: 295px;
  margin-bottom: 20px;
}


@media screen and (max-width: 1279px) {
  .negotiation-content-row {
    width: 100%;
    height: fit-content;
    padding-bottom: 10px;
  }

  .row-detail-container {
    flex-flow: column;
  }

  .negotiation-content-row-description,
  .negotiation-content-row-value-box,
  .negotiation-content-row-stat-box {
    margin-left: 24px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 601px) {
  .negotiation-content-icon {
    height: 46px;
    margin-left: -13px;
  }

  .input-register {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
  }

  .negotiation-content-row {
    flex-flow: column nowrap;
  }

  .negotiation-content-row-logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .negotiation-content-row-logo,
  .negotiation-content-row-description,
  .negotiation-content-row-value-box,
  .negotiation-content-row-stat-box {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .dialog-row-card {
    width: fit-content;
  }

  .dialog-info-container {
    flex-flow: row wrap;
    width: 100%;
  }

  .card-padding {
    padding: 10px;
    width: 100%;
    height: 100%;
  }

  .dialog-card-content {
    position: fixed;
    top: 20px;
    left: 10px;
    right: 10px;
    bottom: 20px;
    height: auto;
    width: auto;
    overflow-y: scroll;
  }

  .dialog-card-content.bid {
    bottom: initial;
  }

  .dialog-content-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .dialog-info-close {
    margin-top: 10px;
    margin-right: 10px;
  }

  .dialog-row-card-container {
    align-items: flex-start;
    width: 100%;
  }

  .dialog-row-card {
    width: 100%;
  }
}

</style>
