<template>
  <div>
    <svg
        height="46"
        viewBox="0 0 46 46"
        width="46"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g
          id="img_play"
          transform="translate(-34 -417)"
      >
        <circle
            cx="23"
            cy="23"
            data-name="Elipse 43"
            fill="red"
            id="Elipse_43"
            opacity="0"
            r="23"
            transform="translate(34 417)"
        />
        <path
            d="M23,0A23,23,0,1,0,46,23,22.969,22.969,0,0,0,23,0Zm7.973,24.227-11.5,7.156a1.442,1.442,0,0,1-.767.2,1.278,1.278,0,0,1-.716-.2,1.4,1.4,0,0,1-.716-1.278V15.793a1.574,1.574,0,0,1,.716-1.278,1.41,1.41,0,0,1,1.482.051l11.5,7.207a1.465,1.465,0,0,1,0,2.453Z"
            data-name="Caminho 8994"
            fill="#fff"
            id="Caminho_8994"
            transform="translate(34 417)"
        />
      </g>
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'PlayIcon',
    props: {
      color: {
        type: String,
        default: '#bcbfc2'
      },
    },
  }
</script>
<style scoped>

</style>