<template>
  <svg
      height="13.856"
      viewBox="0 0 8 13.856"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-27.945 -11)">
      <g transform="translate(28 11)">
        <path
            class="a"
            d="M35.807,15.436l-2.839,2.519V11H30.839v6.778L28,15.258v2.857l3.992,3.548,3.815-3.389Z"
            transform="translate(-28 -11)"
        />
        <rect
            class="a"
            height="2"
            transform="translate(-0.055 11.856)"
            width="8"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'DownloadIcon',
}
</script>
<style scoped>
.a {
  fill: #b4b4b4;
}
</style>