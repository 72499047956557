<template>
  <div class="finalization">
    <v-layout
        justify-space-around
        row
        wrap
    >
      <v-card
          class="mx-12"
          color="transparent"
          elevation="0"
          style="margin-top: 104px; margin-bottom: 164px;"
      >

        <v-card-text>
          <div class="finalization-container">
            <PlaceHolderFlagIcon/>

            <div
                class="register-header"
                style="margin-top: 16px;"
            >
              <div
                  class="register-title"
                  style="font-weight:bold;"
              >{{ $t('finalization.foward') }}
              </div>
            </div>

            <div>
              <div
                  class="label"
                  style="text-align: center; letter-spacing: 0; color: #666666;"
              >
                {{ finalizationText }} {{ $t('finalization.followProcess') }} <span
                  style="color:#60BED4;cursor:pointer;"
                  @click="goToNegotiations"
              >{{ $t('HeaderBar.negotiations') }}</span>.
              </div>
            </div>

            <div
                class="register-header"
                style="margin-top:60px;"
            >
              <div class="register-contato">{{ $t('global.contactsVIX') }}</div>
            </div>

            <div
                class="contact-list"
            >
              <div class="icon-group">
                <div class="finalization-icon">
                  <PhoneIcon/>
                </div>
                <div
                    class="register-link"
                    style="margin-left:10px;"
                >{{ $t('HomeIndex.contact.phone1') }}
                </div>
              </div>
              <div class="icon-group">
                <div class="finalization-icon">
                  <PhoneIcon/>
                </div>
                <div
                    class="register-link"
                    style="margin-left:10px;"
                >{{ $t('HomeIndex.contact.phone2') }}
                </div>
              </div>
                         <div class="icon-group">
                <div class="finalization-icon">
                  <PhoneIcon/>
                </div>
                <div
                    class="register-link"
                    style="margin-left:10px;"
                >{{ $t('HomeIndex.contact.phone3') }}
                </div>
              </div>
              <div class="icon-group">
                <div class="finalization-icon">
                  <MailIcon/>
                </div>
                <div
                    class="register-link"
                    style="margin-left:10px;"
                >{{ $t('HomeIndex.contact.mailto') }}
                </div>
              </div>
            </div>
            <div style="width: 100%; display:flex;">
              <div @click="voltar()" style="margin-left: auto; margin-right: auto;" class="offer-header-button-buy align-content-center">
                {{ $t('finalization.toBack') }}
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-layout>

  </div>
</template>

<script>
import PlaceHolderFlagIcon from '@/shared/Icons/PlaceHolderFlagIcon'
import PhoneIcon from '@/shared/Icons/PhoneIcon'
import MailIcon from '@/shared/Icons/MailIcon'
import { mapState } from 'vuex'

export default {
  name: 'Finalization',
  components: {PlaceHolderFlagIcon, PhoneIcon, MailIcon},
  props: {
    text: {
      type: String
    }
  },
  computed: {
    ...mapState('fluxStore', ['fluxType']),
    finalizationText() {
      if (this.fluxType === 'BID' || this.fluxType === 'AUTO_BID') {
        return this.$t('finalization.bidPlaced')
      } else if (this.fluxType === 'PURPOSE') {
        return this.$t('finalization.purposeSubmitted')
      } else {
        return this.$t('finalization.purchaseReview')
      }
    }
  },
  methods: {
    goToNegotiations () {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/negociacao'
      }).catch((e) => {})
    },
    voltar () {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/home/'
      }).catch(() => {})
    },
  },
}
</script>

<style
    scoped
    type="text/css"
>
.finalization {
  background: #ffffff 0 0 no-repeat padding-box;
  flex: 0;
}

.label {
  color: #8B8B8B;
  text-align: center;
  font: normal normal medium 16px/20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0px;
  opacity: 1;
}

.offer-header-button-buy {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 140px;
  height: 46px;
  margin-top: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

  .header-link-box {
    display: flex;
    justify-content: space-around;
    width: 60%;
    z-index: 4;
  }

  .header-link-box {
    width: 100%;
    justify-content: space-evenly;
  }

  .banner-link {
    font-size: 11px;
  }

  .banner-link {
    text-align: center;
    font: 16px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
    color: #60BED4;
    opacity: 1;
    cursor: pointer;
    flex: 1;
    white-space: nowrap;
  }

.register-title {
  font: normal normal bold 28px/37px HelveticaNeueLTPro-Roman;
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
  text-align: center;
}


.register-contato {
  text-align: center;
  font: normal normal bold 18px/24px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0px;
  color: #2D2D2D;
  opacity: 1;
}

.register-link {
  font: 14px 'HelveticaNeueLTPro-Roman';
  white-space: nowrap;

  text-align: left;
  letter-spacing: 0;
  color: #60BED4;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 8px;
}

.register-header {
  position: relative;
  margin-bottom: 16px;
}

.register-register {
  width: fit-content;
  position: absolute;
  right: 3px;
  top: 14px;
  color: #666666;
  font: 13px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
}

.register-go-register {
  color: #27badc;
  cursor: pointer;
}

.register-term {
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  margin-bottom: 30px;
  color: #666666;
}

.register-term > span > span {
  color: #27BADC;
  cursor: pointer;
}

.register-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 78px;
}

.register-button-disabled {
  color: #2D2D2D !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
}

.register-form-container {
  display: flex;
  flex-wrap: wrap;
  width: 442px;
}

.radio-button-register {
  margin-right: 30px;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}

.radio-button-register > input {
  margin-right: 8px;
}

.register-error-message {
  height: 27px;
  background: #D85757 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #FFFFFF;
}

.has-error {
  border-color: #e09e9e;
}

.finalization-icon {
  position: relative;
  top: 4px;
  width: 16px;
}

.icon-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
}

.contact-list {
  margin-top: 15px;
  margin-left: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.finalization-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 479px;
}

@media screen and (max-width: 601px) {
  .finalization-container {
    width: 100%;
  }

  .contact-list {
    flex-flow: column nowrap;
    margin-left: 0;
    justify-content: space-between;
    align-items: center;
  }

  .icon-group {
    margin-bottom: auto;
  }
}
</style>
