<template>
  <div
      class="icon-btn"
  >  
    <div
        style="height: 50px; width: 50px"
        class="align-content-center"
    >
      <v-img
       :class="{'filter-btn-selected ': selected}"
       :style="style"
        style="text-align: center"
        :src="getImageUrl(obj.ativo, obj.inativo, selected)"  
        @click="emitClick()"
        class="icon-btn cursor-pointer"
        contain       
      />
    </div>

  </div>
</template>
<script>
import { getImageUrl } from '@/api'

export default {
  name: 'IconBtn',
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    height: {
      type: String || Number,
      default: '48px'
      },
      width: {
        type: String || Number,
        default: '50px'
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      style () {
        return `height: ${this.getSize(this.height)}; width: ${this.getSize(this.width)};`
      }
    },
    methods: {
      getSize (size) {
        return size.toString()
          .replace(/\d/g, '') ? size : `${size}px`
      },
      emitClick() {
        this.$emit('click')
      },
      getImageUrl(endpointAtivo, endpointInativo, active) {
        return getImageUrl(active ? endpointAtivo : endpointInativo)
      }
    },
  }
</script>
<style
    scoped
    type="text/css"
>

  .icon-btn {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-items: center;
    background: #F5F5F7;
  }

   .icon-btn.btn-selected {
    background: #60BED4;
    color: #FFFFFF;
    box-shadow: 2px 2px 2px #FFFFFF, -2px -2px 2px #A3B1C699;
  }

</style>
