const state = {
  offers: []
}

const mutations = {
  ['SET_MY_OFFERS'] (state, payload) {
    state.offers = payload
  },
}

const actions = {}

const getters = {}

const negotiationStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default negotiationStore
