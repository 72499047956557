<template>
  <div
      :class="{'selected': selected}"
      class="flux-address-card"
  >
    <div
        style="height: 100%"
    >
      <div
          class="flux-address-card-content"
      >
        <div
            :class="{'selected': selected}"
            class="flux-address-text"
        >
          {{ addressText }}
        </div>
        <div
            class="flux-address-card-actions  "
        >
          <div v-if="showActionUpd"
               :class="{'selected': selected}"
               @click="edit"
               class="flux-address-edit cursor-pointer"
          >
            {{$t('global.editAddress')}}
          </div>
          <div v-if="showActionDel"
               :class="{'selected': selected}"
               @click="remove"
               class="flux-address-delete cursor-pointer"
          >
            {{$t('global.delete')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AddressCard',
  props: {
    address: {
      type: Object
    },
    selected: {
      type: Boolean,
      default: false
    },
    showActionUpd: {
      type: Boolean,
      default: true
    },
    showActionDel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    addressText () {
      let text = `${this.address.endereco} ${this.address.numeroEndereco}, `
      text += this.address.complemento ? `${this.address.complemento}, ` : ''
      text += `${this.address.bairro}, ${this.address.descricaoCidade} - ${this.address.estado} ${this.$t('flux.zipCode')} ${this.address.cep}`

      return text
    }
  },
  methods: {
    edit (e) {
      e.stopPropagation()
      e.preventDefault()
      this.$emit('edit', this.address)
    },
    remove (e) {
      e.stopPropagation()
      e.preventDefault()
      this.$emit('remove', this.address)
    }
  },
}
</script>
<style
    scoped
    type="text/css"
>

.flux-address-card {
  margin-bottom: 24px;
  flex: 0;
  padding: 20px 16px;
  border-radius: 10px;
  min-width: 406px;
  max-width: 406px;
  min-height: 127px;
  max-height: 127px;
  background: #F5F5F7 0 0 no-repeat padding-box;
  cursor: pointer;
}

.flux-address-card.selected {
  background: #60BED4 0 0 no-repeat padding-box;
}

.flux-address-text {
  font: 14px 'HelveticaNeueLTPro-Roman';
  color: #6f6f6f;
  letter-spacing: 0;
  min-height: 63px;
  max-height: 63px;
}

.flux-address-text.selected {
  color: #FFFFFF;
}

.flux-address-card-actions {
  display: flex;
  flex-flow: row;
}

.flux-address-edit {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  margin-right: 20px;
  color: #27badc;
}

.flux-address-edit.selected {
  color: #666666;
}

.flux-address-delete {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #27badc;
}

.flux-address-delete.selected {
  color: #666666;
}


</style>
