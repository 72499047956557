const HomeApi = (vueResource, url) => {
  const resource = vueResource(`${url}/offer{/PATH}{/ID}{?Q*}`)
  const resourcePartner = vueResource(`${url}/partner{/PATH}{/ID}{?Q*}`)

  return {
    async getList (id) {
      try {
        
        return await resource.get({ ID: id })
      } catch (e) {
        return { body: [] }
      }
    },
    async getListByCategory (id) {
      try {
        return await resource.get({ PATH: 'category', ID: id })
      } catch (e) {
        return { body: [] }
      }
    },
    async getBannerList (id) {
      try {
        return await resource.get({ PATH: 'banner', ID: id })
      } catch (e) {
        return { body: [] }
      }
    },
    async get (id) {
      try {
        return await resource.get({ ID: id })
      } catch (e) {
        return { body: null }
      }
    },
    async getPartnerDetails () {
      try {
        return await resourcePartner.get()
      } catch (e) {
        return { body: null }
      }
    },
    async post (payload) {
      try {
        return await resource.save(payload)
      } catch (e) {
        return { body: [] }
      }
    },
    async put (id, payload) {
      try {
        return await resource.update({ ID: id }, payload)
      } catch (e) {
        return { body: [] }
      }
    },
    async delete (id) {
      try {
        return await resource.delete({ ID: id })
      } catch (e) {
        return { body: [] }
      }
    }
  }
}
export default HomeApi