import { render, staticRenderFns } from "./HeaderBarDesktop.vue?vue&type=template&id=1a4c5628&scoped=true"
import script from "./HeaderBarDesktop.vue?vue&type=script&lang=js"
export * from "./HeaderBarDesktop.vue?vue&type=script&lang=js"
import style0 from "./HeaderBarDesktop.vue?vue&type=style&index=0&id=1a4c5628&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a4c5628",
  null
  
)

export default component.exports