<template>
  <v-container>
    <template v-if="hasErrors">
      <v-alert
          color="red"
          dismissible
          type="error"
          v-for="error in errors"
          :key="error"
      >
        {{ error }}
      </v-alert>
    </template>

    <component :is="currentScreen"></component>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import RegisterPartner from "@/screen/RegisterPartner/RegisterPartner";
import PartnerTokenVerifier from "@/screen/RegisterPartner/PartnerTokenVerifier";
import PartnerInterests from "@/screen/RegisterPartner/PartnerInterests";
import PartnerStoreName from "@/screen/RegisterPartner/PartnerStoreName";
import FinishPartnerRegister from "@/screen/RegisterPartner/FinishPartnerRegister";

export default {
  name: "PartnerRegisterFrame",
  components: {
    RegisterPartner,
    PartnerTokenVerifier,
    PartnerInterests,
    PartnerStoreName,
    FinishPartnerRegister
  },
  computed: {
    ...mapState('registerPartnerStore', ['steps', 'step', 'loading', 'errors']),
    ...mapGetters('registerPartnerStore', ['currentScreen', 'hasErrors'])
  },
}
</script>

<style lang="scss" scoped>
</style>
