<template>
  <div class="filter-sidebar">

    <div class="filter-header-back">
      <div @click="closeFilter">
        <BackArrowIcon
            :show-circle="false"
        />
      </div>
    </div>

    <div
        class="filter-header"
    >
      <div class="filter-header-title">
        <p class="filter-title ">{{ $t('FilterSidebar.title') }}</p>
        <div class="filter-clear-container">
          <p
              class="filter-clear"
              @click="clearFilter"
          >
            {{ $t('FilterSidebar.clearFilter') }}
          </p>
        </div>
      </div>

      <div
          class="mb40 filter-grid"
      >
        <FilterBtn
            :selected="vehicle"
            title="Veículo"
            @click="setVehicles()"
        />
        <FilterBtn
            :selected="lot"
            style="margin-left: 16px;"
            title="Lote"
            @click="setLot()"
        />
      </div>
    </div>

    <div class="filter-container">
      <div
          class="filter-container-column"
      >
        <div
            class="mb40"
            style="width: 90%; flex: 0;"
        >
          <p
              class="filter-session-title"
              style="margin-bottom: 30px;"
          >{{ $t('FilterSidebar.priceRanger') }}</p>

          <div
              style="width: 100%;height: 50px;margin-top: 60px;"
          >
            <SliderTest
                :max="price.max"
                :min="price.min"
                :value="price.value"
                @update="updatePrice"
            />
          </div>

        </div>

        <div
            class="mb40"
            style="width: 90%; flex: 0;"
        >
          <p
              class="filter-session-title"
              style="margin-bottom: 30px;"
          >{{ $t('FilterSidebar.kmRanger') }}</p>

          <div
              style="width: 100%;height: 50px;margin-top: 60px;"
          >
            <SliderTest
                :max="usage.max"
                :min="usage.min"
                :value="usage.value"
                tipo="km"
                @update="updateUsage"
            />
          </div>
        </div>

        <div
            class="mb40"
            style="width: 90%; flex: 0;"
        >
          <p
              class="filter-session-title"
              style="margin-bottom: 7px;"
          >{{ $t('FilterSidebar.brands') }}</p>
          <p
              class="filter-session-subtitle"
              style="margin-bottom: 22px;"
          >{{ $tc('FilterSidebar.quantityText', brandsSelected.length, {count: brandsSelected.length}) }}</p>

          <div class="filter-btn-container">
            <BrandBtn
                v-for="(brand, i) in getFromArray(filter.brands, 5)"
                :key="i"
                :obj="brand "
                :selected="isBrandSelected(brand)"
                :style="i < 5 ? 'margin-bottom: 10px;' : '' "
                style="width: fit-content"
                @click="selectBrand(brand)"
            />
          </div>

          <p
              v-if="filter.brands && filter.brands.length > 5"
              class="show-more"
              @click="openModalBrands()"
          >{{ $t('FilterSidebar.showMore') }}</p>

        </div>


        <div
            class="mb40"
            style="width: 90%; flex: 0;"
        >
          <p
              class="filter-session-title"
              style="margin-bottom: 7px;"
          >{{ $t('FilterSidebar.categories') }}</p>
          <p
              class="filter-session-subtitle"
              style="margin-bottom: 22px;"
          >{{
              $tc('FilterSidebar.quantityText', subcategoriesSelected.length, {count: subcategoriesSelected.length})
           }}</p>

          <div class="filter-btn-container">
            <BrandBtn
                v-for="(category, i) in getFromArray(filter.subcategories, 5)"
                :key="i"
                :obj="category "
                :selected="isCategoriesSelected(category)"
                :style="i < 5 ? 'margin-bottom: 10px;' : '' "
                style="width: fit-content"
                width="138"
                @click="selectCategory(category)"
            />
          </div>

          <p
              v-if="filter.subcategories && filter.subcategories.length > 5"
              class="show-more"
              @click="openModalSubcategories()"
          >{{ $t('FilterSidebar.showMore') }}</p>

        </div>
      </div>

      <div
          class="mb40 filter-model-container"
      >
        <p
            class="filter-session-title"
            style="margin-bottom: 7px;"
        >{{ $t('FilterSidebar.models') }}</p>
        <p
            class="filter-session-subtitle"
            style="margin-bottom: 22px;"
        >{{ $tc('FilterSidebar.quantityText', modelsSelected.length, {count: modelsSelected.length}) }}
        </p>
        <div class="filter-grid filter-grid-gap">
          <FilterBtn
              v-for="(models, i) in getFromArray(filter.models, 10)"
              :key="i"
              :selected="isModelsSelected(models)"
              :title="models.name"
              width="106"
              @click="selectModels(models)"
          />
        </div>

        <p
            v-if="filter.models && filter.models.length > 10"
            class="show-more"
            @click="openModalModels()"
        >{{ $t('FilterSidebar.showMore') }}</p>
      </div>
    </div>

    <div>
      <v-dialog
          v-model="modalFilter"
      >
        <div class="modal-content">
          <div style="position: relative;">
            <div
                class="cursor-pointer"
                style="position: absolute; top: 22px; right: 22px; height: 20px; width: 20px;"
                @click="closeModal()"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div
              class="modal-container"
          >
            <p
                class="filter-modal-title"
                style="margin-bottom: 3px;"
            >{{ modal.title }}</p>
            <p
                class="filter-modal-subtitle"
            >
              {{
                $tc('FilterSidebar.quantityText', modal.dataSelected.length, {count: modal.dataSelected.length})
              }}
            </p>

            <div
                class="filter-modal-search"
            >
              <input
                  v-model="modal.search"
                  :placeholder="`Busque por ${modal.title}`"
                  class="input-modal-search"
                  type="text"
              />
              <p
                  class="filter-modal-clear"
                  @click="clearSelected()"
              >{{ $t('FilterSidebar.clearSelects') }}</p>
            </div>

            <div class="modal-content-overflow">
              <div
                  v-if="modal.type === 'SUBCATEGORIES'"
                  class="filter-modal-grid-subcategories"
              >
                <BrandBtn
                    v-for="(category, i) in modalDataFiltered"
                    :key="i"
                    :obj="category "
                    :selected="isCategoriesSelected(category)"
                    :style="i < 5 ? 'margin-bottom: 10px;' : '' "
                    width="138"
                    @click="selectCategory(category)"
                />
              </div>

              <div
                  v-else-if="modal.type === 'BRANDS'"
                  class="filter-modal-grid-brands"
              >
                <BrandBtn
                    v-for="(brand, i) in modalDataFiltered"
                    :key="i"
                    :obj="brand "
                    :selected="isBrandSelected(brand)"
                    :style="i < 5 ? 'margin-bottom: 10px;' : '' "
                    @click="selectBrand(brand)"
                />
              </div>

              <div
                  v-else
                  class="filter-modal-grid-models"
              >
                <FilterBtn
                    v-for="(models, i) in modalDataFiltered"
                    :key="i"
                    :selected="isModelsSelected(models)"
                    :title="models.name"
                    width="106"
                    @click="selectModels(models)"
                />
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import FilterBtn from './FilterBtn'
import BrandBtn from './BrandBtn'
import {FilterResource} from '@/api'
import XMarkIcon from '../Icons/XMarkIcon'
import SliderTest from '@/screen/Home/SliderTest'
import {mapMutations} from 'vuex'
import BackArrowIcon from '@/shared/Icons/BackArrowIcon'
import { productEvents } from '@/analytics'
import { timer, Subject } from 'rxjs'
import { debounce } from 'rxjs/operators'

export default {
  name: 'FilterSidebarMobile',
  components: {BackArrowIcon, SliderTest, XMarkIcon, BrandBtn, FilterBtn},
  data() {
    return {
      modalFilter: false,
      filter: {},
      modelsSelected: [],
      brandsSelected: [],
      subcategoriesSelected: [],
      price: {
        max: 500000,
        min: 0,
        value: [0, 500000],
        timeId: undefined
      },
      usage: {
        max: 150000,
        min: 0,
        value: [0, 150000],
        timeId: undefined
      },
      modal: {
        title: 'Marca',
        data: [],
        dataSelected: [],
        search: '',
        type: 'MODELS'
      },
      vehicle: true,
      lot: true,
      category: 0,
      filterEvents$: new Subject(),
      filterEventsSub: null,
    }
  },
  computed: {
    modalDataFiltered() {
      const search = this.modal.search.toUpperCase()
      return !search ? this.modal.data : this.modal.data.filter(v => v.name.toUpperCase().indexOf(search) >= 0)
    }
  },
  mounted() {
    this.category = this.$route.params.category
    this.init()

    this.filterEventsSub = this.filterEvents$
      .pipe(
        debounce(() => timer(10 * 1000)) // 10s até publicar evento de filtro
      )
      .subscribe((filter) => productEvents.filtered(filter))
  },
  beforeDestroy() {
    this.filterEventsSub.unsubscribe()
  },
  methods: {
    ...mapMutations('headerStore', ['SET_HIDE_HEADER', 'SET_SHOW_SEARCH']),
    ...mapMutations('homeStore', [
      'CLEAR_FILTER',
      'SET_FILTER',
      'SET_SKIP_CLEAR_FILTER',
      'SET_SHOW_FILTER_MOBILE'
    ]),
    async init() {
      const filter = await FilterResource.getList(this.category)
      this.filter = !filter ? {} : filter.body
      this.CLEAR_FILTER()
    },
    closeFilter() {
      this.SET_SHOW_FILTER_MOBILE(false)
      this.SET_HIDE_HEADER(false)
      this.SET_SHOW_SEARCH(false)
    },
    getFromArray(data, size) {
      return data ? data.slice(0, size) : []
    },
    openModalBrands() {
      this.openModal(this.filter.brands, this.brandsSelected, this.$t('FilterSidebar.brands'), 'BRANDS')
    },
    openModalSubcategories() {
      this.openModal(this.filter.subcategories, this.subcategoriesSelected, this.$t('FilterSidebar.categories'), 'SUBCATEGORIES')
    },
    openModalModels() {
      this.openModal(this.filter.models, this.modelsSelected, this.$t('FilterSidebar.models'), 'MODELS')
    },
    openModal(data, dataSelected, title, type) {
      this.modal = {
        title: title,
        data: data,
        dataSelected: dataSelected,
        search: '',
        type: type
      }
      this.modalFilter = true
    },
    closeModal() {
      this.modalFilter = false
      this.modal = {
        title: '***Title***',
        data: [],
        dataSelected: [],
        search: '',
        type: 'MODELS'
      }
    },
    isBrandSelected(model) {
      return this._isSelected(this.brandsSelected, model)
    },
    isCategoriesSelected(model) {
      return this._isSelected(this.subcategoriesSelected, model)
    },
    isModelsSelected(model) {
      return this._isSelected(this.modelsSelected, model)
    },
    _isSelected(list, model) {
      return list.indexOf(model.codigo) >= 0
    },
    selectBrand(model) {
      this._select(this.brandsSelected, model.codigo)
    },
    selectCategory(model) {
      this._select(this.subcategoriesSelected, model.codigo)
    },
    selectModels(model) {
      this._select(this.modelsSelected, model.codigo)
    },
    clearSelected() {
      this.modal.dataSelected.splice(0, 999999)
      this.updateFilters()
    },
    clearFilter() {
      this.SET_SKIP_CLEAR_FILTER(false)
      this.CLEAR_FILTER()
      this.subcategoriesSelected.splice(0, 999999)
      this.brandsSelected.splice(0, 999999)
      this.modelsSelected.splice(0, 999999)
      this.price.value = [0, this.price.max]
      this.usage.value = [0, this.usage.max]
      this.vehicle = true
      this.lot = true
    },
    updatePrice(value) {
      this.price.value = value
      if (this.price.timeId) {
        clearTimeout(this.price.timeId)
      }
      this.price.timeId = setTimeout(this.updateFilters, 500)
    },
    updateUsage(value) {
      this.usage.value = value
      if (this.usage.timeId) {
        clearTimeout(this.usage.timeId)
      }
      this.usage.timeId = setTimeout(this.updateFilters, 500)
    },
    _select(list, codigo) {
      if (list.indexOf(codigo) >= 0) {
        list.splice(list.indexOf(codigo), 1)
      } else {
        list.push(codigo)
      }
      this.updateFilters()
    },
    setVehicles() {
      this.vehicle = !this.vehicle
      this.updateFilters()
    },
    setLot() {
      this.lot = !this.lot
      this.updateFilters()
    },
    updateFilters() {
      this.SET_FILTER({
        lot: this.lot,
        vehicle: this.vehicle,
        usageRanger: {
          start: this.getRangerValue(this.usage.value[0], this.usage.max),
          end: this.getRangerValue(this.usage.value[1], this.usage.max),
        },
        priceRanger: {
          start: this.getRangerValue(this.price.value[0], this.price.max),
          end: this.getRangerValue(this.price.value[1], this.price.max),
        },
        brandsSelected: this.brandsSelected,
        subcategoriesSelected: this.subcategoriesSelected,
        modelsSelected: this.modelsSelected,
      })

      this.filterEvents$.next({
        price_range: `De ${this.price.value[0].float2Money()} até ${this.price.value[1].float2Money()}`,
        mileage_range: `De ${this.getUsageFormatted(this.usage.value[0])} Km até ${this.getUsageFormatted(this.usage.value[1])} Km`,
        brand: this.brandsSelected,
        model: this.modelsSelected,
        category: this.subcategoriesSelected,
      })
    },
    getRangerValue(value, max) {
      return value === max ? -1 : value
    },
    getUsageFormatted(usage) {
      return usage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
}
</script>

<style
    scoped
    type="text/css"
>
.mb40 {
  margin-bottom: 40px;
}

.filter-title {
  text-align: left;
  font: 26px 'HelveticaNeueLTPro-Cn';
  font-weight: bold;
  letter-spacing: 1px;
  color: #5A6222;
  opacity: 0.2;
  top: 28px;
  right: 30px;
  margin-bottom: 20px;
}

.filter-sidebar {
  height: fit-content;
  background: #F5F5F7 0 0 no-repeat padding-box;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-flow: column;
  padding-left: 24px;
  padding-right: 24px;
}

.filter-header {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.filter-header > i {
  position: absolute;
  left: 13px;
  top: 33px;
  color: #5a6222;
  opacity: 0.2;
}

.filter-clear-container {
  flex: 1;
  justify-content: flex-end;
}

.filter-clear {
  text-align: right;
  font: 11px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #60BED4;
  cursor: pointer;
}

.filter-session-title {
  margin-bottom: 30px;
  font: 14px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #565656;
}

.filter-session-subtitle {
  font: 11px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #A7A7A7;
  opacity: 1;
}

.show-more {
  font: 12px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #60BED4;
  margin-top: 20px;
  cursor: pointer;
}

.filter-grid {
  display: flex;
  flex-flow: row wrap;
}

.filter-grid-gap {
  grid-gap: 16px;
}

.modal-shadow {
  z-index: 998;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #2B465E 0 0 no-repeat padding-box;
  opacity: 0.15;
}

.modal-content {
  background: #F5F5F7 0 0 no-repeat padding-box;
  box-shadow: 0 3px 15px #000000C7;
  border-radius: 4px;;
  position: fixed;
  top: 20px;
  left: 10px;
  right: 10px;
  bottom: 20px;
  height: auto;
  width: auto;
}

.filter-modal-title {
  font: 24px 'HelveticaNeueLTPro-Cn';
  font-weight: bold;
  letter-spacing: 1px;
  color: #8B8B8B;
  opacity: 1;
}

.input-modal-search {
  background: #FFFFFF;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  border: 1px solid #d4e2f5;
  height: 28px;
  width: 200px;
  padding-left: 11px;
  margin-bottom: 20px;
}

.input-modal-search::placeholder {
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #C9CDD0;
  opacity: 1;
  padding-left: 11px;
}

.filter-modal-search {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  grid-template-columns: 200px auto;
  align-items: baseline;
  justify-content: space-between;
}

.filter-modal-clear {
  text-align: left;
  font: 11px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #60BED4;
  opacity: 1;
  cursor: pointer;
  margin: 0 0 20px;
}

.filter-modal-subtitle {
  text-align: left;
  font: 11px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #A7A7A7;
  opacity: 1;
  margin-bottom: 13px;
}

.filter-modal-grid-models {
  display: grid;
  grid-template-columns: repeat(auto-fill, 106px);
  justify-content: space-between;
  row-gap: 16px;
  column-gap: 10px;
  padding-right: 3px;
}

.filter-modal-grid-subcategories {
  display: grid;
  grid-template-columns: repeat(auto-fill, 198px);
  justify-content: space-between;
  row-gap: 16px;
  column-gap: 10px;
  padding-right: 3px;
}

.filter-modal-grid-brands {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  justify-content: space-between;
  row-gap: 16px;
  column-gap: 10px;
  padding-right: 3px;
}

.modal-content-overflow {
  max-height: 62vh;
  overflow-y: auto;
  padding-bottom: 10px;
  padding-right: 3px;
}

.modal-content-overflow::-webkit-scrollbar {
  width: 0 !important
}


.filter-header-title {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.filter-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-around;
}

.filter-container-column {
  min-width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.filter-btn-container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter-model-container {
  max-width: 600px;

}

.filter-header-back {
  width: 100%;
  display: flex;
  min-height: 50px;
  align-items: center;
}

.modal-container {
  padding: 15px 10px 30px;
}

.btn-margin-right {
  margin-right: 5px;
}
</style>
