const state = {
  showSearch: false,
  showSideMenu: false,
  hideHeader: false,
}

const mutations = {
  ['SET_SHOW_SEARCH'] (state, payload) {
    state.showSearch = payload
  },
  ['SET_SHOW_SIDE_MENU'] (state, payload) {
    state.showSideMenu = payload
  },
  ['SET_HIDE_HEADER'] (state, payload) {
    state.hideHeader = payload
  },
}

const actions = {}

const getters = {}

const headerStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default headerStore
