import OfferIndex from './OfferIndex'
import OfferViewer from '@/screen/Offer/OfferViewer'
import OfferRouterView from '@/screen/Offer/OfferRouterView'

const OfferRouter = {
  path: 'oferta',
  component: OfferRouterView,
  children: [
    {
      path: 'verificar/:token',
      component: OfferViewer
    },
    {
      path: ':id',
      component: OfferIndex,
    }
  ]
}
export default OfferRouter