<template>
  <div class="dynamic-screen">
    <div class="dynamic-title">
      {{ content.description }}
    </div>
    <div
        class="dynamic-text"
        v-html="content.html"
    />
  </div>
</template>

<script>
export default {
  name: "DynamicScreen",
  props: {
    content: {
      type: Object
    },
  },
}
</script>

<style scoped>
.dynamic-screen {
  display: flex;
  flex-flow: column;
  padding: 20px 79px;
}

.dynamic-title {
  font: 25px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  margin-bottom: 36px;
}

.dynamic-text {
  font: 13px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #6F6F6F;
  opacity: 1;
}
</style>
