const FavoriteApi = (vueResource, url) => {
    const resource = vueResource(`${url}/offer/favorites{/PATH}{/ID}{?Q*}`)
  
    return {
      async getList () {
        try {
          return await resource.get()
        } catch (e) {
          return { body: [] }
        }
      }
    }
  }
  export default FavoriteApi