<template>
  <svg
      height="48"
      viewBox="0 0 50 48"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="clip-path-lot">
        <rect
            data-name="Retângulo 576"
            fill="#d46060"
            height="48"
            id="Retângulo_576"
            rx="3"
            transform="translate(-2171.5 -5776)"
            width="50"
        />
      </clipPath>
    </defs>
    <g
        id="ic_frota"
        transform="translate(-194.5 -274)"
    >
      <g
          clip-path="url(#clip-path-lot)"
          id="logo_frota_off"
          opacity="0"
          transform="translate(2366 6050)"
      >
        <g
            data-name="Grupo 2228"
            id="Grupo_2228"
            transform="translate(-2128.906 -5697.938)"
        >
          <g
              data-name="Grupo 2223"
              id="Grupo_2223"
          >
            <g
                data-name="Grupo 2222"
                id="Grupo_2222"
            >
              <path
                  d="M-14.394-59.689l1.585-.6.365.738-1.262.76Z"
                  data-name="Caminho 9057"
                  fill="#c8c8c8"
                  id="Caminho_9057"
              />
              <path
                  d="M-38.314-59.689l-1.585-.6-.365.738,1.262.76Z"
                  data-name="Caminho 9058"
                  fill="#c8c8c8"
                  id="Caminho_9058"
              />
              <path
                  d="M-15.508-44.526a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645v-5.966h-4.177Z"
                  data-name="Caminho 9059"
                  id="Caminho_9059"
              />
              <path
                  d="M-37.2-44.526a.645.645,0,0,1-.645.645h-2.887a.645.645,0,0,1-.646-.645h0v-5.966H-37.2Z"
                  data-name="Caminho 9060"
                  id="Caminho_9060"
              />
              <path
                  d="M-26.354-46.3H-37.982c-2.25,0-2.814-.638-3.281-1.709-.65-1.493-.452-5.608-.255-7.032s1.964-3.339,2.807-4.869c.336-1.729,2.709-5.728,4.1-6.424.908-.454,6.069-.548,8.259-.548s7.351.094,8.259.548c1.393.7,3.767,4.7,4.1,6.424.843,1.53,2.611,3.445,2.807,4.869s.395,5.54-.255,7.032c-.466,1.071-1.031,1.709-3.281,1.709Z"
                  data-name="Caminho 9061"
                  fill="#bababa"
                  id="Caminho_9061"
              />
              <path
                  d="M-18.134-48.363c0-.7,1.069-1.778,3.079-1.745,1.441.024,1.953.537,1.745,1.157C-13.708-47.762-18.134-47.51-18.134-48.363Z"
                  data-name="Caminho 9062"
                  fill="#818181"
                  id="Caminho_9062"
              />
              <path
                  d="M-17.68-48.454a2.914,2.914,0,0,0,1.116.153c1.424,0,2.715-.433,2.835-.791.047-.139.006-.2-.013-.224-.114-.161-.521-.338-1.32-.351h-.083C-16.8-49.667-17.575-48.867-17.68-48.454Z"
                  data-name="Caminho 9063"
                  id="Caminho_9063"
              />
              <path
                  d="M-18.871-54.578l2.922-5.374,1.1-.393-3.83,5.942Z"
                  data-name="Caminho 9064"
                  fill="#c8c8c8"
                  id="Caminho_9064"
              />
              <path
                  d="M-12.767-56.065a2.475,2.475,0,0,0-1.264-.169l-.258.024a5.119,5.119,0,0,0-1.656.507l-.6.326a3.405,3.405,0,0,0-1.181,1.21l-.206.4a.5.5,0,0,0,.117.7.5.5,0,0,0,.361.088h.63a5.518,5.518,0,0,0,1.7-.326l1.773-.7a1.468,1.468,0,0,0,.889-1.062A1.172,1.172,0,0,0-12.767-56.065Z"
                  data-name="Caminho 9065"
                  fill="#e9e9e9"
                  id="Caminho_9065"
              />
              <path
                  d="M-26.355-48.508h-5.973c-.67,0-1.119-.387-1.119-.806s.322-.516.9-.516h12.386c.58,0,.9.1.9.516s-.448.806-1.119.806Z"
                  data-name="Caminho 9066"
                  fill="#818181"
                  id="Caminho_9066"
              />
              <path
                  d="M-26.355-52.288h-5.093c-1.458,0-2-.817-2-1.543,0-.568.205-.746,1.492-.746h11.207c1.286,0,1.492.179,1.492.746,0,.726-.545,1.543-2,1.543Z"
                  data-name="Caminho 9067"
                  id="Caminho_9067"
              />
              <path
                  d="M-26.355-47.186h-5.479c-.593,0-.8-.34-.8-.634,0-.137.312-.221.814-.221H-20.9c.5,0,.814.084.814.221,0,.294-.2.634-.8.634Z"
                  data-name="Caminho 9068"
                  fill="#818181"
                  id="Caminho_9068"
              />
              <path
                  d="M-34.574-48.363c0-.7-1.069-1.778-3.079-1.745-1.442.024-1.953.537-1.745,1.157C-39-47.762-34.574-47.51-34.574-48.363Z"
                  data-name="Caminho 9069"
                  fill="#818181"
                  id="Caminho_9069"
              />
              <path
                  d="M-35.029-48.454a2.915,2.915,0,0,1-1.115.153c-1.424,0-2.715-.433-2.836-.791-.047-.139-.006-.2.013-.224.114-.161.521-.338,1.32-.351h.083C-35.908-49.667-35.134-48.867-35.029-48.454Z"
                  data-name="Caminho 9070"
                  id="Caminho_9070"
              />
              <path
                  d="M-26.354-52.9c-1.866,0-5.715-.064-5.715-.506s3.823-.476,5.715-.476,5.715.035,5.715.476S-24.488-52.9-26.354-52.9Z"
                  data-name="Caminho 9071"
                  fill="#e9e9e9"
                  id="Caminho_9071"
              />
              <path
                  d="M-26.354-60.172s-10.8.2-11.043-.363,2.234-4.3,3.069-4.842,5.444-.668,7.974-.668,7.139.128,7.974.668,3.315,4.277,3.069,4.842S-26.354-60.172-26.354-60.172Z"
                  data-name="Caminho 9072"
                  id="Caminho_9072"
              />
              <path
                  d="M-33.836-54.578l-2.922-5.374-1.1-.393,3.83,5.942Z"
                  data-name="Caminho 9073"
                  fill="#c8c8c8"
                  id="Caminho_9073"
              />
              <path
                  d="M-39.942-56.065a2.472,2.472,0,0,1,1.264-.169l.258.024a5.134,5.134,0,0,1,1.656.507l.6.326a3.414,3.414,0,0,1,1.181,1.21l.206.4a.5.5,0,0,1-.118.7.5.5,0,0,1-.36.088h-.63a5.512,5.512,0,0,1-1.705-.326l-1.773-.7a1.466,1.466,0,0,1-.889-1.062A1.166,1.166,0,0,1-39.942-56.065Z"
                  data-name="Caminho 9074"
                  fill="#e9e9e9"
                  id="Caminho_9074"
              />
              <path
                  d="M-10.116-60.337c0,.841-.921,1.239-1.729,1.239-.966,0-1.493-.554-1.493-1.239s.527-1.238,1.493-1.238C-11.036-61.575-10.116-61.178-10.116-60.337Z"
                  data-name="Caminho 9075"
                  fill="#bababa"
                  id="Caminho_9075"
              />
              <path
                  d="M-42.593-60.337c0,.841.921,1.239,1.729,1.239.966,0,1.493-.554,1.493-1.239s-.527-1.238-1.493-1.238C-41.672-61.575-42.593-61.178-42.593-60.337Z"
                  data-name="Caminho 9076"
                  fill="#bababa"
                  id="Caminho_9076"
              />
            </g>
            <path
                d="M-34.613-66.334c-1.393.7-3.767,4.7-4.1,6.424l-.033.057-.646-.243a1.375,1.375,0,0,0,.024-.24c0-.684-.527-1.238-1.493-1.238-.809,0-1.729.4-1.729,1.238s.921,1.239,1.729,1.239a1.746,1.746,0,0,0,.943-.244l.62.373c-.892,1.323-2.063,2.784-2.221,3.928a21.87,21.87,0,0,0,.145,6.727v3.787a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645h0V-46.3h10.846V-66.882C-28.545-66.882-33.705-66.788-34.613-66.334Z"
                data-name="Caminho 9077"
                id="Caminho_9077"
                opacity="0.1"
                style="isolation: isolate"
            />
          </g>
          <g
              data-name="Grupo 2227"
              id="Grupo_2227"
          >
            <g
                data-name="Grupo 2226"
                id="Grupo_2226"
            >
              <path
                  d="M3.129-57.212l1.585-.6.365.738-1.263.76Z"
                  data-name="Caminho 9100"
                  fill="#dedede"
                  id="Caminho_9100"
              />
              <path
                  d="M-20.791-57.212l-1.585-.6-.365.738,1.262.76Z"
                  data-name="Caminho 9101"
                  fill="#dedede"
                  id="Caminho_9101"
              />
              <path
                  d="M2.015-42.049a.645.645,0,0,0,.645.645H5.547a.645.645,0,0,0,.645-.645v-5.966H2.015Z"
                  data-name="Caminho 9102"
                  id="Caminho_9102"
              />
              <path
                  d="M-19.677-42.049a.645.645,0,0,1-.645.645h-2.887a.645.645,0,0,1-.645-.645v-5.966h4.178Z"
                  data-name="Caminho 9103"
                  id="Caminho_9103"
              />
              <path
                  d="M-8.832-43.822H-20.464c-2.25,0-2.814-.638-3.281-1.709-.65-1.493-.452-5.608-.255-7.032s1.965-3.339,2.807-4.869c.336-1.729,2.709-5.728,4.1-6.424.908-.454,6.069-.548,8.259-.548s7.351.094,8.259.548c1.393.7,3.767,4.7,4.1,6.424.842,1.53,2.611,3.445,2.807,4.869s.4,5.54-.255,7.032c-.466,1.071-1.03,1.709-3.281,1.709Z"
                  data-name="Caminho 9104"
                  fill="#d2d2d2"
                  id="Caminho_9104"
              />
              <path
                  d="M-.611-45.886c0-.7,1.069-1.778,3.079-1.745,1.441.024,1.953.537,1.745,1.157C3.815-45.285-.611-45.033-.611-45.886Z"
                  data-name="Caminho 9105"
                  fill="#b9b9b9"
                  id="Caminho_9105"
              />
              <path
                  d="M-.157-45.977a2.914,2.914,0,0,0,1.116.153c1.424,0,2.715-.433,2.835-.791.047-.139.006-.2-.013-.224-.114-.161-.52-.338-1.32-.351H2.378C.72-47.19-.052-46.39-.157-45.977Z"
                  data-name="Caminho 9106"
                  id="Caminho_9106"
              />
              <path
                  d="M-1.353-52.1l2.922-5.374,1.1-.393-3.83,5.942Z"
                  data-name="Caminho 9107"
                  fill="#e9e9e9"
                  id="Caminho_9107"
              />
              <path
                  d="M4.756-53.588a2.475,2.475,0,0,0-1.264-.169l-.258.024a5.119,5.119,0,0,0-1.656.507l-.6.326A3.4,3.4,0,0,0-.2-51.691l-.206.4a.5.5,0,0,0,.117.7.5.5,0,0,0,.361.088H.7A5.517,5.517,0,0,0,2.4-50.832l1.773-.7a1.466,1.466,0,0,0,.889-1.062A1.171,1.171,0,0,0,4.756-53.588Z"
                  data-name="Caminho 9108"
                  fill="#f4f4f4"
                  id="Caminho_9108"
              />
              <path
                  d="M-8.831-46.031h-5.976c-.67,0-1.119-.387-1.119-.806s.322-.516.9-.516H-2.64c.58,0,.9.1.9.516s-.448.806-1.119.806Z"
                  data-name="Caminho 9109"
                  fill="#b9b9b9"
                  id="Caminho_9109"
              />
              <path
                  d="M-8.831-49.811h-5.093c-1.458,0-2-.817-2-1.543,0-.568.2-.746,1.492-.746H-3.225c1.286,0,1.492.179,1.492.746,0,.726-.545,1.543-2,1.543Z"
                  data-name="Caminho 9110"
                  id="Caminho_9110"
              />
              <path
                  d="M-8.831-44.709h-5.476c-.593,0-.794-.34-.794-.634,0-.137.311-.221.814-.221H-3.374c.5,0,.814.084.814.221,0,.294-.2.634-.8.634Z"
                  data-name="Caminho 9111"
                  fill="#b9b9b9"
                  id="Caminho_9111"
              />
              <path
                  d="M-17.051-45.886c0-.7-1.069-1.778-3.08-1.745-1.441.024-1.953.537-1.745,1.157C-21.477-45.285-17.051-45.033-17.051-45.886Z"
                  data-name="Caminho 9112"
                  fill="#b9b9b9"
                  id="Caminho_9112"
              />
              <path
                  d="M-17.5-45.977a2.92,2.92,0,0,1-1.116.153c-1.424,0-2.716-.433-2.836-.791-.047-.139-.006-.2.013-.224.114-.161.521-.338,1.32-.351h.083C-18.383-47.19-17.611-46.39-17.5-45.977Z"
                  data-name="Caminho 9113"
                  id="Caminho_9113"
              />
              <path
                  d="M-8.831-50.427c-1.866,0-5.715-.064-5.715-.506s3.824-.476,5.715-.476,5.715.035,5.715.476S-6.961-50.427-8.831-50.427Z"
                  data-name="Caminho 9114"
                  fill="#e9e9e9"
                  id="Caminho_9114"
              />
              <path
                  d="M-8.831-57.695s-10.8.2-11.043-.363,2.235-4.3,3.069-4.842,5.444-.668,7.974-.668,7.139.128,7.974.668,3.315,4.277,3.069,4.842S-8.831-57.695-8.831-57.695Z"
                  data-name="Caminho 9115"
                  id="Caminho_9115"
              />
              <path
                  d="M-16.314-52.1l-2.922-5.374-1.1-.393,3.83,5.942Z"
                  data-name="Caminho 9116"
                  fill="#e9e9e9"
                  id="Caminho_9116"
              />
              <path
                  d="M-22.421-53.588a2.472,2.472,0,0,1,1.264-.169l.258.024a5.121,5.121,0,0,1,1.655.507l.6.326a3.411,3.411,0,0,1,1.181,1.209l.206.4a.5.5,0,0,1-.118.7.5.5,0,0,1-.36.088h-.63a5.512,5.512,0,0,1-1.7-.326l-1.773-.7a1.464,1.464,0,0,1-.889-1.062A1.167,1.167,0,0,1-22.421-53.588Z"
                  data-name="Caminho 9117"
                  fill="#f4f4f4"
                  id="Caminho_9117"
              />
              <path
                  d="M7.407-57.86c0,.841-.92,1.239-1.729,1.239-.966,0-1.493-.554-1.493-1.239S4.712-59.1,5.678-59.1C6.487-59.1,7.407-58.7,7.407-57.86Z"
                  data-name="Caminho 9118"
                  fill="#d2d2d2"
                  id="Caminho_9118"
              />
              <path
                  d="M-25.067-57.86c0,.841.921,1.239,1.729,1.239.966,0,1.493-.554,1.493-1.239s-.527-1.238-1.493-1.238C-24.149-59.1-25.067-58.7-25.067-57.86Z"
                  data-name="Caminho 9119"
                  fill="#d2d2d2"
                  id="Caminho_9119"
              />
            </g>
            <path
                d="M-17.09-63.857c-1.393.7-3.767,4.7-4.1,6.424-.01.019-.022.038-.033.057l-.646-.243a1.375,1.375,0,0,0,.024-.24c0-.684-.527-1.238-1.493-1.238-.809,0-1.729.4-1.729,1.238s.921,1.239,1.729,1.239a1.745,1.745,0,0,0,.942-.244l.62.373c-.892,1.323-2.063,2.784-2.221,3.928a21.87,21.87,0,0,0,.145,6.727v3.787a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645v-1.773H-8.829V-64.4C-11.022-64.4-16.182-64.311-17.09-63.857Z"
                data-name="Caminho 9120"
                id="Caminho_9120"
                opacity="0.1"
                style="isolation: isolate"
            />
          </g>
        </g>
      </g>
      <g
          clip-path="url(#clip-path-lot)"
          id="logo_frota_on"
          transform="translate(2366 6050)"
      >
        <g
            data-name="Grupo 2228"
            id="Grupo_2228-2"
            transform="translate(-1578.052 -5375.694)"
        >
          <g
              data-name="Grupo 2223"
              id="Grupo_2223-2"
              transform="translate(-593.448 -389.122)"
          >
            <g
                data-name="Grupo 2222"
                id="Grupo_2222-2"
                transform="translate(0 0)"
            >
              <path
                  d="M-397.531-334.132l1.585-.6.365.738-1.262.76Z"
                  data-name="Caminho 9057"
                  fill="#4cbfd6"
                  id="Caminho_9057-2"
                  transform="translate(425.73 341.325)"
              />
              <path
                  d="M-575.316-334.132l-1.585-.6-.365.738,1.262.76Z"
                  data-name="Caminho 9058"
                  fill="#4cbfd6"
                  id="Caminho_9058-2"
                  transform="translate(579.595 341.325)"
              />
              <path
                  d="M-405.272-260.719a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645v-5.966h-4.177Z"
                  data-name="Caminho 9059"
                  fill="#464749"
                  id="Caminho_9059-2"
                  transform="translate(432.357 283.075)"
              />
              <path
                  d="M-580.827-260.719a.645.645,0,0,1-.645.645h-2.887a.645.645,0,0,1-.645-.645v-5.966h4.177Z"
                  data-name="Caminho 9060"
                  fill="#464749"
                  id="Caminho_9060-2"
                  transform="translate(586.219 283.075)"
              />
              <path
                  d="M-571.972-359.972H-583.6c-2.25,0-2.814-.638-3.281-1.709-.65-1.493-.452-5.608-.255-7.032s1.964-3.339,2.807-4.869c.336-1.729,2.709-5.728,4.1-6.424.908-.454,6.069-.548,8.259-.548s7.351.094,8.259.548c1.393.7,3.767,4.7,4.1,6.424.843,1.53,2.611,3.445,2.807,4.869s.395,5.54-.255,7.032c-.466,1.071-1.03,1.709-3.281,1.709Z"
                  data-name="Caminho 9061"
                  fill="#00b3cf"
                  id="Caminho_9061-2"
                  transform="translate(588.211 380.555)"
              />
              <path
                  d="M-423.518-262.274c0-.7,1.069-1.778,3.079-1.745,1.441.024,1.953.537,1.745,1.157C-419.092-261.673-423.518-261.421-423.518-262.274Z"
                  data-name="Caminho 9062"
                  fill="#00889d"
                  id="Caminho_9062-2"
                  transform="translate(447.977 280.793)"
              />
              <path
                  d="M-420.361-259.736a2.923,2.923,0,0,0,1.116.153c1.424,0,2.715-.433,2.835-.791.047-.139.006-.2-.013-.224-.114-.161-.521-.338-1.32-.351h-.083C-419.483-260.949-420.256-260.149-420.361-259.736Z"
                  data-name="Caminho 9063"
                  fill="#313133"
                  id="Caminho_9063-2"
                  transform="translate(445.274 278.164)"
              />
              <path
                  d="M-428.64-329.365l2.922-5.374,1.1-.393-3.83,5.942Z"
                  data-name="Caminho 9064"
                  fill="#4cbfd6"
                  id="Caminho_9064-2"
                  transform="translate(452.362 341.669)"
              />
              <path
                  d="M-417.439-306.489a2.473,2.473,0,0,0-1.264-.169l-.258.024a5.115,5.115,0,0,0-1.656.507l-.6.326a3.411,3.411,0,0,0-1.181,1.21l-.206.4a.5.5,0,0,0,.478.785h.63a5.53,5.53,0,0,0,1.705-.326l1.773-.7a1.467,1.467,0,0,0,.889-1.062A1.169,1.169,0,0,0-417.439-306.489Z"
                  data-name="Caminho 9065"
                  fill="#e0e1e3"
                  id="Caminho_9065-2"
                  transform="translate(447.265 317.306)"
              />
              <path
                  d="M-522.827-260.758H-528.8c-.67,0-1.119-.387-1.119-.806s.322-.516.9-.516h12.386c.58,0,.9.1.9.516s-.448.806-1.119.806Z"
                  data-name="Caminho 9066"
                  fill="#00889d"
                  id="Caminho_9066-2"
                  transform="translate(539.065 279.132)"
              />
              <path
                  d="M-522.827-292.777h-5.093c-1.458,0-2-.817-2-1.543,0-.568.205-.746,1.492-.746h11.207c1.286,0,1.492.179,1.492.746,0,.726-.545,1.543-2,1.543Z"
                  data-name="Caminho 9067"
                  fill="#313133"
                  id="Caminho_9067-2"
                  transform="translate(539.065 307.371)"
              />
              <path
                  d="M-517.921-248.807H-523.4c-.593,0-.795-.34-.795-.634,0-.137.312-.221.814-.221h10.913c.5,0,.814.084.814.221,0,.294-.2.634-.795.634Z"
                  data-name="Caminho 9068"
                  fill="#00889d"
                  id="Caminho_9068-2"
                  transform="translate(534.159 268.503)"
              />
              <path
                  d="M-566.694-262.274c0-.7-1.069-1.778-3.079-1.745-1.442.024-1.953.537-1.745,1.157C-571.121-261.673-566.694-261.421-566.694-262.274Z"
                  data-name="Caminho 9069"
                  fill="#00889d"
                  id="Caminho_9069-2"
                  transform="translate(574.713 280.793)"
              />
              <path
                  d="M-564.521-259.736a2.922,2.922,0,0,1-1.115.153c-1.424,0-2.715-.433-2.836-.791-.047-.139-.006-.2.013-.224.114-.161.521-.338,1.32-.351h.083C-565.4-260.949-564.626-260.149-564.521-259.736Z"
                  data-name="Caminho 9070"
                  fill="#313133"
                  id="Caminho_9070-2"
                  transform="translate(572.085 278.164)"
              />
              <path
                  d="M-514.616-289.282c-1.866,0-5.715-.064-5.715-.506s3.824-.476,5.715-.476,5.715.035,5.715.476S-512.75-289.282-514.616-289.282Z"
                  data-name="Caminho 9071"
                  fill="#e0e1e3"
                  id="Caminho_9071-2"
                  transform="translate(530.855 303.26)"
              />
              <path
                  d="M-546.407-368.872s-10.8.2-11.043-.363,2.234-4.3,3.069-4.842,5.444-.668,7.974-.668,7.139.128,7.974.668,3.315,4.277,3.069,4.842S-546.407-368.872-546.407-368.872Z"
                  data-name="Caminho 9072"
                  fill="#313133"
                  id="Caminho_9072-2"
                  transform="translate(562.646 375.582)"
              />
              <path
                  d="M-556.563-329.365l-2.922-5.374-1.1-.393,3.83,5.942Z"
                  data-name="Caminho 9073"
                  fill="#4cbfd6"
                  id="Caminho_9073-2"
                  transform="translate(565.32 341.669)"
              />
              <path
                  d="M-576.942-306.489a2.473,2.473,0,0,1,1.264-.169l.258.024a5.115,5.115,0,0,1,1.656.507l.6.326a3.41,3.41,0,0,1,1.181,1.21l.206.4a.5.5,0,0,1-.478.785h-.63a5.531,5.531,0,0,1-1.705-.326l-1.773-.7a1.467,1.467,0,0,1-.889-1.062A1.169,1.169,0,0,1-576.942-306.489Z"
                  data-name="Caminho 9074"
                  fill="#e0e1e3"
                  id="Caminho_9074-2"
                  transform="translate(579.593 317.306)"
              />
              <path
                  d="M-386.971-342.443c0,.841-.921,1.239-1.729,1.239-.966,0-1.493-.554-1.493-1.239s.527-1.238,1.493-1.238C-387.891-343.681-386.971-343.284-386.971-342.443Z"
                  data-name="Caminho 9075"
                  fill="#00b3cf"
                  id="Caminho_9075-2"
                  transform="translate(419.448 348.988)"
              />
              <path
                  d="M-593.448-342.443c0,.841.921,1.239,1.729,1.239.966,0,1.493-.554,1.493-1.239s-.527-1.238-1.493-1.238C-592.527-343.681-593.448-343.284-593.448-342.443Z"
                  data-name="Caminho 9076"
                  fill="#00b3cf"
                  id="Caminho_9076-2"
                  transform="translate(593.448 348.988)"
              />
            </g>
            <path
                d="M-585.468-380.007c-1.393.7-3.767,4.7-4.1,6.424l-.033.057-.646-.243a1.267,1.267,0,0,0,.024-.24c0-.684-.527-1.238-1.493-1.238-.809,0-1.729.4-1.729,1.238s.921,1.239,1.729,1.239a1.744,1.744,0,0,0,.943-.244l.62.373c-.892,1.323-2.063,2.784-2.221,3.928a21.918,21.918,0,0,0,.145,6.727v3.787a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645v-1.773h10.846v-20.583C-579.4-380.555-584.56-380.461-585.468-380.007Z"
                data-name="Caminho 9077"
                fill="#1a1818"
                id="Caminho_9077-2"
                opacity="0.1"
                style="mix-blend-mode: multiply;isolation: isolate"
                transform="translate(593.448 380.555)"
            />
          </g>
          <g
              data-name="Grupo 2227"
              id="Grupo_2227-2"
              transform="translate(-575.925 -386.645)"
          >
            <g
                data-name="Grupo 2226"
                id="Grupo_2226-2"
                transform="translate(0 0)"
            >
              <path
                  d="M-275.786-316.919l1.585-.6.365.738-1.263.76Z"
                  data-name="Caminho 9100"
                  fill="#d3d5d6"
                  id="Caminho_9100-2"
                  transform="translate(303.985 324.112)"
              />
              <path
                  d="M-453.57-316.919l-1.585-.6-.365.738,1.262.76Z"
                  data-name="Caminho 9101"
                  fill="#d3d5d6"
                  id="Caminho_9101-2"
                  transform="translate(457.849 324.112)"
              />
              <path
                  d="M-283.527-243.506a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645v-5.966h-4.177Z"
                  data-name="Caminho 9102"
                  fill="#464749"
                  id="Caminho_9102-2"
                  transform="translate(310.612 265.862)"
              />
              <path
                  d="M-459.082-243.506a.645.645,0,0,1-.645.645h-2.887a.645.645,0,0,1-.645-.645v-5.966h4.178Z"
                  data-name="Caminho 9103"
                  fill="#464749"
                  id="Caminho_9103-2"
                  transform="translate(464.475 265.862)"
              />
              <path
                  d="M-450.228-342.759H-461.86c-2.25,0-2.814-.638-3.281-1.709-.65-1.493-.452-5.608-.255-7.032s1.965-3.339,2.807-4.869c.336-1.729,2.709-5.728,4.1-6.424.908-.454,6.069-.548,8.259-.548s7.351.094,8.259.548c1.393.7,3.767,4.7,4.1,6.424.842,1.53,2.611,3.445,2.807,4.869s.395,5.54-.255,7.032c-.466,1.071-1.03,1.709-3.281,1.709Z"
                  data-name="Caminho 9104"
                  fill="#c7c9cb"
                  id="Caminho_9104-2"
                  transform="translate(466.466 363.342)"
              />
              <path
                  d="M-301.773-245.061c0-.7,1.069-1.778,3.079-1.745,1.441.024,1.953.537,1.745,1.157C-297.347-244.46-301.773-244.208-301.773-245.061Z"
                  data-name="Caminho 9105"
                  fill="#aeb0b3"
                  id="Caminho_9105-2"
                  transform="translate(326.232 263.58)"
              />
              <path
                  d="M-298.615-242.523a2.922,2.922,0,0,0,1.116.153c1.424,0,2.715-.433,2.835-.791.047-.139.006-.2-.013-.224-.114-.161-.52-.338-1.32-.351h-.083C-297.738-243.736-298.51-242.936-298.615-242.523Z"
                  data-name="Caminho 9106"
                  fill="#313133"
                  id="Caminho_9106-2"
                  transform="translate(323.528 260.951)"
              />
              <path
                  d="M-306.9-312.152l2.922-5.374,1.1-.393-3.83,5.942Z"
                  data-name="Caminho 9107"
                  fill="#e0e1e3"
                  id="Caminho_9107-2"
                  transform="translate(330.617 324.457)"
              />
              <path
                  d="M-295.694-289.276a2.473,2.473,0,0,0-1.264-.169l-.258.024a5.117,5.117,0,0,0-1.656.507l-.6.326a3.408,3.408,0,0,0-1.181,1.209l-.206.4a.5.5,0,0,0,.478.785h.63a5.532,5.532,0,0,0,1.705-.326l1.773-.7a1.467,1.467,0,0,0,.889-1.062A1.169,1.169,0,0,0-295.694-289.276Z"
                  data-name="Caminho 9108"
                  fill="#edeeef"
                  id="Caminho_9108-2"
                  transform="translate(325.52 300.093)"
              />
              <path
                  d="M-401.082-243.545h-5.976c-.67,0-1.119-.387-1.119-.806s.322-.516.9-.516h12.386c.58,0,.9.1.9.516s-.448.806-1.119.806Z"
                  data-name="Caminho 9109"
                  fill="#aeb0b3"
                  id="Caminho_9109-2"
                  transform="translate(417.321 261.919)"
              />
              <path
                  d="M-401.082-275.564h-5.093c-1.458,0-2-.817-2-1.543,0-.568.205-.746,1.492-.746h11.207c1.286,0,1.492.179,1.492.746,0,.726-.545,1.543-2,1.543Z"
                  data-name="Caminho 9110"
                  fill="#313133"
                  id="Caminho_9110-2"
                  transform="translate(417.321 290.158)"
              />
              <path
                  d="M-396.175-231.594h-5.476c-.593,0-.794-.34-.794-.634,0-.137.311-.221.814-.221h10.913c.5,0,.814.084.814.221,0,.294-.2.634-.795.634Z"
                  data-name="Caminho 9111"
                  fill="#aeb0b3"
                  id="Caminho_9111-2"
                  transform="translate(412.414 251.29)"
              />
              <path
                  d="M-444.95-245.061c0-.7-1.069-1.778-3.08-1.745-1.441.024-1.953.537-1.745,1.157C-449.376-244.46-444.95-244.208-444.95-245.061Z"
                  data-name="Caminho 9112"
                  fill="#aeb0b3"
                  id="Caminho_9112-2"
                  transform="translate(452.969 263.58)"
              />
              <path
                  d="M-442.776-242.523a2.925,2.925,0,0,1-1.116.153c-1.424,0-2.716-.433-2.836-.791-.047-.139-.006-.2.013-.224.114-.161.521-.338,1.32-.351h.083C-443.654-243.736-442.882-242.936-442.776-242.523Z"
                  data-name="Caminho 9113"
                  fill="#313133"
                  id="Caminho_9113-2"
                  transform="translate(450.341 260.951)"
              />
              <path
                  d="M-392.87-272.069c-1.866,0-5.715-.064-5.715-.506s3.824-.476,5.715-.476,5.715.035,5.715.476S-391-272.069-392.87-272.069Z"
                  data-name="Caminho 9114"
                  fill="#e0e1e3"
                  id="Caminho_9114-2"
                  transform="translate(409.109 286.047)"
              />
              <path
                  d="M-424.663-351.659s-10.8.2-11.043-.363,2.235-4.3,3.069-4.842,5.444-.668,7.974-.668,7.139.128,7.974.668,3.315,4.277,3.069,4.842S-424.663-351.659-424.663-351.659Z"
                  data-name="Caminho 9115"
                  fill="#313133"
                  id="Caminho_9115-2"
                  transform="translate(440.902 358.369)"
              />
              <path
                  d="M-434.819-312.152l-2.922-5.374-1.1-.393,3.83,5.942Z"
                  data-name="Caminho 9116"
                  fill="#e0e1e3"
                  id="Caminho_9116-2"
                  transform="translate(443.575 324.457)"
              />
              <path
                  d="M-455.2-289.276a2.472,2.472,0,0,1,1.264-.169l.258.024a5.116,5.116,0,0,1,1.655.507l.6.326a3.408,3.408,0,0,1,1.181,1.209l.206.4a.5.5,0,0,1-.478.785h-.63a5.53,5.53,0,0,1-1.705-.326l-1.773-.7a1.467,1.467,0,0,1-.889-1.062A1.169,1.169,0,0,1-455.2-289.276Z"
                  data-name="Caminho 9117"
                  fill="#edeeef"
                  id="Caminho_9117-2"
                  transform="translate(457.849 300.093)"
              />
              <path
                  d="M-265.226-325.23c0,.841-.92,1.239-1.729,1.239-.966,0-1.493-.554-1.493-1.239s.527-1.238,1.493-1.238C-266.146-326.468-265.226-326.071-265.226-325.23Z"
                  data-name="Caminho 9118"
                  fill="#c7c9cb"
                  id="Caminho_9118-2"
                  transform="translate(297.703 331.775)"
              />
              <path
                  d="M-471.7-325.23c0,.841.921,1.239,1.729,1.239.966,0,1.493-.554,1.493-1.239s-.527-1.238-1.493-1.238C-470.782-326.468-471.7-326.071-471.7-325.23Z"
                  data-name="Caminho 9119"
                  fill="#c7c9cb"
                  id="Caminho_9119-2"
                  transform="translate(471.703 331.775)"
              />
            </g>
            <path
                d="M-463.723-362.794c-1.393.7-3.767,4.7-4.1,6.424-.01.019-.022.038-.033.057l-.646-.243a1.247,1.247,0,0,0,.024-.24c0-.684-.527-1.238-1.493-1.238-.809,0-1.729.4-1.729,1.238s.921,1.239,1.729,1.239a1.744,1.744,0,0,0,.942-.244l.62.373c-.892,1.323-2.063,2.784-2.221,3.928a21.923,21.923,0,0,0,.145,6.727v3.787a.645.645,0,0,0,.645.645h2.887a.645.645,0,0,0,.645-.645v-1.773h10.846v-20.583C-457.655-363.342-462.815-363.248-463.723-362.794Z"
                data-name="Caminho 9120"
                fill="#1a1818"
                id="Caminho_9120-2"
                opacity="0.1"
                style="mix-blend-mode: multiply;isolation: isolate"
                transform="translate(471.703 363.342)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'VehicleLotIcon'
}
</script>
<style scoped>


</style>