import {PartnerResource} from "@/api";

const state = {
  steps: [
    'RegisterPartner',
    'PartnerTokenVerifier',
    'PartnerInterests',
    'PartnerStoreName',
    'FinishPartnerRegister'
  ],
  step: 0,
  success: false,
  errors: [],
  loading: false,
  email: null,
}

const mutations = {
  ['NEXT_STEP'] (state) {
    state.step += 1
  },
  ['SET_LOADING'] (state, isLoading) {
    state.loading = isLoading
  },
  ['SET_EMAIL'] (state, email) {
    state.email = email
  },
  ['ADD_ERROR'] (state, error) {
    state.errors.push(error)
  },
  ['ADD_ERRORS'] (state, errors) {
    state.errors = errors
  },
  ['RESET_ERRORS'] (state) {
    state.errors = []
  },
}

const actions = {
  async ['REGISTER_PARTNER'] ({ commit, rootState }, partner) {
    commit('RESET_ERRORS')
    commit('SET_LOADING', true)
    const response = await PartnerResource.postNewPartner(partner)

    if (response.ok) {
      commit('SET_EMAIL', partner.email)
      commit('NEXT_STEP')
      commit('authStore/SET_INTEREST_TOKEN', response.data.data, { root: true })
    } else {
      commit('ADD_ERRORS', response.data.erros)
    }
    commit('SET_LOADING', false)
  },

  async ['VERIFY_TOKEN'] ({ commit, state }, token) {
    commit('RESET_ERRORS')
    commit('SET_LOADING', true)
    const isValid = await PartnerResource.verifyToken(token, state.email)
    if (isValid) {
      commit('NEXT_STEP')
    } else {
      commit('ADD_ERROR', "Token informado é inválido")
    }
    commit('SET_LOADING', false)
  },
  async ['REGISTER_STORE_NAME'] ({ commit, state, rootState }, siteName) {
    commit('RESET_ERRORS')
    commit('SET_LOADING', true)

    const response = await PartnerResource.postSiteName({ urlLoja: siteName, tokenUsrio: rootState.authStore.interestToken })

    if (response.ok) {
      commit('NEXT_STEP')
    } else if (response.data.erros) {
      for (const erro of response.data.erros) {
        commit('ADD_ERROR', erro)
      }
    } else {
      commit('ADD_ERROR', "Houve um erro ao salvar nome do site.")
    }

    commit('SET_LOADING', false)
  },
  ['FINISH_REGISTRATION'] ({ commit }) {
    commit('NEXT_STEP')
  },
  ['NEXT_STEP'] ({ commit }) {
    commit('NEXT_STEP')
  },
  ['SET_LOADING'] ({ commit }, isLoading) {
    commit('SET_LOADING', isLoading)
  },
}

const getters = {
  currentScreen: state => {
    return state.steps[state.step]
  },
  hasErrors: state => {
    return state.errors.length > 0
  },
}

const registerPartnerStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default registerPartnerStore