import Cookies from 'universal-cookie'

class CookieControl {
  tokenCookie = 'token'
  cookies = new Cookies()
  cookieConfig = {
    secure: true
  }
  idiomCookie = 'idiom'
  idiomDefault = 'PTBR'

  saveCookie(name, value) {
    this.cookieConfig.secure = window.location.origin.indexOf('https://') !== -1
    this.cookies.set(name, value, this.cookieConfig)
  }

  getCookie(name) {
    return this.cookies.get(name)
  }

  removeCookie (name) {
    this.cookieConfig.secure = window.location.origin.indexOf('https://') !== -1
    this.cookies.remove(name, this.cookieConfig)
  }

  signOut () {
    this.cookieConfig.secure = window.location.origin.indexOf('https://') !== -1
    this.cookies.remove(this.tokenCookie, this.cookieConfig)
  }

  isLogin () {
    return !!this.cookies.get(this.tokenCookie)
  }

  idiom () {
    let idiom = this.cookies.get(this.idiomCookie)
    if (!idiom) {
      this.saveCookie(this.idiomCookie, this.idiomDefault)
    }
    return idiom || this.idiomDefault
  }
}

export default CookieControl
