<template>
  <div class="flux-personal">
    
    <div
        :class="{'has-error': !showError}"
        class="profile-error-message align-content-center"
        style="width: 100%;"
    >
      {{ showError }}
    </div>
    <div class="flux-personal-title">{{ $t('flux.registration') }}</div>
    <v-form
        ref="form"
        class="flux-personal-form"
    >
      
      <div class="flux-personal-form-column">
        

        <label>
          <div class="flux-personal-label">
            {{ perfil.tipoPerfilFlag === 'J' ? $t('global.firstNameJuri') : $t('global.firstName') }}
          </div>
          <input
              v-model="perfil.nome"
              class="flux-personal-input firstname"
              maxlength="100"
              name="name"
              required
              type="text"
          />
        </label>

        <label>
          <div class="flux-personal-label">
            {{ perfil.tipoPerfilFlag === 'J' ? $t('global.lastNameJuri') : $t('global.lastName') }}
          </div>
          <input
              v-model="perfil.sobrenome"
              class="flux-personal-input lastname"
              maxlength="100"
              name="lastName"
              required
              type="text"
          />
        </label>

        <label>
          <div class="flux-personal-label">
            {{ perfil.tipoPerfilFlag === 'J' ? 'CNPJ' : 'CPF' }}
          </div>
          <input
              v-model="perfil.cpf"
              class="flux-personal-input"
              name="cpf"
              readonly
              required
              type="text"
          />
        </label>

        <label>
          <div class="flux-personal-label">
            {{ $t('global.mail') }}
          </div>
          <input
              v-model="perfil.email"
              class="flux-personal-input medium"
              name="email"
              readonly
              required
              type="email"
          />
        </label>

        <label>
          <div class="flux-personal-label">
            {{ $t('global.phone') }}
          </div>
          <input
              v-model="perfil.telefone"
              class="flux-personal-input small"
              maxlength="11"
              name="phone"
              required
              type="text"
          />
        </label>

        <label
            v-if="perfil.tipoPerfilFlag === 'J'"
        >
          <div class="flux-personal-label">
            {{ $t('global.fantasyName') }}
          </div>
          <input
              v-model="perfil.nomeFantasia"
              class="flux-personal-input"
              name="nomeFantasia"
              required
              type="text"
          />
        </label>

        <label
            v-if="perfil.tipoPerfilFlag === 'J' && !perfil.isento"
        >
          <div class="flux-personal-label">
            {{ $t('global.stateRegistration') }}
          </div>
          <input
              v-model="perfil.inscricaoSocial"
              class="flux-personal-input"
              name="inscricaoSocial"
              readonly
              required
              type="text"
          />
        </label>

        <label
            v-if="perfil.tipoPerfilFlag === 'C'"
        >
          <div class="flux-personal-label">
            {{ $t('global.registration') }}
          </div>
          <input
              v-model="perfil.matricula"
              class="flux-personal-input"
              name="matricula"
              required
              readonly
              type="text"
          />
        </label>

      </div>

      <div class="flux-personal-form-column">

        <label
            v-if="perfil.tipoPerfilFlag !== 'J'"
        >
          <div class="flux-personal-label">
            {{ $t('global.birthDate') }}
          </div>
          <input
              v-model="perfil.nascimento"
              v-mask="mask"
              class="flux-personal-input medium"
              name="nascimento"
              required
              type="text"
          />
        </label>
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { FluxResource } from '@/api'

export default {
  name: 'FluxPersonal',
  computed: {
    ...mapState('fluxStore', ['user']),
    isValid () {
      let basic = this.perfil.email &&
          this.perfil.login &&
          this.perfil.nome &&
          this.perfil.sobrenome &&
          this.perfil.cpf &&
          this.perfil.telefone

      if (!basic) return false

      if (this.perfil.tipoPerfilFlag === 'F') {
        basic = this.perfil.nascimento
      }

      if (this.perfil.tipoPerfilFlag === 'J') {
        return !!this.perfil.nomeFantasia
      } else if (this.perfil.tipoPerfilFlag === 'C') {
        return !!this.perfil.matricula && this.perfil.nascimento
      }
      return !!basic
    }
  },
  watch: {
    isValid (newValue) {
      this.SET_CAN_NEXT(newValue)
    },
    user (newValue) {
      this.perfil = newValue
    }
  },
  data () {
    return {
      perfil: {},
      showError: false,
      success: false,
      mask: '##/##/####'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('fluxStore', ['SET_CAN_NEXT', 'SET_USER']),
    init () {
      this.perfil = this.user
    },
    testTel () {
      return !this.perfil.telefone.replace(/[\d./-]/g, '')
    },
    async save () {

      if (!this.isValid) {
        this.showError = this.$t('global.fillFields')
        return 'erro'
      }
      if (!this.testTel()) {
        this.showError = this.$t('Auth.failTestTel')
        return 'erro'
      }

      const response = await FluxResource.putUser({
        email: this.perfil.email,
        login: this.perfil.login,
        nome: this.perfil.nome,
        sobrenome: this.perfil.sobrenome,
        tipoPerfil: this.perfil.tipoPerfil,
        nomePerfil: this.perfil.nomePerfil,
        cpf: this.perfil.cpf,
        telefone: this.perfil.telefone,
        inscricaoSocial: this.perfil.inscricaoSocial,
        nomeFantasia: this.perfil.nomeFantasia,
        isento: this.perfil.isento,
        matricula: this.perfil.matricula,
        allowEmail: this.perfil.allowEmail,
        nascimento: this.perfil.nascimento,
        oldPassword: null,
        password: null
      })

      if (response.ok === true) {
        const responseUser = await FluxResource.getUser()
        this.SET_USER(responseUser.body)
        this.showError = false
        return this.success = true
      } else {
        return this.showError = response.body ? response.body.erros[0] : this.$t('global.saveFail')
      }
    },
  },
}
</script>
<style
    scoped
    type="text/css"
>
label {
  width: fit-content;
  height: fit-content;
}

.flux-personal-title {
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 52px;
}

.flux-personal-form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flux-personal-form-column {
  min-width: 406px;
  max-width: 406px;
  flex: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.flux-personal-label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
}

.flux-personal-input {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 28px;
  width: 406px;
  padding-left: 11px;
  margin-bottom: 20px;
  flex: 0;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}

.input-select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}

.flux-personal-input:read-only {
  opacity: 0.3;
}

.flux-personal-input.medium {
  width: 232px;
}

.flux-personal-input.small {
  width: 149px;
}

.flux-personal-input.lastname {
  width: 211px;
}

.flux-personal-input.firstname {
  width: 170px;
}

.profile-error-message {
  height: 27px;
  background: #D85757 0 0 no-repeat padding-box;
  opacity: 1;
  margin-bottom: 20px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #FFFFFF;
}

/*noinspection CssUnusedSymbol*/
.has-error {
  visibility: hidden;
}

.flux-personal {
  width: 100%;
}

@media screen and (max-width: 1279px) {
  label {
    width: 100%;
  }

  .flux-personal {
    width: 100%;
    /*min-height: 650px;*/
  }

  /*noinspection CssUnusedSymbol*/
  .has-error {
    display: none;
  }


  .flux-personal-form {
    flex-flow: row wrap;
  }

  .flux-personal-input,
  .flux-personal-input.medium,
  .flux-personal-input.small,
  .flux-personal-input.firstname,
  .flux-personal-input.lastname {
    width: 100%;
  }

  .flux-personal-form-column {
    min-width: 290px;
    max-width: 100%;
    padding-right: 4px;
    padding-left: 4px;
  }

  .flux-personal-title {
    margin-bottom: 30px;
  }

  .flux-personal {
    min-width: 406px;
    max-width: 406px;
  }
}

@media screen and (max-width: 720px) {
  .flux-personal {
    max-width: 100%;
    min-width: 100%;
  }

  .flux-personal {
    width: 100%;
    min-height: auto;
  }
}
</style>
