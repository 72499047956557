const _FacebookApi = () => {

  return {
    async login () {
      return await new Promise((resolve, reject) => {
        window.FB.login((resourceLogin) => {
          if (resourceLogin.status !== 'connected') {
            return reject({ error: 'Falha ao conectar.' })
          }

          window.FB.api('/me', { fields: 'name, email' }, (responseMe) => {
            resolve({
              email: responseMe.email,
              id: responseMe.id,
              name: responseMe.name,
              accessToken: resourceLogin.authResponse.accessToken,
            })
          })
        }, { scope: 'email' })
      })
    },
  }
}

const FacebookApi = _FacebookApi()

export default FacebookApi