<template>
  <div
      :class="{'border': show}"
      class="info-card-content"
  >
    <div
        class="info-title"
    >
      {{ title }}
    </div>
    <div
        class="info-value"
    >
      {{ getValue }}
    </div>

  </div>
</template>
<script>
export default {
  name: 'InfoCard',
  props: {
    show: {
      type: Boolean,
      default: true
      },
      title: {
        type: String,
        default: 'Número de portas'
      },
      value: {
        type: [String, Number],
        default: '1'
      },
      type: {
        type: String,
        default: '',
      }
    },
    computed: {
      getValue(){
        if(this.type==='N' && Number.isInteger(parseFloat(this.value))){
          return parseInt(this.value, 10)
        }
        return this.value
      }
    }
  }
</script>
<style
    scoped
    type="text/css"
>

.footer-copyright > p {
  margin-bottom: 0;
}

.info-title {
  text-align: left;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  flex: 1;
}

.info-value {
  text-align: right;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
}

/*noinspection ALL*/
.border {
  border-bottom: 1px solid;
  border-bottom-color: RGBA(139, 139, 139, 0.4);
}

.info-card-content {
  width: 259px;
  height: 36px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}
</style>
