<template>
  <div>
    <!--    ativo -->
    <svg
        height="22"
        v-if="active"
        viewBox="0 0 24 24"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g
          id="ic_favorite_on"
          transform="translate(-548 -521)"
      >
        <g
            data-name="Retângulo 46"
            fill="#fff"
            id="Retângulo_46"
            opacity="0"
            stroke="#707070"
            stroke-width="1"
            transform="translate(548 521)"
        >
          <rect
              height="24"
              stroke="none"
              width="24"
          />
          <rect
              fill="none"
              height="23"
              width="23"
              x="0.5"
              y="0.5"
          />
        </g>
        <path
            d="M357.2,1110.84a4.188,4.188,0,0,0,0-6.07,4.58,4.58,0,0,0-6.326,0l-.373.36-.372-.36a4.579,4.579,0,0,0-6.325,0,4.188,4.188,0,0,0,0,6.07l6.674,6.47Zm.7-6.79a5.192,5.192,0,0,1,0,7.51l-7.418,7.13-7.368-7.13a5.193,5.193,0,0,1,0-7.51,5.579,5.579,0,0,1,7.394-.29A5.579,5.579,0,0,1,357.894,1104.05Z"
            data-name="Caminho 7072"
            fill="#bcbfc2"
            id="Caminho_7072"
            transform="translate(210 -577.499)"
        />
        <path
            d="M357.894,1104.05a5.192,5.192,0,0,1,0,7.51l-7.418,7.13-7.368-7.13a5.193,5.193,0,0,1,0-7.51,5.579,5.579,0,0,1,7.394-.29A5.579,5.579,0,0,1,357.894,1104.05Z"
            data-name="Caminho 7073"
            fill="#d85757"
            id="Caminho_7073"
            transform="translate(210 -577.499)"
        />
      </g>
    </svg>
    <!--    inativo -->
    <svg
        height="22"
        v-else
        viewBox="0 0 24 24"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g
          id="ic_favorite_off"
          transform="translate(-548 -521)"
      >
        <g
            data-name="Retângulo 46"
            fill="#fff"
            id="Retângulo_46"
            opacity="0"
            stroke="#707070"
            stroke-width="1"
            transform="translate(548 521)"
        >
          <rect
              height="24"
              stroke="none"
              width="24"
          />
          <rect
              fill="none"
              height="23"
              width="23"
              x="0.5"
              y="0.5"
          />
        </g>
        <path
            d="M357.2,1110.84a4.188,4.188,0,0,0,0-6.07,4.58,4.58,0,0,0-6.326,0l-.373.36-.372-.36a4.579,4.579,0,0,0-6.325,0,4.188,4.188,0,0,0,0,6.07l6.674,6.47Zm.7-6.79a5.192,5.192,0,0,1,0,7.51l-7.418,7.13-7.368-7.13a5.193,5.193,0,0,1,0-7.51,5.579,5.579,0,0,1,7.394-.29A5.579,5.579,0,0,1,357.894,1104.05Z"
            data-name="Caminho 7072"
            fill="#bcbfc2"
            id="Caminho_7072"
            transform="translate(210 -577.499)"
        />
      </g>
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'FavoriteIcon',
    props: {
      active: {
        type: Boolean,
        default: false
      },
    },
  }
</script>
<style scoped>

</style>