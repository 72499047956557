<template>
  <div>
    <v-layout
        justify-space-around
        row
        wrap
    >
      <div
          class="align-content-center"
          style="width: 100vw; height: 600px;"
      >
        <v-card
            class="mx-12"
            color="transparent"
            elevation="0"
        >

          <v-card-text>
            <div class="confirm-header">
              <div class="confirm-title">{{ title }}</div>
            </div>

            <v-form
                ref="form"
                class="confirm-form-container"
            >
              <div
                  class="confirm-button align-content-center"
                  @click="goToHome()"

              >
                <span>{{ $t('global.goToHome') }}</span>
              </div>

            </v-form>

          </v-card-text>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<script>


export default {
  name: 'Confirm',
  data () {
    return {
      error: false,
      errorMessage: '',
      success: false,
      divHeight: 450,
      showError: ''
    }
  },
  computed: {
    isForgot () {
      return this.$router.history.current.fullPath === '/auth/confirmar/forgot'
    },
    title () {
      return this.isForgot ? this.$t('Auth.emailSuccess') : this.$t('Auth.passwordSuccess')
    }
  },
  methods: {
    goToHome() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/home`
      })
    }
  }
}
</script>

<style
    scoped
    type="text/css"
>
.confirm-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  text-align: center;
}

.confirm-header {
  position: relative;
  margin-bottom: 16px;
}

.confirm-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin: 73px auto 78px;

  justify-content: center;
  align-items: center;
  display: flex;
}

.confirm-button > span {
  display: block;
  line-height: 1;
}

.confirm-form-container {
  display: flex;
  flex-wrap: wrap;
}

</style>
