const state = {
  fluxType: 'bpy',
  bid: 0,
  purpose: {
    text: '',
    value: ''
  },
  offerPrice: {},
  offer: {},
  addresses: [],
  selectedAddress: undefined,
  user: {},
  canNext: false,
  step: 0,
  freight: '',
  parceiro: null
}

const mutations = {
  ['SET_OFFER_PRICE'] (state, payload) {
    state.offerPrice = payload
  },
  ['SET_USER'] (state, payload) {
    state.user = payload
  },
  ['SET_STEP'] (state, payload) {
    state.step = Number.tryParseInt(payload)
  },
  ['SET_CAN_NEXT'] (state, payload) {
    state.canNext = payload
  },
  ['SET_OFFER'] (state, payload) {
    state.offer = payload
  },
  ['SET_FLUX_BUY'] (state) {
    state.fluxType = 'BUY'
    state.bid = 0
  },
  ['SET_FLUX_BID'] (state, payload) {
    state.fluxType = 'BID'
    state.bid = payload
  },
  ['SET_FLUX_AUTO_BID'] (state, payload) {
    state.fluxType = 'AUTO_BID'
    state.bid = payload
  },
  ['SET_FLUX_PURPOSE'] (state, payload) {
    state.fluxType = 'PURPOSE'
    state.bid = payload
  },
  ['SET_SELECTED_ADDRESS'] (state, payload) {
    state.selectedAddress = payload
  },
  ['SET_ADDRESSES'] (state, payload) {
    state.addresses = payload
  },
  ['SET_FREIGHT'] (state,payload) {
    state.freight = payload
  },
  ['SET_PARCEIRO'] (state,payload) {
    state.parceiro = payload
  }
}

const actions = {}

const getters = {}

const fluxStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default fluxStore
