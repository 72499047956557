<template>
  <svg class="building-icon" :class="{'small': small}" v-if="active" xmlns="http://www.w3.org/2000/svg" width="81.101" height="92.687" viewBox="0 0 81.101 92.687">
    <path id="Icon_awesome-building" data-name="Icon awesome-building" d="M78.929,86.894H75.308V4.345A4.345,4.345,0,0,0,70.963,0H10.138A4.345,4.345,0,0,0,5.793,4.345V86.894H2.172A2.172,2.172,0,0,0,0,89.066v3.621H81.1V89.066A2.172,2.172,0,0,0,78.929,86.894ZM23.172,13.758a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172V21a2.172,2.172,0,0,1-2.172,2.172H25.344A2.172,2.172,0,0,1,23.172,21Zm0,17.379a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172v7.241a2.172,2.172,0,0,1-2.172,2.172H25.344a2.172,2.172,0,0,1-2.172-2.172Zm9.414,26.792H25.344a2.172,2.172,0,0,1-2.172-2.172V48.516a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172v7.241A2.172,2.172,0,0,1,32.585,57.929ZM46.343,86.894H34.758V71.688a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172ZM57.929,55.757a2.172,2.172,0,0,1-2.172,2.172H48.516a2.172,2.172,0,0,1-2.172-2.172V48.516a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172Zm0-17.379a2.172,2.172,0,0,1-2.172,2.172H48.516a2.172,2.172,0,0,1-2.172-2.172V31.137a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172Zm0-17.379a2.172,2.172,0,0,1-2.172,2.172H48.516A2.172,2.172,0,0,1,46.343,21V13.758a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172Z" fill="#FFFFFF"/>
  </svg>
  <svg class="building-icon" :class="{'small': small}" v-else xmlns="http://www.w3.org/2000/svg" width="81.101" height="92.687" viewBox="0 0 81.101 92.687">
    <path id="Icon_awesome-building" data-name="Icon awesome-building" d="M78.929,86.894H75.308V4.345A4.345,4.345,0,0,0,70.963,0H10.138A4.345,4.345,0,0,0,5.793,4.345V86.894H2.172A2.172,2.172,0,0,0,0,89.066v3.621H81.1V89.066A2.172,2.172,0,0,0,78.929,86.894ZM23.172,13.758a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172V21a2.172,2.172,0,0,1-2.172,2.172H25.344A2.172,2.172,0,0,1,23.172,21Zm0,17.379a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172v7.241a2.172,2.172,0,0,1-2.172,2.172H25.344a2.172,2.172,0,0,1-2.172-2.172Zm9.414,26.792H25.344a2.172,2.172,0,0,1-2.172-2.172V48.516a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172v7.241A2.172,2.172,0,0,1,32.585,57.929ZM46.343,86.894H34.758V71.688a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172ZM57.929,55.757a2.172,2.172,0,0,1-2.172,2.172H48.516a2.172,2.172,0,0,1-2.172-2.172V48.516a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172Zm0-17.379a2.172,2.172,0,0,1-2.172,2.172H48.516a2.172,2.172,0,0,1-2.172-2.172V31.137a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172Zm0-17.379a2.172,2.172,0,0,1-2.172,2.172H48.516A2.172,2.172,0,0,1,46.343,21V13.758a2.172,2.172,0,0,1,2.172-2.172h7.241a2.172,2.172,0,0,1,2.172,2.172Z" fill="#a7a7a7"/>
  </svg>
</template>

<script>
export default {
  name: 'BuildingIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.building-icon {
  height: 80px;
  width: 90px;

  &.small {
    height: 40px !important;
    width: 40px !important;;
  }
}
</style>
