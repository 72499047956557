import dataLayer from '../data-layer'
import { getLoggedUserId } from '../util'

class SignupEvents {

  completed({user_id, status}) {
    dataLayer.event({
      event: 'Signup Completed',
      user_id: user_id || '',
      status: status,
    });
  }

  verified({user_id, status}) {
    const userId = getLoggedUserId() || user_id
    dataLayer.event({
      event: 'Signup Verified',
      user_id: userId,
      status: status,
    });
  }
}

export default SignupEvents;
