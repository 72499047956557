<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="44.399" :height="height" viewBox="0 0 44.399 44.313">
    <g id="Grupo_578" data-name="Grupo 578" transform="translate(-0.055)">
      <g id="VIX_Seminovos-12" data-name="VIX Seminovos-12">
        <g id="Retângulo_378" data-name="Retângulo 378" opacity="0">
          <rect id="Retângulo_436" data-name="Retângulo 436" width="44" height="44" transform="translate(0.454)"/>
          <rect id="Retângulo_437" data-name="Retângulo 437" width="43.259" height="43.259" transform="translate(0.555 0.555)" stroke="#707070" stroke-width="1"/>
        </g>
      </g>
      <g id="Icon_ionic-md-speedometer" data-name="Icon ionic-md-speedometer" transform="translate(5.546 5.269)">
        <path id="Caminho_77" data-name="Caminho 77" d="M9,29.25H28.966v3.744H9Z" transform="translate(-2.761 -0.55)" fill="#a7a7a7"/>
        <path id="Caminho_78" data-name="Caminho 78" d="M18.61,21.106a2.5,2.5,0,1,0-.686-4.9l-5.2-3.8-.312.273,3.814,5.2a2.434,2.434,0,0,0-.109.725A2.493,2.493,0,0,0,18.61,21.106Z" transform="translate(-2.388 -2.388)" fill="#a7a7a7"/>
        <path id="Caminho_79" data-name="Caminho 79" d="M19.6,3.375A16.218,16.218,0,0,0,6.814,29.58H11.4l.312-.312,1.724-1.724L11.65,25.758,9.926,27.474a12.431,12.431,0,0,1-2.745-6.629H9.614v-2.5H7.181A12.431,12.431,0,0,1,9.926,11.72l1.724,1.716,1.786-1.786L11.72,9.926a12.431,12.431,0,0,1,6.629-2.745V9.614h2.5V7.181a12.431,12.431,0,0,1,6.629,2.745L25.758,11.65l1.786,1.786,1.724-1.716a12.431,12.431,0,0,1,2.745,6.629H29.58v2.5h2.433a12.431,12.431,0,0,1-2.745,6.629l-1.724-1.716-1.786,1.786,1.724,1.724.312.312H32.38A16.218,16.218,0,0,0,19.6,3.375Z" transform="translate(-3.375 -3.375)" fill="#a7a7a7"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VelocimeterIcon',
  props: {
    height: {
      type: String,
      default: '42'
    },
  }
}
</script>

<style scoped>

</style>