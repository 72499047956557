<template>
  <v-container>

    <v-row align="center" justify="center" class="mt-10">
      <span class="client-type-question">É para você ou sua empresa?</span>
    </v-row>

    <div class="d-flex" :class="{ 'flex-column align-center' : $vuetify.breakpoint.smAndDown, 'justify-center': $vuetify.breakpoint.mdAndUp}">
      <UserTypeCard
        :mobile="$vuetify.breakpoint.smAndDown"
        :title="'Para mim'"
        :subtitle="''"
        :active="isPessoaFisica"
        :type="'PESSOA_FISICA'"
        @click="setClientType"
      >
        <PersonIcon :small="$vuetify.breakpoint.smAndDown" :active="isPessoaFisica"/>
      </UserTypeCard>

      <UserTypeCard
        :mobile="$vuetify.breakpoint.smAndDown"
        :title="'Para minha empresa'"
        :subtitle="''"
        :active="isPessoaJuridica"
        :type="'PESSOA_JURIDICA'"
        @click="setClientType"
        :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 10px' : 'margin-left: 10px'"
      >
        <BuildingIcon :small="$vuetify.breakpoint.smAndDown" :active="isPessoaJuridica"/>
      </UserTypeCard>

      <UserTypeCard
        :mobile="$vuetify.breakpoint.smAndDown"
        :title="'Quero ser um parceiro VIX'"
        :subtitle="''"
        :active="isParceiroVix"
        :type="'PARCEIRO_VIX'"
        @click="setClientType"
        :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 10px' : 'margin-left: 10px'"
      >
        <PartnerIcon :small="$vuetify.breakpoint.smAndDown" :active="isParceiroVix"/>
      </UserTypeCard>
    </div>


  </v-container>
</template>

<script>
import PersonIcon from '@/shared/Icons/PersonIcon'
import BuildingIcon from '@/shared/Icons/BuildingIcon'
import PartnerIcon from '@/shared/Icons/PartnerIcon'
import UserTypeCard from '@/screen/Auth/UserTypeCard'
import { mapMutations } from 'vuex'

export default {
  name: 'ClientType',
  components: {UserTypeCard, BuildingIcon, PersonIcon, PartnerIcon},
  data: () => ({
    clientType: null,
  }),
  computed: {
    isPessoaFisica() {
      return this.clientType !== null && this.clientType === 'PESSOA_FISICA'
    },
    isPessoaJuridica() {
      return this.clientType !== null && this.clientType === 'PESSOA_JURIDICA'
    },
    isParceiroVix() {
      return this.clientType !== null && this.clientType === 'PARCEIRO_VIX'
    },
  },
  methods: {
    ...mapMutations('authStore', ['SET_CLIENT_TYPE']),
    setClientType(type) {
      this.clientType = type
    },
    save() {
      if (this.clientType === 'PARCEIRO_VIX') {
        this.$router.replace('seja-um-parceiro')
      } else {
        return this.clientType
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .page-content {

    .page-title {
      font: 30px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #666666;
      opacity: 1;
    }

    .enter-here {
      font: 13px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #666666;
      opacity: 1;

      a {
        text-decoration: none;
        color: #60BED4;
      }
    }

    .client-type-question {
      font: 18px 'HelveticaNeue-Bold';
      letter-spacing: 0;
      color: #2D2D2D;
      opacity: 1;
      margin-bottom: 40px;

      @media (min-width:960px) {
        font: 30px 'HelveticaNeue-Bold';
      }
    }
  }
</style>
