const DynamicApi = (vueResource, url) => {
  const resource = vueResource(`${url}/dynamic{/PATH}{/ID}{?Q*}`)
  return {
    async getCompany() {
      return await this.getDynamic('TELA_EMPRESA')
    },
    async getVix() {
      return await this.getDynamic('TELA_VIX')
    },
    async getTermApp() {
      return await this.getDynamic('TELA_TERMOS_APP')
    },
    async getTermSite() {
      return await this.getDynamic('TELA_TERMOS_SITE')
    },
    async getShopkeeper() {
      return await this.getDynamic('TELA_LOJISTA')
    },
    async getCollaborator() {
      return await this.getDynamic('TELA_COLABORADOR')
    },
    async getLanceInfo() {
      return await this.getDynamic('TELA_FUNC_LANCE')
    },
    async getTermSiteParceiro() {
      return await this.getDynamic('TERMOS-USO-PARCEIRO') 
    },
    async getTermSitePoliticaPrivacidade() {
      return await this.getDynamic('POLITICA-PRIVACIDADE')
    },
    async getTermSitePoliticaPrivacidadeParceiro() {
      return await this.getDynamic('PRIVACIDADE-PARCEIRO')
    },
    async getDynamic(id) {
      try {
        return await resource.get({ID: id})
      } catch (e) {
        return {
          body: undefined
        }
      }
    }
  }
}

export default DynamicApi
