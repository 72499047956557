<template>
  <div>
    <div class="header-bar desktop">

      <nav class="header-nav">
        <div class="header-logo cursor-pointer" @click="goToHome">
          <VixLogo :hide-label="true" :expanded="false" />
        </div>
        <ul class="header-links">
          <li><router-link to="/seja-um-parceiro"> {{ $t('HeaderBar.partner') }} </router-link></li>
          <li><router-link to="/texto/vix"> {{ $t('HeaderBar.aboutVix') }} </router-link></li>
          <li><a href="#footer" >{{ $t('HeaderBar.contactUs') }}</a></li>
          <!--<li><a class="header-links__button" href="">{{ $t('HeaderBar.forResellers') }}</a></li>
          <li><a class="header-links__button" href="">{{ $t('HeaderBar.forCompanies') }}</a></li>-->
        </ul>
      </nav>
      <div class="header-auth-singin">
        <UserMenu/>
      </div>
    </div>
    <div style="height: 94px;"></div>

    <div class="header-content">
      <div
          v-for="(category, i) in showCategories"
          :key="i"
          class="cursor-pointer"
          @click="goToLink(category.codigo)"
      >
        <HeaderCategory
          :category="category"
          :selected="category.codigo.toString() === categorySelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderCategory from '@/shared/HeaderBar/HeaderCategory'
import { HeaderResource } from '@/api'
import { mapMutations, mapState } from 'vuex'
import VixLogo from '@/shared/Icons/VixLogo'
import CookieControl from '@/cookie-control'
import UserMenu from '@/shared/HeaderBar/UserMenu'

const cookieControl = new CookieControl()

export default {
  name: 'HeaderBarDesktop',
  components: {VixLogo, UserMenu, HeaderCategory},
  watch: {
    routePath: {
      handler: () => {
        this && this.refreshToken && this.refreshToken()
      },

      immediate: true
    }
  },
  computed: {
    ...mapState('headerStore', ['showSearch']),
    ...mapState('authStore', ["user"]),
    showCategories() {
      return this.showSearch ? [] : this.categories
    },
    userLogged() {
      return this.user.codigoUsuario !== undefined
    },
    routePath() {
      return this.$route.path
    },
    routePathTextoVix() {
      return (window.PARTNER===undefined?"":"/"+window.PARTNER)+"/texto/vix"
    }
  },
  data () {
    return {
      categories: [],
      categorySelected: 0,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('homeStore', [
      'SET_TERM',
      'SET_ANCHOR',
      'CLEAR_FILTER',
      'SET_SKIP_CLEAR_FILTER'
    ]),
    ...mapMutations('authStore', [
      'SIGN_OUT',
      'SET_USER'
    ]),
    async init () {
      const responseHeaders = await HeaderResource.getList()
      this.categories = responseHeaders.body.slice(0, 7)
      this.categorySelected = this.$route.params.category
      await this.refreshToken()
    },
    async refreshToken () {
      if (!this.isUserLogin()) return this.SIGN_OUT()

      const userData = await HeaderResource.refreshToken()
      if (!userData.data) {
        if (this.isUserLogin()) {
          this.goToLogoff()
        } else {
          this.SIGN_OUT()
        }
      } else {
        this.SET_USER(userData.data)
        if (userData.data.alteraPassword === 'S' && this.$route.path !== '/auth/senha') {
          this.goToLogoff()
        }
      }
    },
    goToPassword() {
      this.success = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/senha`
      })
    },
    async goToLink(link) {
      this.SET_SKIP_CLEAR_FILTER(false)
      this.CLEAR_FILTER()
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      })
    },
    goToHome() {
      this.SET_SKIP_CLEAR_FILTER(false)
      this.CLEAR_FILTER()
      window.scrollTo(0, 0)
      window.PARTNER = undefined
      this.$router.push({
        path: `/home`
      })
    },
    goToLogoff() {
      this.SIGN_OUT()
      this.goToLink(this.categorySelected)
    },
    isUserLogin() {
      return cookieControl.isLogin()
    },
    hideMenu() {
      if (this.showUserMenu) {
        this.showUserMenu = false
      }
    },
  },
}
</script>

<style scoped>

.header-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 23;
  height: 94px;
  width: 100%;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #0000000D;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}

.header-nav {
  display: flex;
}
.header-logo {
  display: flex;
  align-items: center;
  font: 22px 'HelveticaNeue-Bold';
  color: #5B621C;
  margin-left: 20px;
}
.header-links {
  display: flex;
  margin-left: 40px !important;
}

.header-links > li {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.header-links >* a {
  font: 16px 'HelveticaNeue-Bold';
  text-decoration: none;
  color: #5B621C;
  opacity: 1;
}

.header-links > li:not(:first-child) {
  margin-left: 20px;
}

.header-links__button {
  background: #5B621C 0 0 no-repeat padding-box;
  color: #FFFFFF !important;
  padding: 8px 12px;
  border-radius: 5px;
}

.header-auth-singin {
  display: flex;
  align-items: center;
}


/*noinspection CssUnusedSymbol*/
.header-bar-search {
  grid-template-columns: 120px  auto 30px 135px;
  align-items: baseline;
  justify-items: center;
}

a {
  opacity: 0;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;
  height: 70px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-bottom: 5px solid rgb(205, 212, 63) !important;
}

.header-user-dropdown > * {
  cursor: pointer;
}

</style>
