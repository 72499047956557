import { render, staticRenderFns } from "./LogoVixIcon.vue?vue&type=template&id=ea2ce9e2&scoped=true"
import script from "./LogoVixIcon.vue?vue&type=script&lang=js"
export * from "./LogoVixIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea2ce9e2",
  null
  
)

export default component.exports