<template>
  <v-container>

    <v-row>
      <v-col md="4" cols="12" class="page-title" style="padding-block: 0">Escolha o nome da sua loja</v-col>
    </v-row>
    <v-row>
      <v-col md="4" cols="12" class="page-subtitle" style="padding-block: 0">Insira abaixo o nome da sua loja para montar o link</v-col>
    </v-row>

    <v-row class="mt-16">
      <v-col md="4" cols="12" class="page-subtitle-bold">Agora só falta o nome da sua loja na internet:</v-col>
    </v-row>

    <div class="d-inline-flex align-center pl-3 mb-16">
      <div class="mr-3 text-site">https://seminovos.vix.com.br/</div>
      <input id="site" type="text" class="input-site" v-model.trim="$v.site.$model" placeholder="minha-loja">
    </div>

    <v-row>
      <v-col md="4" cols="12">
        <div class="d-flex flex-column">
          <div v-if="!$v.site.onlyLowerCase" style="color: #F64A14;">
            Deve conter apenas letras minúsculas
          </div>
          <div v-if="!$v.site.onlyMiddleHyphen" style="color: #F64A14;">
            O hífen é permitido apenas no meio do nome do site. Ex: <em>minha-loja</em>
          </div>
          <div v-if="!$v.site.noWhiteSpaces" style="color: #F64A14;">
            Não deve conter espaços
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="actions-button">
      <button type="button" class="p-button p-button-primary" @click="submit">Próximo</button>
    </v-row>

  </v-container>
</template>

<script>
import {required, helpers} from "vuelidate/lib/validators";
import {mapActions} from "vuex";


const onlyLowerCase = (value) => !helpers.req(value) || /[a-z]/.test(value) && !(/[A-Z]/.test(value))

const onlyMiddleHyphen = (value) => !helpers.req(value) || (!value.startsWith("-") && !value.endsWith("-"))

const noWhiteSpaces = (value) => !helpers.req(value) || !(/(\s)/.test(value))

export default {
  name: "PartnerStoreName",
  data: () => ({
    site: null,
  }),
  validations: {
    site: {
      required,
      onlyLowerCase,
      onlyMiddleHyphen,
      noWhiteSpaces
    }
  },
  methods: {
    ...mapActions('registerPartnerStore', ['REGISTER_STORE_NAME']),
    async submit() {
      if (!this.$v.$invalid) {
        await this.REGISTER_STORE_NAME(this.site)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  font: 40px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
}

.page-subtitle {
  font: 22px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #565656;
  opacity: 1;
}

.page-subtitle-bold {
  font: 20px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #2D2D2D;
  opacity: 1;
}

.text-site {
  font: 18px 'HelveticaNeueLTPro-Lt';
  color: #A7A7A7;
}

.input-site {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  opacity: 1;
  height: 48px;
  width: 433px;
  font: 16px 'HelveticaNeueLTPro-Lt';

  padding-left: 17px;

  &::placeholder {
    letter-spacing: 0;
    color: #A7A7A7;
    opacity: 1;
    font: 18px 'HelveticaNeueLTPro-Lt';
  }
}

.actions-button {
  justify-content: center;
  margin-block: 20px;
  gap: 20px;
}

@media (max-width:768px) {
  .actions-button {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
</style>