<template>
  <svg id="img_cor" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Retângulo_378" data-name="Retângulo 378" fill="#c9cdd0" stroke="#707070" stroke-width="1" opacity="0">
    <rect width="40" height="40" stroke="none"/>
    <rect x="0.5" y="0.5" width="39" height="39" fill="none"/>
  </g>
  <g id="noun_color_1658230" transform="translate(-138.953 -82.068)">
    <g id="Grupo_2232" data-name="Grupo 2232" transform="translate(144.952 86.068)">
      <g id="Grupo_2231" data-name="Grupo 2231" transform="translate(0 0)">
        <path id="Caminho_9123" data-name="Caminho 9123" d="M169.439,104.208a12.355,12.355,0,0,1-.112,1.662c.019-.131.037-.266.052-.4a12.39,12.39,0,0,1-.85,3.1c.049-.12.1-.24.15-.359a12.477,12.477,0,0,1-1.587,2.714l.236-.3a12.46,12.46,0,0,1-2.209,2.209l.3-.236a12.329,12.329,0,0,1-2.714,1.587c.12-.049.24-.1.359-.15a12.234,12.234,0,0,1-3.1.85c.131-.019.266-.037.4-.052a12.643,12.643,0,0,1-3.321,0c.131.019.266.037.4.052a12.39,12.39,0,0,1-3.1-.85c.12.049.24.1.359.15a12.478,12.478,0,0,1-2.714-1.587l.3.236a12.461,12.461,0,0,1-2.209-2.209l.236.3a12.329,12.329,0,0,1-1.587-2.714c.049.12.1.24.15.359a12.234,12.234,0,0,1-.85-3.1c.019.131.037.266.052.4a12.661,12.661,0,0,1,0-3.3c-.019.131-.037.266-.052.4a12.405,12.405,0,0,1,.854-3.126c-.049.12-.1.24-.15.359a12.428,12.428,0,0,1,1.6-2.733l-.236.3a22.239,22.239,0,0,1,1.879-2.059c.786-.786,1.591-1.546,2.407-2.3,1.46-1.348,2.946-2.666,4.448-3.968.314-.273.629-.543.947-.812h-2.119q1.337,1.14,2.651,2.306c1.655,1.464,3.295,2.946,4.867,4.5a24.939,24.939,0,0,1,2.156,2.336l-.236-.3a12.428,12.428,0,0,1,1.6,2.733c-.049-.12-.1-.24-.15-.359a12.406,12.406,0,0,1,.854,3.126c-.019-.131-.037-.266-.052-.4a14.941,14.941,0,0,1,.1,1.64,1.5,1.5,0,1,0,3,0,13.925,13.925,0,0,0-1.7-6.634,17.332,17.332,0,0,0-2.98-3.785c-1.048-1.063-2.141-2.082-3.246-3.089q-1.544-1.41-3.122-2.785c-.532-.464-1.067-.932-1.606-1.389-.007-.007-.015-.011-.022-.019a1.562,1.562,0,0,0-1.059-.438,1.679,1.679,0,0,0-1.059.438c-.659.562-1.31,1.131-1.962,1.7-1.374,1.205-2.733,2.422-4.066,3.673a36.735,36.735,0,0,0-3.463,3.534,13.152,13.152,0,0,0-2.254,3.822,13.544,13.544,0,0,0-.91,4.141,13.733,13.733,0,0,0,24.818,8.906,13.6,13.6,0,0,0,1.67-3.032,13.455,13.455,0,0,0,.861-3.437,12.868,12.868,0,0,0,.1-1.61,1.514,1.514,0,0,0-1.5-1.5A1.53,1.53,0,0,0,169.439,104.208Z" transform="translate(-144.952 -86.068)" fill="#c9cdd0"/>
      </g>
    </g>
    <g id="Grupo_2233" data-name="Grupo 2233" transform="translate(158.694 87.571)">
      <path id="Caminho_9124" data-name="Caminho 9124" d="M524.242,142.837A12.242,12.242,0,0,1,512,155.08V126.2s7.207,6.132,9.453,8.862A12.185,12.185,0,0,1,524.242,142.837Z" transform="translate(-512 -126.2)" fill="#c9cdd0"/>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  name: 'ColorIcon'
}
</script>
<style scoped>
</style>