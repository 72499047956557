const state = {
  offers: [],
  filteredOffers: [],
  filteredSpotlight: [],
  vehicle: true,
  lot: true,
  priceRanger: {
    start: 0,
    end: -1
  },
  usageRanger: {
    start: 0,
    end: -1
  },
  brandsSelected: [],
  subcategoriesSelected: [],
  modelsSelected: [],
  categorySelected: null,
  location: null,
  cidadeSelected: '',
  estadoSelected: '',
  orderBy: '',
  term: '',
  hideSidebar: false,
  goToAnchor: false,
  codOffer: '',
  favorite: false,
  showFilter: false,
  showFilterMobile: false,
  skipClearFilter: false,
}

const mutations = {
  ['SET_SHOW_FILTER'](state, payload) {
    state.showFilter = payload
  },
  ['SET_SHOW_FILTER_MOBILE'](state, payload) {
    state.showFilterMobile = payload
  },
  ['SET_ANCHOR'](state, payload) {
    state.goToAnchor = payload
  },
  ['SET_SKIP_CLEAR_FILTER'](state, payload) {
    state.skipClearFilter = payload
  },
  ['SET_FAV_CARD'](state, payload) {
    const offer = state.offers.find(v => v.codigo === payload.codigo)
    if (offer) offer.favorite = payload.favorite
  },
  ['SET_HIDE_SIDEBAR'](state, payload) {
    state.hideSidebar = payload
  },
  ['SET_OFFERS'](state, payload) {
    state.offers = payload

    applyFilter(state)
  },
  ['SET_OFFER'] (state, payload) {
    state.offers = payload
  },
  ['SET_ORDER_BY'] (state, payload) {
    state.orderBy = payload
    applyFilter(state)
  },
  ['SET_TERM'] (state, payload) {
    state.term = payload
    applyFilter(state)
  },
  ['SET_LOCATION_BY'] (state, payload) {
    state.location = payload
    applyFilter(state)
  },
  ['SET_CIDADE'] (state, payload) {
    state.cidadeSelected = payload
  },
  ['SET_ESTADO'] (state, payload) {
    state.estadoSelected = payload
  },
  ['SET_FILTER'] (state, payload) {
    state.lot = payload.lot
    state.vehicle = payload.vehicle
    state.priceRanger = payload.priceRanger
    state.usageRanger = payload.usageRanger
    state.brandsSelected = payload.brandsSelected
    state.subcategoriesSelected = payload.subcategoriesSelected
    state.modelsSelected = payload.modelsSelected

    applyFilter(state)
  },
  ['CLEAR_FILTER'] (state) {
    state.lot = true
    state.vehicle = true
    state.priceRanger = {
      start: 0,
      end: -1,
    }
    state.usageRanger = {
      start: 0,
      end: -1
    }
    state.brandsSelected = []
    state.subcategoriesSelected = []
    state.modelsSelected = []
    state.categorySelected = undefined
    state.location = null
    state.cidadeSelected = ''
    state.estadoSelected = ''
    state.orderBy = ''

    if (!state.skipClearFilter) {
      state.term = ''
    }

    applyFilter(state)
  }
}

const actions = {}

const getters = {}

function applyFilter (state) {
  state.filteredOffers = filterOffers(state)
  state.filteredSpotlight = state.filteredOffers.filter(offer => offer.spotlight)
}

function filterOffers (state) {
  return state.offers && state.offers.filter(offer => {

    if (!state.lot && offer.vehicles.length > 1) return false
    if (!state.vehicle && offer.vehicles.length === 1) return false

    if (offer.price) {
      if (offer.price.real2centavos() <= state.priceRanger.start.real2centavos()) return false
      if (state.priceRanger.end !== -1 && offer.price.real2centavos() >= state.priceRanger.end.real2centavos()) return false
    }
    if (offer.vehicles[0].km) {
      if (offer.vehicles[0].km <= state.usageRanger.start) return false
      if (state.usageRanger.end !== -1 && offer.vehicles[0].km >= state.usageRanger.end) return false
    }
    if (state.brandsSelected.length > 0) {
      if (state.brandsSelected.indexOf(offer.vehicles[0].codigoMarca) === -1) return false
    }
    if (state.subcategoriesSelected.length > 0) {
      if (state.subcategoriesSelected.indexOf(offer.vehicles[0].codigoSubcategoria) === -1) return false
    }
    if (state.modelsSelected.length > 0) {
      if (state.modelsSelected.indexOf(offer.vehicles[0].codigoModel) === -1) return false
    }
    if (state.categorySelected && state.categorySelected.codigo !== 0) {
      const codigo = state.categorySelected.codigo
      if (offer.vehicles[0].codigoCategoria !== codigo) return false
    }
    if (state.term) {
      const searchString = (state.term || '').toLowerCase()
      const inDescription = (offer.description || '').toLowerCase().indexOf(searchString) === -1
      const inTitle = (offer.title || '').toLowerCase().indexOf(searchString) === -1
      var inCatgr = false
      var inModel = false
      var inMarca = false
      for(var veicl of offer.vehicles) {
          inCatgr = (veicl.categoria || '').toLowerCase().indexOf(searchString) === -1
        inModel = (veicl.model || '').toLowerCase().indexOf(searchString) === -1
        inMarca = (veicl.marca || '').toLowerCase().indexOf(searchString) === -1
        if (!inCatgr || !inModel || !inMarca) {
          break
        }
      }
      const inTag = (offer.tags || '').toLowerCase().indexOf(searchString) === -1
      if (inDescription && inTitle && inCatgr && inModel && inMarca && inTag) return false
    }
    if(state.location) {
      var inLocation = false
      for(var veiclLocation of offer.vehicles) {
        inLocation = ((veiclLocation.cidade || '').toLowerCase().indexOf(state.location.location.cidade.toLowerCase()) === -1) || ((veiclLocation.estado || '').toLowerCase().indexOf(state.location.location.estado.toLowerCase()) === -1)
        if(!inLocation) {
          break
        }
      }
      if(inLocation) return false
    }
    return true
  }).sort(getSort(state.orderBy)) || []
}

function getSort (orderBy) {
  switch (orderBy.toUpperCase()) {
    case 'MENOR_PRECO': {
      return (a, b) => a.price - b.price
    }
    case 'MAIOR_PRECO': {
      return (a, b) => b.price - a.price
    }
    /*    case 'ANO DE FABRICAÇÃO': {
          return (a, b) => {
            try {
              return a.vehicles[0].yearManufacture - b.vehicles[0].yearManufacture
            } catch (e) {
              return 0
            }
          }
        }*/
    /*   case 'ANO DO MODELO': {
         return (a, b) => {
           try {
             return a.vehicles[0].yearModel - b.vehicles[0].yearModel
           } catch (e) {
             return 0
           }
         }
       }*/
    case 'MAIS_ANTIGOS': {
      return (a, b) => {
        if (a.codigo < b.codigo) return -1
        if (a.codigo > b.codigo) return 1
        return 0
      }
    }
    case 'DISTANCIA': {
      return (a, b) => {
        return a.vehicles[0].dist - b.vehicles[0].dist
      }
    }
    default:
      return (a, b) => {
        if (a.codigo < b.codigo) return 1
        if (a.codigo > b.codigo) return -1
        return 0
      }
  }
}

/*
MAIS_RECENTES

MAIS_NOVOS
MAIS_ANTIGOS
MAIOR_PREÇO
MENOR_PREÇO
*/

const offerStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default offerStore
