<template>
<svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256">
  <g id="placeholder_flag" transform="translate(-535 -327)">
    <g id="Retângulo_1009" data-name="Retângulo 1009" transform="translate(535 327)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="256" height="256" stroke="none"/>
      <rect x="0.5" y="0.5" width="255" height="255" fill="none"/>
    </g>
    <circle id="Elipse_45" data-name="Elipse 45" cx="92" cy="92" r="92" transform="translate(571 363)" fill="#e8f1fe"/>
    <g id="Bandeira" transform="translate(531.774 -155.52)">
      <path id="Caminho_9002" data-name="Caminho 9002" d="M137.8,555.175l13.159-1.57,17.1-2.4,30.254-7.859,6.137-2.209,9.864-.883,11.366-1.734,2.889.833,28.2,23.449,1.649,13.883v30.254L228.559,629.3l-13.289,1.586L198.3,633.273l-29.631,8.55-15.851,2.293L137.8,645.944l-30.609-16.565-3.066-19.039V554.758l32.44,2.176Z" fill="#fff"/>
      <g id="Grupo_2195" data-name="Grupo 2195">
        <path id="Caminho_9003" data-name="Caminho 9003" d="M137.8,585.029v30.254c10.085.188,20.17-1.572,30.254-3.98V581.049C157.966,583.457,147.881,585.216,137.8,585.029Z" fill="#9fabbe"/>
        <path id="Caminho_9004" data-name="Caminho 9004" d="M198.3,573.175V603.43c10.084-2.408,20.169-4.168,30.254-3.981V569.2C218.474,569.008,208.389,570.767,198.3,573.175Z" fill="#9fabbe"/>
        <path id="Caminho_9005" data-name="Caminho 9005" d="M259.005,546.28a66.186,66.186,0,0,0-31.924-7.76c-14.69,0-29.6,4.04-44.009,7.945-14.364,3.893-29.216,7.917-43.8,7.917a65.39,65.39,0,0,1-31.542-7.664l-.6-.319v-5.574a2.305,2.305,0,0,0-2.305-2.3h-1.3a2.305,2.305,0,0,0-2.305,2.3V722.92h5.91V638.083l.215.115a66.171,66.171,0,0,0,31.923,7.76c14.69,0,29.6-4.039,44.009-7.945,14.364-3.892,29.216-7.917,43.8-7.917a65.376,65.376,0,0,1,31.541,7.665l.6.319V546.4Zm-.6,60.659a65.5,65.5,0,0,0-29.848-7.49V629.3c-.492-.009-.982-.02-1.478-.02-9.566,0-19.223,1.717-28.776,3.99V603.43c-10.085,2.408-20.17,5.465-30.255,7.873v29.829c-9.566,2.283-19.228,4.013-28.776,4.013-.5,0-.986-.011-1.478-.02V615.283a65.493,65.493,0,0,1-29.847-7.489V577.54a65.493,65.493,0,0,0,29.847,7.489V555.175c.492.009.983.02,1.479.02,9.566,0,19.222-1.716,28.775-3.99v29.844c10.085-2.408,20.17-5.465,30.255-7.874V543.346c9.566-2.283,19.228-4.012,28.776-4.012.5,0,.986.01,1.478.02V569.2a65.5,65.5,0,0,1,29.848,7.49Z" fill="#9fabbe"/>
        <rect id="Retângulo_1006" data-name="Retângulo 1006" width="47" height="5" rx="2.5" transform="translate(78.226 719.52)" fill="#9fabbe"/>
        <rect id="Retângulo_1007" data-name="Retângulo 1007" width="24" height="5" rx="2.5" transform="translate(45.226 719.52)" fill="#9fabbe"/>
        <rect id="Retângulo_1008" data-name="Retângulo 1008" width="79" height="5" rx="2.5" transform="translate(134.226 719.52)" fill="#9fabbe"/>
      </g>
    </g>
    <g id="Brilhos" transform="translate(-15 2)">
      <path id="Caminho_45" data-name="Caminho 45" d="M1012.984,28a7.964,7.964,0,0,1-4.248-3.1c-.96-1.7-1.126-3.522-1.8-5.437l-.117-.977-.117.977c-.673,1.915-.839,3.736-1.8,5.437a7.962,7.962,0,0,1-4.248,3.1l-3.107,1.037,3.107,1.037a7.96,7.96,0,0,1,4.248,3.1c.96,1.7,1.127,3.522,1.8,5.437l.117.977.117-.977c.673-1.915.839-3.736,1.8-5.437a7.962,7.962,0,0,1,4.248-3.1l3.107-1.037Z" transform="translate(-323.973 328.512)" fill="none" stroke="#cdd43f" stroke-width="1"/>
      <path id="Caminho_47" data-name="Caminho 47" d="M1006.429,23.961a4.582,4.582,0,0,1-2.444-1.783,18.553,18.553,0,0,1-1.035-3.128l-.067-.562-.067.562a18.509,18.509,0,0,1-1.035,3.128,4.58,4.58,0,0,1-2.444,1.783l-1.787.6,1.787.6a4.579,4.579,0,0,1,2.444,1.783,18.509,18.509,0,0,1,1.035,3.128l.067.562.067-.562a18.553,18.553,0,0,1,1.035-3.128,4.581,4.581,0,0,1,2.444-1.783l1.787-.6Z" transform="translate(-260.838 351.08)" fill="none" stroke="#cdd43f" stroke-width="1"/>
      <path id="Caminho_48" data-name="Caminho 48" d="M1006.429,23.961a4.582,4.582,0,0,1-2.444-1.783,18.553,18.553,0,0,1-1.035-3.128l-.067-.562-.067.562a18.509,18.509,0,0,1-1.035,3.128,4.58,4.58,0,0,1-2.444,1.783l-1.787.6,1.787.6a4.579,4.579,0,0,1,2.444,1.783,18.509,18.509,0,0,1,1.035,3.128l.067.562.067-.562a18.553,18.553,0,0,1,1.035-3.128,4.581,4.581,0,0,1,2.444-1.783l1.787-.6Z" transform="translate(-269.06 332.992)" fill="none" stroke="#cdd43f" stroke-width="1"/>
      <path id="Caminho_49" data-name="Caminho 49" d="M1009.1,25.606a5.958,5.958,0,0,1-3.178-2.319,24.131,24.131,0,0,1-1.347-4.068l-.087-.731-.088.731a24.076,24.076,0,0,1-1.346,4.068,5.956,5.956,0,0,1-3.178,2.319l-2.324.776,2.324.776a5.955,5.955,0,0,1,3.178,2.319,24.076,24.076,0,0,1,1.346,4.068l.088.731.087-.731a24.131,24.131,0,0,1,1.347-4.068,5.956,5.956,0,0,1,3.178-2.319l2.324-.776Z" transform="translate(-250.325 485.456)" fill="none" stroke="#cdd43f" stroke-width="1"/>
      <path id="Caminho_46" data-name="Caminho 46" d="M1012.984,28a7.964,7.964,0,0,1-4.248-3.1c-.96-1.7-1.126-3.522-1.8-5.437l-.117-.977-.117.977c-.673,1.915-.839,3.736-1.8,5.437a7.962,7.962,0,0,1-4.248,3.1l-3.107,1.037,3.107,1.037a7.96,7.96,0,0,1,4.248,3.1c.96,1.7,1.127,3.522,1.8,5.437l.117.977.117-.977c.673-1.915.839-3.736,1.8-5.437a7.962,7.962,0,0,1,4.248-3.1l3.107-1.037Z" transform="translate(-417.549 399.221)" fill="none" stroke="#cdd43f" stroke-width="1"/>
    </g>
  </g>
</svg>
</template>
<script>
export default {
  name: 'PlaceHolderFlagIcon'
}
</script>
<style scoped>

</style>