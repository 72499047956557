<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="154" height="154" viewBox="0 0 154 154">
        <g id="Grupo_172" data-name="Grupo 172" transform="translate(-0.138 0.229)">
            <g id="Grupo_171" data-name="Grupo 171" transform="translate(0.138 -0.229)">
            <g id="Elipse_63" data-name="Elipse 63" transform="translate(0)" fill="#fff" stroke="#cdd43f" stroke-width="4">
                <circle cx="77" cy="77" r="77" stroke="none"/>
                <circle cx="77" cy="77" r="75" fill="none"/>
            </g>
            <path id="Icon_feather-check" data-name="Icon feather-check" d="M80.28,9,29.212,60.067,6,36.855" transform="translate(34.914 41.4)" fill="none" stroke="#cdd43f" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
  name: 'CircleCheckedIcon',
  props: {
    height: {
      type: String,
      default: '154'
    },
    width: {
      type: String,
      default: '154'
    }
  },
}
</script>
<style scoped>
</style>