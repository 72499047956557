<template>
  <div
      v-if="!isMobileHideHeader"
  >
    <div
        class="header-bar tablet"
    >
      <div
          :class="{'compact': showingSearch}"
          class="header-left-column"
      >
        <div
            class="cursor-pointer"
            @click="showSideMenu"
        >
          <BurgerIcon/>
        </div>
      </div>

      <div
          v-if="!showingSearch"
          @click="goToLink()"
      >
        <VixLogo
            :hide-label="true"
        />
      </div>

      <div
          class="header-right-column"
      >

<!--        <div-->
<!--            v-if="!showingSearch"-->
<!--            @click="goToFilter"-->
<!--            class="align-content-center"-->
<!--            style="height: 100%;"-->
<!--        >-->

<!--          <FilterIcon-->
<!--              :fill="filterFillColor"-->
<!--              :show-circle="false"-->
<!--          />-->
<!--        </div>-->

<!--        <SearchInput-->
<!--            :style="showingSearch ? '': 'width: fit-content;'"-->
<!--            class="hidden-xs-only"-->
<!--        />-->

        <UserMenu
            class="margin-mobile"
        />

        <LocaleMenu
            :show-label="false"
            class="margin-mobile"
            v-if="false"
        />
      </div>


    </div>
    <div
        style="height: 54px;"
    />

    <SideMenu/>
  </div>
</template>

<script>
import { HeaderResource } from '@/api'
import { mapMutations, mapState } from 'vuex'
import VixLogo from '@/shared/Icons/VixLogo'
import CookieControl from '@/cookie-control'
import LocaleMenu from '@/shared/LocaleMenu/LocaleMenu'
import UserMenu from '@/shared/HeaderBar/UserMenu'
import BurgerIcon from '@/shared/Icons/BurgerIcon'
import SideMenu from '@/shared/HeaderBar/SideMenu'

const cookieControl = new CookieControl()

export default {
  name: 'HeaderBarTablet',
  components: {SideMenu, BurgerIcon, UserMenu, LocaleMenu, VixLogo},
  watch: {
    routePath: {
      handler: () => {
        this && this.refreshToken && this.refreshToken()
      },

      immediate: true
    }
  },
  computed: {
    ...mapState('headerStore', ['showSearch', 'hideHeader']),
    ...mapState('homeStore', ['showFilterMobile']),
    showingSearch() {
      return this.showSearch
    },
    showingFilterMobile: {
      get() {
        return this.showFilterMobile
      },
      set (v) {
        this.SET_SHOW_FILTER_MOBILE(v)
      }
    },
    isMobileHideHeader () {
      return this.hideHeader && this.$vuetify.breakpoint.xsOnly
    },
    showCategories() {
      return this.showSearch ? [] : this.categories
    },
    userLogged() {
      return this.user.codigoUsuario !== undefined
    },
    filterFillColor() {
      return this.showingFilterMobile ? '#60bed4' : '#c9cdd0'
    },
    routePath() {
      this.refreshToken()
      return this.$route.path;
    }
  },
  data () {
    return {
      categories: [],
      categorySelected: 0,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('headerStore', ['SET_SHOW_SIDE_MENU', 'SET_HIDE_HEADER']),
    ...mapMutations('homeStore', [
      'SET_TERM',
      'SET_ANCHOR',
      'CLEAR_FILTER',
      'SET_SKIP_CLEAR_FILTER',
      'SET_SHOW_FILTER_MOBILE'
    ]),
    ...mapMutations('authStore', [
      'SIGN_OUT',
      'SET_USER'
    ]),
    async init () {
      this.showingFilterMobile = false
      const responseHeaders = await HeaderResource.getList()
      this.categories = responseHeaders.body.slice(0, 7)
      this.categorySelected = this.$route.params.category

      await this.refreshToken()
    },
    async refreshToken () {
      if (!this.isUserLogin()) return this.SIGN_OUT()

      const userData = await HeaderResource.refreshToken()
      if (!userData.data) {
        if (this.isUserLogin()) {
          this.goToLogoff()
        } else {
          this.SIGN_OUT()
        }
      } else {
        this.SET_USER(userData.data)
        if (userData.data.alteraPassword === 'S' && this.$route.path !== '/auth/senha') {
          this.goToLogoff()
        }
      }
    },
    goToPassword() {
      this.success = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/senha`
      })
    },
    async goToFilter() {
      window.scrollTo(0, 0)
      if (this.$router.history.current.fullPath.indexOf('/home') === -1) {
        await this.goToLink()
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        this.SET_HIDE_HEADER(true)
        this.showingFilterMobile = true
      } else {
        this.showingFilterMobile = !this.showingFilterMobile
      }
    },
    async goToLink (link) {
      this.SET_SKIP_CLEAR_FILTER(false)
      this.CLEAR_FILTER()
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      })
    },
    isUserLogin () {
      return cookieControl.isLogin()
    },
    hideMenu () {
      if (this.showUserMenu) {
        this.showUserMenu = false
      }
    },
    showSideMenu () {
      this.SET_SHOW_SIDE_MENU(true)
    }
  },
}
</script>

<style scoped>

.header-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 23;
  height: 54px;
  width: 100%;
  background: #FFF;
  display: grid;
  grid-template-areas: "left div right";
  grid-template-columns: 45px auto 215px;
  justify-items: center;

  border-bottom: 2px solid rgb(205, 212, 63) !important;
  padding-left: 24px;
  padding-top: 6px;
  padding-right: 24px;
}

/*noinspection CssUnusedSymbol*/
.header-bar-search {
  grid-template-columns: 120px  auto 30px 135px;
  align-items: baseline;
  justify-items: center;
}

a {
  opacity: 0;
}

.header-left-column {
  grid-area: left;
  flex: 1;
  justify-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 8px;
}

/*noinspection CssUnusedSymbol*/
.compact {
  flex: 0;
}

.header-logo-column {
  flex: 0;
}

.header-right-column {
  grid-area: right;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-wrap: nowrap;
}


.header-user-dropdown > * {
  cursor: pointer;
}

@media screen and (max-width: 601px) {
  .margin-mobile {
    margin-left: 15px;
  }
}

</style>
