<template>
  <div>
    <div style="width: 100%;z-index: 19; position: relative;">
      <v-card
          color="#ff5722"
          dark
      >
        <v-row class="pa-0">
          <v-col cols6>
            <h2 class="text-center">
              {{ $t('OfferBar.testPage') }}
            </h2>
          </v-col>
          <v-col
              class="mr-3"
              cols6
          >
            <v-select
                :items="profileList"
                background-color="#ffffff"
                dense
                flat
                hide-details
                light
                outlined
                v-model="profileSelected"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>


    <div
        :style="galleryInShow ? '': 'z-index: 1; position: relative;'"
        v-if="loaded"
    >
      <OfferScreen
          :offer="offer"
          :offer-price="offerPrice"
          :photos="offerPhotos"
      />
    </div>
  </div>
</template>

<script>
import { AuthResource, OfferResource } from '@/api'
import OfferScreen from '@/screen/Offer/OfferScreen'
import { mapState } from 'vuex'

export default {
  name: 'OfferViewer',
  components: { OfferScreen },
  data () {
    return {
      offer: null,
      offerPriceList: [],
      profileList: [],
      token: 0,
      profileSelected: {},
      galleries: [],
      loaded: false
    }
  },
  computed: {
    ...mapState('offerStore', ['galleryInShow']),
    offerPrice () {
      return this.offerPriceList.find(n => n.codigoPerfil === this.profileSelected) || {}
    },
    offerPhotos () {
      const gallery = this.galleries.find(n => n.codigoProfile === this.profileSelected)
      return gallery && gallery.photos || []
    }
  },
  mounted () {
    this.token = this.$route.params.token
    this.init()
  },
  methods: {
    async init () {

      const responseOffer = await OfferResource.getItemByToken(this.token)
      this.offer = responseOffer.body

      const responsePriceList = await OfferResource.getPriceList(this.token)
      this.offerPriceList = responsePriceList.body || []

      const responseProfiles = await AuthResource.getProfiles(this.token)
      let body = responseProfiles.body && responseProfiles.body.data || []
      this.profileList = body.map(n => {
        return {
          text: n.descricao,
          value: n.codigo,
        }
      })

      const responseGallery = await OfferResource.getAllGallery(this.token)
      this.galleries = responseGallery.body || []

      this.loaded = true
    },
  },
}
</script>

<style scoped>

</style>