<template>
  <div
    class="offer-card cursor-pointer"
    v-if="offer.codigo"
    @click.stop="goToLink"
  >

    <div
        class="relative offer-card-image-container"
    >
      <!--suppress HtmlUnknownTarget -->
      <v-img
          :src="getImage(currentPhoto)"
          class="offer-card-image"
          height="100%"
      />

      <div class="offer-card-image-arrow ml-2" @click.stop="previousPhoto">

        <ChevronLeft :size="24"  class="cursor-pointer"
                      aria-hidden="true" />
      </div>

      <div class="offer-card-image-arrow mr-2" style="right: 0" @click.stop="nextPhoto">
        <ChevronRight :size="24" class="cursor-pointer"
                      aria-hidden="true" />
      </div>

      <div class="absolute d-inline-flex justify-center align-center" style="width: 100%; height: 9px; bottom: 10px;">
        <div
          class="offer-card-image-bullet"
          :key="i" v-for="(picture, i) in firstFive"
          :class="{ 'selected': i === currentIndex }"
        ></div>
      </div>

      <div
          @click.stop="goToPurpose"
          class="offer-card-proposal absolute"
          v-if="offer.doOffer === 'S' && !offer.hasBid"
          style="display: flex; justify-content: center; align-items: center;"
      >
        <span style=" line-height: 1">{{ $t('OfferBar.sendPurpose') }}</span>
      </div>

      <div class="absolute favorite-icon-container" @click.stop="favorites($event)">
        <FavoriteIcon :active="offer.favorite"/>
      </div>
    </div>

    <div v-if="offer.parceiro!==null && offer.parceiro!==undefined "
    style="
        position: absolute;
        transform: translateY(-5px);
        background: #5a6222;
        width: 100%;
        height: 18px;
    ">
      <span style="
        font-size: 13px;
        line-height: 18px;
        width: 100%;
        text-align: center;
        display: block;
        color: white;
      ">Parceiro VIX</span>
    </div>

    <div
        class="relative offer-card-info-container"
    >
      <v-row class="offer-card-metrics text-truncate" :class="{ 'd-none': isLote }" justify="space-between">
        <span>{{ offer.vehicles[0].yearManufacture }}/{{ offer.vehicles[0].yearModel }}</span>
        <span>{{ metric }}</span>
      </v-row>

      <p
          class="text-truncate offer-card-title"
      >
        {{ offer.title }}
      </p>

      <p
          :style="isLote ? 'visibility: hidden' : ''"
          class="text-truncate offer-card-subtitle"
      >
        {{ offer.vehicles[0].version }}
      </p>

      <div class="d-flex">
        <v-icon class="icon-location">mdi-map-marker</v-icon>
        <div
          class="text-truncate offer-card-location"
          :title="locations"
        >
          {{ locations }}
        </div>
      </div>

      <p
          class="text-truncate offer-card-price"
      >
        {{ price }}
      </p>

      <v-row class="flex-nowrap mt-md-5 mt-sm-2" justify="space-between">
        <button class="offer-card-action-buy" @click.stop="goToBid">{{ $t('OfferBar.buy') }}</button>
        <button ref="showDetailButton" class="offer-card-action-detail included" style="margin-left: 10px;" @click.stop="showDetail = !showDetail">Detalhes</button>

        <div ref="tooltip" id="tooltip" role="tooltip">
          <offer-detail
            v-show="showDetail"
            :offer="offer"
            @close="closeDetail"
            v-click-outside="{
              handler: closeDetail,
              include: include,
            }"></offer-detail>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>

import { getImageUrl, OfferResource } from '@/api'
import FavoriteIcon from '@/shared/Icons/FavoriteIcon'
import { mapMutations } from 'vuex'
import CookieControl from '../../cookie-control'
import { productEvents } from '@/analytics'
import OfferDetail from '@/shared/OfferCard/OfferDetail'
import ChevronRight from "@/shared/Icons/ChevronRight"
import ChevronLeft from "@/shared/Icons/ChevronLeft"

import { createPopper } from '@popperjs/core';

const cookieControl = new CookieControl()

export default {
  name: 'offer-card',
  components: {ChevronLeft, ChevronRight, FavoriteIcon, OfferDetail},
  props: {
    offer: {
      type: Object
    }
  },
  data: () => ({
    photos: [],
    currentIndex: 0,
    showDetail: false,
    object: this
  }),
  computed: {
    getAddictionInfo () {
      let addictionInfos = (
          this.offer
          && this.offer.vehicles[0]
          && this.offer.vehicles[0].typeVehiclesDto
          && this.offer.vehicles[0].typeVehiclesDto.additionalInformation
      ) || []
      return addictionInfos.slice(0, 2)
    },
    price () {
      if (!this.offer.price) return 'R$ 0.000,00*'
      return this.offer.price.number2Money()
    },
    isLote () {
      return this.offer.vehicles.length > 1
    },
    metric () {
      if (!this.offer.vehicles[0].km) return '-- ' + this.offer.vehicles[0].tipoContador
      return this.offer.vehicles[0].km.float2Money('', '', '.', '') + ' ' + this.offer.vehicles[0].tipoContador
    },
    locations() {
      let locations = ''
      let cidadesEstados = []
      for(let i=0; i < this.offer.vehicles.length; i++) {
        if(!cidadesEstados.includes(this.offer.vehicles[i].cidade + '-' + this.offer.vehicles[i].estado)) {
          cidadesEstados.push(this.offer.vehicles[i].cidade + '-' + this.offer.vehicles[i].estado)
        }
      }
      for(let i=0;i<cidadesEstados.length;i++) {
        if(i === cidadesEstados.length - 1) {
          locations = locations + cidadesEstados[i]
        } else {
          locations = locations + cidadesEstados[i] + '/'
        }
      }
      return locations
    },
    firstFive() {
      return this.photos.slice(0, 5)
    },
    currentPhoto() {
      if (this.photos.length) {
        return this.photos[this.currentIndex]
      }
      return this.offer.vehicles[0].photo
    }
  },
  mounted () {
    const popper = createPopper(this.$refs["showDetailButton"], this.$refs["tooltip"], {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-30, 8],
          },
        },
      ],
    });
    this.init()
  },
  methods: {
    ...mapMutations('fluxStore', [
      'SET_FLUX_BUY', 'SET_OFFER'
    ]),
    async init () {
      await this.getOfferGallery()
    },
    ...mapMutations('homeStore', [
      'SET_FAV_CARD'
    ]),
    ...mapMutations('offerStore', [
      'SET_OFFERS_SEM_FAV'
    ]),
    goToBid () {
      window.scrollTo(0, 0)
      let prefixURL = "";
      if(window.PARTNER!==undefined){
        prefixURL = "/"+window.PARTNER;
      }else if(this.offer.parceiro !== null){
        prefixURL = "/"+this.offer.parceiro;
      }
      this.$router.push({
        path: prefixURL+'/oferta/' + this.offer.codigo + '?view=leilao'
      }).catch(() => {})
    },
    goToPurpose () {
      window.scrollTo(0, 0)
      let prefixURL = "";
      if(window.PARTNER!==undefined){
        prefixURL = "/"+window.PARTNER;
      }else if(this.offer.parceiro !== null){
        prefixURL = "/"+this.offer.parceiro;
      }
      this.$router.push({
        path: prefixURL+'/oferta/' + this.offer.codigo + '?view=proposta'
      }).catch(() => {})
    },
    goToBuy () {
      let prefixURL = "";
      if(window.PARTNER!==undefined){
        prefixURL = "/"+window.PARTNER;
      }else if(this.offer.parceiro !== null){
        prefixURL = "/"+this.offer.parceiro;
      }
      if (!this.isUserLogin()) {
        return this.$router.push({
          path: prefixURL+'/oferta/' + this.offer.codigo
        }).catch(() => {})
      }

      this.SET_OFFER(this.offer)
      this.SET_FLUX_BUY()

      window.scrollTo(0, 0)
      this.$router.push({
        path: prefixURL+'/fluxo'
      }).catch(() => {})
    },
    goToLink () {
      let prefixURL = "";
      if(window.PARTNER!==undefined){
        prefixURL = "/"+window.PARTNER;
      }else if(this.offer.parceiro !== null){
        prefixURL = "/"+this.offer.parceiro;
      }
      window.scrollTo(0, 0)
      this.$router.push({
        path: prefixURL+'/oferta/' + this.offer.codigo
      }).catch(() => {})
    },

    getIcon (iconUnicode) {
      return `&#x${iconUnicode};`
    },

    isUserLogin () {
      return cookieControl.isLogin()
    },

    getImage (endpoint) {
      return getImageUrl(endpoint)
    },
    async favorites (e) {
      if (this.isUserLogin()) {
        e.stopPropagation()
        e.preventDefault()

        this.SET_FAV_CARD({
          codigo: this.offer.codigo,
          favorite: !this.offer.favorite
        })
        this.SET_OFFERS_SEM_FAV({
          codigo: this.offer.codigo,
          favorite: !this.offer.favorite
        })

        await OfferResource.postFavorite({
          'codigoOferta': this.offer.codigo,
        })

        if (!this.favorite) {
          await productEvents.favorited(this.offer)
        }
      }
    },
    async getOfferGallery () {
      const response = await OfferResource.getGallery(this.offer.codigo)
      // recupera a galeria de fotos
      this.photos = response.body.photos
      // recupera foto da oferta
      // let firstPhoto = this.offer.vehicles[0].photo
      // coloca a foto da oferta como sendo a primeira da galeria
      // photoArray.unshift(photoArray.splice(photoArray.findIndex(item => this.getPhotoHash(item) === this.getPhotoHash(firstPhoto)), 1)[0])
      // this.photos = photoArray
    },
    nextPhoto() {
      if (this.currentIndex + 1 === this.firstFive.length) {
        this.currentIndex = 0
        return
      }
      this.currentIndex += 1
    },
    previousPhoto() {
      if (this.currentIndex === 0) {
        return
      }
      this.currentIndex -= 1
    },
    // recupera somente o identificador da url da foto 
    getPhotoHash(photo){
      return photo.split('/').pop().split('?')[0]
    },
    closeDetail() {
      this.showDetail = false
    },
    include () {
      return [this.$refs['showDetailButton']]
    },
  }
}

</script>

<style
  scoped
  lang="scss"
>

@function rem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}

.favorite-icon-container {
  color: #FFFFFF;
  right: 0;
  top: 0;
  background: #FFFFFF;
  padding: rem(10);
  border-radius: 50%;
  margin: .5rem;
}

.offer-card-image-bullet {
  background-color: #FFFFFF;
  width: rem(7);
  height: rem(7);
  border-radius: 50%;
  margin-left: rem(6);
  transition: transform 200ms;
  transition-timing-function: ease-in, ease-out;

  &.selected {
    background-color: #CDD43F;
    transform: scale(1.5);
  }
}

.offer-card-image-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: rem(20);
  color: #FFFFFF;
  top: 50%;
}

.offer-card-image-container {
  height: 190px;
  width: 100%;
}

.offer-card {
  box-shadow: -2px -2px 2px #FFFFFF80, 2px 2px 2px rgba(231, 231, 232, 0.80);
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  max-width: 17rem;
  position: relative;
}

.offer-card-info-container {
  line-height: 1;
  padding: 1rem;
}

.offer-card-image {
  border-radius: 8px 8px 0 0;
}

.offer-card-proposal {
  background: #60BED4 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Md';

  letter-spacing: 0;
  color: #FFFFFF;

  height: 24px;
  width: 108px;

  right: 7px;
  bottom: 13px;
}

.offer-card-proposal > span {
  display: block;
  line-height: 1;
}

.align-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.offer-card-title {
  font-family: HelveticaNeue-Medium, serif;
  font-size: rem(20);
  letter-spacing: 0;
  color: #2D2D2D;
  margin-bottom: 0;
  margin-top: rem(8);
}

p.offer-card-subtitle {
  font-family: HelveticaNeueLTPro-Roman, serif;
  font-size: rem(13);
  letter-spacing: 0;
  color: #666666;
}

p.offer-card-price {
  text-align: left;
  font-family: HelveticaNeue-Bold, serif;
  font-size: rem(22);
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 10px;
  margin-top: 10px;
}

p.offer-card-price-description {
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-top: rem(16);
}

.offer-card-location {
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #60BED4;
}

.icon-location {
  font-size:20px;
  color: #60BED4;
  margin-left: -5px;
}

.line-through {
  text-decoration: line-through;
}

.offer-card-metrics {
  font: 0.813em 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
}

p.offer-card-year {
  text-align: left;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  margin-bottom: 0;
}

p.offer-card-mileage {
  text-align: right;
  font: 12px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  margin-bottom: 0;
}

.offer-card-action-buy {
  opacity: 1;
  text-align: center;
  letter-spacing: 0;
  color: #2D2D2D;
  height: rem(40);
  width: rem(150);
  font: 1rem HelveticaNeue-Medium;
  background: #CDD43F 0 0 no-repeat padding-box;
  border-radius: rem(20);
  box-shadow: -1px -1px 1px #ffffff;
}

.offer-card-action-detail {
  opacity: 1;
  text-align: center;
  letter-spacing: 0;
  color: #2D2D2D;
  height: rem(40);
  width: rem(130);
  font: 1rem HelveticaNeue-Medium;
  border-radius: rem(20);
  box-shadow: -1px -1px 1px #ffffff;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #CDD43F;
}

.offer-card-buy {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFF, 1px 1px 2px #CBCBCB;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0;
  color: #2D2D2D;
  height: 24px;
  width: 73px;

  font: 13px 'HelveticaNeueLTPro-Md';
  justify-content: center;
  align-items: center;
  display: flex;
}

.offer-card-buy > span {
  display: block;
  line-height: 1;
}

p.offer-card-bid {
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #27BADC;
  margin: 0;
}
@media (max-width: 768px) {

  .offer-card-image-container {
    width: 100%;
    height: 114px;
  }

  .offer-card {
    width: 150px;
  }

  p.offer-card-title {
    font-size: 11px;
  }

  p.offer-card-subtitle {
    font-size: 10px;
  }

  p.offer-card-price {
    font-size: 15px;
  }

  .offer-card-metrics {
    font-size: 10px;
  }

  .offer-card-action-buy, .offer-card-action-detail {
    width: 70px;
    height: 24px;
    font-size: 11px;
  }

  .offer-card-image-bullet {
    width: rem(5);
    height: rem(5);
  }

  .favorite-icon-container {
    padding: rem(2);
  }

  .offer-card-image-arrow {
    z-index: 2;
  }

  .offer-card-location {
    font: 11px 'HelveticaNeueLTPro-Lt';
    margin-left: 3px;
  }

  .icon-location {
    font-size: 10px;
    color: #60BED4;
    margin-left: -5px;
  }

  .offer-card-info-container {
    line-height: 1;
    padding: .6rem;
  }
}

#tooltip {
  display: inline-block;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 4px;
  z-index: 22;
}

</style>
