import { render, staticRenderFns } from "./PlaceHolderFlagIcon.vue?vue&type=template&id=225f61fc&scoped=true"
import script from "./PlaceHolderFlagIcon.vue?vue&type=script&lang=js"
export * from "./PlaceHolderFlagIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225f61fc",
  null
  
)

export default component.exports