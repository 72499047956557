<template>
  <div style="width: 100%;">
    <div class="talk-grid">
      <div class="question-icon">
        <QuestionIcon
            color="#2D2D2D"
        />
      </div>
      <div
          class="question-container"
      >
        <div
            class="chat-question"
        >
          {{ talk.pergunta }}
        </div>
        <div
            class="chat-answer"
            v-if="talk.resposta"
        >
          {{ talk.resposta }}
        </div>

        <hr
            class="talk-line"
            v-if="show"
        >
      </div>
    </div>
    <div
        class="talk-margin-bottom"
        :style="show ? 'margin-bottom: 24px;' : 'margin-bottom: 42px;'"
    />
  </div>
</template>
<script>
import QuestionIcon from '../../shared/Icons/QuestionIcon'

export default {
  name: 'ChatTalk',
  components: { QuestionIcon },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    talk: {
      type: Object,
      default: () => { return {} }
      }
    },
  }
</script>
<style
    scoped
    type="text/css"
>

  .talk-line {
    width: 100%;
    height: 1px;
    background-color: RGBA(139, 139, 139, 0.4);
    border: none;
  }

  .chat-question {
    text-align: left;
    font: 13px 'HelveticaNeueLTPro-Md';
    letter-spacing: 0;
    color: #2D2D2D;
    margin-bottom: 16px;
    word-break: break-all;
  }

  .chat-answer {
    text-align: left;
    font: 13px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
    color: #27BADC;
    margin-bottom: 24px;
    word-break: break-all;
  }

  .question-container {
    flex: 1;
  }

  .question-icon {
    width: 22px;
    margin-right: 25px;
  }

  .talk-grid {
    display: flex;
    width: 100%;
  }
</style>