export default {
  HeaderBar: {
    profile: 'Profile',
    notifications: 'Notifications',
    favorites: 'Favorites',
    negotiations: 'Negotiations',
    myPurchase: 'My Purchases',
    logoff: 'Exit',
    loginOrRegister: 'Log in or register',
    userGreeting: 'Hi!, ',
    logoTitle: 'Seminovos',
    aboutVix: 'Sobre a Vix',
    contactUs: 'Contact us',
    forCompanies: 'Companies',
    forResellers: 'Resellers',
    partner: 'Be a partner'
  },
  HomeIndex: {
    contact: {
      phone1: 'Automóveis e utilitários',
      phone2: 'Máquinas e ônibus',
      phone3: 'Caminhões e implementos', 
      whatsapp: '(27) 99999-9999',
      mailto: 'seminovos@vix.com.br',
    },
    OffersRow: {
      title: 'Highlights'
    },
    about: {
      benefits: {
        title: 'Advantages for those who buy',
        paymentMethodsTitle: 'Payment methods',
        paymentMethodsText: 'Here you have flexibility in payment methods, including financing and consortium.',
        scheduledDeliveryPickupTitle: 'Scheduled delivery and pickup',
        scheduledDeliveryPickupText: 'Schedule your technical delivery at the store, or receive the vehicle by delivery in any city in the country.',
        conditionsResellersTitle: 'Conditions for resellers and fleet owners',
        conditionsResellersText: 'Vehicles with competitive price, variety of models and availability of immediate delivery.',
        reportDocumentTitle: 'Origin vehicles',
        reportDocumentText: 'Original and overhauled cars, from a single owner, from the Águia Branca Group.',
      },
      qualities: {
        title: 'Quality in vehicles',
        autoDetailTitle: 'Carefully inspected cars',
        autoDetailText: 'Each vehicle is evaluated by a specialized technical team before being made available for sale.',
        noTamperingTitle: 'No tampering',
        noTamperingText: 'All vehicles have the original factory structure, with original chassis and engine.',
        carGreatConditionTitle: 'Car with great conditions',
        carGreatConditionText: 'Single-owner vehicles with up-to-date revisions made according to the automakers standard.',
        noDocumentaryRestrictionsTitle: 'No documentary restrictions',
        noDocumentaryRestrictionsText: 'All vehicles are free from any kind of administrative impediments or restrictions.',
      },
    },
    offerNotFound: {
      title: 'Nothing here',
      text: `Unfortunately we couldn't find any results for your search.`,
    },
  },
  Partner:{
    memberSince: "Member since "
  },
  FilterSidebar: {
    title: 'Search',
    clearFilter: 'Clear filters',
    priceRanger: 'Price range',
    kmRanger: 'Km Range',
    showMore: 'See more',
    quantityText: 'no items selected | 1 selected item | {count} selected items',
    brands: 'Brands',
    categories: 'Categories',
    models: 'Models',
    clearSelects: 'Clear selected'
  },
  OfferScreen: {
    features: 'Features',
    resume: 'Summary',
    specifications: 'Specifications',
    questionDialogText: {
      text: 'Your question has been sent and will be answered shortly.',
      sub: 'Keep an eye on notifications!',
    },
    question: 'Questions',
    questionPlaceholder: 'Type your question or comment here...',
    questionPlaceholderDisabled: 'You must be logged in to ask a question...',
    observation: 'Description',
    laudo: 'Vehicle report',
    implements: 'Implements',
    implement: 'Implement',
    tanks: 'Tanks',
    tank: 'Tank',
  },
  OfferBar: {
    buy: 'Buy',
    sendPurpose: 'Make an offer',
    buyLimit: 'Monthly deductible limit exceeded',
    buyLimitText1: 'As a security measure, we adopt a monthly shopping franchise, according to the profile of each user.',
    buyLimitText2: 'If you want to continue shopping, click the button below.',
    bidLimit: 'Bid declined',
    bidLimitText1: 'Your last bid was a few minutes ago. Next bid will be available in a few moments.',
    buyLimitButton: 'OK',
    doBid: 'Bid',
    sendBid: 'Bid',
    autoBid: 'Automatic bidding',
    autoBidBar: 'Set up automatic bidding',
    actualBid: 'Current bid',
    countdown: 'Time remaining',
    backDefaultBuy: 'Go to standard purchase',
    vehicleUnit: '{count} units',
    color: 'Color',
    fuel: 'Fuel',
    testPage: 'Test page',
    dialogPurposeText: 'You can suggest a value proposal for this asset by sending it to the seller through the field below.',
    dialogPurposeValue: 'Proposal value',
    dialogBidInfo: {
      title: 'How does the current bid work?',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet ultrices tellus. Nunc ornare eleifend tortor, nec consectetur leo porttitor eget. Curabitur pretium turpis sed tortor aliquam, id efficitur lacus vehicula. Vestibulum placerat erat ligula. Curabitur fermentum, nisl malesuada sodales egestas, nunc odio dignissim quam, ac placerat nisljust et nunc. Donec tellus sapien, scelerisque tempus ligula sit amet, sagittis mattis arcu. Fusce nec felis in neque venenatis porta. Nulla facilisi. ',
    },
    dialogAutoBIdText01: 'Enter the maximum amount you are willing to pay for the asset.',
    dialogAutoBIdText02: 'The system will cover all bids made, increasing the current bid amount by  R$ {count},00 automatically.',
    dialogAutoBIdText03: 'If the maximum amount is reached, the system will stop making automatic bids.',
    dialogAutoBidMaxValue: 'Maximum bid amount',
    isMyBid: 'Current bid made by me'
  },
  Auth: {
    informMail: 'Enter your email to receive a temporary password.',
    login: 'Login',
    register: 'Register',
    notMember: 'Not a member?',
    signup: 'Register',
    isMember: 'Already a member?',
    enterHere: 'Enter here',
    userOrMail: 'User / Email',
    password: 'Password',
    changePassword: 'Change password',
    forgotPass: 'I forgot my password',
    loginEnter: 'Enter',
    loginGoogle: 'Sign in with Google',
    loginFacebook: 'Sign in with Facebook',
    loginSuccess: 'Login successful, welcome',
    registerSuccess: 'Your registration was successful! Now select your interests for the best experience! ',
    requiredFieldsLogin: 'Fill in the fields to login.',
    requiredFieldsRegister: 'Fill in the fields to register.',
    createNewPass: 'Create a new password',
    createPass: 'Create password',
    newPass: 'New Password',
    passConfirmation: 'Confirmation',
    repeatPass: 'Repeat password',
    requiredFieldsPass: 'Fill in the fields to create a new password.',
    requiredSamePass: 'Passwords must be the same.',
    userNotFound: 'User not found.',
    requiredASmallCase: 'Password must contain at least one lowercase character',
    requiredUppercase: 'Password must contain at least one uppercase character',
    requiredNumber: 'Password must contain at least one numeric character',
    requiredSpecialChar: 'Password must contain at least one special character',
    requiredMinChar: 'Password must contain at least 8 characters',
    agree: 'I agree with all rules, including those',
    registerGoogle: 'Register with Google',
    registerFacebook: 'Register with Facebook',
    failIsValid: 'Fill in the fields to register.',
    failTestCpf: '{doc} doesn\'t just contain numbers!',
    failTamanhoCpf: 'CPF must have 11 characters.',
    failTamanhoCnpj: 'CNPJ must have 14 characters.',
    failTestTel: 'Phone doesn\'t just contain numbers!',
    failIsEmailValid: 'E-Mail Invalid!',
    failTestMatricula: 'Registration is mandatory!',
    failTestDivisaoEmpresa: 'Division/Company is mandatory!',
    failTestCargo: 'Role is mandatory!',
    failTestDepartamento: 'Departament is mandatory!',
    failTestLoginFunc: 'Network Login is mandatory!',
    failSpaceInUsername: 'Username space is not allowed.',
    emailSuccess: 'E-mail successfully sent!',
    passwordSuccess: 'Password created successfully!',
    passwordRequirements: 'Password requirements',
    min8characters: 'At least 8 characters',
    atLeast1Low: 'At least 1 lowercase character',
    atLeast1Upp: 'At least 1 uppercase character',
    atLeast1Num: 'At least 1 numeric character',
    atLeast1Esp: 'At least 1 special character',
    receiveMail: 'I want to receive emails from Vix Seminovos with promotions.',
  },
  interests: {
    interests:'Interests',
    checkInterests: 'Check your interests below for a personalized experience.',
  },
  favorites: {
    favorites: 'Favorites',
    noFavorite: 'No Favorite',
    haventAdd: 'You haven\'t added any favorites yet.',
  },
  finalization: {
    bidPlaced: 'Your bid has been placed and at the end of the bid time you will receive an email with the result.',
    purposeSubmitted: 'Your proposal has been submitted and will be submitted for review.',
    purchaseReview: 'Your purchase was for review and we will contact you shortly for guidance.',
    followProcess: 'You can follow the process in',
    foward: 'All Right!',
    toBack: 'Back to start'
  },
  profile: {
    profile: 'Profile',
  },
  negotiation: {
    timeLeft: 'Time Remaining',
    bid: 'Bid',
    doBid: 'New bid',
    overcome: 'Beaten bid',
    cancel: 'Canceled',
    cancelAnalyze: 'Cancellation under review',
    msgSolCancel: 'Withdrawal request sent. \r\n Follow the progress of the request by email.',
    msgCancel: 'Your purchase has been canceled.',
    msgErrorCancel: 'Failed to request cancellation.',
    msgPurpouseCancel: 'Your proposal has been canceled.',
    cancelReject: 'Cancellation Rejected (Awaiting Withdrawal)',
    cancelRejectCard: 'Cancellation Rejected - Awaiting Withdrawal',
    cancelRejectBuy: 'Cancellation Rejected (Purchase Completed)',
    cancelRejectCardBuy: 'Cancellation Rejected - Purchase Completed',
    reject: 'rejected',
    cancell: 'canceled',
    withdrawal: 'withdrawal',
    cancelBuy: 'Cancel Proposal/Purchase',
    bidReject: 'Proposal Rejected',
    bidCancel: 'Bid Canceled',
    bidWithdrawal: 'Withdrawal Proposal',
    negotiation: 'Negotiation',
    waitingContact: 'Awaiting Contact',
    waitingLiberation: 'Awaiting Credit Release',
    waitingWithdrawal: 'Awaiting Withdrawal',
    checkout: 'Purchase Completed',
    missedOffer: 'Lost Offer',
    purpose: 'Purpose',
    seeBids: 'See Bids',
    bidOf: 'Bid',
    done: 'done',
    offerDone: 'Offer Done',
    bidExc: 'Your bid has been exceeded',
    negotiationsNotFound: {
      title: 'No negotiation',
      text: `You have not yet negotiated.`,
    },
    myPurchaseNotFound: {
      title: 'No purchase',
      text: `You have not yet purchased any assets.`,
    },
  },
  footerBar: {
    vehicles: 'Vehicles',
    clientes: 'Customers',
    lojistas: 'Tenants',
    empresa: 'Company',
    colaborador: 'Collaborator',
    pessoaFisica: 'Individual',
    sobreVIX: 'About VIX',
    VIXLogistica: 'VIX Logística',
    VIXSeminovos: 'VIX Seminovos',
    trabalheConosco: 'Work with us',
    contato: 'Contact',
    privacidade: 'Terms and Conditions of Use',
    politicaVendas: 'Sales Policy',
    avisosLegais: 'Legal Notices',
    mapaSite: 'Site Map',
    textFooter: 'Open from 7:30 am to 5:30 pm - Rodovia Governador Mário Covas, 2817 - Planalto de Carapina, Serra - ES, 29163-274',
    rights: 'All rights reserved',
    cnpj: '32.681.371/0010-63',
    contactSeller: 'Talk to a seller',
    portalPrivacidade: 'Privacy Portal',
    politicaPrivacidade: 'Privacy Policy',  
    politicaPrivacidadeParceiro: 'Partner Privacy Policy'
  },


  flux: {
    address: 'Address',
    addAddress: 'Add Address',
    zipCode: 'Zip Code',
    number: 'Number',
    complement: 'Complement',
    neighborhood: 'Neighborhood',
    city: 'City',
    state: 'State',
    add: 'Add',
    buyLimit: '* The purchase of a certain number of assets per month is allowed, according to the user\'s profile. If this deductible is exceeded, a release request will be required. In this case, instructions will be given at the time of purchase.',
    zipCodeNumber: 'Zip Code doesn\'t just contain numbers!',
    chooseAddress: 'Need to choose an address!',
    buyActive: 'Purchase Asset',
    negotiationType: 'Type of Negotiation',
    automaticBid: 'Automatic Bidding',
    buy: 'Buy',
    yourOffer: 'Your Offer',
    maxBid: 'Maximum bid amount',
    bidAmout: 'Bid amount',
    amount: 'Total amount',
    registration: 'Registration data',
    revision: 'Review',
    offerEnded: 'The offer has already been finalized!',
    offerMaxBid: 'The offer has already received a higher bid!',
    needLogin: 'You need to be logged in! Or create a register if you don\'t have one! ',
    offerMaxBidPurpose: 'The offer has already received a bid, it is not possible to bid!',
    data: 'Data',
    cancel: 'Cancel',
    simple: 'Store Pickup',
    subSimple: 'Pick up your vehicle at our store',
    deliveryAddress: 'Delivery Address',
    subDeliveryAddress: 'The vehicle will be delivered to a preferred address',
    freight: 'Freight',
  },
  global: {
    sendButton: 'Send',
    needLogin: 'You must be logged in or request a registration, if you don\'t have one!',
    invalidOffer: 'Offer has already been completed!',
    goToHome: 'Go home',
    successSendMail: 'E-mail sent successfully!',
    successPassCreated: 'Password created successfully!',
    firstName: 'First name',
    lastName: 'Last name',
    firstNameJuri: 'Responsible Name',
    lastNameJuri: 'Responsible Surname',
    mail: 'Email',
    phone: 'Phone',
    clientType: 'Customer type',
    username: 'Username',
    fantasyName: 'Fantasy Name',
    stateRegistration: 'State Registration',
    exempt: 'Exempt',
    description: 'Description',
    registration: 'Registration',
    companyDivision: 'Division/Company',
    role: 'Role',
    department: 'Department',
    networkLogin: 'Network Login',
    account: 'Has Network Login?',
    serviceTerm: 'terms of service',
    needMail: 'Fill in the email.',
    mailNotFound: 'Email not found.',
    fail: 'Failed to register.',
    or: 'or',
    required: 'Required.',
    maxChar: 'Max {count} characters',
    minChar: 'Min {count} characters',
    loginFail: 'Failed to login.',
    registerFail: 'Failed to login.',
    incorrectData: 'Incorrect Data',
    invalidResult: 'Invalid result',
    register: 'Register',
    continue: 'Continue',
    contactsVIX: 'VIX Seminovos Contacts',
    linkedGoogle: 'Linked to Google',
    linkedFacebook: 'Linked to Facebook',
    actualPwd: 'Actual Password',
    save: 'Save',
    dataSaved: 'Data saved successfully!',
    fillCorrectly: 'Fill in the fields correctly.',
    numbersPhone: 'Phone doesn\'t just contain numbers!',
    invalidNewPwd: 'New password invalid',
    passwordMatch: 'The new password and confirmation do not match.',
    samePassword: 'The new password cannot be the same as the previous one.',
    saveFail: 'Falha ao salvar.',
    unlimited: 'Unlimited',
    birthDate: 'Birth Date',
    genre: 'Genre',
    chooseGenre: 'Choose a genre',
    male: 'Male',
    female: 'Female',
    mothersName: 'Mother\'s full name',
    fillFields:'Preencha os campos restantes.',
    anotherValue: 'Another Value',
    units: 'Units',
    editAddress: 'Edit Address',
    delete: 'Delete',
    mother: 'Mother',
    today: 'Today',
    yesterday: 'Yesterday',
    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    lot: 'Lot',
    similarVechicle: 'Similar Vehicles',
    bad: 'Bad',
    regular: 'Regular',
    good: 'Good',
    color: 'Color',
    finalPlaca: 'License Plate',
    yearModel: 'Year / Model',
    gas: 'Fuel',
    year: 'Year',
    findBy: 'Find By {id}',
    vixAddress: 'Rod. Gov. Mário Covas, s/n - Planalto de Carapina, Serra - ES, 29161-160',
    details: 'Detalhes',
    previous: 'Previous'
  },
  orderBy: {
    title: 'Sort by',
    MAIS_RECENTES: 'Most recent',
    MAIS_ANTIGOS: 'Older',
    MAIOR_PRECO: 'Higher price',
    MENOR_PRECO: 'Lowest price', 
  },
  reseller:{
    contact: 'Contact a reseller',
    dialogContactInfo: {
      title: 'Contact details',
      text: 'This information will be used to contact you',
      userName: 'Name',
      userNamePlaceholder: 'Type your name',
      userPhone: 'phone',
      userPhonePlaceholder: 'Type your phone',
      userEmail: 'Email',
      userEmailPlaceholder: 'Type your e-mail' ,
      next: 'Next'   
    },
    dialogSuccesInfo: {
      title: 'Request has been successfully sent!',
      text: 'We have received your contact request and one of our \n specialists will contact you shortly. \n \n In the next few minutes you will receive an email with instructions for the next steps.  \n \n If you have any questions, you can also use one of the options below.',
      contact: 'Contact',
      ok: 'OK'   
    }
  }
}


