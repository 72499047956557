import { render, staticRenderFns } from "./RegisterFrame.vue?vue&type=template&id=8100ef38&scoped=true"
import script from "./RegisterFrame.vue?vue&type=script&lang=js"
export * from "./RegisterFrame.vue?vue&type=script&lang=js"
import style0 from "./RegisterFrame.vue?vue&type=style&index=0&id=8100ef38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8100ef38",
  null
  
)

export default component.exports