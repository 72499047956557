<template>
  <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="ic_facebook"
        transform="translate(17185 -5494)"
    >
      <g
          data-name="Retângulo 946"
          fill="#fff"
          id="Retângulo_946"
          opacity="0"
          stroke="#707070"
          stroke-width="1"
          transform="translate(-17185 5494)"
      >
        <rect
            height="24"
            stroke="none"
            width="24"
        />
        <rect
            fill="none"
            height="23"
            width="23"
            x="0.5"
            y="0.5"
        />
      </g>
      <g
          data-name="Grupo 2106"
          id="Grupo_2106"
          transform="translate(-17274 5404)"
      >
        <g
            data-name="Grupo 2105"
            id="Grupo_2105"
            transform="translate(92 93)"
        >
          <path
              d="M108.182,93H94.52A2.52,2.52,0,0,0,92,95.52v13.662a2.52,2.52,0,0,0,2.52,2.52h6.738l.012-6.683H99.533a.409.409,0,0,1-.41-.408l-.008-2.154a.41.41,0,0,1,.41-.411h1.733V99.964a3.4,3.4,0,0,1,3.63-3.731h1.768a.41.41,0,0,1,.41.41v1.816a.41.41,0,0,1-.409.41h-1.085c-1.172,0-1.4.557-1.4,1.374v1.8h2.575a.409.409,0,0,1,.407.458l-.255,2.154a.41.41,0,0,1-.407.361h-2.308l-.012,6.683h4.009a2.52,2.52,0,0,0,2.52-2.52V95.52a2.52,2.52,0,0,0-2.52-2.52Zm0,0"
              data-name="Caminho 8988"
              fill="#355da8"
              id="Caminho_8988"
              transform="translate(-92 -93)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'FacebookIcon'
}
</script>