<template>
  <div
      class="interests-container"
  >
    <div class="interests-header">
      <div class="interests-title">{{ $t('interests.interests') }}</div>
      <div class="interests-subtitle">
        {{ $t('interests.checkInterests') }}
      </div>
    </div>

    <v-form
        ref="form"
    >
      <div
          class="interests-category-box"
      >
        <div
            :key="i"
            @click="select(interest)"
            class="interests-category-button align-content-center"
            v-for="(interest, i) in interests"
        >
          <div
              :class="{'selected': isSelected(interest)}"
              class="interests-category-button-circle align-content-center"
          >
            <div
                v-html="forceSize(interest.iconSvgAtivo)"
                v-if="isSelected(interest)"
            />
            <div
                v-else
                v-html="forceSize(interest.iconSvgInativo)"
            />
          </div>
          <div class="interests-category-button-text">
            {{ interest.name }}
          </div>
        </div>
      </div>

      <div
          @click="saveInterests"
          class="interests-button align-content-center"
          style="margin-left: auto; margin-right: auto;"
          v-if="showButton"
      >
        <span>{{ $t('global.continue') }}</span>
      </div>

    </v-form>
  </div>
</template>

<script>
import { HeaderResource, InterestsResource } from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'Interests',
  props: {
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      interests: [],
      selected: []
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState('authStore', [
      'interestToken'
    ]),
  },
  methods: {
    async init () {
      const responseCategories = await HeaderResource.getList()
      this.interests = responseCategories.body

      const responseInterests = await InterestsResource.getList()
      if (responseInterests.body) {
        this.selected = responseInterests.body.map(v => v.codigoCategoria)
      }
    },
    select (interest) {
      if (!interest) { return }
      if (this.selected.indexOf(interest.codigo) >= 0) {
        this.selected.splice(this.selected.indexOf(interest.codigo), 1)
      } else {
        this.selected.push(interest.codigo)
      }
    },
    isSelected (interest) {
      if (!interest) { return false }
      return this.selected.indexOf(interest.codigo) >= 0
    },
    async saveInterests () {
      if (this.interestToken) {
        await InterestsResource.postInterests({
          tokenUsrio: this.interestToken,
          codigoCategoria: this.selected
        })
      } else {
        await InterestsResource.putInterests({
          codigoCategoria: this.selected
        })
      }

      this.$emit('save', true)
    },
    forceSize (svg) {
      if (!svg) return ''
      const clean = svg
          .replace(/width=["']\d*\.?\d*["']/, '')
          .replace(/height=["']\d*\.?\d*["']/, '')
          .replace(/[\n\r\t]/g, '')
      const rgx = /<svg.*>/

      try {
        return clean.replace(rgx, rgx.exec(clean)[0].replace('>', 'width="36" height="36">'))
      } catch (e) {
        return clean
      }
    },
  }
}
</script>

<style
    scoped
    type="text/css"
>

.interests-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 16px;
}

.interests-header {
  position: relative;
  margin-bottom: 50px;
}

.interests-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 212px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 78px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.interests-button > span {
  display: block;
  line-height: 1;
}

.interests-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
}

.interests-category-button {
  width: 80px;
  height: 92px;
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
}

.interests-category-button-circle {
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
  box-shadow: -2px -2px 2px #FFFFFF, 2px 2px 2px #A3B1C6;
  background: #EBF0F6 0 0 no-repeat padding-box;
  border-radius: 100%;
}

.interests-category-button-circle.selected {
  background: #60BED4 0 0 no-repeat padding-box;
  box-shadow: 2px 2px 2px #FFFFFF, -2px -2px 2px #A3B1C6;
}

.interests-category-button-text {
  text-align: center;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: none;
}

.interests-category-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 87px;
  justify-content: center;
  /*justify-content: space-evenly;*/
  column-gap: 30px;
}

.interests-container {
  max-width: 500px;
}

@media screen and (max-width: 601px) {
  .interests-container {
    max-width: 100%;
  }
}

</style>
