<template>
  <div>
    <router-view :key="$route.path"/>
  </div>
</template>

<script>
export default {
  name: 'OfferRouterView'
}
</script>

<style scoped>

</style>