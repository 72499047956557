<template>
  
<svg xmlns="http://www.w3.org/2000/svg" width="45" :height="height" viewBox="0 0 45 44.422">
  <g id="Grupo_580" data-name="Grupo 580" transform="translate(0.305 0.109)">
    <g id="VIX_Seminovos-12" data-name="VIX Seminovos-12">
      <g id="Retângulo_378" data-name="Retângulo 378" opacity="0">
        <rect id="Retângulo_436" data-name="Retângulo 436" width="45" height="44" transform="translate(-0.305 -0.109)"/>
        <rect id="Retângulo_437" data-name="Retângulo 437" width="43.259" height="43.259" transform="translate(0.555 0.555)" stroke="#707070" stroke-width="1"/>
      </g>
    </g>
    <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,28.777a2.978,2.978,0,0,0,2.977,2.977H24.808a2.978,2.978,0,0,0,2.977-2.977V11.908H0ZM19.847,16.621a.746.746,0,0,1,.744-.744h2.481a.746.746,0,0,1,.744.744V19.1a.746.746,0,0,1-.744.744H20.591a.746.746,0,0,1-.744-.744Zm0,7.939a.746.746,0,0,1,.744-.744h2.481a.746.746,0,0,1,.744.744v2.481a.746.746,0,0,1-.744.744H20.591a.746.746,0,0,1-.744-.744Zm-7.939-7.939a.746.746,0,0,1,.744-.744h2.481a.746.746,0,0,1,.744.744V19.1a.746.746,0,0,1-.744.744H12.652a.746.746,0,0,1-.744-.744Zm0,7.939a.746.746,0,0,1,.744-.744h2.481a.746.746,0,0,1,.744.744v2.481a.746.746,0,0,1-.744.744H12.652a.746.746,0,0,1-.744-.744ZM3.969,16.621a.746.746,0,0,1,.744-.744H7.194a.746.746,0,0,1,.744.744V19.1a.746.746,0,0,1-.744.744H4.714a.746.746,0,0,1-.744-.744Zm0,7.939a.746.746,0,0,1,.744-.744H7.194a.746.746,0,0,1,.744.744v2.481a.746.746,0,0,1-.744.744H4.714a.746.746,0,0,1-.744-.744ZM24.808,3.969H21.831V.992A1,1,0,0,0,20.839,0H18.854a1,1,0,0,0-.992.992V3.969H9.923V.992A1,1,0,0,0,8.931,0H6.946a1,1,0,0,0-.992.992V3.969H2.977A2.978,2.978,0,0,0,0,6.946V9.923H27.785V6.946A2.978,2.978,0,0,0,24.808,3.969Z" transform="translate(7.764 6.655)" fill="#a7a7a7"/>
  </g>
</svg>

</template>
<script>
export default {
  name: 'YearModelIcon',
  props: {
    color: {
      type: String,
      default: '#4caf50'
    },
    height: {
      type: String,
      default: '42'
    },
    width: {
      type: String,
      default: '42'
    }
  },
}
</script>
<style scoped>
</style>