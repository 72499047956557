<template>
  <div class="flux-personal-page">
    <div class="flux-resume-title">{{ $t('flux.revision') }}</div>
    <div
        class="flux-resume"
    >
      <div class="flux-resume-column">
        <div class="flux-resume-item-title">{{ $t('flux.registration') }}</div>
        <div class="flux-resume-item-name">{{ user.nome + "&nbsp;" + user.sobrenome }}</div>
        <div
            class="flux-resume-item-info"
        >
          {{ user.tipoPerfilFlag === 'J' ? 'CNPJ' : 'CPF' }}: {{ user.cpf }}
        </div>
        <div
            v-if="user.tipoPerfilFlag !== 'J'"
            class="flux-resume-item-info"
        >
          {{ $t('global.birthDate') }}: {{ user.nascimento }}
        </div>
        <div
            v-if="user.tipoPerfilFlag === 'J'"
            class="flux-resume-item-info"
        >
          {{ $t('global.fantasyName') }}: {{ user.nomeFantasia }}
        </div>
        <div
            v-if="user.tipoPerfilFlag === 'J'"
            class="flux-resume-item-info"
            style="margin-bottom: 30px;"
        >
          {{ $t('global.stateRegistration') }}: {{ user.isento ? this.$t('global.exempt') : user.inscricaoSocial }}
        </div>
        <div
            v-if="user.tipoPerfilFlag === 'C'"
            class="flux-resume-item-info"
            style="margin-bottom: 30px;"
        >
          {{ $t('global.registration') }}: {{ user.matricula }}
        </div>
        <div class="flux-resume-item-info">{{ user.email }}</div>
        <div class="flux-resume-item-info">{{ user.telefone }}</div>
      </div>

      <div class="flux-resume-column">
        <div class="flux-resume-item-title">{{ $t('flux.address') }}</div>
        <AddressCard
            :address="selectedAddress"
            :showActionDel="false"
            style="cursor:default;"
            @edit="edit(selectedAddress)"
        />
      </div>

    </div>

    <!-- Dialogo de informação -->
    <v-dialog
        v-model="dialogInfo"
        persistent
        width="fit-content"
    >
      <v-card>
        <v-card-text style="padding: 24px;">
          <div class="dialog-info-header">
            <div class="dialog-info-title"></div>
            <div
                class="dialog-info-close"
                @click="dialogInfo = false; reload();"
            >
              <XMarkIcon/>
            </div>
          </div>

          <div class="flux-address-form">
            <label>
              <div class="flux-address-label">
                {{ $t('flux.zipCode') }}
              </div>
              <input
                  v-model="addressForm.cep"
                  class="flux-address-input medium"
                  maxlength="20"
                  name="cep"
                  required
                  type="text"
                  @focusout="getCepData"
              />
            </label>
            <label>
              <div class="flux-address-label">
                {{ $t('flux.number') }}
              </div>
              <input
                  v-model="addressForm.numeroEndereco"
                  class="flux-address-input small"
                  name="number"
                  required
                  type="text"
              />
            </label>
            <label>
              <div class="flux-address-label">
                {{ $t('flux.address') }}
              </div>
              <input
                  v-model="addressForm.endereco"
                  class="flux-address-input"
                  name="endereco"
                  required
                  type="text"
              />
            </label>
            <label>
              <div class="flux-address-label">
                {{ $t('flux.complement') }}
              </div>
              <input
                  v-model="addressForm.complemento"
                  class="flux-address-input"
                  name="observation"
                  required
                  type="text"
              />
            </label>
            <label>
              <div class="flux-address-label">
                {{ $t('flux.neighborhood') }}
              </div>
              <input
                  v-model="addressForm.bairro"
                  class="flux-address-input"
                  name="bairro"
                  required
                  type="text"
              />
            </label>

            <label>
              <div class="flux-address-label">
                {{ $t('flux.city') }}
              </div>
              <select
                  v-model="addressForm.cidade"
                  class="flux-address-input medium input-select"
                  name="descricaoCidade"
                  required
              >
                <option
                    v-for="optionCidade in optionsCidades"
                    :key="optionCidade.codigoCidade"
                    :value="optionCidade.codigoCidade"
                >
                  {{ optionCidade.cidade }}
                </option>
              </select>
            </label>

            <label>
              <div class="flux-address-label">
                {{ $t('flux.state') }}
              </div>
              <select
                  v-model="addressForm.estado"
                  class="flux-address-input small input-select"
                  name="name"
                  required
              >
                <option
                    v-for="option in options"
                    v-bind:key="option.codigoEstado"
                >
                  {{ option.codigoEstado }}
                </option>
              </select>
            </label>
          </div>

          <div class="flux-button">
            <div
                :class="{'disable':  !isValid}"
                class="flux-button-save align-content-center"
                @click="saveNewAddress"
            >
                <span>
                  {{ addressForm.codEnd ? $t('global.save') : $t('flux.add') }}
                </span>
            </div>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogo de informação ^^-->

    <v-dialog
        v-model="errorCep"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="error"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgErrorCep }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="errorCep=false"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

        <!-- dialogo de limite compra ^^-->
    <v-dialog
        v-model="dialogFake"
        width="fit-content"
    >
      <v-card>
        <v-card-text style="padding: 24px;">
          <div
              class="dialog-info-header"
              style="margin-bottom: 20px;"
          >
            <div class="dialog-info-title">{{ isBuy ? $t('OfferBar.buyLimit') : $t('OfferBar.bidLimit') }}</div>
            <div
                class="dialog-info-close"
                @click="dialogFake = false; isBid = false; isBuy = false;"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div class="dialog-bid">
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ isBuy ? $t('OfferBar.buyLimitText1') : $t('OfferBar.bidLimitText1') }}
            </div>
            <div
                class="dialog-info-text"
                style="margin-bottom: 30px;"
            >
              {{ isBuy ? $t('OfferBar.buyLimitText2') : '' }}
            </div>

            <div class="flux-button-fake">

              <div
                  class="offer-sidebar-button-buy align-content-center"
                  style="width: 92px;"
                  @click="goToOffer"
              >
                <span>{{ $t('OfferBar.buyLimitButton') }}</span>
              </div>
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialogo de limite compra ^^-->


    <v-dialog
        v-model="error"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="error"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgError }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="goToOffer"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>

  </div>
</template>
<script>
import AddressCard from '@/screen/Flux/AddressCard'
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import {mapMutations, mapState} from 'vuex'
import {FluxResource, OfferResource} from '@/api'
import CookieControl from '../../cookie-control'
import { checkoutEvents } from '../../analytics'

const cookieControl = new CookieControl()

export default {
  name: 'FluxResume',
  components: {AddressCard, XMarkIcon},
  computed: {
    ...mapState('fluxStore', ['selectedAddress', 'user', 'bid', 'fluxType', 'offer', 'offerPrice', 'freight','canNext', 'parceiro']),
    isValid() {
      if (this.freight !== '') {
        return !!(this.addressForm.cep &&
            this.addressForm.endereco &&
            this.addressForm.numeroEndereco &&
            this.addressForm.estado &&
            this.addressForm.cidade &&
            this.addressForm.bairro)
      } else if (this.freight === '') {
        return false
      }
      return true
    },
    addressText() {
      let text = ''
      if (this.selectedAddress) {
        text = `${this.selectedAddress.endereco} ${this.selectedAddress.numeroEndereco}, `
        text += this.selectedAddress.complemento ? `${this.selectedAddress.complemento}, ` : ''
        text += `${this.selectedAddress.bairro}, ${this.selectedAddress.descricaoCidade} - ${this.selectedAddress.estado} CEP ${this.selectedAddress.cep}`
      } //else {
        //text = `${this.vixAddress.endereco} ${this.vixAddress.numeroEndereco}, `
        //text += this.vixAddress.complemento ? `${this.vixAddress.complemento}, ` : ''
        //text += `${this.vixAddress.bairro}, ${this.vixAddress.descricaoCidade} - ${this.vixAddress.estado} CEP ${this.vixAddress.cep}`
      //}
      return text
    },
  },
  data() {
    return {
      msgError: '',
      error: false,
      dialogInfo: false,
      dialogFake: false,
      isBid: false,
      isBuy: false,
      addressForm: {},
      options: [],
      optionsCidades: [],
      msgErrorCep: '',
      errorCep: false,
      vixAddress: {
        bairro: "Jardim Limoeiro",
        cep: "29000000",
        cidade: 882,
        codEnd: "",
        complemento: "",
        descricaoCidade: "Vitória",
        endereco: "Rua Gonçalves Dias",
        estado: "ES",
        numeroEndereco: "95",
        principal: "",
      },
    }
  },
  watch: {
    'addressForm.estado': function (newValue) {
      this.combos(newValue)
    }
  },
  methods: {
    ...mapMutations('fluxStore', ['SET_SELECTED_ADDRESS','SET_CAN_NEXT']),
    async combos(estado) {
      let estados = await FluxResource.getEstados()
      this.options = estados.body
      let cidades = await FluxResource.getCidades(estado)
      this.optionsCidades = cidades.body
    },
    async reload() {
      const response = await FluxResource.getAddress()
      this.SET_SELECTED_ADDRESS(response.body[0])
    },
    async getCepData() {
      if (!this.addressForm.cep.onlyNumber()) return
      const response = await FluxResource.getCep(this.addressForm.cep.onlyNumber())
      this.addressForm.endereco = response.body.logradouro
      this.addressForm.complemento = response.body.complemento
      this.addressForm.bairro = response.body.bairro
      this.addressForm.cidade = response.body.localidade
      this.addressForm.estado = response.body.uf
      this.combos(this.addressForm.estado)
    },
    edit(address) {
      this.addressForm = address
      this.dialogInfo = true
    },
    validatenumber(valor) {
      var regex = /^[0-9\b]+$/
      if (!regex.test(valor)) {
        return true
      }
      return false
    },
    async saveNewAddress() {
      if (this.validatenumber(this.addressForm.cep)) {
        this.msgErrorCep = this.$t('flux.zipCodeNumber')
        this.errorCep = true

        return
      }
      if (this.addressForm.codEnd) {
        // edit
        await FluxResource.putAddress({
          'codEnd': this.addressForm.codEnd,
          'cep': this.addressForm.cep,
          'endereco': this.addressForm.endereco,
          'numeroEndereco': this.addressForm.numeroEndereco,
          'complemento': this.addressForm.complemento,
          'estado': this.addressForm.estado,
          'cidade': this.addressForm.cidade,
          'descricaoCidade': null,
          'bairro': this.addressForm.bairro,
          'principal': this.selectedAddress.codEnd == this.addressForm.codEnd ? 'S' : null
        })
      }
      this.dialogInfo = false
      await this.reload()
    },
    async save() {
      if (!this.isUserLogin()) return
      
      if(this.canNext) {
        this.SET_CAN_NEXT(false)
        switch (this.fluxType) {
          case 'PURPOSE':
            return await this.doPurpose()
          case 'BID':
            return await this.doBid()
          case 'AUTO_BID':
            return await this.doBid(true)
          default:
            return await this.buyNow()
        }
      }
    },
    async buyNow() {

      const payload = {
        cdofrta: this.offer.codigo,
        cdmoeda: 1,
        vllance: this.getOfferPrice(),
        frete: this.freight,
        endereco: this.addressText,
      }

      if (this.parceiro) {
        payload.parceiroId = this.parceiro.id
      }

      const response = await OfferResource.postBuyNow(payload)

      if (response.status === 406 || response.status === 404) {
        this.setError(this.$t('flux.offerEnded'))
        return 'erro'
      } else if (response.status === 401) {
        this.setError(this.$t('flux.needLogin'))
        return 'erro'
      } else if(response.status === 206) {
        this.dialogFake = true
        this.isBuy = true
        return 'erro'
      } else {
        await checkoutEvents.completed(this.offer, 'Compra', payload.vllance)
        return 'ok'
      }
    },
    getOfferPrice() {
      const valorCompreJa = this.offerPrice.valorCompreJa || 0
      return valorCompreJa.toFixed(2).string2int().centavos2real()
    },

    isUserLogin() {
      return cookieControl.isLogin()
    },
    async doBid(isMax) {

      const payload = {
        cdofrta: this.offer.codigo,
        cdmoeda: 1,
        vllance: this.bid.string2int().centavos2real(),
        ismaximo: !!isMax,
        frete: this.freight,
        endereco: this.addressText
      }

      if (this.parceiro) {
        payload.parceiroId = this.parceiro.id
      }

      const response = await OfferResource.postLance(payload)

      if (response.status === 406 || response.status === 404) {
        this.setError(this.$t('flux.offerEnded'))
        return 'erro'
      } else if (response.status === 409) {
        this.setError(this.$t('flux.offerMaxBid'))
        return 'erro'
      } else if (response.status === 401) {
        this.setError(this.$t('flux.needLogin'))
        return 'erro'
      } else if(response.status === 206) {
        this.dialogFake = true
        this.isBid = true
        return 'erro'
      } else {
        checkoutEvents.completed(this.offer, isMax ? 'Lance Automático' : 'Lance Manual', payload.vllance)
        return 'ok'
      }
    },
    async doPurpose(isMax) {

      const payload = {
        cdusrio: this.user.codigoUsuario,
        cdofrta: this.offer.codigo,
        cdmoeda: 1,
        vllance: this.bid.string2int().centavos2real(),
        frete: this.freight,
        endereco: this.addressText,
      }

      if (this.parceiro) {
        payload.parceiroId = this.parceiro.id
      }

      const response = await OfferResource.postPurpose(payload)

      if (response.status === 406 || response.status === 404) {
        this.setError(this.$t('flux.offerEnded'))
        return 'erro'
      } else if (response.status === 409) {
        this.setError(this.$t('flux.offerMaxBidPurpose'))
        return 'erro'
      } else if (response.status === 401) {
        this.setError(this.$t('flux.needLogin'))
        return 'erro'
      } else if(response.status === 206) {
        this.dialogFake = true
        this.isBid = true
        return 'erro'
      } else {
        checkoutEvents.completed(this.offer, 'Proposta', payload.vllance)
        return 'ok'
      }
    },
    setError(msg) {
      this.msgError = msg
      this.error = true
    },
    goToOffer() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/oferta/' + this.offer.codigo
      }).catch(() => {
      })
    }
  },
}
</script>
<style
    scoped
    type="text/css"
>
label {
  width: fit-content;
}

.flux-personal-page {
  width: 100%;
}

.flux-resume-title {
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 52px;
}

.flux-resume {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.flux-resume-column {
  min-width: 406px;
  max-width: 406px;
}

.flux-resume-item-title {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 30px;
  width: 100%;
}

.flux-resume-item-name {
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 30px;
  width: 100%;
}

.flux-resume-item-info {
  text-align: left;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 10px;
  width: 100%;
}

.flux-address-header {
  display: flex;
  flex-flow: row;
}

.flux-address-title {
  flex: 1;
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 52px;
}

.flux-address-header > .flux-address-add-link {
  flex: 0;
  margin-top: 4px;
  cursor: pointer;
}

.flux-address-add-link {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #27BADC;
  white-space: nowrap;
  cursor: pointer;
}

.flux-address-content {
  flex: 1;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  min-width: 100%;
  max-width: 100%;
  min-height: fit-content;
  max-height: 497px;
  justify-content: space-between;
  align-items: center;
}

.flux-address-content.center {
  flex: 0;
  justify-content: center;
}

.flux-address-card {
  margin-bottom: 24px;
  flex: 0;
  padding: 20px 16px;
  border-radius: 10px;
  min-width: 406px;
  max-width: 406px;
  min-height: 127px;
  max-height: 127px;
  background: #F5F5F7 0 0 no-repeat padding-box;
}


.flux-address-card-content {
  min-width: 90px;
  min-height: 90px;
}

.dialog-info-header {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: nowrap;
}

.dialog-info-title {
  text-align: left;
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  flex: 1;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
}

.dialog-info-text {
  width: 496px;
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #2D2D2D;
}

.dialog-bid {
  width: 496px;
}

.flux-button-fake {
  flex: 0;
  min-width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
}

.offer-sidebar-button-buy {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 140px;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
}

.offer-sidebar-button-buy > span {
  display: block;
  line-height: 1;
}

.flux-address-label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
}

.flux-address-input {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 28px;
  width: 406px;
  padding-left: 11px;
  margin-bottom: 20px;
  flex: 0;
}

.flux-address-input.medium {
  width: 232px;
}

.flux-address-input.small {
  width: 149px;
}

.flux-button-save {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 140px;
  flex: 0;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flux-button-save > span {
  display: block;
  line-height: 1;
}

/*noinspection ALL*/
.flux-button-save.disable {
  color: #2d2d2d !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
  cursor: default;
}

.flux-address-form {
  width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.input-select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}

@media screen and (max-width: 1279px) {
  /*noinspection CssUnusedSymbol*/
  .has-error {
    display: none;
  }

  
  .flux-personal-page {
    width: 100%;
    min-height: 650px;
  }

  .flux-resume {
    flex-flow: row wrap;
  }

  .flux-resume-column {
    min-width: 290px;
    max-width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    margin-bottom: 30px;
  }

  .flux-resume-title {
    margin-bottom: 30px;
  }

  .flux-address-card {
    max-width: 100%;
    min-width: 100%;
  }

  .flux-address-content {
    height: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .flux-address-box {
    min-height: 497px;
    width: 100%;
    height: 100%;
  }

  .flux-address-card-md-down {
    width: 100%;
  }

  .flux-address-title {
    margin-bottom: 10px;
  }

  .flux-personal-page {
    min-width: 406px;
    max-width: 406px;
  }
}


@media screen and (max-width: 720px) {
  .flux-personal-page {
    max-width: 100%;
    min-width: 100%;
  }
  .flux-personal {
    width: 100%;
    min-height: auto;
  }
}

@media screen and (max-width: 601px) {
  label,
  .flux-address-input,
  .flux-address-input.medium,
  .flux-address-input.small {
    width: 100%;
  }

  .flux-address-form {
    width: 100%;
  }

  .dialog-info-text {
    width: 100%;
  }

  .dialog-bid {
    width: 100%;
  }

}
</style>
