<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <div v-if="loaded">
    <OfferScreen
        :offer="offer"
        :offer-price="offerPrice"
        :photos="photos"
    />
<!--    <OfferPage :offer="offer" :offer-price="offerPrice" :photos="photos"/>-->
  </div>
</template>

<script>
import {OfferResource} from '@/api'
import OfferScreen from '@/screen/Offer/OfferScreen'
import {productEvents} from '@/analytics'

export default {
  name: 'OfferIndex',
  components: {OfferScreen},
  data() {
    return {
      id: null,
      offer: {},
      offerPrice: {
        codigoOferta: 0,
        codigoPerfil: 0,
        valorCompreJa: 0,
        valorLeilao: 0
      },
      photos: [],
      loaded: false
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },
  methods: {
    async init() {
      await this.getOffer()
      await this.getOfferPrice()
      await this.getOfferGallery()
      this.loaded = true
      await productEvents.detailsViewed(this.offer)
    },
    async getOfferGallery () {
      const response = await OfferResource.getGallery(this.id)
      this.photos = response.body.photos.concat(response.body.videos)
    },
    async getOffer() {
      const response = await OfferResource.getItem(this.id)
      this.offer = response.body
    },
    async getOfferPrice() {
      // if (this.timeoutId) clearTimeout(this.timeoutId)
      //
      const response = await OfferResource.getPrice(this.id)
      this.offerPrice = response.body || {
        codigoOferta: 0,
        codigoPerfil: 0,
        valorCompreJa: 0,
        valorLeilao: 0
      }
      // this.timeoutId = setTimeout(this.getOfferPrice, 1500)
    },
  },
}
</script>
