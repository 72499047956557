<template>
  <svg
      height="256"
      viewBox="0 0 256 256"
      width="256"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="plceholder_heart"
        transform="translate(-535 -327)"
    >
      <g
          id="Retângulo_1009"
          data-name="Retângulo 1009"
          fill="#fff"
          opacity="0"
          stroke="#707070"
          stroke-width="1"
          transform="translate(535 327)"
      >
        <rect
            height="256"
            stroke="none"
            width="256"
        />
        <rect
            fill="none"
            height="255"
            width="255"
            x="0.5"
            y="0.5"
        />
      </g>
      <circle
          id="Elipse_45"
          cx="92"
          cy="92"
          data-name="Elipse 45"
          fill="#e8f1fe"
          r="92"
          transform="translate(571.001 360)"
      />
      <path
          id="Caminho_9007"
          d="M516.718,268.853a53.971,53.971,0,0,0-16.786-38.039l-.016-.014c-20.933-20.335-55.018-21.67-77.593-3.04l-1.591,1.313-1.591-1.313a58.86,58.86,0,0,0-77.594,3.04c-.614.586-1.2,1.172-1.77,1.769a54.4,54.4,0,0,0,1.755,76.83l78.921,76.371L499.9,309.4c.615-.586,1.207-1.178,1.787-1.785A53.968,53.968,0,0,0,516.718,268.853Zm-20.949,36.276-75.307,72.483-74.78-72.494c-.568-.54-1.14-1.112-1.7-1.7a48.4,48.4,0,0,1,1.714-68.347,52.536,52.536,0,0,1,72.675,0l2.348,2.271,2.343-2.262a52.558,52.558,0,0,1,72.7-.009c.582.553,1.154,1.126,1.711,1.712A48.4,48.4,0,0,1,495.769,305.129Z"
          data-name="Caminho 9007"
          fill="#9fabbe"
          transform="translate(242.246 163.378)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'PlaceHolderHearthIcon'
}
</script>
<style scoped>

</style>
