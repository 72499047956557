const state = {
  answers: [],
}

const mutations = {
  ['ADD_ANSWER'] (state, payload) {
    state.answers.push(payload)
  },
  ['RESET_ANSWERS'] (state) {
    state.answers = []
  },
}

const actions = {}

const getters = {}

const surveyStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default surveyStore
