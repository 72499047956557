<template>
  <div>
    <!--    ativo -->
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" v-if="active">
  <g id="ic_entrega_on" transform="translate(-52 -223)">
    <rect id="Retângulo_1004" data-name="Retângulo 1004" width="42" height="42" rx="6" transform="translate(52 223)" fill="#fff"/>
    <g id="Grupo_2220" data-name="Grupo 2220" transform="translate(2893.138 190.688)">
      <path id="Caminho_9044" data-name="Caminho 9044" d="M-2833.45,57.195h16.382a.685.685,0,0,0,.688-.688V45.35a2.049,2.049,0,0,0-2.063-2.038h-13.631a2.05,2.05,0,0,0-2.064,2.038V56.507A.685.685,0,0,0-2833.45,57.195Z" fill="#60bed4"/>
      <path id="Caminho_9045" data-name="Caminho 9045" d="M-2831.361,57.882h-2.089a.685.685,0,0,0-.688.688v.917a1.365,1.365,0,0,0,1.376,1.35h.433a.12.12,0,0,0,.127-.127v-.458a3.552,3.552,0,0,1,.917-2.191C-2831.183,57.959-2831.208,57.882-2831.361,57.882Z" fill="#60bed4"/>
      <path id="Caminho_9046" data-name="Caminho 9046" d="M-2828.661,57.73a2.735,2.735,0,0,0-2.726,2.751,2.73,2.73,0,0,0,2.726,2.726,2.73,2.73,0,0,0,2.726-2.726A2.734,2.734,0,0,0-2828.661,57.73Zm0,3.8a1.049,1.049,0,0,1-1.044-1.044,1.033,1.033,0,0,1,1.044-1.045,1.034,1.034,0,0,1,1.045,1.045A1.049,1.049,0,0,1-2828.661,61.525Z" fill="#60bed4"/>
      <path id="Caminho_9047" data-name="Caminho 9047" d="M-2811.107,57.73a2.733,2.733,0,0,0-2.725,2.751,2.729,2.729,0,0,0,2.725,2.726,2.73,2.73,0,0,0,2.726-2.726A2.734,2.734,0,0,0-2811.107,57.73Zm0,3.8a1.049,1.049,0,0,1-1.045-1.044,1.034,1.034,0,0,1,1.045-1.045,1.034,1.034,0,0,1,1.045,1.045A1.049,1.049,0,0,1-2811.107,61.525Z" fill="#60bed4"/>
      <path id="Caminho_9048" data-name="Caminho 9048" d="M-2806.522,57.908V54.445a2.408,2.408,0,0,0-.408-1.223L-2810.776,48a1.516,1.516,0,0,0-1.1-.535h-2.445a1.364,1.364,0,0,0-1.375,1.35v8.66a.4.4,0,0,1-.408.408h-9.861c-.153,0-.178.076-.076.178a3.556,3.556,0,0,1,.917,2.191v.025a.5.5,0,0,1,.025.2.572.572,0,0,1-.025.2v.025a.12.12,0,0,0,.127.127h10.217a.12.12,0,0,0,.128-.127.69.69,0,0,1-.026-.229,3.577,3.577,0,0,1,3.567-3.567,3.577,3.577,0,0,1,3.566,3.567c0,.076-.025.152-.025.229a.12.12,0,0,0,.127.127,1.364,1.364,0,0,0,1.375-1.35V58.57A.724.724,0,0,0-2806.522,57.908Zm-2.827-4.3a.4.4,0,0,1-.408.408h-3.719a.4.4,0,0,1-.408-.408V49.681a.4.4,0,0,1,.408-.407h.968a.8.8,0,0,1,.637.331l2.293,3.057a1.393,1.393,0,0,1,.229.637Z" fill="#60bed4"/>
    </g>
  </g>
</svg>
    <!--    inativo -->
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" v-else>
  <g id="ic_entrega_off" transform="translate(-52 -223)">
    <rect id="Retângulo_1004" data-name="Retângulo 1004" width="42" height="42" rx="6" transform="translate(52 223)" fill="#fff" opacity="0"/>
    <g id="Grupo_2220" data-name="Grupo 2220" transform="translate(2893.138 190.688)">
      <path id="Caminho_9044" data-name="Caminho 9044" d="M-2833.45,57.195h16.382a.685.685,0,0,0,.688-.688V45.35a2.049,2.049,0,0,0-2.063-2.038h-13.631a2.05,2.05,0,0,0-2.064,2.038V56.507A.685.685,0,0,0-2833.45,57.195Z" fill="#60bed4"/>
      <path id="Caminho_9045" data-name="Caminho 9045" d="M-2831.361,57.882h-2.089a.685.685,0,0,0-.688.688v.917a1.365,1.365,0,0,0,1.376,1.35h.433a.12.12,0,0,0,.127-.127v-.458a3.552,3.552,0,0,1,.917-2.191C-2831.183,57.959-2831.208,57.882-2831.361,57.882Z" fill="#60bed4"/>
      <path id="Caminho_9046" data-name="Caminho 9046" d="M-2828.661,57.73a2.735,2.735,0,0,0-2.726,2.751,2.73,2.73,0,0,0,2.726,2.726,2.73,2.73,0,0,0,2.726-2.726A2.734,2.734,0,0,0-2828.661,57.73Zm0,3.8a1.049,1.049,0,0,1-1.044-1.044,1.033,1.033,0,0,1,1.044-1.045,1.034,1.034,0,0,1,1.045,1.045A1.049,1.049,0,0,1-2828.661,61.525Z" fill="#60bed4"/>
      <path id="Caminho_9047" data-name="Caminho 9047" d="M-2811.107,57.73a2.733,2.733,0,0,0-2.725,2.751,2.729,2.729,0,0,0,2.725,2.726,2.73,2.73,0,0,0,2.726-2.726A2.734,2.734,0,0,0-2811.107,57.73Zm0,3.8a1.049,1.049,0,0,1-1.045-1.044,1.034,1.034,0,0,1,1.045-1.045,1.034,1.034,0,0,1,1.045,1.045A1.049,1.049,0,0,1-2811.107,61.525Z" fill="#60bed4"/>
      <path id="Caminho_9048" data-name="Caminho 9048" d="M-2806.522,57.908V54.445a2.408,2.408,0,0,0-.408-1.223L-2810.776,48a1.516,1.516,0,0,0-1.1-.535h-2.445a1.364,1.364,0,0,0-1.375,1.35v8.66a.4.4,0,0,1-.408.408h-9.861c-.153,0-.178.076-.076.178a3.556,3.556,0,0,1,.917,2.191v.025a.5.5,0,0,1,.025.2.572.572,0,0,1-.025.2v.025a.12.12,0,0,0,.127.127h10.217a.12.12,0,0,0,.128-.127.69.69,0,0,1-.026-.229,3.577,3.577,0,0,1,3.567-3.567,3.577,3.577,0,0,1,3.566,3.567c0,.076-.025.152-.025.229a.12.12,0,0,0,.127.127,1.364,1.364,0,0,0,1.375-1.35V58.57A.724.724,0,0,0-2806.522,57.908Zm-2.827-4.3a.4.4,0,0,1-.408.408h-3.719a.4.4,0,0,1-.408-.408V49.681a.4.4,0,0,1,.408-.407h.968a.8.8,0,0,1,.637.331l2.293,3.057a1.393,1.393,0,0,1,.229.637Z" fill="#60bed4"/>
    </g>
  </g>
</svg>
  </div>
</template>
<script>
  export default {
    name: 'TruckIcon',
    props: {
      active: {
        type: Boolean,
        default: false
      },
    },
  }
</script>
<style scoped>

</style>