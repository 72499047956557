<template>
  <div>
    <router-view :key="$route.path"/>
  </div>
</template>

<script>
export default {
  name: 'AuthIndex',
}
</script>

<style type="text/css">

</style>