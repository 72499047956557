const BrandApi = (vueResource, url) => {
  const resource = vueResource(`${url}/brand{/PATH}{/ID}{?Q*}`);

  return {
    async listByIds(ids) {
      try {
        return await resource.get({ ids: ids.join(",") });
      } catch (e) {
        return { body: null };
      }
    },
  };
};

export default BrandApi;
