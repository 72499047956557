<template>

  <div
      class="header-locale"
      style="visibility: hidden"
  >
    <!--
    <div style="position:relative; width: 100%; height: 100%;">
      <div
          style="display:flex; align-items: center; height: 100%;"
          @click="showLocaleMenu = true"
      >
        <LocaleCard
            :locale="selectedLocale"
            :show-label="showLabel"
        />
      </div>

      <div
          v-if="showLocaleMenu"
          style="position: fixed; top: 0;bottom: 0;left: 0;right: 0; background: transparent; z-index: 2"
          @click="showLocaleMenu = false"
      />

      <div
          v-if="showLocaleMenu"
          class="header-language-dropdown"
      >
        <div
            class="header-language-dropdown-offline"
        >
          <div
              v-for="(locale, i) in locales"
              :key="i"
              :style="i >= locales.length-1 ? '': 'margin-bottom: 16px;'"
              @click="setLocale(locale)"
          >
            <LocaleCard
                :key="locale.sigla"
                :locale="locale"
            />
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
//import LocaleCard from '@/shared/LocaleMenu/LocaleCard'
import { HeaderResource } from '@/api'
import CookieControl from '@/cookie-control'

const cookieControl = new CookieControl()

export default {
  name: 'LocaleMenu',
  //components: { LocaleCard },
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      showLocaleMenu: false,
      locales: [],
      selectedLocale: {
        'sigla': 'PTBR',
        'descricao': 'Português',
        'svg': '<svg height="16" viewBox="0 0 15.593 16" width="16" xmlns="http://www.w3.org/2000/svg"><defs></defs><g class="a"><g transform="translate(0 0)"><g transform="translate(0 0)"><circle cx="8" cy="8" r="8" style="fill: #009c3b"/></g></g></g></svg>'
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const responseLocales = await HeaderResource.getIdiomList()
      this.locales = responseLocales.body
      this.getUserLocale()
    },
    setLocale (locale) {
      this.$i18n.locale = locale.sigla
      cookieControl.saveCookie(cookieControl.idiomCookie, locale.sigla)
      this.selectedLocale = locale
      this.showLocaleMenu = false
      this.$router.go({
        path: (this.$router.param.parceiro===undefined?"":"/"+this.$router.param.parceiro)+this.$router.history.current.fullPath
      }).catch((e) => {})
    },
    getUserLocale () {
      let sigla = cookieControl.idiom()
      this.$i18n.locale = sigla
      this.selectedLocale = this.locales.find(v => v.sigla === sigla)
    }
  },
}
</script>
<style scoped>

a {
  opacity: 0;
}


.header-user-dropdown > * {
  cursor: pointer;
}

.header-locale {
  display: inline-grid;
  margin-left: 135px;
}

.header-language-dropdown {
  width: fit-content;
  height: fit-content;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  padding: 10px 12px;
  z-index: 3;
  position: absolute;
  top: 10px;
  right: 0;
}
</style>
