<template>
  <svg
      height="16"
      inkscape:version="1.0 (4035a4fb49, 2020-05-01)"
      sodipodi:docname="scratch_1_1.svg"
      version="1.1"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      id="ic_email"
      xmlns:svg="http://www.w3.org/2000/svg"
  >
    <metadata
        id="metadata11"
    >
      <rdf:RDF>
        <cc:Work
            rdf:about=""
        >
          <dc:format>image/svg+xml</dc:format>
          <dc:type
              rdf:resource="http://purl.org/dc/dcmitype/StillImage"
          />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs
        id="defs9"
    />
    <sodipodi:namedview
        id="namedview7"
        bordercolor="#666666"
        borderopacity="1"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:current-layer="ic_email"
        inkscape:cx="3.2115732"
        inkscape:cy="8"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-height="1052"
        inkscape:window-maximized="1"
        inkscape:window-width="1920"
        inkscape:window-x="0"
        inkscape:window-y="31"
        inkscape:zoom="34.5625"
        objecttolerance="10"
        pagecolor="#ffffff"
        showgrid="false"
    />
    <path
        id="Caminho_7075"
        fill="#eeefe8"
        d="m 16,3.1829137 -4.181,3.779 L 16,12.428914 Z"
        data-name="Caminho 7075"
    />
    <path
        id="Caminho_7076"
        fill="#eeefe8"
        d="m 0.804,2.5399137 5.588,5.025 a 2.446,2.446 0 0 0 3.216,0 l 5.588,-5.025 z"
        data-name="Caminho 7076"
    />
    <path
        id="Caminho_7077"
        fill="#eeefe8"
        d="M 0,12.428914 4.181,6.9619137 0,3.1829137 Z"
        data-name="Caminho 7077"
    />
    <path
        id="Caminho_7078"
        d="m 15.478,13.434914 -4.422,-5.7890003 -0.764,0.683 a 3.411,3.411 0 0 1 -4.583,0 l -0.764,-0.683 -4.422,5.7890003 z"
        data-name="Caminho 7078"
        fill="#eeefe8"
    />
  </svg>

</template>
<script>
export default {
  name: 'MailIcon'
}
</script>
<style scoped>

</style>