<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42" height="42" x="0px" y="0px"
       viewBox="0 0 420.76 420.76" style="enable-background:new 0 0 420.76 420.76;" xml:space="preserve">
      <path fill="#c9cdd0" d="M210.38,0C94.37,0,0,94.38,0,210.38c0,2.2,0.03,4.38,0.1,6.56c0.68,22.08,4.78,43.32,11.78,63.2
        c28.84,81.83,106.93,140.62,198.5,140.62c91.59,0,169.7-58.83,198.51-140.69c7-19.88,11.1-41.11,11.77-63.19
        c0.07-2.16,0.1-4.32,0.1-6.5C420.76,94.38,326.38,0,210.38,0z M83.931,258.73c3.685-1.125,7.402-2.218,11.186-3.243
        c22.096-5.987,45.328-10.147,69.436-12.461l5.853,96.684C130.688,327.407,98.726,297.296,83.931,258.73z M212,242.98
        c-15.74,0-28.5-12.76-28.5-28.5c0-15.74,12.76-28.5,28.5-28.5s28.5,12.76,28.5,28.5C240.5,230.22,227.74,242.98,212,242.98z
         M250.495,339.666l5.85-96.637c24.055,2.312,47.245,6.461,69.31,12.432c3.784,1.022,7.501,2.114,11.186,3.237
        C322.07,297.237,290.158,327.333,250.495,339.666z M332.986,189.901c-38.779-9.556-80.008-14.401-122.542-14.401
        c-42.612,0-83.888,4.858-122.679,14.438c-3.916,0.966-7.784,1.995-11.629,3.054c0.093-0.72,0.17-1.444,0.275-2.161
        C85.91,125.41,142.37,75,210.38,75c68,0,124.45,50.39,133.96,115.79c0.105,0.716,0.183,1.441,0.277,2.161
        C340.771,191.893,336.902,190.865,332.986,189.901z"/>
  </svg>
</template>

<script>
export default {
  name: 'SteeringWheel',
  props: {
    size: {
      type: Number,
      default: 42
    },
  }
}
</script>

<style scoped>
</style>
