<template>
  <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="ic_google"
        transform="translate(17216 -5494)"
    >
      <g
          data-name="Retângulo 945"
          fill="#fff"
          id="Retângulo_945"
          opacity="0"
          stroke="#707070"
          stroke-width="1"
          transform="translate(-17216 5494)"
      >
        <rect
            height="24"
            stroke="none"
            width="24"
        />
        <rect
            fill="none"
            height="23"
            width="23"
            x="0.5"
            y="0.5"
        />
      </g>
      <g
          id="logo_google-01"
          transform="translate(-17213.58 5496.293)"
      >
        <path
            d="M20.06,5.157a8.089,8.089,0,0,1,1.551-2.232,9.322,9.322,0,0,1,12.856-.657c.14.12.175.191.023.34-.782.766-1.553,1.545-2.328,2.32-.079.079-.133.176-.274.047a5.365,5.365,0,0,0-7.212.161A5.955,5.955,0,0,0,23.127,7.5c-.048-.032-.1-.06-.145-.095Z"
            data-name="Caminho 8984"
            fill="#ea4335"
            fill-rule="evenodd"
            id="Caminho_8984"
            transform="translate(-19.055 0)"
        />
        <path
            d="M22.951,222.19a6.26,6.26,0,0,0,1.144,1.953,5.5,5.5,0,0,0,4.894,1.874,5.927,5.927,0,0,0,2.5-.873c.074.066.145.137.223.2q1.355,1.058,2.712,2.113a7.764,7.764,0,0,1-3.5,1.912,9.408,9.408,0,0,1-8.557-1.726,8.678,8.678,0,0,1-2.474-3.083Z"
            data-name="Caminho 8985"
            fill="#34a853"
            fill-rule="evenodd"
            id="Caminho_8985"
            transform="translate(-18.903 -211.036)"
        />
        <path
            d="M191.907,159.839q-1.356-1.056-2.712-2.113c-.078-.061-.149-.132-.223-.2a4.741,4.741,0,0,0,1.458-1.722,5.958,5.958,0,0,0,.317-.869c.061-.2.042-.284-.21-.282-1.505.013-3.01.007-4.515.007-.319,0-.319,0-.319-.33,0-1.02.005-2.04,0-3.06,0-.2.033-.273.254-.271q4.164.012,8.328,0c.15,0,.244.011.27.193a10.243,10.243,0,0,1-1.2,6.89A6.874,6.874,0,0,1,191.907,159.839Z"
            data-name="Caminho 8986"
            fill="#4285f4"
            fill-rule="evenodd"
            id="Caminho_8986"
            transform="translate(-176.381 -143.419)"
        />
        <path
            d="M4.048,108.687,1,111.056a8.454,8.454,0,0,1-.917-2.948,9.233,9.233,0,0,1,.782-5.154c.042-.09.094-.175.142-.263l2.921,2.249c.046.035.1.063.145.095A5.9,5.9,0,0,0,4.048,108.687Z"
            data-name="Caminho 8987"
            fill="#fbbc04"
            fill-rule="evenodd"
            id="Caminho_8987"
            transform="translate(0 -97.533)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'GoogleIcon'
}
</script>