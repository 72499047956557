<template>
  
<svg xmlns="http://www.w3.org/2000/svg" width="44.368" height="44.368" viewBox="0 0 44.368 44.368">
  <g id="Grupo_579" data-name="Grupo 579" transform="translate(0 0)">
    <g id="VIX_Seminovos-12" data-name="VIX Seminovos-12">
      <g id="Retângulo_378" data-name="Retângulo 378" transform="translate(0 0)" opacity="0">
        <rect id="Retângulo_436" data-name="Retângulo 436" width="44.368" height="44.368"/>
        <rect id="Retângulo_437" data-name="Retângulo 437" width="43.259" height="43.259" transform="translate(0.555 0.555)" stroke="#707070" stroke-width="1"/>
      </g>
    </g>
    <g id="Grupo_291" data-name="Grupo 291" transform="translate(7.765 7.764)">
      <circle id="Elipse_75" data-name="Elipse 75" cx="14.974" cy="14.974" r="14.974" transform="translate(0 0)" fill="none" stroke="#a7a7a7" stroke-width="2"/>
      <g id="Grupo_290" data-name="Grupo 290" transform="translate(8.568 7.732)">
        <line id="Linha_50" data-name="Linha 50" y1="15.669" transform="translate(0)" fill="none" stroke="#a7a7a7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Linha_51" data-name="Linha 51" y1="15.669" transform="translate(6.933)" fill="none" stroke="#a7a7a7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Caminho_40" data-name="Caminho 40" d="M1972.678-333.147v5.408a2.912,2.912,0,0,1-2.912,2.912h-10.955" transform="translate(-1958.812 333.147)" fill="none" stroke="#a7a7a7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </g>
  </g>
</svg>

</template>
<script>
export default {
  name: 'TransmissionIcon',
  props: {
    color: {
      type: String,
      default: '#4caf50'
    },
    height: {
      type: String,
      default: '42'
    },
    width: {
      type: String,
      default: '42'
    }
  },
}
</script>
<style scoped>
</style>