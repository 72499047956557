const state = {
  display: 'buy',
  favorite: false,
  vehicleSelectedId: 0,
  galleryInShow: false,
  scrollToQuestion: false,
  offersSpotlight: [],
}

const mutations = {
  ['SET_SCROLL_QUESTION'] (state, payload) {
    state.scrollToQuestion = payload
  },
  ['SET_OFFERS_SEM'] (state,payload) {
    state.offersSpotlight = payload
  },
  ['SET_OFFERS_SEM_FAV'] (state, payload) {
    const offer = state.offersSpotlight.find(v => v.codigo === payload.codigo)
    if (offer) offer.favorite = payload.favorite
  },
  ['SET_DISPLAY'] (state, payload) {
    state.display = payload
  },
  ['SET_VEHICLE_ID'] (state, payload) {
    state.vehicleSelectedId = payload
  },
  ['SET_GALLERY'] (state, payload) {
    state.galleryInShow = payload
  },
  ['SET_FAVORITE'] (state, payload) {
    state.favorite = payload
  },
}

const actions = {}

const getters = {}

const offerStore = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default offerStore
