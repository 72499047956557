import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import VueResource from '@/api'
import store from '@/store'
import router from '@/router'
import { VueMaskDirective } from 'v-mask'
import Vue2TouchEvents from 'vue2-touch-events'
import Vuelidate from 'vuelidate'

Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

Vue.use(Vue2TouchEvents)

Vue.use(Vuelidate)

new Vue({
  vuetify,
  i18n,
  VueResource,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

export const PARTNER_URL = window.PARTNER_URL
