<template>
  <div class="header">
    <!--            MENU DESLOGADO -->
    <div v-if="!user.codigoUsuario" 
     @click="goToLogin" >
      <!--            USER ICON WITH LOGIN REGISTER TEXT -->
      <div v-if="!isMobile" class="cursor-pointer align-content-center relative user-menu">
        <v-col cols="3">
         <UserIcon
          :on="!!user.codigoUsuario"
          class="cursor-pointer"
         />
        </v-col>
        <v-col  cols="9" class="header-user-dropdown-offline">
          <span style="font: 14px 'HelveticaNeueLTPro-Roman'; color: #666666;"> {{ $t('HeaderBar.visitorGreeting') }} </span>
          <span style="font: 14px 'HelveticaNeueLTPro-Roman'; color: #666666; text-decoration: underline;"> {{ $t('HeaderBar.loginOrRegister') }} </span>
        </v-col>
      </div>
     
      <div v-else>
        <!--            USER ICON ONLY -->
        <UserIcon
          :on="!!user.codigoUsuario"
          class="cursor-pointer"
        />          
      </div>
    </div>
    <div v-else>  
     <div
      class="align-content-center relative user-menu"
     >
    <div
        @click="showUserMenu = !showUserMenu"
    > 
      <div v-if="!isMobile" class="cursor-pointer align-content-center relative user-menu">
        <v-col cols="3">
            <UserIcon
              :on="!!user.codigoUsuario"
              class="cursor-pointer"
            />
          </v-col>
          <v-col  cols="9" class="header-user-dropdown-online">
            <v-row>
              <span style="font: 14px 'HelveticaNeueLTPro-Roman'; font-weight:bold; color: #60BED4;"> {{ $t('HeaderBar.userGreeting') + user.nome }}
              <i v-if="!showUserMenu" class="fa fa-angle-down" aria-hidden="true" vertical-align="bottom"></i>
            </span>
            </v-row>
            <v-row v-if="isPartner">
              <a :href="partnerUrl" target="_blank" class="partner-link">Plataforma de Parceiros</a>
            </v-row>
         </v-col>

      </div>
      <!--            USER ICON ONLY -->
      <div v-else>
        <UserIcon
          :on="!!user.codigoUsuario"
          class="cursor-pointer"
        /> 
      </div> 
     
    </div>
    <!--          MENU -->

    <!--            BANNER CLICKOUT-->
    <div
        v-if="showUserMenu"
        style="position: fixed; top: 0;bottom: 0;left: 0;right: 0; background: transparent;"
        @click="showUserMenu = false"
    />
    <div
        v-if="showUserMenu"
        class="header-user-dropdown"
    >
      <!--            MENU LOGADO -->
      <div
          v-if="user.codigoUsuario"
          class="header-user-dropdown-online"
      >
        <div
            style="display: flex; flex-wrap:nowrap; margin-bottom: 11px;"
        >
          <div
              class="header-menu-alerts"
          >
          </div>
          <div
              @click="goToPerfil"

          >{{ $t('HeaderBar.profile') }}
          </div>
        </div>
        <div
            style="display: flex; flex-wrap:nowrap; margin-bottom: 11px;"
        >
          <div
              class="header-menu-alerts"
          >
            {{ user.notifications > 0 ? user.notifications : '' }}
          </div>
          <div
              @click="goToNotification"

          >{{ $t('HeaderBar.notifications') }}
          </div>
        </div>
        <div
            style="display: flex; flex-wrap:nowrap; margin-bottom: 11px;"
        >
          <div
              class="header-menu-alerts"
          >
          </div>
          <div
              @click="goToFavorites"

          >{{ $t('HeaderBar.favorites') }}
          </div>
        </div>


        <div
            style="display: flex; flex-wrap:nowrap; margin-bottom: 11px;"
        >
          <div
              class="header-menu-alerts"
          >
          </div>
          <div
              @click="goToNegotiations(false)"
          >{{ $t('HeaderBar.negotiations') }}
          </div>
        </div>

        <div
            style="display: flex; flex-wrap:nowrap; margin-bottom: 11px;"
        >
          <div
              class="header-menu-alerts"
          >
          </div>
          <div
              @click="goToNegotiations(true)"
          >{{ $t('HeaderBar.myPurchase') }}
          </div>
        </div>

        <div
            style="display: flex; flex-wrap:nowrap; margin-bottom: 11px;"
        >
          <div
              class="header-menu-alerts"
          >

          </div>
          <div
              @click="goToLogoff"

          >{{ $t('HeaderBar.logoff') }}
          </div>
        </div>
      </div>
      <!--            MENU DESLOGADO -->
      <div
          v-else
          class="header-user-dropdown-offline"
      >  
      </div>
    </div>
  </div>
    </div>
  </div>
  
</template>
<script>
import UserIcon from '@/shared/Icons/UserIcon'
import CookieControl from '@/cookie-control'
import {mapMutations, mapState} from 'vuex'
import {AuthResource} from "@/api";
import {sessionEvents} from '@/analytics'
import {PARTNER_URL} from "@/main";

const cookieControl = new CookieControl()

export default {
  name: 'UserMenu',
  components: {UserIcon},
  data() {
    return {
      showUserMenu: false,
    }
  },
  computed: {
    ...mapState('authStore', ['user']),
    ...mapState('authStore', [
      'user',
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    partnerUrl() {
      return `${PARTNER_URL}`
    },
    isPartner() {
      return this.user != null && this.user.isPartner
    },
  },
  mounted() {
   
  },
  methods: {
    ...mapMutations('authStore', ['SIGN_OUT']),
    goToLink (link) {
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {})
      this.$router.go({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {})
    },
    goToLogin () {
      this.showUserMenu = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/auth'
      }).catch((e) => {})
    },
    goToNotification () {
      this.showUserMenu = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/notification'
      }).catch((e) => {})
    },
    goToFavorites () {
      this.showUserMenu = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/favoritos'
      }).catch((e) => {})
    },
    goToPerfil () {
      this.showUserMenu = false
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/perfil'
      }).catch((e) => {
      })
    },
    goToNegotiations(isMyBuy) {
      this.showUserMenu = false
      let path = '/negociacao?view='
      path += isMyBuy ? 'compra' : 'negociacao'
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch((e) => {
      })
    },
    async goToLogoff() {
      await AuthResource.postLogout()
      this.SIGN_OUT()
      this.goToLink(this.categorySelected)
      sessionEvents.logout({ user_id: '', status: 'Sucesso' })
    },
    isUserLogin() {
      return cookieControl.isLogin()
    },

  },
}
</script>
<style scoped>

/*noinspection CssUnusedSymbol*/

a {
  opacity: 0;
}

.header{
  padding-top: 1px;
}
.header-user-dropdown {
  padding: 20px 16px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  white-space: nowrap;
  position: absolute;
  top: 48px;
  right: -30px;
}

.header-user-dropdown-online {
  padding-left: 15px;
  padding-right: 15px;
  min-width: 200px;
  text-align: left;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}


.header-user-dropdown-offline {
  width: 206px;
  font: 14px 'HelveticaNeueLT';
  letter-spacing: 0;
  color: #666666;
  display: flex;
  flex-direction: column;
}

.header-user-dropdown > * {
  cursor: pointer;
}

.header-menu-alerts {
  width: 20px;
  color: red;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
}

.user-menu {
  height: 40px;
  padding: 5px;
}

.partner-link {
  font: 14px 'HelveticaNeue-Bold';
  text-decoration: none;
  color: #5B621C;
  opacity: 1;
}
</style>
