<template>
  <div>
    <FilterSidebar
        :style="canShowFilterMobile ? '' : 'display: none;'"
        @click="showingFilterMobile = false"
    />
    <div
        v-if="!canShowFilterMobile"
    >
      <HomeBanner
          :banners="banners"
      />
      <div
          ref="filter-grid"
      >  
        <!--      Tela desktop -->
        <v-row
            v-if="$vuetify.breakpoint.lgAndUp"
            style=" background: #F5F5F7 0 0 no-repeat padding-box;"
        >
          <div style="display: flex; flex-wrap:nowrap; flex-flow: row; width: 100%;">
            <div
                class="pt-0"
                style="flex: 0; max-width: 280px; min-width: 280px; padding-left: 8px;"
            >
              <div
                  :style="this.showParceiro ? '' : 'display: none;'"
                  class="pt-0"
                  style="display: flex; flex-direction: row; flex-wrap: wrap; flex: 0; max-width: 280px; padding-left: 8px; padding-top: 50px !important; padding-bottom: 50px; justify-content: center; border-bottom: 1px solid #C9CDD0;"
              >
                <v-img
                    style="border-radius: 50%; flex-grow:0"
                    :src="this.partnerDetails.urlFoto"
                    :height="170"
                    :width="170"
                />
                <p style="text-align: center; font-size: 37px; font-family: 'HelveticaNeueLTPro-Roman', serif; letter-spacing: 0; color: #2D2D2D; width: 100%; margin: 0;">
                  {{this.partnerDetails.nome}}
                </p>
                <p style="width: 100%; text-align: center; color: #A7A7A7; font-weight: normal; margin: 0 0 20px;">
                  {{ $t('Partner.memberSince') }}{{this.partnerDetails.dataMembro}}
                </p>
                <p style="width: 100%; text-align: center">{{this.partnerDetails.descricao}}</p>
              </div>
              <div
                  :style="showingFilter ? '' : 'display: none;'"
                  class="pt-0"
                  style="flex: 0; max-width: 280px; min-width: 280px; padding-left: 8px;"
              >
                <FilterSidebarDesktop
                    @click="showingFilter = false"
                />
              </div>
              <div
                  v-if="!showingFilter"
                  class="pt-0"
                  style="flex: 0; max-width: 52px; min-width: 52px;"

              >
                <div
                    class="show-filter"
                    @click="showingFilter = true"
                >
                  <FilterIcon/>
                </div>
              </div>
            </div>

            <div
                :class="{'align-content-center': !showingFilter}"
                class="pt-0 pl-0"
                style="flex: 1; justify-content: center; display:flex;"
            >
              <div class="offers-grid-content">
                <OffersRow
                    v-if="filteredSpotlight.length"
                    :offers="filteredSpotlight"
                    :order-by="true"
                    :filter-by="true"
                    title="HomeIndex.OffersRow.title"
                    :width="getGridWidth"
                    style=" background: #F5F5F7 0 0 no-repeat padding-box;"
                />

                <OffersGrid
                    :offers="filteredOffers"
                    :order-by="!filteredSpotlight.length"
                    :filter-by="!filteredSpotlight.length"
                    :width="getGridWidth"
                />
              </div>

            </div>
            <div
                :style="showingFilter ? 'flex: 1; max-width: 280px' : 'flex: 1; max-width: 52px; min-width: 52px;'"
            />
          </div>
        </v-row>

        <!--      Tela Tablet + mobile -->
        <v-row
            v-else
            style=" background: #F5F5F7 0 0 no-repeat padding-box;"
        >
          <div style="display: flex; flex-wrap:nowrap; flex-flow: row; width: 100%;">

            <div
                class="pt-0 pl-0 align-content-center"
                style="flex: 1; justify-content: center; display:flex;"
            >
              <div class="offers-grid-content mobile">

                <div class="filter-icon"  @click="openFilterMobile">
                  <FilterIcon/>
                  <span class="filter-title">
                    {{ $t('FilterSidebar.title') }}
                  </span>               
                </div>
                
                <OffersRow
                    v-if="filteredSpotlight.length"
                    :offers="filteredSpotlight"
                    :order-by="true"
                    :filter-by="true"
                    title="HomeIndex.OffersRow.title"
                    style=" background: #F5F5F7 0 0 no-repeat padding-box;"
                    width="100vw"
                />

                <OffersGrid
                    :offers="filteredOffers"
                    :order-by="!filteredSpotlight.length"
                    :filter-by="!filteredSpotlight.length"
                    width="100vw"
                />
              </div>

            </div>
          </div>
        </v-row>

      </div>
    </div>
  </div>
</template>

<script>
import OffersRow from '@/shared/OffersRow/OffersRow'
import OffersGrid from '@/shared/OffersGrid/OffersGrid'
import { HomeResource } from '@/api'
import FilterSidebar from '@/shared/FilterSidebar/FilterSidebar'
import HomeBanner from './HomeBanner'
import { mapMutations, mapState } from 'vuex'
import FilterIcon from '@/shared/Icons/FilterIcon'
import FilterSidebarDesktop from '@/shared/FilterSidebar/FilterSidebarDesktop'

export default {
  name: 'home-index',
  components: {
    FilterSidebarDesktop,
    FilterIcon,
    HomeBanner,
    FilterSidebar,
    OffersGrid,
    OffersRow
  },
  data () {
    return {
      offers: [],
      banners: [],
      cols: 10,
      colsdois: 12,
      category: 0,
      parceiro: "",
      showParceiro: false,
      partnerDetails: {
        nome:"",
        urlFoto:"",
        dataMembro:"",
        descricao:"",
      }
    }
  },
  computed: {
    ...mapState('homeStore', [
      'filteredOffers',
      'filteredSpotlight',
      'goToAnchor',
      'showFilter',
      'showFilterMobile'
    ]),
    canShowFilterMobile () {
      return this.showingFilterMobile && this.$vuetify.breakpoint.mdAndDown
    },
    showingFilter: {
      get () {
        return this.showFilter
      },
      set (v) {
        this.SET_SHOW_FILTER(v)
      }
    },
    showingFilterMobile: {
      get() {
        return this.showFilterMobile
      },
      set(v) {
        this.SET_SHOW_FILTER_MOBILE(v)
      }
    },
    getGridWidth() {
      if (this.$vuetify.breakpoint.lgAndDown) {
        return this.showingFilter ? '878px' : '1123px'
      } else {
        return this.showingFilter ? '1123px' : '1368px'
      }
    }
  },
  watch: {
    goToAnchor (newValue, oldValue) {
      if (newValue) {
        this.toAnchor()
        this.SET_ANCHOR(false)
      }
    }
  },
  mounted () {
    this.category = this.$route.params.category
    this.parceiro = window.PARTNER
    this.showParceiro = this.parceiro!==undefined;
    this.init()
  },
  methods: {
    ...mapMutations('homeStore', [
      'SET_OFFERS',
      'SET_ANCHOR',
      'SET_SHOW_FILTER',
      'SET_SHOW_FILTER_MOBILE'
    ]),
    init () {
      this.showingFilter = true
      this.getOffers()
      this.getBanners()
      if(this.showParceiro){
        this.getPartner()
      }
    },
    async getOffers () {
      let offerResponse
      if (this.category) {
        offerResponse = await HomeResource.getListByCategory(this.category)
      } else {
        offerResponse = await HomeResource.getList()
      }
      const offers = !offerResponse ? [] : offerResponse.body.data

      this.SET_OFFERS(offers)
    },
    async getBanners () {
      const bannersResponse = await HomeResource.getBannerList(this.category)
      this.banners = !bannersResponse ? [] : bannersResponse.body
    },
    async getPartner () {
      const partner = await HomeResource.getPartnerDetails()
      this.partnerDetails = !partner ? {
        nome:"",
        urlFoto:"",
        dataMembro:"",
        descricao:"",
        id: null
      } : partner.body
      if(this.partnerDetails.id === null){
        window.PARTNER = undefined
        this.$router.push({
          path: `/home`
        })
      }
    },

    toAnchor () {
      let element = this.$refs['filter-grid']
      let top = element.offsetTop

      window.scrollTo(0, top)
    },

    openFilterMobile() {
      this.SET_SHOW_FILTER_MOBILE(true)
    }
  }
}
</script>

<style
    scoped
    type="text/css"
>

.show-filter {
  margin-left: 20px;
  margin-top: 26px;
  cursor: pointer;
}

.offers-grid-content {
  justify-content: center;
  flex: 0;
  max-width: fit-content;
  min-width: 100%;
}

.offers-grid-content.mobile {
  flex: 1;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.filter-icon {
  padding-top: 20px;
  margin-left: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.filter-title {
  margin-left: 11px;
  text-align: left;
  font: 24px 'HelveticaNeueLTPro-Roman';
  color: #8B8B8B;
  opacity: 1;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
</style>
