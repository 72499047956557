<template>
  <v-layout
      justify-space-around
      row
      wrap
  >
    404
  </v-layout>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>
