<template>
  <svg
      height="32"
      id="ic_next"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
  >
    <circle
        cx="16"
        cy="16"
        data-name="Blur branco"
        fill="#fff"
        id="Blur_branco"
        opacity="0.74"
        r="16"
    />
    <path
        d="M1,0A1,1,0,0,0,.293,1.707L9.586,11,.293,20.293a1,1,0,0,0,1.414,1.414l10-10a1,1,0,0,0,0-1.414l-10-10A1,1,0,0,0,1,0Z"
        data-name="Caminho 7071"
        fill="#666"
        id="Caminho_7071"
        transform="translate(10 5)"
    />
  </svg>
</template>
<script>
export default {
  name: 'NextArrowIcon'
}
</script>
<style scoped>

</style>