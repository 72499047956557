// configure router
import VueRouter from 'vue-router'
import Vue from 'vue'
import HomeRouter from './screen/Home/router'
import DashboardIndex from './screen/Dashboard/DashboardIndex'
import NotFoundIndex from './screen/NotFound/NotFoundIndex'
// import Starter from './screen/Starter/StarterIndex'
import OfferRouter from '@/screen/Offer/router'
import AuthRouter from '@/screen/Auth/router'
import NotificationRouter from '@/screen/Notification/router'
import NegotiationRouter from '@/screen/Negotiation/router'
import InterestsRouter from '@/screen/Interests/router'
import ProfileRouter from '@/screen/Profile/router'
import FavoritesRouter from '@/screen/Favorites/router'
import PartnerRouter from '@/screen/RegisterPartner/router'
import FluxRouter from '@/screen/Flux/router'
import FinalizationRouter from '@/screen/Finalization/router'
import DynamicRouter from "@/screen/Dynamic/router";
import SurveyRouter from '@/screen/Survey/router'
import SurveyFinalizationRouter from "@/screen/SurveyFinalization/router";
import PrivacyPolicyRouter from "@/screen/PrivacyPolicy/router"
import PartnerPrivacyPolicyRouter from './screen/PartnerPrivacyPolicy/router'
import CancelamentoRouter from "@/screen/Cancelamento/router";

Vue.use(VueRouter)

let children = [
  HomeRouter,
  OfferRouter,
  AuthRouter,
  NotificationRouter,
  InterestsRouter,
  NegotiationRouter,
  ProfileRouter,
  FavoritesRouter,
  PartnerRouter,
  FluxRouter,
  FinalizationRouter,
  DynamicRouter,
  SurveyRouter,
  SurveyFinalizationRouter,
  PrivacyPolicyRouter,
  PartnerPrivacyPolicyRouter,
  CancelamentoRouter
]

/*let childrenParceiro = children.map(e=>{
  e.path = ":parceiro?/"+e.path
  return e;
})*/

let routes = [
  /*{
    path: '/',
    component: Starter,
  },*/
  {
    path: '/:parceiro?',
    component: DashboardIndex,
    redirect: '/:parceiro?/home',
    children
  },
  { path: '*', component: NotFoundIndex }
]

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {

  window.scrollTo(0, 0)

  if(to.params.parceiro!==undefined){
    window.PARTNER = to.params.parceiro
  }else if(window.PARTNER !== undefined){
    if(!to.fullPath.includes(window.PARTNER)){
      window.PARTNER = undefined;
    }
  }
  next()
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
