<template>
  <div>
    <div class="flux-screen">

      <div class="flux-container">
        <div class="flux-box">

          <div class="flex-content">
            
            <div class="flux-sidebar-offer-title">
              <div class="flux-title">{{ $t('OfferScreen.resume') }}</div>
                <div class="offer-group-content">
                  <div class="offer-subgroup-content">
                    <div class="info-grid">
                      <div class="info-title" style="line-height:20px;"><strong style="color:#000;">{{ offer.title }}</strong></div>
                      <div class="info-value"  style="line-height:20px;"></div>
                    </div>
                  </div>
                  <div style="width: 100%;">
                    <!-- grupo 01 -->
                    <div class="info-grid">
                      <div class="info-card-content">
                        <div class="info-title">Valor:</div>
                        <div class="info-value">{{ price }}</div>
                      </div>

                      <div class="info-card-content">
                        <div class="info-title">Ano / Modelo</div>
                        <div class="info-value">{{this.offer.vehicles[0].yearManufacture + '/' + this.offer.vehicles[0].yearModel }}</div>
                      </div>

                      <div class="info-card-content">
                        <div class="info-title">Combustível</div>
                        <div class="info-value">{{this.offer.vehicles[0].combustivel}}</div>
                      </div>
                    </div>

                    <!-- grupo 02 -->
                    <div class="info-grid">

                      <div class="info-card-content">
                        <div class="info-title">Final Placa: </div>
                        <div v-if="this.offer.vehicles[0].placa"  class="info-value">{{this.offer.vehicles[0].placa.substring(this.offer.vehicles[0].placa.length-4,this.offer.vehicles[0].placa.length)}}</div>
                      </div>

                      <div class="info-card-content">
                        <div class="info-title">Cor:</div>
                        <div class="info-value">{{this.offer.vehicles[0].cor}}</div>
                      </div>

                      <div class="info-card-content">
                        <div class="info-title">km:</div>
                        <div class="info-value">{{this.offer.vehicles[0].km.float2Money('', '', '.', '')}}</div>
                      </div>
                    </div>

                    <!-- grupo 03  -->
                    <div class="info-grid" v-if="this.offer.vehicles[0].typeVehiclesDto">                      
                      <div v-for="item in this.offer.vehicles[0].typeVehiclesDto.additionalInformation" :key="item.value" class="info-card-content">
                        <div class="info-title">{{item.title}}:</div> <div class="info-value">{{item.value}}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>

          <div class="flex-content">
            <slot></slot>
          </div>

          <div
              class="hidden-md-and-up"
          />

          <div style="display: block">
            <div class="flex-footer">
              <div class="flux-stepper">
                <Steps
                    :actual-step="step"
                />
              </div>
              <div class="cancel-next-group">
                
                <div class="flux-cancel">
                  <div
                      class="cursor-pointer"
                      style="padding: 2px; margin-top: 4px;"
                      @click="voltar()"
                  >{{ $t('flux.toBack') }}
                  </div>
                </div>
                <div class="flux-button">
                  <div
                      :class="{'disable': !canNext}"
                      class="flux-button-continue align-content-center"
                      style="margin-left: auto; margin-right: auto;"
                      @click="next"
                  >
                    <span>{{ $t('global.continue') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
import { getImageUrl } from '@/api'
import { mapState } from 'vuex'
import Steps from '@/screen/Flux/Steps'
import {OfferResource} from '@/api'

export default {
  name: 'FluxFrame',
  components: { Steps },  
  props: {
    offer: {
      type: Object,
      default: () => { return {} }
    },
    negotiationType: {
      type: String,
      default: 'Compra'
    },
    priceTitle: {
      type: String,
      default: 'Valor total'
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('fluxStore', ['canNext', 'bid', 'offerPrice', 'fluxType', 'step']),
    isLote () {
      return this.offer.vehicles.length > 1
    },
    price () {
      if (this.bid) return this.bid.number2Money()
      if (!this.offerPrice.valorCompreJa) return 'R$ 0.000,00*'
      return this.offerPrice.valorCompreJa.number2Money()
    },
    metric () {
      if (!this.offer.vehicles[0].km) return '-- ' + this.offer.vehicles[0].tipoContador
      return this.offer.vehicles[0].km.float2Money('', '', '.', '') + ' ' + this.offer.vehicles[0].tipoContador
    }
  },
  mounted() {
    this.getOffer()
  },
  methods: {
    getImage (endpoint) {
      return getImageUrl(endpoint)
    },
    getImageActive (endpoint) {
      return getImageUrl(endpoint)
    },
    next () {
      window.scrollTo(0, 0)
      this.$emit('click')
    },
    cancel () {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/oferta/' + this.offer.codigo
      }).catch(() => {})
    }, 

    voltar () {
      this.$router.go(-1)
    },

    async getOffer() {
      const response = await OfferResource.getItem(this.offer.codigo)
      this.offer = response.body
    }
  },
}
</script>

<style
    scoped
    type="text/css"
>
.flux-screen {
  background: #FCFCFC 0 0 no-repeat padding-box;
  display: flex;
  flex-flow: row;
  width: 100%;
  flex-wrap: nowrap;
}

.info-title{
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 8px;
}

.info-value {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
  text-indent:7px;
}

.flux-container {
  padding: 40px 35px 63px;
  flex: 1;
  justify-content: center;
  display: flex;
}

.flux-box {
  min-width: 849px;
  min-height: 643px;
  max-width: 849px;
  /*max-height: 643px;*/
  display: flex;
  flex-flow: column;
  flex: 0;
}

.flex-content {
  flex: 1;
  min-width: 100%;
}

.flex-footer {
  flex: 0;
  min-width: 100%;
  min-height: 70px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
}

.flux-title {
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #7D7D7D;
  margin-bottom: 52px;
}

.flux-stepper {
  flex: 1;
  display: flex;
  align-items: center;
}

.flux-cancel {
  flex: 0;
  min-width: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #27BADC;
}

.flux-button {
  flex: 0;
  min-width: fit-content;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flux-button-continue {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  min-width: 140px;
  flex: 0;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flux-button-continue > span {
  display: block;
  line-height: 1;
}

/*noinspection ALL*/
.flux-button-continue.disable {
  color: #2d2d2d !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
  cursor: default;
}

.flux-sidebar {
  min-width: 360px;
  max-width: 360px;
  max-height: 797px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  background: #F5F5F7 0 0 no-repeat padding-box;
}

.flux-sidebar-title {
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  width: 100%;
  margin-bottom: 18px;
}

.flux-sidebar-image {
  width: 106px;
  height: 68px;
  margin-bottom: 18px;
}

.flux-image-box {
  background: #000;
  box-shadow: 0 2px 4px #00000080;
  height: 100%;
}

.flux-sidebar-offer-box {
  width: 100%;
  height: 168px;
  margin-bottom: 36px;
}

.flux-sidebar-offer-icon {
  height: 50px;
  width: 100%;
  margin-bottom: 12px;
}

.flux-sidebar-offer-title {
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 8px;
}

.flux-sidebar-offer-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 20px;
}

.flux-sidebar-offer-year {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 8px;
}

.flux-sidebar-offer-metric {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
}

/*noinspection ALL*/
.flux-hidden {
  visibility: hidden;
}

.flux-sidebar-limit {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #ACACAC;
  margin-top: 45px;
}

.flux-sidebar-negotiation-type-title {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 10px;
}

.flux-sidebar-negotiation-type {
  font: 16px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 36px;
}

.flux-sidebar-price-title {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 8px;
}

.flux-sidebar-price {
  font: 30px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #000000;
}

.cancel-next-group {
  display: flex;
  flex-flow: row nowrap;
}

.offer-subgroup-content{
  width: 100%;
  margin-bottom: 20px;
}


.info-title {
  margin-bottom: 5px;
}

/*noinspection ALL*/
.border {
  border-bottom: 1px solid;
  border-bottom-color: RGBA(139, 139, 139, 0.4);
}

.info-card-content {
  width: 200px;
  height: auto;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  padding: 0;
}

.info-grid {
  display: flex;
  width: 100%;
  height: fit-content;
  /*grid-template-columns: repeat(auto-fit, 259px);*/
  flex-flow: row wrap;
  column-gap: 62px;
  /*row-gap: 20px;*/
  align-items: center;
  justify-items: center;
}


.info-value {
  text-align: right;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
}

.hidden-md-and-up{
  height: 30px; 
  width: 100%;
}

@media screen and (max-width: 1279px) {
  .flux-box {
    max-height: fit-content;
    min-width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }

  .flux-sidebar {
    max-height: 100%;
    min-width: 260px;
    flex: 1;
  }

  .flux-container {
    padding-right: 0;
    padding-left: 0;
  }

  .flex-footer {
    flex-wrap: wrap-reverse;
  }

  .cancel-next-group {
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;

  }

  .offer-subgroup-content{
    width: 100%;
    margin-bottom: 8px;
  }

  .offer-group-content{
    margin-bottom: 30px;
    width: 100%;
  }

  .info-grid{
    width: 100%;
  }

  .info-card-content{
    width:100%!important;
  }

  .info-value{
    width:100%;
    text-align: left!important;
    text-indent: 15px;
  }

  .flux-cancel{
    line-height: 30px;
    margin-bottom: 15px;
  }

  .flux-button, .flux-cancel{
    flex-basis: 100%;
    width: 100%!important;
    margin-left: 0px;
  }

  .flux-container{
    padding: 40px 0px 0px;
  }

  .hidden-md-and-up{
    height: 10px; 
  }

}

@media screen and (max-width: 720px) {
  .flux-sidebar {
    max-height: 100%;
    min-width: 260px;
    flex: 0;
  }

  .offer-subgroup-content{
    width: 100%;
    margin-bottom: 8px;
  }

  .offer-group-content{
    margin-bottom: 30px;
  }

  .info-card-content{
    width: 100%;
  }

  .info-grid{
    width: 100%;
  }

  .info-card-content{
    width:100%!important;
  }

  .info-value{
    width:100%;
    text-align: left!important;
    text-indent: 15px;
  }

  
  .cancel-next-group{
    display: flex;
    flex-wrap: wrap;
  }

  .flux-cancel{
    line-height: 30px;
    margin-bottom: 15px;
  }

  .flux-button, .flux-cancel{
    flex-basis: 100%;
    width: 100%!important;
    margin-left: 0px;
  }

  .flux-container{
    padding: 40px 0px 0px;
  }

  .hidden-md-and-up{
    height: 10px; 
  }

}

@media screen and (max-width: 601px) {
  .flux-screen {
    flex-wrap: wrap-reverse;
  }

  .flux-title {
    margin-bottom: 20px;
  }

  .flux-sidebar {
    width: 100%;
    flex: 1;
    min-width: 100%;
    padding-bottom: 30px;
  }

  /*noinspection ALL*/
  .flux-sidebar-hide {
    display: none;
  }

  .flex-footer {
    flex-wrap: wrap;
  }

  .flux-stepper {
    min-width: 100%;
    margin-top: 20px;
  }

  .offer-subgroup-content{
    width: 100%;
    margin-bottom: 8px;
  }

  .offer-group-content{
    margin-bottom: 30px;
  }

  .info-title{
    width: 70%;
  }

  .info-card-content{
    width: 100%!important
  }

  .info-grid{
    width: 100%;
  }

  .info-value{
    width:100%;
    text-align: left!important;
    text-indent: 15px;
  }
  
  .cancel-next-group{
    display: flex;
    flex-wrap:wrap-reverse;
    margin-top:60px;
  }

  .flux-cancel{
    line-height: 30px;
    margin-top: 15px;
  }

  .flux-button, .flux-cancel{
    flex-basis: 100%;
    width: 100%!important;
    margin-left: 0px;
  }

  .flux-container{
    padding: 40px 0px 0px;
  }

  .hidden-md-and-up{
    height: 10px; 
  }

}
</style>
