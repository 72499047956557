<template>
  <div
      v-if="banners && selectedBanner"
      :style="$vuetify.breakpoint.mdAndDown ? '' : 'min-height: 280px;'"
  >
    <div
        v-touch:swipe.left="next"
        v-touch:swipe.right="previous"
        class="relative"
    >
      <v-img
          :class="{'cursor-pointer': selectedBanner.linkImagem}"
          :src="getImage(selectedBanner.guid)"
          :height="imageHeight"
          @click="goToLink(selectedBanner.linkImagem)"
      />
      <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="absolute align-content-center cursor-pointer"
          style=" left: 8px; bottom: 50%; top: 50%; color: #666666; z-index: 5;"
          @click="previous"
      >
        <BackArrowIcon/>
      </div>
      <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="absolute align-content-center cursor-pointer"
          style=" right: 8px; bottom: 50%; top: 50%; color: #666666; z-index: 5;"
          @click="next"
      >
        <NextArrowIcon/>
      </div>

      <div
          class="absolute align-content-center"
          style="right: 0;left: 0;bottom: 16px; z-index: 4;"
      >
        <div class="columns-bullet">
          <div
              v-for="(banner, i) in banners"
              :key="i"
              :class="{'selected': (i===selected) }"
              class="column-bullet cursor-pointer"
              @click="selected = i"
          >
            &bullet;
          </div>
        </div>
      </div>

      <div
          v-if="!selectedBanner.linkImagem"
          class="banner-places"
          @click="()=> true"
      >

        <div
            v-for="i in 9"
            :key="i"
            style="width: 100%; height: 100%;word-break: break-all;"
            @click="()=> true"
        >
          <div
              v-if="i === textPosition"
              class="banner-box"
              @click="()=> true"
          >
            <span
                class=" banner-title"
                @click="()=> true"
            >
            {{ selectedBanner.titulo }}
            </span>
            <span
                class=" banner-subtitle"
                @click="()=> true"
            >
            {{ selectedBanner.descricao }}
            </span>

            <div
                class="touch-slider"
                @click="()=>true"
                v-if="$vuetify.breakpoint.xs"
            />
            <div
                class="header-link-box"
            >
              <span
                  v-if="selectedBanner.botaoComprar === 'S' && selectedBanner.codigoOferta"
                  class=" banner-link "
                  @click.stop="goToBuy(selectedBanner.codigoOferta)"
              >
                {{ $t('OfferBar.buy') }}
              </span>
              <span
                  v-if="selectedBanner.botaoLance === 'S' && selectedBanner.codigoOferta"
                  class=" banner-link"
                  @click.stop="goToOffer(selectedBanner.codigoOferta, true)"
              >
                {{ $t('OfferBar.doBid') }}
              </span>
              <span
                  v-if="selectedBanner.botaoOutro === 'S'"
                  class=" banner-link"
                  @click.stop="goToLink(selectedBanner.linkBotaoOutro)"
              >
                {{ selectedBanner.textoBotaoOutro }}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import { getImageUrl, OfferResource } from '@/api'
import BackArrowIcon from '@/shared/Icons/BackArrowIcon'
import NextArrowIcon from '@/shared/Icons/NextArrowIcon'
import CookieControl from '../../cookie-control'
import { mapMutations } from 'vuex'

const cookieControl = new CookieControl()

export default {
  name: 'HomeBanner',
  components: { NextArrowIcon, BackArrowIcon },
  props: {
    banners: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      selected: 0,
      intervalo: 10000,
      setTimeoutId: null,
      mousePosX: -1,
    }
  },
  mounted () {
    this.next_auto()
  },
  computed: {
    selectedBanner () {
      if (this.banners.length < 0) return {}
      return this.banners[this.selected]
    },
    imageHeight () {
      if (this.$vuetify.breakpoint.xs) {
        return '162pt'
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return '300px'
      }
      return '280px'
    },
    textPosition () {
      let pos = 1
      const posicaoImagem = this.banners[this.selected].posicaoImagem

      switch (posicaoImagem) {
        case 'EC':
          pos = 1
          break
        case 'EB':
          pos = 7
          break
        case 'CC':
          pos = 2
          break
        case 'CB':
          pos = 8
          break
        case 'DC':
          pos = 3
          break
        case 'DB':
          pos = 9
          break
        case 'ME':
          pos = 4
          break
        case 'MC':
          pos = 5
          break
        case 'MD':
          pos = 6
          break
      }

      return pos
    }
  },
  methods: {
    ...mapMutations('fluxStore', [
      'SET_FLUX_BUY', 'SET_OFFER'
    ]),
    getImage (endpoint) {
      return getImageUrl(endpoint)
    },
    goToLink (url) {
      if (!url) { return }
      window.open(url, '_blank').focus()
    },
    goToOffer (id, bid) {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/oferta/' + id + (bid ? '?view=leilao' : '')
      }).catch(() => {})
    },
    isUserLogin () {
      return cookieControl.isLogin()
    },
    async goToBuy (e) {
      if (!this.isUserLogin()) {
        return this.$router.push({
          path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/oferta/' + this.selectedBanner.codigoOferta
        }).catch(() => {})
      }
      //pegar aqui a oferta
      const offer = await OfferResource.getOfferId(this.selectedBanner.codigoOferta)
      this.SET_OFFER(offer.body)
      this.SET_FLUX_BUY()

      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/fluxo'
      }).catch(() => {})
    },
    previous () {
      this.next_auto()
      let validOffset = this.selected - 1
      const limit = 0
      this.selected = validOffset < limit ? this.banners.length - 1 : validOffset
    },
    next () {
      this.next_auto()
      let validOffset = this.selected + 1
      this.selected = validOffset >= this.banners.length ? 0 : validOffset
    },
    next_auto () {
      if (this.setTimeoutId) {
        clearTimeout(this.setTimeoutId)
      }
      this.setTimeoutId = setTimeout(() => {
        this.next()
        this.next_auto()
      }, this.intervalo)
    }
  },
}
</script>
<style scoped>

.columns-bullet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.column-bullet {
  flex: 1;
  margin-left: 6px;
  text-align: center;
  font: 12px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #8B8B8B;
  cursor: pointer;
}

.column-bullet:first-child {
  margin-left: 0;
}

.selected {
  color: #60BED4;
  font: 18px 'HelveticaNeueLTPro-Md';

}

.banner-title {
  text-align: center;
  font: 41px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-bottom: 3px;
  width: 100%;
}

.banner-subtitle {
  text-align: center;
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  margin-bottom: 24px;
  width: 100%;
}

.banner-link {
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #60BED4;
  opacity: 1;
  cursor: pointer;
  flex: 1;
  white-space: nowrap;
}


.banner-places {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
  display: grid;
  grid-template-rows:repeat(3, auto);
  grid-template-columns:repeat(3, auto);

  align-items: stretch;
  justify-items: stretch;

}

.banner-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: center;
}

.header-link-box {
  display: flex;
  justify-content: space-around;
  width: 60%;
  z-index: 4;
}

.touch-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #2222ec;
  opacity: 0;
}

@media screen and (max-width: 1279px) {
  .banner-title {
    font-size: 32px;
  }

  .banner-subtitle {
    font-size: 16px;
  }

  .banner-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 601px) {
  .banner-title {
    font-size: 20px;
  }

  .banner-subtitle {
    font-size: 12px;
  }

  .banner-link {
    font-size: 11px;
  }

  .header-link-box {
    width: 100%;
    justify-content: space-evenly;
  }

  .banner-subtitle {
    margin-bottom: 16px;
  }

  .banner-places {
    padding: 15px;
  }
}

</style>
