<template>
  <div class="filter-sidebar">

    <div class="filter-header mb-10">
      <div class="d-flex mt-5 cursor-pointer" @click="$emit('click')">
        <div style="margin-left: 8px; display: flex; align-items: center">
          <ChevronLeft :showCircle=false :size=0 height="24" width="14" class=" cursor-pointer d-flex align-center" style="color: #8B8B8B;" />
       </div>   
        <div class="filter-icon">
          <FilterIcon/>
        </div>
        <span class="filter-title">
          {{ $t('FilterSidebar.title') }}
        </span>
      </div>

      <div class="d-flex mt-5">
        <SearchInput/>
      </div>
    </div>

    <div style="margin-left: 37px;">
      <p
          class="filter-clear mb-5"
          @click="clearFilter"
      >{{ $t('FilterSidebar.clearFilter') }}</p>

      <div
          class="mb40">
           <p
            class="filter-session-title"
            style="margin-bottom: 7px;"
        >{{ $t('FilterSidebar.brands') }}</p>
        <p
            class="filter-session-subtitle"
            style="margin-bottom: 22px;"
        >{{ $tc('FilterSidebar.quantityText', brandsSelected.length, { count: brandsSelected.length }) }}</p>
        
        <IconBtn
            v-for="(brand, i) in getFromArray(filter.brands, 5)"
            :key="i"
            :obj="brand "
            :selected="isBrandSelected(brand)"
            :style="i < 5 ? 'margin-bottom: 10px;' : '' "
            style="margin-right: 21.25px;"
            @click="selectBrand(brand)"        
        />

        <p
            class="show-more"
            v-if="filter.brands && filter.brands.length > 5"
            @click="openModalBrands()"
        >{{ $t('FilterSidebar.showMore') }}</p>
        
      </div>

       <div
          class="mb40">

           <p
            class="filter-session-title"
            style="margin-bottom: 7px;"
        >{{ $t('FilterSidebar.categories') }}</p>
        <p
            class="filter-session-subtitle"
            style="margin-bottom: 22px;"
        >{{
            $tc('FilterSidebar.quantityText', subcategoriesSelected.length, { count: subcategoriesSelected.length })
         }}</p>

         <IconBtn
            v-for="(category, i) in getFromArray(filter.subcategories, 5)"
            :key="i"
            :obj="category "
            :selected="isCategoriesSelected(category)"
            :style="i < 5 ? 'margin-bottom: 10px;' : '' "
            style="margin-right: 40px;"
            @click="selectCategory(category)"        
        />

        <p
            class="show-more"
            v-if="filter.subcategories && filter.subcategories.length > 5"
            @click="openModalSubcategories()"
        >{{ $t('FilterSidebar.showMore') }}</p>
      
      </div>

      <div
          class="mb40"
      >
        <p
            class="filter-session-title"
            style="margin-bottom: 7px;"
        >{{ $t('FilterSidebar.models') }}</p>
        <p
            class="filter-session-subtitle"
            style="margin-bottom: 22px;"
        >{{ $tc('FilterSidebar.quantityText', modelsSelected.length, { count: modelsSelected.length }) }}
        </p>
        <div class="filter-grid filter-grid-gap">
          
          <FilterBtn
              v-for="(models, i) in getFromArray(filter.models, 10)"
              :key="i"
              :selected="isModelsSelected(models)"
              :title="models.name"
              width="106"
              @click="selectModels(models)"
          />
        </div>

        <p
            class="show-more"
            v-if="filter.models && filter.models.length > 10"
            @click="openModalModels()"
        >{{ $t('FilterSidebar.showMore') }}</p>
      </div>

      <div
          class="mb40 filter-grid"
      >
        <FilterBtn
            :selected="vehicle"
            :title="this.$t('global.vehicle')"
            @click="setVehicles()"
        />
        <FilterBtn
            :selected="lot"
            style="margin-left: 16px;"
            :title="this.$t('global.lot')"
            @click="setLot()"
        />
      </div>

      <div
          class="mb40"
      >
        <p
            class="filter-session-title"
            style="margin-bottom: 30px;"
        >{{ $t('FilterSidebar.priceRanger') }}</p>

        <div
            style="width: 80%;height: 50px;margin-top: 60px;"
        >
          <SliderTest
              :max="price.max"
              :min="price.min"
              :value="price.value"
              @update="updatePrice"
          />
        </div>

      </div>

      <div
          class="mb40"
      >
        <p
            class="filter-session-title"
            style="margin-bottom: 30px;"
        >{{ $t('FilterSidebar.kmRanger') }}</p>

        <div
            style="width: 80%;height: 50px;margin-top: 60px;"
        >
          <SliderTest
              :max="usage.max"
              :min="usage.min"
              :value="usage.value"
              tipo="km"
              @update="updateUsage"
          />
        </div>
      </div>

    </div>

    <div>
      <v-dialog
          v-model="modalFilter"
          width="90vw"
      >
        <div class="modal-content">
          <div style="position: relative;">
            <div
                class="cursor-pointer"
                style="position: absolute; top: 22px; right: 22px; height: 20px; width: 20px;"
                @click="closeModal()"
            >
              <XMarkIcon/>
            </div>
          </div>
          <div
              style="padding: 25px 43px 30px;"
          >
            <p
                class="filter-modal-title"
                style="margin-bottom: 3px;"
            >{{ modal.title }}</p>
            <p
                class="filter-modal-subtitle"
            >{{
                $tc('FilterSidebar.quantityText', modal.dataSelected.length, { count: modal.dataSelected.length })
             }}</p>

            <div
                class="filter-modal-search"
            >
              <input
                  v-model="modal.search"
                  :placeholder="$t('global.findBy', {id:modal.title})"
                  class="input-modal-search"
                  type="text"
              />
              <p
                  class="filter-modal-clear"
                  @click="clearSelected()"
              >{{ $t('FilterSidebar.clearSelects') }}</p>
            </div>

            <div class="modal-content-overflow">
              <div
                  v-if="modal.type === 'SUBCATEGORIES'"
                  class="filter-modal-grid-subcategories"
              >
                <BrandBtn
                    v-for="(category, i) in modalDataFiltered"
                    :key="i"
                    :obj="category "
                    :selected="isCategoriesSelected(category)"
                    :style="i < 5 ? 'margin-bottom: 10px;' : '' "
                    width="138"
                    @click="selectCategory(category)"
                />
              </div>

              <div
                  v-else-if="modal.type === 'BRANDS'"
                  class="filter-modal-grid-brands"
              >
                <BrandBtn
                    v-for="(brand, i) in modalDataFiltered"
                    :key="i"
                    :obj="brand "
                    :selected="isBrandSelected(brand)"
                    :style="i < 5 ? 'margin-bottom: 10px;' : '' "
                    @click="selectBrand(brand)"
                />
              </div>

              <div
                  v-else
                  class="filter-modal-grid-models"
              >
                <FilterBtn
                    v-for="(models, i) in modalDataFiltered"
                    :key="i"
                    :selected="isModelsSelected(models)"
                    :title="models.name"
                    width="106"
                    @click="selectModels(models)"
                />
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import FilterBtn from './FilterBtn'
import IconBtn from './IconBtn'
import { FilterResource } from '@/api'
import XMarkIcon from '../Icons/XMarkIcon'
import SliderTest from '@/screen/Home/SliderTest'
import {mapMutations, mapState} from 'vuex'
import { productEvents } from '@/analytics'
import { timer, Subject } from 'rxjs'
import { debounce } from 'rxjs/operators'
import SearchInput from '@/shared/HeaderBar/SearchInput'
import FilterIcon from '@/shared/Icons/FilterIcon'
import ChevronLeft from '@/shared/Icons/ChevronLeft'
import BackArrowIcon from '@/shared/Icons/BackArrowIcon'
import BrandBtn from '@/shared/FilterSidebar/BrandBtn'

export default {
  name: 'FilterSidebarDesktop',
  // eslint-disable-next-line vue/no-unused-components
  components: {ChevronLeft, SliderTest, XMarkIcon, FilterBtn, IconBtn, SearchInput, FilterIcon, BackArrowIcon, BrandBtn},
  data () {
    return {
      modalFilter: false,
      filter: {},
      modelsSelected: [],
      brandsSelected: [],
      subcategoriesSelected: [],
      price: {
        max: 500000,
        min: 0,
        value: [0, 500000],
        timeId: undefined
      },
      usage: {
        max: 150000,
        min: 0,
        value: [0, 150000],
        timeId: undefined
      },
      modal: {
        title: 'Marca',
        data: [],
        dataSelected: [],
        search: '',
        type: 'MODELS'
      },
      vehicle: true,
      lot: true,
      category: 0,
      filterEvents$: new Subject(),
      filterEventsSub: null,
    }
  },
  watch: {
    term(newValue, oldValue) {
      if (newValue) {
        this.clearFilter(true)
      }
    }
  },
  computed: {
    ...mapState('homeStore', ['term']),
    modalDataFiltered() {
      const search = this.modal.search.toUpperCase()
      return !search ? this.modal.data : this.modal.data.filter(v => v.name.toUpperCase().indexOf(search) >= 0)
    }
  },
  mounted() {
    this.category = this.$route.params.category
    this.init()

    this.filterEventsSub = this.filterEvents$
      .pipe(
        debounce(() => timer(10 * 1000)) // 10s até publicar evento de filtro
      )
      .subscribe((filter) => productEvents.filtered(filter))
  },
  beforeDestroy() {
    this.filterEventsSub.unsubscribe()
  },
  methods: {
    ...mapMutations('homeStore', [
      'CLEAR_FILTER',
      'SET_FILTER',
      'SET_SKIP_CLEAR_FILTER'
    ]),
    async init () {
      const filter =  await FilterResource.getList(this.category)
      this.filter = !filter ? {} : filter.body
      this.CLEAR_FILTER()
    },
    getFromArray (data, size) {
      return data ? data.slice(0, size) : []
    },
    openModalBrands () {
      this.openModal(this.filter.brands, this.brandsSelected, this.$t('FilterSidebar.brands'), 'BRANDS')
    },
    openModalSubcategories () {
      this.openModal(this.filter.subcategories, this.subcategoriesSelected, this.$t('FilterSidebar.categories'), 'SUBCATEGORIES')
    },
    openModalModels () {
      this.openModal(this.filter.models, this.modelsSelected, this.$t('FilterSidebar.models'), 'MODELS')
    },
    openModal (data, dataSelected, title, type) {
      this.modal = {
        title: title,
        data: data,
        dataSelected: dataSelected,
        search: '',
        type: type
      }
      this.modalFilter = true
    },
    closeModal () {
      this.modalFilter = false
      this.modal = {
        title: '***Title***',
        data: [],
        dataSelected: [],
        search: '',
        type: 'MODELS'
      }
    },
    isBrandSelected (model) {
      return this._isSelected(this.brandsSelected, model)
    },
    isCategoriesSelected (model) {
      return this._isSelected(this.subcategoriesSelected, model)
    },
    isModelsSelected (model) {
      return this._isSelected(this.modelsSelected, model)
    },
    _isSelected (list, model) {
      return list.indexOf(model.codigo) >= 0
    },
    selectBrand (model) {
      this._select(this.brandsSelected, model.codigo)
    },
    selectCategory(model) {
      this._select(this.subcategoriesSelected, model.codigo)
    },
    selectModels(model) {
      this._select(this.modelsSelected, model.codigo)
    },
    clearSelected() {
      this.modal.dataSelected.splice(0, 999999)
      this.updateFilters()
    },
    clearFilter(skipTerm) {
      this.SET_SKIP_CLEAR_FILTER(skipTerm)
      this.CLEAR_FILTER()
      this.subcategoriesSelected.splice(0, 999999)
      this.brandsSelected.splice(0, 999999)
      this.modelsSelected.splice(0, 999999)
      this.price.value = [0, this.price.max]
      this.usage.value = [0, this.usage.max]
      this.vehicle = true
      this.lot = true
    },
    updatePrice(value) {
      this.price.value = value
      if (this.price.timeId) {
        clearTimeout(this.price.timeId)
      }
      this.price.timeId = setTimeout(this.updateFilters, 500)
    },
    updateUsage (value) {
      this.usage.value = value
      if (this.usage.timeId) { clearTimeout(this.usage.timeId) }
      this.usage.timeId = setTimeout(this.updateFilters, 500)
    },
    _select (list, codigo) {
      if (list.indexOf(codigo) >= 0) {
        list.splice(list.indexOf(codigo), 1)
      } else {
        list.push(codigo)
      }
      this.updateFilters()
    },
    setVehicles () {
      this.vehicle = !this.vehicle
      this.updateFilters()
    },
    setLot () {
      this.lot = !this.lot
      this.updateFilters()
    },
    updateFilters () {
      this.SET_FILTER({
        lot: this.lot,
        vehicle: this.vehicle,
        usageRanger: {
          start: this.getRangerValue(this.usage.value[0], this.usage.max),
          end: this.getRangerValue(this.usage.value[1], this.usage.max),
        },
        priceRanger: {
          start: this.getRangerValue(this.price.value[0], this.price.max),
          end: this.getRangerValue(this.price.value[1], this.price.max),
        },
        brandsSelected: this.brandsSelected,
        subcategoriesSelected: this.subcategoriesSelected,
        modelsSelected: this.modelsSelected,
      })

      this.filterEvents$.next({
        price_range: `De ${this.price.value[0].float2Money()} até ${this.price.value[1].float2Money()}`,
        mileage_range: `De ${this.getUsageFormatted(this.usage.value[0])} Km até ${this.getUsageFormatted(this.usage.value[1])} Km`,
        brand: this.brandsSelected,
        model: this.modelsSelected,
        category: this.subcategoriesSelected,
      })
    },
    getRangerValue (value, max) {
      return value === max ? -1 : value
    },
    getUsageFormatted(usage) {
      return usage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
}
</script>

<style
    scoped
    type="text/css"
>
.mb40 {
  margin-bottom: 40px;
}

.filter-title {
  text-align: left;
  font: 24px 'HelveticaNeueLTPro-Cn';
  color: #8B8B8B;
  opacity: 1;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.filter-sidebar {
  height: fit-content;
  min-height: fit-content;
  max-height: 1774px;
  width: 280px;
  background: #F5F5F7 0 0 no-repeat padding-box;
}

.filter-header {
  height: 80px;
}

.filter-header > i {
  color: #8B8B8B;
  opacity: 1;
}

.filter-icon {
  margin-left: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.filter-clear { 
  text-align: left;
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  opacity: 1;
  color: #60BED4;
  cursor: pointer;
}

.filter-session-title {
  margin-bottom: 30px;
  font: 14px 'HelveticaNeue-Bold';
  letter-spacing: 0;
  color: #565656;
}

.filter-session-subtitle {
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #A7A7A7;
  opacity: 1;
}

.show-more {
  font: 13px 'HelveticaNeue-Medium';
  letter-spacing: 0;
  color: #60BED4;
  margin-top: 20px;
  cursor: pointer;
}

.filter-grid {
  display: inline-grid;
  grid-template-columns: auto auto;
}

.filter-grid-gap {
  grid-gap: 16px;
}

.modal-shadow {
  z-index: 998;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #2B465E 0 0 no-repeat padding-box;
  opacity: 0.15;
}

.modal-content {
  top: 103px;
  background: #F5F5F7 0 0 no-repeat padding-box;
  box-shadow: 0 3px 15px #000000C7;
  border-radius: 4px;;
}

.filter-modal-title {
  font: 24px 'HelveticaNeueLTPro-Cn';
  font-weight: bold;
  letter-spacing: 1px;
  color: #8B8B8B;
  opacity: 1;
}

.input-modal-search {
  background: #FFFFFF;
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  border: 1px solid #d4e2f5;
  height: 28px;
  width: 200px;
  padding-left: 11px;
}

.input-modal-search::placeholder {
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #C9CDD0;
  opacity: 1;
  padding-left: 11px;
}

.filter-modal-search {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 200px auto;
  align-items: baseline;
  justify-items: right;
  margin-bottom: 20px;
}

.filter-modal-clear {
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #60BED4;
  opacity: 1;
  margin: 0;
  cursor: pointer;
}

.filter-modal-subtitle {
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #A7A7A7;
  opacity: 1;
  margin-bottom: 13px;
}

.filter-modal-grid-models {
  display: grid;
  grid-template-columns: repeat(auto-fill, 106px);
  column-gap: 39px;
  row-gap: 16px;
}

.filter-modal-grid-subcategories {
  display: grid;
  grid-template-columns: repeat(auto-fill, 198px);
  column-gap: 80px;
  row-gap: 16px;
}

.filter-modal-grid-brands {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  column-gap: 80px;
  row-gap: 16px;
}

.modal-content-overflow {
  max-height: 62vh;
  overflow-y: auto;
  padding-bottom: 10px;
  padding-right: 3px;
}

.modal-content-overflow::-webkit-scrollbar {
  width: 0 !important
}


</style>
