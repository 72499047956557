<template>
  <!--    Header -->

  <div
      v-if="continueAddr"
     class="minHeight"
  >
    <div class="flux-address-title">
      {{ $t('flux.freight') }}
    </div>
    <div style="width: 100%;">
      <div
          style="width: 100%;"
          @click="changeFreight('R')"
      >
        <FreightCard
            :freight-sub="$t('flux.subSimple')"
            :freight-text="$t('flux.simple')"
            :selected="selectedFreightR"
        />
      </div>
    </div>
  </div>

  <div
      v-else
      class="minHeight"
  >
    <div class="flux-address-overflow">
      <div class="flux-address-header">
        <div class="flux-address-title">
          {{ $t('flux.address') }}
        </div>

        <div
            v-if="showHeaderAddLink"
            class="flux-address-add-link"
            @click="edit({})"
        >
          {{ $t('flux.addAddress') }}
        </div>
      </div>
      <!--    Header -->

      <!--    content-->
      <div
          class="align-content-center flux-address-box"
      >
        <div
            :class="{'center': centered}"
            class="flux-address-content"
        >

          <!--      Add card -->
          <div
              v-if="!showHeaderAddLink"
              class="flux-address-card cursor-pointer"
              @click="edit({})"
          >
            <div
                class="flux-address-card-content align-content-center"
            >
              <div class="flux-address-add-link">
                {{ $t('flux.addAddress') }}
              </div>
            </div>
          </div>
          <div
              v-for="(address, i) in addresses"
              :key="i"
              class="flux-address-card-md-down"
              @click="select(address, i)"
          >
            <AddressCard
                :address="address"
                :selected="selected === i"
                @edit="edit(address)"
                @remove="remove(address)"
            />
          </div>

        </div>
      </div>
      <!--    content-->

      <!-- Dialogo de informação -->
      <v-dialog
          v-model="dialogInfo"
          persistent
          width="fit-content"
      >
        <v-card>
          <v-card-text style="padding: 24px;">
            <div class="dialog-info-header">
              <div class="dialog-info-title"></div>
              <div
                  class="dialog-info-close"
                  @click="dialogInfo = false; reload();"
              >
                <XMarkIcon/>
              </div>
            </div>

            <div class="flux-address-form">
              <label>
                <div class="flux-address-label">
                  {{ $t('flux.zipCode') }}
                </div>
                <input
                    v-model="addressForm.cep"
                    class="flux-address-input medium"
                    maxlength="20"
                    name="cep"
                    required
                    type="text"
                    @focusout="getCepData"
                />
              </label>
              <label>
                <div class="flux-address-label">
                  {{ $t('flux.number') }}
                </div>
                <input
                    v-model="addressForm.numeroEndereco"
                    class="flux-address-input small"
                    name="number"
                    required
                    type="text"
                />
              </label>
              <label>
                <div class="flux-address-label">
                  {{ $t('flux.address') }}
                </div>
                <input
                    v-model="addressForm.endereco"
                    class="flux-address-input"
                    name="endereco"
                    required
                    type="text"
                />
              </label>
              <label>
                <div class="flux-address-label">
                  {{ $t('flux.complement') }}
                </div>
                <input
                    v-model="addressForm.complemento"
                    class="flux-address-input"
                    name="observation"
                    required
                    type="text"
                />
              </label>
              <label>
                <div class="flux-address-label">
                  {{ $t('flux.neighborhood') }}
                </div>
                <input
                    v-model="addressForm.bairro"
                    class="flux-address-input"
                    name="bairro"
                    required
                    type="text"
                />
              </label>

              <label>
                <div class="flux-address-label">
                  {{ $t('flux.city') }}
                </div>
                <select
                    v-model="addressForm.cidade"
                    class="flux-address-input medium input-select"
                    name="descricaoCidade"
                    required
                >
                  <option
                      v-for="optionCidade in optionsCidades"
                      :key="optionCidade.codigoCidade"
                      :value="optionCidade.codigoCidade"
                  >
                    {{ optionCidade.cidade }}
                  </option>
                </select>
              </label>

              <label>
                <div class="flux-address-label">
                  {{ $t('flux.state') }}
                </div>
                <select
                    v-model="addressForm.estado"
                    class="flux-address-input small input-select"
                    name="name"
                    required
                >
                  <option
                      v-for="option in options"
                      v-bind:key="option.codigoEstado"
                  >
                    {{ option.codigoEstado }}
                  </option>
                </select>
              </label>
            </div>

            <div class="flux-button">
              <div
                  :class="{'disable':  !isValid}"
                  class="flux-button-save align-content-center"
                  @click="saveNewAddress"
              >
                <span>
                  {{ addressForm.codEnd ? $t('global.save') : $t('flux.add') }}
                </span>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Dialogo de informação ^^-->
    </div>
    <v-dialog
        v-model="error"
        persistent
        width="500"
    >
      <v-alert
          class="mb-0"
          prominent
          type="error"
      >
        <v-row align="center">
          <v-col class="grow">{{ msgError }}</v-col>
          <v-col class="shrink">
            <v-btn
                text
                @click="error=false"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import AddressCard from '@/screen/Flux/AddressCard'
import FreightCard from '@/screen/Flux/FreightCard'
import { mapMutations, mapState } from 'vuex'
import { FluxResource } from '@/api'

export default {
  name: 'FluxAddress',
  components: { AddressCard, XMarkIcon, FreightCard },
  data () {
    return {
      selected: '',
      dialogInfo: false,
      addressForm: {},
      options: [],
      optionsCidades: [],
      msgError: '',
      error: false,
      selectedFreightR: false,
      continueAddr: false
    }
  },
  watch: {
    'addressForm.estado': function (newValue) {
      this.combos(newValue)
    },
  },
  computed: {
    ...mapState('fluxStore', ['addresses', 'selectedAddress', 'freight', 'step']),
    showHeaderAddLink () {
      return this.addresses.length > 0
    },
    centered () {
      return this.addresses.length < 2
    },
    isValid () {
        return !!(this.addressForm.cep &&
            this.addressForm.endereco &&
            this.addressForm.numeroEndereco &&
            this.addressForm.estado &&
            this.addressForm.cidade &&
            this.addressForm.bairro)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('fluxStore', ['SET_SELECTED_ADDRESS', 'SET_CAN_NEXT', 'SET_ADDRESSES', 'SET_FREIGHT', 'SET_STEP']),
    init () {
      this.SET_FREIGHT('')
      this.SET_SELECTED_ADDRESS('')
      this.SET_CAN_NEXT(false)

      if (this.addresses.length === 1) {
        this.select(this.addresses[0], 0)
      }
    },
    async combos (estado) {
      let estados = await FluxResource.getEstados()
      this.options = estados.body
      let cidades = await FluxResource.getCidades(estado)
      this.optionsCidades = cidades.body
    },
    async reload () {
      const response = await FluxResource.getAddress()
      this.SET_ADDRESSES(response.body)
    },
    async getCepData () {
      if (!this.addressForm.cep.onlyNumber()) return
      const response = await FluxResource.getCep(this.addressForm.cep.onlyNumber())
      this.addressForm.endereco = response.body.logradouro
      this.addressForm.complemento = response.body.complemento
      this.addressForm.bairro = response.body.bairro
      this.addressForm.cidade = response.body.localidade
      this.addressForm.estado = response.body.uf
      this.combos(this.addressForm.estado)
    },
    async remove (address) {
      await FluxResource.deleteAddress(address.codEnd)
      await this.reload()
    },
    edit (address) {
      this.addressForm = address
      this.dialogInfo = true
    },
    validateNumber(valor) {
      const regex = /^[0-9\b]+$/
      return !regex.test(valor)
    },
    changeFreight (freight) {
      this.SET_FREIGHT(freight)
      if (freight === 'R') {
        this.selectedFreightR = !this.selectedFreightR
        this.selectedFreightE ? this.selectedFreightE = false : this.selectedFreightE = this.selectedFreightE
        this.SET_CAN_NEXT(true)
      } 

      if(!this.selectedFreightR) {
        this.SET_FREIGHT('')
        this.SET_CAN_NEXT(false)
      }
    },
    async saveNewAddress () {
      if (this.validateNumber(this.addressForm.cep)) {
        this.msgError = this.$t('flux.zipCodeNumber')
        this.error = true

        return
      }
      if (this.addressForm.codEnd) {
        // edit
        await FluxResource.putAddress({
          'codEnd': this.addressForm.codEnd,
          'cep': this.addressForm.cep,
          'endereco': this.addressForm.endereco,
          'numeroEndereco': this.addressForm.numeroEndereco,
          'complemento': this.addressForm.complemento,
          'estado': this.addressForm.estado,
          'cidade': this.addressForm.cidade,
          'descricaoCidade': null,
          'bairro': this.addressForm.bairro,
          'principal': this.selectedAddress.codEnd === this.addressForm.codEnd ? 'S' : null
        })
      } else {
        // insert
        await FluxResource.postAddress({
          'cep': this.addressForm.cep,
          'endereco': this.addressForm.endereco,
          'numeroEndereco': this.addressForm.numeroEndereco,
          'complemento': this.addressForm.complemento,
          'estado': this.addressForm.estado,
          'cidade': this.addressForm.cidade,
          'descricaoCidade': null,
          'bairro': this.addressForm.bairro,
          'principal': 'S'
        })
      }
      this.dialogInfo = false
      await this.reload()
    },
    async saveAddr (address) {
      if (address.codEnd) {
        // edit
        await FluxResource.putAddress({
          'codEnd': address.codEnd,
          'cep': address.cep,
          'endereco': address.endereco,
          'numeroEndereco': address.numeroEndereco,
          'complemento': address.complemento,
          'estado': address.estado,
          'cidade': address.cidade,
          'descricaoCidade': null,
          'bairro': address.bairro,
          'principal': 'S'
        })
      }
      this.dialogInfo = false
      await this.reload()
    },
    async save () {
      if (this.freight !== '' && this.continueAddr) {
        return true
      } else if (this.freight === '' && !this.continueAddr) {
        if (!this.selectedAddress) {
          this.msgError = this.$t('flux.chooseAddress')
          this.error = true
          return 'erro'
        } else {
            this.continueAddr = true
            this.SET_CAN_NEXT(false)
            return "endereco"
        }
      } else if (this.freight === '') {
        this.msgError = this.$t('flux.chooseFreight')
        this.error = true
        return 'erro'
      }
    },
    select (address, i) {
      this.SET_SELECTED_ADDRESS(address)
      this.SET_CAN_NEXT(true)
      if(address.principal !== 'S') {
        this.saveAddr(address)
      }
      this.selected = Number.tryParseInt(i)

    }
  },
}
</script>

<style
    scoped
    type="text/css"
>
label {
  width: fit-content;
}

.flux-address-header {
  display: flex;
  flex-flow: row;
}

.flux-address-title {
  flex: 1;
  font: 20px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 52px;
}

.flux-address-header > .flux-address-add-link {
  flex: 0;
  margin-top: 4px;
  cursor: pointer;
}

.flux-address-add-link {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #27BADC;
  white-space: nowrap;
  cursor: pointer;
}

.flux-address-content {
  flex: 1;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  min-width: 100%;
  max-width: 100%;
  min-height: fit-content;
  max-height: 497px;
  justify-content: space-between;
  align-items: center;
}

/*noinspection CssUnusedSymbol*/
.flux-address-content.center {
  flex: 0;
  justify-content: center;
}

.flux-address-card {
  margin-bottom: 24px;
  flex: 0;
  padding: 20px 16px;
  border-radius: 10px;
  min-width: 406px;
  max-width: 406px;
  min-height: 127px;
  max-height: 127px;
  background: #F5F5F7 0 0 no-repeat padding-box;
}


.flux-address-card-content {
  min-width: 90px;
  min-height: 90px;
}

.dialog-info-header {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: nowrap;
}

.dialog-info-title {
  text-align: left;
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  flex: 1;
}

.dialog-info-close {
  flex: 0;
  cursor: pointer;
}

.flux-address-label {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
}

.flux-address-input {
  background: #F5F6F7 0 0 no-repeat padding-box;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #161616;
  opacity: 1;
  border: 1px solid #ECECEC;
  height: 28px;
  width: 406px;
  padding-left: 11px;
  margin-bottom: 20px;
  flex: 0;
  box-shadow: -2px -2px 2px #00000014, 2px 2px 2px #FFFFFF;
  border-radius: 6px;
}

.flux-address-input.medium {
  width: 232px;
}

.flux-address-input.small {
  width: 149px;
}

.flux-button-save {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 140px;
  flex: 0;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flux-button-save > span {
  display: block;
  line-height: 1;
}

/*noinspection ALL*/
.flux-button-save.disable {
  color: #2d2d2d !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
  cursor: default;
}

.flux-address-box {
  min-height: 300px;
  width: 100%;
}

.flux-address-overflow {
  height: 100%;
  overflow: auto;
}

.flux-address-form {
  width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.input-select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}

@media screen and (max-width: 1279px) {

  .flux-address-content {
    height: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .flux-address-box {
    min-height: 300px;
    width: 100%;
    height: 100%;
  }

  .flux-address-title {
    margin-bottom: 10px;
  }

  .minHeight {
     min-height: 650px;
  }

}

@media screen and (max-width: 720px) {
  .flux-address-card {
    max-width: 100%;
    min-width: 100%;
  }

  .flux-address-card-md-down {
    width: 100%;
  }

  .minHeight {
     min-height: auto;
  }

}

@media screen and (max-width: 601px) {
  label,
  .flux-address-input,
  .flux-address-input.medium,
  .flux-address-input.small {
    width: 100%;
  }

  .flux-address-form {
    width: 100%;
  }

}

</style>
