<template>
  <div
      class="countdown"
      :class="{'small': small}"
  >
    <span class="time-initials-legend">{{ $t('OfferBar.countdown') }}: </span>

    <span v-if="countdown.day > 0">
      <span class="time-count">{{ countdown.day }}</span>
      <span class="time-initials" :style="countdown.day > 0 ? 'margin-right: 10px;' : ''">D</span>
    </span>

    <span class="time-count">{{ countdown.hour }}</span>
    <span class="time-initials" :style="countdown.day > 0 ? 'margin-right: 10px;' : ''">H</span>

    <span class="time-count">{{ countdown.min }}</span>
    <span class="time-initials" :style="countdown.day > 0 ? 'margin-right: 10px;' : ''">Min</span>

    <span class="time-count">{{ countdown.sec }}</span>
    <span class="time-initials" :style="countdown.day > 0 ? 'margin-right: 10px;' : ''">S</span>

  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'Countdown',
  props: {
    time: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countdown: {
        day: 10,
        hour: 10,
        min: 10,
        sec: 10,
      },
      timeoutId: null
    }
  },
  mounted () {
    this.recursiveCountdown()
  },
  watch: {
    time (newValue, oldValue) {
      if (newValue === oldValue) { return }
      this.recursiveCountdown()
    }
  },
  methods: {
    recursiveCountdown () {
      if (this.timeoutId) { clearTimeout(this.timeoutId) }
      this.countdown = this.getCountdown(this.time)
      if (this.countdown.day > 0 || this.countdown.hour > 0 || this.countdown.min > 0 || this.countdown.sec > 0) {
        return this.timeoutId = setTimeout(this.recursiveCountdown, 1000)
      }
      this.countdown = {
        day: 0,
        hour: 0,
        min: 0,
        sec: 0,
      }
    },  
    getCountdown (end) {
      const actualDate = new Date(moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
      const finalDate = new Date(moment(end).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
      const days = Math.floor(Math.abs(finalDate.getTime() - actualDate.getTime()) / (1000 * 3600 * 24));

      const endMoment = moment(end)
      const countdown = moment.duration(endMoment.diff(moment()))
      return {
        day: days,
        hour: countdown.hours(),
        min: countdown.minutes(),
        sec: countdown.seconds(),
      }
    },
  },
}
</script>
<style
    scoped
    type="text/css"
>

p.countdown {
  font: 30px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  margin-bottom: 12px;
}

p.countdown.small {
  font: 20px 'HelveticaNeueLTPro-Bd';
  margin-bottom: 8px;
}

.time-count {
  font: 14px 'HelveticaNeue-Bold';
  color: #000000;
}

.time-initials {
  font: 10px 'HelveticaNeue-Light';
  letter-spacing: 0;
  color: #000000;
  font-weight: bold;
  margin-right: 30px;
  margin-left: 3px;
}

.time-initials-legend {
  font: 12px 'HelveticaNeueLTPro-Roman';
  color: #000000;
  font-weight: bold;
  margin-right: 10px;
}

</style>