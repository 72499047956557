
const FilterApi = (vueResource, url) => {
  const resource = vueResource(`${url}/filter{/PATH}{/ID}{?Q*}`)

  return {
    async getList (id) {
      try {
        return await resource.get({ ID: id })
      } catch (e) {
        return { body: [] }
      }
    }
  }
}
export default FilterApi