<template>
  <svg v-if="on" id="Grupo_777" data-name="Grupo 777" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-1 -1 44 44">
  <g id="Elipse_99" data-name="Elipse 99" fill="#fff" stroke="#60BED4" stroke-width="1">
    <circle cx="21" cy="21" r="21" stroke="#60BED4"/>
    <circle cx="21" cy="21" r="20.5" fill="none"/>
  </g>
  <g id="Grupo_65" data-name="Grupo 65" transform="translate(11.25 10.275)">
    <g id="Grupo_64" data-name="Grupo 64">
      <g id="Grupo_62" data-name="Grupo 62" transform="translate(4.386)">
        <path id="Caminho_16" data-name="Caminho 16" d="M496.74,396.86a5.768,5.768,0,0,0,.58.506A5.385,5.385,0,0,1,496.74,396.86Z" transform="translate(-495.167 -387.694)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_17" data-name="Caminho 17" d="M498.88,398.62a5.593,5.593,0,0,0,1.149.591A5.169,5.169,0,0,1,498.88,398.62Z" transform="translate(-496.511 -388.8)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_7" data-name="Caminho 7" d="M499.673,372.22a5.352,5.352,0,0,0-4.293,2.161,5.365,5.365,0,0,1,9.657,3.207v.007a5.364,5.364,0,0,1-2.8,4.709,5.367,5.367,0,0,0-2.567-10.084Z" transform="translate(-494.312 -372.22)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_18" data-name="Caminho 18" d="M503.234,377.613v.007a5.364,5.364,0,0,1-2.8,4.709,2.464,2.464,0,0,1-.242.123c-.078.037-.156.074-.238.108s-.16.067-.242.1c-.16.056-.324.108-.491.153a3.34,3.34,0,0,1-.346.074,1.611,1.611,0,0,1-.193.034c-.082.015-.167.026-.253.033a1.692,1.692,0,0,1-.2.015c-.115.011-.231.015-.35.015a5.484,5.484,0,0,1-1.856-.324,5.594,5.594,0,0,1-1.149-.591c-.074-.048-.145-.1-.216-.149a5.776,5.776,0,0,1-.58-.506c-.119-.119-.234-.246-.346-.376s-.212-.272-.309-.417-.186-.29-.268-.439a5.348,5.348,0,0,1-.651-2.559v-.007q0-.156.011-.312a4.09,4.09,0,0,1,.045-.428,1.234,1.234,0,0,1,.022-.164c.011-.059.022-.119.033-.175.022-.115.048-.231.082-.342s.071-.242.112-.357a4.7,4.7,0,0,1,.227-.547c.074-.152.156-.3.242-.446a.013.013,0,0,1,0-.007c.089-.145.186-.283.286-.42,0,0,0,0,0-.007a5.365,5.365,0,0,1,9.657,3.206Z" transform="translate(-492.51 -372.245)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
      </g>
      <g id="Grupo_63" data-name="Grupo 63" transform="translate(0 12.993)">
        <path id="Caminho_19" data-name="Caminho 19" d="M499.883,403.731c-.144-.044-.291-.074-.436-.106C499.594,403.655,499.74,403.689,499.883,403.731Z" transform="translate(-492.481 -403.491)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_20" data-name="Caminho 20" d="M495.976,403.412a5.766,5.766,0,0,0-1.152-.117l-.007,0a5.525,5.525,0,0,0-.9.063A5.374,5.374,0,0,1,495.976,403.412Z" transform="translate(-489.01 -403.278)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_21" data-name="Caminho 21" d="M514.327,403.408a5.469,5.469,0,0,0-1.209-.126,5.774,5.774,0,0,0-1.812.294,6.6,6.6,0,0,1-.8.208,6.424,6.424,0,0,0,.785-.208v-.007A5.449,5.449,0,0,1,514.327,403.408Z" transform="translate(-499.429 -403.265)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_9" data-name="Caminho 9" d="M500.216,410.953a1.819,1.819,0,0,0,.141-.7,7.379,7.379,0,0,0-.246-1.9,7.528,7.528,0,0,1,.242,1.893v.007A1.817,1.817,0,0,1,500.216,410.953Zm0,0a1.832,1.832,0,0,1-.387.573A1.7,1.7,0,0,0,500.212,410.957Zm-.655.792a1.826,1.826,0,0,1-.815.3A1.727,1.727,0,0,0,499.558,411.749Zm-16.9.305h-.007a1.789,1.789,0,0,1-.532-.082,1.725,1.725,0,0,0,.539.086h15.9a1.335,1.335,0,0,0,.175-.011c-.063,0-.123.007-.186.007Zm14.392-7.834a5.532,5.532,0,0,1,.982.658A4.93,4.93,0,0,0,497.05,404.22Zm-.532-.242a5.7,5.7,0,0,1,.532.242A4.028,4.028,0,0,0,496.518,403.979ZM485.24,403.8a5.258,5.258,0,0,0-2.7,1.722A5.431,5.431,0,0,1,485.24,403.8Zm-3.121,8.172a1.844,1.844,0,0,1-.737-.446A1.742,1.742,0,0,0,482.119,411.972Zm-.956-.714a1.853,1.853,0,0,1-.223-.465A1.731,1.731,0,0,0,481.163,411.258Z" transform="translate(-480.858 -403.601)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
        <path id="Caminho_22" data-name="Caminho 22" d="M500.215,409.938v.007a1.817,1.817,0,0,1-.138.7,0,0,0,0,1,0,0,1.834,1.834,0,0,1-.387.573,1.356,1.356,0,0,1-.268.219,1.826,1.826,0,0,1-.815.3s0,0-.007,0c-.063,0-.123.007-.186.007h-15.9a1.789,1.789,0,0,1-.532-.082,1.844,1.844,0,0,1-.737-.446,1.505,1.505,0,0,1-.219-.268,1.851,1.851,0,0,1-.223-.465,1.788,1.788,0,0,1-.082-.532v-.007a7.509,7.509,0,0,1,.335-2.213q.045-.151.1-.3a7.515,7.515,0,0,1,1.246-2.217,5.431,5.431,0,0,1,2.7-1.722c.175-.045.35-.086.528-.115a5.531,5.531,0,0,1,.9-.063l.007,0a5.711,5.711,0,0,1,1.588.223c.078.022.152.045.231.071a6.746,6.746,0,0,0,3.448.208,6.587,6.587,0,0,0,.8-.208,5.773,5.773,0,0,1,1.812-.294,5.471,5.471,0,0,1,1.209.126c.093.022.186.045.279.071.164.048.327.1.484.164a5.7,5.7,0,0,1,.532.242,5.528,5.528,0,0,1,.982.658c.074.063.149.13.223.2s.145.141.212.212.138.149.2.227l0,0a7.475,7.475,0,0,1,1.343,2.522c.034.1.06.2.089.305a.013.013,0,0,1,0,.007A7.527,7.527,0,0,1,500.215,409.938Z" transform="translate(-480.72 -403.293)" fill="none" stroke="#60BED4" stroke-width="1.5"/>
      </g>
    </g>
  </g>
</svg>
<svg v-else id="Grupo_777" data-name="Grupo 777" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-1 -1 44 44">
  <g id="Elipse_99" data-name="Elipse 99" fill="#fff" stroke="#666666" stroke-width="1">
    <circle cx="21" cy="21" r="21" stroke="#666666"/>
    <circle cx="21" cy="21" r="20.5" fill="none"/>
  </g>
  <g id="Grupo_65" data-name="Grupo 65" transform="translate(11.25 10.275)">
    <g id="Grupo_64" data-name="Grupo 64">
      <g id="Grupo_62" data-name="Grupo 62" transform="translate(4.386)">
        <path id="Caminho_16" data-name="Caminho 16" d="M496.74,396.86a5.768,5.768,0,0,0,.58.506A5.385,5.385,0,0,1,496.74,396.86Z" transform="translate(-495.167 -387.694)" fill="none" stroke="#666" stroke-width="1.5"/>
        <path id="Caminho_17" data-name="Caminho 17" d="M498.88,398.62a5.593,5.593,0,0,0,1.149.591A5.169,5.169,0,0,1,498.88,398.62Z" transform="translate(-496.511 -388.8)" fill="none" stroke="#666666" stroke-width="1.5"/>
        <path id="Caminho_7" data-name="Caminho 7" d="M499.673,372.22a5.352,5.352,0,0,0-4.293,2.161,5.365,5.365,0,0,1,9.657,3.207v.007a5.364,5.364,0,0,1-2.8,4.709,5.367,5.367,0,0,0-2.567-10.084Z" transform="translate(-494.312 -372.22)" fill="none" stroke="#666666" stroke-width="1.5"/>
        <path id="Caminho_18" data-name="Caminho 18" d="M503.234,377.613v.007a5.364,5.364,0,0,1-2.8,4.709,2.464,2.464,0,0,1-.242.123c-.078.037-.156.074-.238.108s-.16.067-.242.1c-.16.056-.324.108-.491.153a3.34,3.34,0,0,1-.346.074,1.611,1.611,0,0,1-.193.034c-.082.015-.167.026-.253.033a1.692,1.692,0,0,1-.2.015c-.115.011-.231.015-.35.015a5.484,5.484,0,0,1-1.856-.324,5.594,5.594,0,0,1-1.149-.591c-.074-.048-.145-.1-.216-.149a5.776,5.776,0,0,1-.58-.506c-.119-.119-.234-.246-.346-.376s-.212-.272-.309-.417-.186-.29-.268-.439a5.348,5.348,0,0,1-.651-2.559v-.007q0-.156.011-.312a4.09,4.09,0,0,1,.045-.428,1.234,1.234,0,0,1,.022-.164c.011-.059.022-.119.033-.175.022-.115.048-.231.082-.342s.071-.242.112-.357a4.7,4.7,0,0,1,.227-.547c.074-.152.156-.3.242-.446a.013.013,0,0,1,0-.007c.089-.145.186-.283.286-.42,0,0,0,0,0-.007a5.365,5.365,0,0,1,9.657,3.206Z" transform="translate(-492.51 -372.245)" fill="none" stroke="#666666" stroke-width="1.5"/>
      </g>
      <g id="Grupo_63" data-name="Grupo 63" transform="translate(0 12.993)">
        <path id="Caminho_19" data-name="Caminho 19" d="M499.883,403.731c-.144-.044-.291-.074-.436-.106C499.594,403.655,499.74,403.689,499.883,403.731Z" transform="translate(-492.481 -403.491)" fill="none" stroke="#666666" stroke-width="1.5"/>
        <path id="Caminho_20" data-name="Caminho 20" d="M495.976,403.412a5.766,5.766,0,0,0-1.152-.117l-.007,0a5.525,5.525,0,0,0-.9.063A5.374,5.374,0,0,1,495.976,403.412Z" transform="translate(-489.01 -403.278)" fill="none" stroke="#666666" stroke-width="1.5"/>
        <path id="Caminho_21" data-name="Caminho 21" d="M514.327,403.408a5.469,5.469,0,0,0-1.209-.126,5.774,5.774,0,0,0-1.812.294,6.6,6.6,0,0,1-.8.208,6.424,6.424,0,0,0,.785-.208v-.007A5.449,5.449,0,0,1,514.327,403.408Z" transform="translate(-499.429 -403.265)" fill="none" stroke="#666666" stroke-width="1.5"/>
        <path id="Caminho_9" data-name="Caminho 9" d="M500.216,410.953a1.819,1.819,0,0,0,.141-.7,7.379,7.379,0,0,0-.246-1.9,7.528,7.528,0,0,1,.242,1.893v.007A1.817,1.817,0,0,1,500.216,410.953Zm0,0a1.832,1.832,0,0,1-.387.573A1.7,1.7,0,0,0,500.212,410.957Zm-.655.792a1.826,1.826,0,0,1-.815.3A1.727,1.727,0,0,0,499.558,411.749Zm-16.9.305h-.007a1.789,1.789,0,0,1-.532-.082,1.725,1.725,0,0,0,.539.086h15.9a1.335,1.335,0,0,0,.175-.011c-.063,0-.123.007-.186.007Zm14.392-7.834a5.532,5.532,0,0,1,.982.658A4.93,4.93,0,0,0,497.05,404.22Zm-.532-.242a5.7,5.7,0,0,1,.532.242A4.028,4.028,0,0,0,496.518,403.979ZM485.24,403.8a5.258,5.258,0,0,0-2.7,1.722A5.431,5.431,0,0,1,485.24,403.8Zm-3.121,8.172a1.844,1.844,0,0,1-.737-.446A1.742,1.742,0,0,0,482.119,411.972Zm-.956-.714a1.853,1.853,0,0,1-.223-.465A1.731,1.731,0,0,0,481.163,411.258Z" transform="translate(-480.858 -403.601)" fill="none" stroke="#666666" stroke-width="1.5"/>
        <path id="Caminho_22" data-name="Caminho 22" d="M500.215,409.938v.007a1.817,1.817,0,0,1-.138.7,0,0,0,0,1,0,0,1.834,1.834,0,0,1-.387.573,1.356,1.356,0,0,1-.268.219,1.826,1.826,0,0,1-.815.3s0,0-.007,0c-.063,0-.123.007-.186.007h-15.9a1.789,1.789,0,0,1-.532-.082,1.844,1.844,0,0,1-.737-.446,1.505,1.505,0,0,1-.219-.268,1.851,1.851,0,0,1-.223-.465,1.788,1.788,0,0,1-.082-.532v-.007a7.509,7.509,0,0,1,.335-2.213q.045-.151.1-.3a7.515,7.515,0,0,1,1.246-2.217,5.431,5.431,0,0,1,2.7-1.722c.175-.045.35-.086.528-.115a5.531,5.531,0,0,1,.9-.063l.007,0a5.711,5.711,0,0,1,1.588.223c.078.022.152.045.231.071a6.746,6.746,0,0,0,3.448.208,6.587,6.587,0,0,0,.8-.208,5.773,5.773,0,0,1,1.812-.294,5.471,5.471,0,0,1,1.209.126c.093.022.186.045.279.071.164.048.327.1.484.164a5.7,5.7,0,0,1,.532.242,5.528,5.528,0,0,1,.982.658c.074.063.149.13.223.2s.145.141.212.212.138.149.2.227l0,0a7.475,7.475,0,0,1,1.343,2.522c.034.1.06.2.089.305a.013.013,0,0,1,0,.007A7.527,7.527,0,0,1,500.215,409.938Z" transform="translate(-480.72 -403.293)" fill="none" stroke="#666666" stroke-width="1.5"/>
      </g>
    </g>
  </g>
</svg>

</template>
<script>
export default {
  name: 'UserIcon',
  props: {
    on: {
      type: Boolean,
      default: false
    },
  },
}
</script>
<style scoped>

a {
  opacity: 0;
}

.b {
  fill: none;
}

.c, .d {
  stroke: none;
}

.d {
  fill: #c9cdd0;
}

</style>
