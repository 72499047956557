<template>
  <div
      class="flux-freight-card"
    :class="{'selected': selected}"
  >
    <div
        style="height: fit-content"
    >
      <div
          class="flux-freight-card-content"
      >
      <div class="flux-freight-icon">
        <div v-if="icon === 'Retirada'">
          <TruckIcon
           :active="selected"
          />
        </div>
        <div v-else>
          <TakingIcon
           :active="selected"
          />
        </div>
      </div>
        <div
            class="flux-freight-text"
            :class="{'selected': selected}"
        >
          {{freightText}}
        </div>
         <div
             class="flux-freight-text-sub"
             :class="{'selected': selected}"
         >
           {{ freightSub }}
         </div>
      </div>
    </div>
  </div>
</template>
<script>

import TruckIcon from '@/shared/Icons/TruckIcon'
import TakingIcon from '@/shared/Icons/TakingIcon'

export default {
  name: 'FreightCard',
  components: {
    TruckIcon,
    TakingIcon
  },
  props: {
    freightText: {
      type: String
    },
    freightSub: {
      type: String
    },
    selected: {
      type: Boolean
    },
    icon: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style
    scoped
    type="text/css"
>

.flux-freight-card {
  margin-bottom: 24px;
  flex: 0;
  padding: 20px 16px;
  border-radius: 10px;
  min-width: 406px;
  max-width: 406px;
  min-height: 75px;
  max-height: 75px;
  background: #F5F5F7 0 0 no-repeat padding-box;
  cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.flux-freight-card.selected {
  background: #60BED4 0 0 no-repeat padding-box;
}

.flux-freight-icon {
    width: 55px;
    height: 45px;
    float: left;
}

.flux-freight-text {
  font: 14px 'HelveticaNeueLTPro-Roman';
  color: #565656;
  letter-spacing: 0;
}

.flux-freight-text-sub {
  font: 12px 'HelveticaNeueLTPro-Roman';
  color: #B1B1B1;
  letter-spacing: 0;
  margin-top: 6px;
}

/*noinspection CssUnusedSymbol*/
.flux-freight-text.selected {
  color: #FFFFFF;
}

/*noinspection CssUnusedSymbol*/
.flux-freight-text-sub.selected {
  color: #FFFFFF;
}

.flux-freight-card-actions {
  display: flex;
  flex-flow: row;
}

.flux-freight-edit {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  margin-right: 20px;
  color: #27badc;
}

.flux-freight-edit.selected {
  color: #666666;
}

.flux-freight-delete {
  font: 14px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #27badc;
}

.flux-freight-delete.selected {
  color: #666666;
}


@media screen and (max-width: 720px) {
  .flux-freight-card {
    min-width: 100%;
    max-width: 100%;
  }
}


</style>
