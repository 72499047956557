// store.js
import Vue from 'vue'
import Vuex from 'vuex'
import homeStore from '@/screen/Home/store'
import offerStore from '@/screen/Offer/store'
import authStore from '@/screen/Auth/store'
import notificationStore from '@/screen/Notification/store'
import interestsStore from '@/screen/Interests/store'
import negotiationStore from '@/screen/Negotiation/store'
import profileStore from '@/screen/Profile/store'
import FavoritesStore from '@/screen/Favorites/store'
import fluxStore from '@/screen/Flux/store'
import headerStore from '@/shared/HeaderBar/store'
import surveyStore from '@/screen/Survey/store'
import cancelamentoStore from "@/screen/Cancelamento/store";
import registerPartnerStore from "@/screen/RegisterPartner/store";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    homeStore,
    offerStore,
    authStore,
    notificationStore,
    interestsStore,
    negotiationStore,
    profileStore,
    FavoritesStore,
    fluxStore,
    headerStore,
    surveyStore,
    cancelamentoStore,
    registerPartnerStore
  }
})

export default store