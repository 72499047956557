<template>
  <v-layout
      justify-space-around
      row
      wrap
  >
    <v-card
        class="mx-12 my-12"
        color="transparent"
        elevation="0"
    >
      <Interests
          @save="saveInterests"
      />
    </v-card>
  </v-layout>
</template>

<script>
import Interests from '@/screen/Interests/Interests'

export default {
  name: 'InterestsIndex',
  components: { Interests },
  methods: {
    async saveInterests () {
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/auth/cadastro-finalizado`
      })
    },
  }
}
</script>

<style
    scoped
    type="text/css"
>

</style>