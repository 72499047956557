<template>
  <div>
    <!--<HeaderBarDesktop v-if="isDesktop"/>
    <HeaderBarTablet v-else/>
    <HeaderBarMobile v-if="isMobile && false"/>-->
    <HeaderBarDesktop v-if="isDesktop"/>
    <HeaderBarTablet v-else-if="isTablet"/>
    <HeaderBarMobile v-else/>
  </div>
</template>

<script>

import HeaderBarDesktop from '@/shared/HeaderBar/HeaderBarDesktop'
import HeaderBarTablet from '@/shared/HeaderBar/HeaderBarTablet'
import HeaderBarMobile from '@/shared/HeaderBar/HeaderBarMobile'
import { mapMutations } from 'vuex'

export default {
  name: 'HeaderBar',
  components: { HeaderBarMobile, HeaderBarTablet, HeaderBarDesktop },
  computed: {
    isDesktop () {
      return this.$vuetify.breakpoint.lgAndUp
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    isTablet () {
      return !this.$vuetify.breakpoint.lgAndUp && !this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    isDesktop (newValue, oldValue) {
      if (newValue !== oldValue) this.reload()
    },
    isMobile (newValue, oldValue) {
      if (newValue !== oldValue) this.reload()
    },
    isTablet (newValue, oldValue) {
      if (newValue !== oldValue) this.reload()
    },
  },
  methods: {
    ...mapMutations('headerStore', ['SET_SHOW_SIDE_MENU', 'SET_HIDE_HEADER']),
    ...mapMutations('homeStore', ['SET_SHOW_FILTER_MOBILE']),
    reload () {
      this.SET_SHOW_SIDE_MENU(false)
      this.SET_HIDE_HEADER(false)
      this.SET_SHOW_FILTER_MOBILE(false)
    }
  }
}
</script>

<style scoped>

</style>