<template>
  <div class="mb-16">
    <div
        class="interests-container"
    >
      <div class="interests-header">
        <div class="interests-title">{{ $t('interests.interests') }}</div>
        <div class="interests-subtitle">
          {{ $t('interests.checkInterests') }}
        </div>
      </div>
      

      <v-form
          ref="form"
      >
        <div
            class="interests-category-box"
        >
          <div
              :key="i"
              @click="select(interest)"
              class="interests-category-button align-content-center"
              v-for="(interest, i) in interests"
          >
            <div
                :class="{'selected': isSelected(interest)}"
                class="interests-category-button-circle align-content-center"
            >
              <div
                  v-html="forceSize(interest.iconSvgAtivo)"
                  v-if="isSelected(interest)"
              />
              <div
                  v-else
                  v-html="forceSize(interest.iconSvgInativo)"
              />
            </div>
            <div class="interests-category-button-text">
              {{ capitalize(interest.name) }}
            </div>
          </div>
        </div>
      </v-form>
    </div>

    <v-row class="actions-button">
      <button type="button" class="p-button p-button-primary" @click="saveInterests">Próximo</button>
    </v-row>
  </div>
</template>

<script>
import {HeaderResource, InterestsResource} from '@/api'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'PartnerInterests',
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    goNextStep: {
      type: Function,
    },
    goPreviousStep: {
      type: Function,
    }
  },
  data () {
    return {
      interests: [],
      selected: []
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState('authStore', [
      'interestToken'
    ]),
  },
  methods: {
    ...mapActions('registerPartnerStore', ['NEXT_STEP', 'SET_LOADING']),
    async init () {
      const responseCategories = await HeaderResource.getList()
      this.interests = responseCategories.body
    },
    select (interest) {
      if (!interest) { return }
      if (this.selected.indexOf(interest.codigo) >= 0) {
        this.selected.splice(this.selected.indexOf(interest.codigo), 1)
      } else {
        this.selected.push(interest.codigo)
      }
    },
    isSelected (interest) {
      if (!interest) { return false }
      return this.selected.indexOf(interest.codigo) >= 0
    },
    async saveInterests () {
      this.SET_LOADING(true)
      if (this.interestToken) {
        await InterestsResource.postInterests({
          tokenUsrio: this.interestToken,
          codigoCategoria: this.selected
        })
      } else {
        await InterestsResource.putInterests({
          codigoCategoria: this.selected
        })
      }
      this.SET_LOADING(false)
      this.NEXT_STEP()
    },
    forceSize (svg) {
      if (!svg) return ''
      const clean = svg
          .replace(/width=["']\d*\.?\d*["']/, '')
          .replace(/height=["']\d*\.?\d*["']/, '')
          .replace(/[\n\r\t]/g, '')
      const rgx = /<svg.*>/

      try {
        return clean.replace(rgx, rgx.exec(clean)[0].replace('>', 'width="36" height="36">'))
      } catch (e) {
        return clean
      }
    },
    capitalize(str) {
      if (str === null || str.length === 0) return "";
      const firstLetter = str.charAt(0)
      const firstLetterCap = firstLetter.toUpperCase()
      const remainingLetters = str.slice(1)
      return firstLetterCap + remainingLetters.toLowerCase()
    },
  }
}
</script>

<style
    scoped
    type="text/css"
>

.interests-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-bottom: 16px;
}

.interests-header {
  position: relative;
  margin-bottom: 50px;
}

.actions {
  display: flex;
  gap: 20px;
  width: 100%;
}

.interests-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 3px #FFFFFF80;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 18px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 308px;
  height: 46px;
  cursor: pointer;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.interests-button-back {
  background-color: white;
  border: 1px solid #C9C9C9;
}

.interests-button > span {
  display: block;
  line-height: 1;
}

.interests-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
}

.interests-category-button {
  width: 80px;
  height: 92px;
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
}

.interests-category-button-circle {
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
  background: #FFF 0 0 no-repeat padding-box;
  border-radius: 100%;
}

.interests-category-button-circle.selected {
  background: #60BED4 0 0 no-repeat padding-box;
}

.interests-category-button-text {
  text-align: center;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.interests-category-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 87px;
  justify-content: center;
  /*justify-content: space-evenly;*/
  column-gap: 7px;
}

.interests-container {
  max-width: 430px;
  margin: 0 auto;
  margin-top: 3rem;
}

@media screen and (max-width: 601px) {
  .interests-container {
    max-width: 100%;
  }
}

.actions-button {
  justify-content: center;
  margin-block: 20px;
  gap: 20px;
}

@media (max-width:768px) {
  .actions-button {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
</style>
