<template>
  <svg
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="btn_filtro"
        transform="translate(-20 -566)"
    >
      <circle
          cx="15"
          cy="15"
          data-name="Elipse 13"
          fill="#cdd43f"
          id="Elipse_13"
          r="15"
          transform="translate(20 566)"
          v-if="showCircle"
      />
      <g
          id="ic_filter"
          transform="translate(17)"
      >
        <g
            data-name="Grupo 21"
            id="Grupo_21"
            transform="translate(21.237 571.976)"
        >
          <path
              d="M66.863,21.278A2.384,2.384,0,0,0,65.15,19V16.569a.669.669,0,1,0-1.338,0V19A2.36,2.36,0,0,0,62.1,21.278a2.392,2.392,0,0,0,1.712,2.3v9.873a.669.669,0,1,0,1.338,0V23.579A2.392,2.392,0,0,0,66.863,21.278Zm-1.338.027a1.043,1.043,0,1,1-1.043-1.043A1.045,1.045,0,0,1,65.525,21.3Z"
              data-name="Caminho 7080"
              :fill="fill"
              id="Caminho_7080"
              transform="translate(-62.1 -15.9)"
          />
        </g>
        <g
            data-name="Grupo 22"
            id="Grupo_22"
            transform="translate(15.886 572.003)"
        >
          <path
              d="M46.863,29.378A2.384,2.384,0,0,0,45.15,27.1V16.669a.669.669,0,1,0-1.338,0V27.13A2.36,2.36,0,0,0,42.1,29.4a2.392,2.392,0,0,0,1.712,2.3v1.846a.669.669,0,0,0,1.338,0V31.706A2.425,2.425,0,0,0,46.863,29.378Zm-1.338.027a1.043,1.043,0,1,1-1.043-1.043A1.045,1.045,0,0,1,45.525,29.4Z"
              data-name="Caminho 7081"
              :fill="fill"
              id="Caminho_7081"
              transform="translate(-42.1 -16)"
          />
        </g>
        <g
            data-name="Grupo 23"
            id="Grupo_23"
            transform="translate(10 572.003)"
        >
          <path
              d="M24.863,24.562a2.384,2.384,0,0,0-1.712-2.274V16.669a.669.669,0,1,0-1.338,0v5.645A2.36,2.36,0,0,0,20.1,24.589a2.392,2.392,0,0,0,1.712,2.3v6.662a.669.669,0,1,0,1.338,0V26.863A2.392,2.392,0,0,0,24.863,24.562Zm-1.338.027a1.043,1.043,0,1,1-1.043-1.043A1.045,1.045,0,0,1,23.525,24.589Z"
              data-name="Caminho 7082"
              :fill="fill"
              id="Caminho_7082"
              transform="translate(-20.1 -16)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'FilterIcon',
  props: {
    showCircle: {
      type: Boolean,
      default: true
    },
    fill: {
      type: String,
      default: '#5a6222'
    },
  },
}
</script>
<style scoped>

</style>