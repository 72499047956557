import AuthStore from '@/screen/Auth/store';
import { OfferResource } from '@/api'

export const getAdditionalInformationValue = function(vehicle, idAddInfo) {
  if (
    vehicle &&
    vehicle.typeVehiclesDto &&
    vehicle.typeVehiclesDto.additionalInformation
  ) {
    const addInfoList = vehicle.typeVehiclesDto.additionalInformation;
    const addInfo = addInfoList.find(
      (info) => info.codigoAdditionalInformation === idAddInfo
    );
    if (addInfo) {
      return addInfo.value;
    }
    return null;
  }
}

export const getOfferProperties = async function(offer) {
  const offer_id = offer.codigo
  const vehicles = offer.vehicles

  let category = ''
  let model = ''
  let year = ''
  let mileage = 0
  let fuel = ''
  let product_id = ''
  let transimission = ''
  let doors = 0
  let type_offer = 'Comum'

  // Oferta comum, i.e, apenas um ativo
  if (vehicles.length === 1) {
    const vehicle = vehicles[0]
    category = vehicle.categoria
    model = vehicle.model
    year = `${vehicle.yearManufacture}/${vehicle.yearModel}`
    mileage = vehicle.km
    fuel = vehicle.combustivel
    product_id = vehicle.version
    transimission = getAdditionalInformationValue(vehicle, 119)
    doors = getAdditionalInformationValue(vehicle, 120)
  } else {
    type_offer = 'Lote'
  }

  const response = await OfferResource.getPrice(offer_id)
  const offerPrice = response.body || {
    codigoOferta: 0,
    codigoPerfil: 0,
    valorCompreJa: 0,
    valorLeilao: 0
  }

  return {
    offer_id,
    type_offer,
    category,
    model,
    year,
    mileage,
    price: offerPrice.valorCompreJa,
    fuel,
    product_id,
    transimission,
    doors: parseInt(doors)
  }
}

export const getLoggedUserId = function() {
  const user = AuthStore.state.user
  if (user.codigoUsuario) {
    return `${user.codigoUsuario}`
  }
  return null
}

export const getBidTypeFrom = function(fluxType) {
  switch (fluxType) {
    case 'PURPOSE':
      return 'PROPOSTA'
    case 'AUTO_BID':
      return 'Lance Automático'
    case 'BID':
      return 'Lance Manual'
    default:
      return 'Compra'
  }
}
