<template>
  <div
      :style="style"
      class="align-content-center"
      ref="placeholder"
  >
    <slot>
      <h5
          :style="onlyTitle === 'true' ? '' : 'margin-right: 12px'"
          v-if="text"
      >{{text}}</h5>
      <h5 v-if="onlyTitle !== 'true'">{{print}}</h5>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'Placeholder',
    props: {
      text: {
        type: String,
        default: ''
      },
      height: {
        type: String || Number,
        default: '100%'
      },
      width: {
        type: String || Number,
        default: '100%'
      },
      color: {
        type: String,
        default: '#babaca'
      },
      randomColor: {
        type: Boolean,
        default: false
      },
      onlyTitle: {
        type: String,
        default: 'false',
      }
    },
    data () {
      return {
        border: '',
        print: 'HxW'
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.updateSize)
    },
    computed: {
      style () {
        return `background: ${this.color}; ${this.heightStyle} ${this.widthStyle} ${this.border}`
      },
      heightStyle () {
        return `height: ${this.getSize(this.height)};`
      },
      widthStyle () {
        return `width: ${this.getSize(this.width)};`
      }
    },
    methods: {
      getSize (size) {
        return size.toString()
          .replace(/\d/g, '') ? size : `${size}px`
      },
      printText () {
        const height = this.$refs.placeholder ? this.$refs.placeholder.offsetHeight : this.height
        const width = this.$refs.placeholder ? this.$refs.placeholder.offsetWidth : this.width
        return `${height}x${width}`
      },
      getRandomColor () {
        var letters = '0123456789ABCDEF'
        var color = '#'
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)]
        }
        return color
      },
      updateSize () {
        this.print = this.printText()
      }
    },
    mounted () {
      this.border = `border: 1px solid ${this.getRandomColor()}`
      if (this.randomColor) {
        this.color = this.getRandomColor()
      }

      window.addEventListener('resize', this.updateSize)

      setTimeout(this.updateSize, 1)
    }
  }
</script>

<style scoped>


</style>