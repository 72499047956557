const PartnerApi = (vueResource, url) => {
  const resourcePartner = vueResource(`${url}/partner{/PATH}{/ID}{?Q*}`)

  return {
    async postNewPartner(payload) {
      try {
        return await resourcePartner.save(payload)
      } catch (e) {
        return e
      }
    },
    async verifyToken(token, email) {
      try {
        const response = await resourcePartner.get({ PATH: 'verify-token', Q: {token, email}})
        return response.ok
      } catch (e) {
        return false
      }
    },
    async postSiteName(payload) {
      try {
        return await resourcePartner.save({ PATH: 'url-loja' }, payload)
      } catch (e) {
        return e
      }
    },
  }
}

export default PartnerApi
