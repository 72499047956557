<template>
  <div class="survey-page">
    <v-alert
      color="red"
      type="warning"
      dense
      :dismissible="true"
      v-model="showAlertError"
    >
      Você deve responder para prosseguir
    </v-alert>

    <v-container v-if="ready">
      <question ref="question" :question="currentQuestion"></question>

      <v-row justify="center">
        <button type="button" class="dialog-button mt-10" @click="next" v-if="canNext">Próxima</button>
        <button type="button" class="dialog-button mt-10" @click="finalize" v-if="!canNext">Finalizar</button>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Question from '@/screen/Survey/Question'
import {mapMutations, mapState} from 'vuex'
import {SurveyResource} from '@/api'

export default {
  name: 'SurveyIndex',
  components: {
    Question
  },
  async mounted() {
    const guid = this.$route.query.guid
    const lance = this.$route.query.lance_id
    const response = await SurveyResource.getSurvey(guid, lance)
    this.survey = response.body
    if (this.survey.respondida) {
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/`
      })
    }
    this.questions = this.survey.perguntas
    this.ready = true
  },
  data: () => ({
    ready: false,
    questions: [],
    index: 0,
    survey: {
      respondida: false,
      perguntas: []
    },
    showAlertError: false
  }),
  methods: {
    ...mapMutations('surveyStore', ['ADD_ANSWER', 'RESET_ANSWERS']),
    next() {
      const form = this.$refs.question.form
      const isTextoLivre = this.currentQuestion.tipo === 'TEXTO_LIVRE'

      if (isTextoLivre && form.text === null) {
        this.showAlertError = true
      } else if (!isTextoLivre && form.selected.valor == null) {
        this.showAlertError = true
      } else {
        this.showAlertError = false

        if (this.canNext) {
          const saved = this.$refs.question.save()

          if (saved.isText) {
            this.ADD_ANSWER({
              pergunta: saved.question,
              opcao: null,
              resposta: saved.text
            })
          } else {
            this.ADD_ANSWER({
              pergunta: saved.question,
              opcao: saved.option,
              resposta: null
            })
          }

          this.index += 1
        }
      }
    },
    async finalize() {
      const form = this.$refs.question.form
      const isTextoLivre = this.currentQuestion.tipo === 'TEXTO_LIVRE'

      if (isTextoLivre && form.text === null) {
        this.showAlertError = true
      } else if (form.selected.valor == null) {
        this.showAlertError = true
      } else {
        const saved = this.$refs.question.save()

        if (saved.isText) {
          this.ADD_ANSWER({
            pergunta: saved.question,
            opcao: null,
            resposta: saved.text
          })
        } else {
          this.ADD_ANSWER({
            pergunta: saved.question,
            opcao: saved.option,
            resposta: null
          })
        }

        const answers = this.answers
        const guid = this.$route.query.guid
        const lance = this.$route.query.lance_id

        const response = await SurveyResource.postSurveyAnswers(guid, lance, { respostas: answers })

        if (response.ok) {
          await this.$router.push({
            path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+`/survey-finalization`
          })
        }
      }
    }
  },
  computed: {
    ...mapState('surveyStore', ['answers']),
    currentQuestion() {
      if (this.questions.length) {
        return this.questions[this.index]
      }
      return null
    },
    canNext() {
      return this.index + 1 < this.questions.length
    }
  },
}
</script>

<style lang="scss" scoped>
  .survey-page {
    background: #FFF;
    padding: 30px;
  }
  .question-text {
    font: 36px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
    margin-bottom: 50px;
    margin-top: 30px;
  }

  .question-option {
    font: 16px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
  }

  .dialog-button {
    background: #CDD43F 0 0 no-repeat padding-box;
    box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
    border-radius: 23px;
    opacity: 1;
    text-align: center;
    font: 16px 'HelveticaNeueLTPro-Md';
    letter-spacing: 0;
    color: #2D2D2D;
    min-width: 140px;
    flex: 0;
    height: 46px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

    &.cancel {
      background: #27BADC 0 0 no-repeat padding-box;
    }
  }

</style>
