<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="53" :height="height" viewBox="0 0 53 32">
    <g id="Grupo_1966" data-name="Grupo 1966" transform="translate(-784 -577)">
      <g id="Grupo_2093" data-name="Grupo 2093">
        <g id="Retângulo_591" data-name="Retângulo 591" transform="translate(784 577)" fill="#f5f5f7" stroke="#a7a7a7" stroke-width="1.5">
          <rect width="53" height="32" rx="4" stroke="none"/>
          <rect x="0.75" y="0.75" width="51.5" height="30.5" rx="3.25" fill="none"/>
        </g>
        <g id="Retângulo_592" data-name="Retângulo 592" transform="translate(787 581)" fill="#f5f5f7" stroke="#a7a7a7" stroke-width="1">
          <rect width="47" height="24" rx="1" stroke="none"/>
          <rect x="0.5" y="0.5" width="46" height="23" rx="0.5" fill="none"/>
        </g>
        <g id="Retângulo_593" data-name="Retângulo 593" transform="translate(794 584)" fill="#f5f5f7" stroke="#a7a7a7" stroke-width="1">
          <rect width="2" height="2" stroke="none"/>
          <rect x="0.5" y="0.5" width="1" height="1" fill="none"/>
        </g>
        <g id="Retângulo_594" data-name="Retângulo 594" transform="translate(794 600)" fill="#f5f5f7" stroke="#a7a7a7" stroke-width="1">
          <rect width="2" height="2" stroke="none"/>
          <rect x="0.5" y="0.5" width="1" height="1" fill="none"/>
        </g>
        <g id="Retângulo_595" data-name="Retângulo 595" transform="translate(825 600)" fill="#f5f5f7" stroke="#a7a7a7" stroke-width="1">
          <rect width="2" height="2" stroke="none"/>
          <rect x="0.5" y="0.5" width="1" height="1" fill="none"/>
        </g>
        <g id="Retângulo_596" data-name="Retângulo 596" transform="translate(825 584)" fill="#f5f5f7" stroke="#a7a7a7" stroke-width="1">
          <rect width="2" height="2" stroke="none"/>
          <rect x="0.5" y="0.5" width="1" height="1" fill="none"/>
        </g>
        <text id="ABC-1234" transform="translate(794 595.5)" fill="#a7a7a7" font-size="7" font-family="HelveticaNeueLT-75Bd, Helvetica Neue LT"><tspan x="0" y="0">ABC-1234</tspan></text>
        <line id="Linha_74" data-name="Linha 74" x2="45" transform="translate(788 588)" fill="none" stroke="#a7a7a7" stroke-width="1"/>
        <line id="Linha_76" data-name="Linha 76" x2="45" transform="translate(788 598)" fill="none" stroke="#a7a7a7" stroke-width="1"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LicensePlateIcon',
  props: {
    height: {
      type: String,
      default: '42'
    },
    width: {
      type: String,
      default: '42'
    }
  }
}
</script>

<style scoped>

</style>
