<template>
<v-container>

  <v-row align="center" justify="center" class="mt-10 mb-10">
    <span class="company-type-info mb-5">
      Você possui CNAE de revenda de automóveis e máquinas? Selecione a opção <b>Lojista*</b> abaixo e confira as ofertas exclusivas que temos para você.
    </span>
    <span class="company-type-info">
      Não possui CNAE de revenda? Nós também temos ofertas arrasadoras para a sua empresa. É só selecionar <b>Empresas em geral</b> para conferir.
    </span>
  </v-row>

  <v-row class="d-flex" :class="{ 'flex-column align-center' : $vuetify.breakpoint.smAndDown, 'justify-center': $vuetify.breakpoint.mdAndUp}">
    <UserTypeCard
      :title="'Lojista*'"
      :active="isLojista"
      :type="'LOJISTA'"
      @click="setCompanyType"
      :mobile="$vuetify.breakpoint.smAndDown"
    >
<!--      <PersonIcon :small="$vuetify.breakpoint.smAndDown" :active="isLojista"/>-->

      <car-dealership-icon :small="$vuetify.breakpoint.smAndDown" :active="isLojista"/>
    </UserTypeCard>

    <UserTypeCard
      :title="'Empresas em geral'"
      :active="isGeral"
      :type="'GERAL'"
      @click="setCompanyType"
      :mobile="$vuetify.breakpoint.smAndDown"
      :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 10px' : 'margin-left: 10px'"
    >
      <BuildingIcon :small="$vuetify.breakpoint.smAndDown" :active="isGeral"/>
    </UserTypeCard>
  </v-row>

  <v-row justify="center" class="mt-10">
    <span class="company-type-info-plus">*Exclusivo para CNAE de compra e venda de automóveis, caminhões, máquinas, implementos e ônibus.</span>
  </v-row>

</v-container>
</template>

<script>
import BuildingIcon from '@/shared/Icons/BuildingIcon'
import UserTypeCard from '@/screen/Auth/UserTypeCard'
import CarDealershipIcon from '@/shared/Icons/CarDealershipIcon'

export default {
  name: 'CompanyType',
  components: {UserTypeCard, BuildingIcon, CarDealershipIcon},
  data: () => ({
    companyType: null,
  }),
  computed: {
    isLojista() {
      return this.companyType !== null && this.companyType === 'LOJISTA'
    },
    isGeral() {
      return this.companyType !== null && this.companyType === 'GERAL'
    },
  },
  methods: {
    setCompanyType(type) {
      this.companyType = type
    },
    save() {
      return this.companyType
    }
  }
}
</script>

<style lang="scss" scoped>

.page-content {
  .page-title {
    font: 30px 'HelveticaNeue-Medium';
    letter-spacing: 0;
    color: #666666;
    opacity: 1;
  }

  .enter-here {
    font: 13px 'HelveticaNeue-Medium';
    letter-spacing: 0;
    color: #666666;
    opacity: 1;

    a {
      text-decoration: none;
      color: #60BED4;
    }
  }

  .company-type-info {
    font: 16px 'HelveticaNeue-Medium';
    letter-spacing: 0;
    color: #5D5D5D;
    opacity: 1;
    width: 70%;
    text-align: center;
  }

  @media (max-width: 768px) {
    .company-type-info {
      font-size: 10px;
      width: 100%;
      text-align: center;
    }

    .company-type-info-plus {
      font-size: 12px !important;
    }
  }

  .company-type-info-plus {
    font: 17px 'HelveticaNeue-Medium';
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
  }

}

.company-type-page {
  display: flex;
  margin: 30px;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 50px;

    .title {
      font: 36px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #666666;
      opacity: 1;
    }

    .enter-here {
      font: 13px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #666666;
      opacity: 1;

      a {
        text-decoration: none;
        color: #60BED4;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .message {
      font: 16px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #5D5D5D;
      opacity: 1;
      margin-bottom: 40px;
      width: 50%;

      @media only screen and (max-width: 475px) {
        font: 18px 'HelveticaNeue-Bold';
      }
    }

    .cards {
      display: flex;
      margin-bottom: 40px;

      .card:not(:first-child) {
        margin-left: 50px;
      }
    }

    .info-message {
      font: 17px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
      margin-bottom: 30px;
    }

    .actions {
      display: flex;
      justify-content: center;

      button:disabled {
        background: #D3D3D3 0 0 no-repeat padding-box;
        cursor: default;
      }

      .previous {
        font: 16px 'HelveticaNeue-Medium';
        width: 211px;
        height: 45px;
        background: #FFFFFF 0 0 no-repeat padding-box;
        border: 1px solid #C9C9C9;
        border-radius: 23px;
        opacity: 1;
        cursor: pointer;
      }

      .next {
        font: 16px 'HelveticaNeue-Medium';
        width: 211px;
        height: 45px;
        background: #CDD43F 0 0 no-repeat padding-box;
        box-shadow: -1px -1px 3px #FFFFFF80;
        border-radius: 23px;
        opacity: 1;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

}
</style>