<template>
  <svg
      :height="height"
      id="ic_back"
      viewBox="0 0 32 32"
      :width="width"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        data-name="ic_back"
        id="ic_back-2"
        transform="translate(30 29) rotate(180)"
    >
      <circle
          cx="16"
          cy="16"
          data-name="Blur branco"
          fill="#fff"
          id="Blur_branco"
          opacity="0.74"
          r="16"
          transform="translate(30 29) rotate(180)"
          v-if="showCircle"
      />
      <path
          d="M9,24a1,1,0,0,1-.707-1.707L17.586,13,8.293,3.707A1,1,0,0,1,9.707,2.293l10,10a1,1,0,0,1,0,1.414l-10,10A1,1,0,0,1,9,24Z"
          data-name="Caminho 7071"
          fill="#666"
          id="Caminho_7071"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'BackArrowIcon',
  props: {
    showCircle: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 32
    },
    width: {
      type: Number,
      default: 32
    },
  },
}
</script>
<style scoped>
</style>