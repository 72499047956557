<template>
  <div
      v-if="!hideHeader"
  >
    <div
        class="header-bar mobile"
    >
      <div
          class="header-left-column"
          :class="{'compact': showSearch}"
      >
        <div
            class="cursor-pointer"
            @click="showSideMenu"
        >
          <BurgerIcon/>
        </div>
      </div>
      
      <div 
          v-if="!showSearch"
          @click="goToLink()"
      >
        <VixLogo
            :hide-label="false"
        />
      </div>

      <div
          class="header-right-column"
      >
       <UserMenu/>
      </div>

    </div>
    <div
        style="height: 54px;"
    />

    <SideMenu/>
  </div>
</template>

<script>
import { HeaderResource } from '@/api'
import { mapMutations, mapState } from 'vuex'
import VixLogo from '@/shared/Icons/VixLogo'
import CookieControl from '@/cookie-control'
import UserMenu from '@/shared/HeaderBar/UserMenu'
import BurgerIcon from '@/shared/Icons/BurgerIcon'
import SideMenu from '@/shared/HeaderBar/SideMenu'

const cookieControl = new CookieControl()

export default {
  name: 'HeaderBarMobile',
  components: { SideMenu, BurgerIcon, UserMenu, VixLogo },
  computed: {
    ...mapState('headerStore', ['showSearch', 'hideHeader']),
    ...mapState('homeStore', ['showFilterMobile']),
    showingFilterMobile: {
      get () {
        return this.showFilterMobile
      },
      set (v) {
        this.SET_SHOW_FILTER_MOBILE(v)
      }
    },
  },
  data () {
    return {
      categories: [],
      categorySelected: 0,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('headerStore', ['SET_SHOW_SIDE_MENU']),
    ...mapMutations('homeStore', [
      'SET_TERM',
      'SET_ANCHOR',
    ]),
    ...mapMutations('authStore', [
      'SIGN_OUT',
      'SET_USER'
    ]),
    async init () {
      const responseHeaders = await HeaderResource.getList()
      this.categories = responseHeaders.body.slice(0, 7)
      this.categorySelected = this.$route.params.category
      await this.refreshToken()
    },
    async refreshToken () {
      if (!this.isUserLogin()) return this.SIGN_OUT()
      const userData = await HeaderResource.refreshToken()
      if (!userData.data) {
        if (this.isUserLogin()) {
          this.goToLogoff()
        } else {
          this.SIGN_OUT()
        }
      } else {
        this.SET_USER(userData.data)
      }
    },
    async goToFilter () {
      if (this.$router.history.current.fullPath.indexOf('/home') === -1) {
        await this.goToLink()
      }
      this.showingFilterMobile = !this.showingFilterMobile
    },
    async goToLink(link) {
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      })
    },
    goToLogoff() {
      this.SIGN_OUT()
      this.goToLink(this.categorySelected)
    },
    isUserLogin() {
      return cookieControl.isLogin()
    },
    hideMenu() {
      if (this.showUserMenu) {
        this.showUserMenu = false
      }
    },
    showSideMenu() {
      this.SET_SHOW_SIDE_MENU(true)
    }
  },
}
</script>

<style scoped>

.header-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 23;
  height: 54px;
  width: 100%;
  background: #FFF;
  display: flex;
  justify-items: center;

  border-bottom: 2px solid rgb(205, 212, 63) !important;
  padding-left: 24px;
  padding-top: 6px;
  padding-right: 24px;
}

/*noinspection CssUnusedSymbol*/
.header-bar-search {
  grid-template-columns: 120px  auto 30px 135px;
  align-items: baseline;
  justify-items: center;
}

a {
  opacity: 0;
}

.header-left-column {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 8px;
}

.compact {
  flex: 0;
}

.header-logo-column {
  flex: 0;
}

.header-right-column {
  align-items: baseline;
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 12px;
}


.header-user-dropdown > * {
  cursor: pointer;
}

</style>
