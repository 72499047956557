const AuthApi = (vueResource, url) => {
  const resource = vueResource(`${url}/auth{/PATH}{/ID}{?Q*}`)
  const resourceGoogle = vueResource(`${url}/google{/PATH}{/ID}{?Q*}`)
  const resourceFacebook = vueResource(`${url}/facebook{/PATH}{/ID}{?Q*}`)
  const resourceRegister = vueResource(`${url}/usrio{/PATH}{/ID}{?Q*}`)
  const resourceProfiles = vueResource(`${url}/perfil{/PATH}{/ID}{?Q*}`)

  return {
    async getMe() {
      try {
        return await resource.get({PATH: 'me'})
      } catch (e) {
        return {body: null}
      }
    },
    async postLogout() {
      try {
        return await resource.save({PATH: 'logout'}, {})
      } catch (e) {
        return {body: null}
      }
    },
    async postRegister(payload) {
      try {
        return await resourceRegister.save(payload)
      } catch (e) {
        return e
      }
    },
    async postRegisterPessoaFisica(payload) {
      try {
        return await resourceRegister.save({ PATH: 'pessoa-fisica' }, payload)
      } catch (e) {
        return e
      }
    },
    async postRegisterPessoaJuridica(payload) {
      try {
        return await resourceRegister.save({ PATH: 'pessoa-juridica' }, payload)
      } catch (e) {
        return e
      }
    },
    async getProfiles(id) {
      try {
        return await resourceProfiles.get({ID: id})
      } catch (e) {
        return { body: null }
      }
    },
    async postLogin (payload) {
      try {
        return await resource.save({ PATH: 'login' }, payload)
      } catch (e) {
        return e
      }
    },
    async requestAcessCode (payload) {
      try {
        return await resource.save({ PATH: 'envio_codigo_acesso' }, payload)
      } catch (e) {
        return e
      }
    },
    async postLoginGoogle (payload) {
      try {
        return await resourceGoogle.save({ PATH: 'login' }, payload)
      } catch (e) {
        return e
      }
    },
    async postRegisterGoogle (payload) {
      try {
        return await resourceGoogle.save({ PATH: 'cadastro' }, payload)
      } catch (e) {
        return e
      }
    },
    async postLoginFacebook (payload) {
      try {
        return await resourceFacebook.save({ PATH: 'login' }, payload)
      } catch (e) {
        return e
      }
    },
    async postRegisterFacebook (payload) {
      try {
        return await resourceFacebook.save({ PATH: 'cadastro' }, payload)
      } catch (e) {
        return e
      }
    },
    async postForgot (payload) {
      try {
        return await resource.save({ PATH: 'forgot' }, payload)
      } catch (e) {
        return e
      }
    },
    async postPassword (payload) {
      try {
        return await resource.save({ PATH: 'password' }, payload)
      } catch (e) {
        return e
      }
    },
    async refreshToken (payload) {
      try {
        return await resource.save({ PATH: 'refresh_token' }, payload)
      } catch (e) {
        return { body: null }
      }
    },
  }
}
export default AuthApi
