export default {
  HeaderBar: {
    profile: 'Perfil',
    notifications: 'Notificações',
    favorites: 'Favoritos',
    negotiations: 'Negociações',
    myPurchase: 'Minhas Compras',
    logoff: 'Sair',
    visitorGreeting: 'Olá, Visitante',
    loginOrRegister: 'Entre ou cadastre-se', 
    userGreeting: 'Olá, ',
    logoTitle: 'Seminovos',
    aboutVix: 'Sobre a Vix',
    contactUs: 'Fale conosco',
    forCompanies: 'Para empresas',
    forResellers: 'Para revendas',
    partner: 'Seja um parceiro'

  },
  HomeIndex: {
    contact: {
      phone1: 'Automóveis e utilitários',
      phone2: 'Máquinas e ônibus',
      phone3: 'Caminhões e implementos', 
      whatsapp: '(27) 99999-9999',
      mailto: 'seminovos@vix.com.br',
    },
    OffersRow: {
      title: 'Destaques'
    },
    about: {
      benefits: {
        title: 'Vantagens para quem compra',
        paymentMethodsTitle: 'Formas de pagamento',
        paymentMethodsText: 'Aqui você tem flexibilidade nas formas de pagamento, incluindo financiamento e consórcio.',
        scheduledDeliveryPickupTitle: 'Entrega e retirada programada',
        scheduledDeliveryPickupText: 'Agende a sua entrega técnica na loja, ou receba o veículo por delivery em qualquer cidade do país.',
        conditionsResellersTitle: 'Condições para revendas e frotistas',
        conditionsResellersText: 'Veículos com preço competitivo, variedade de modelos e disponibilidade de entrega imediata.',
        reportDocumentTitle: 'Veículos de procedência',
        reportDocumentText: 'Automóveis originais e revisados, de único dono, provenientes do Grupo Águia Branca.',
      },
      qualities: {
        title: 'Qualidade nos veículos',
        autoDetailTitle: 'Automóveis detalhadamente inspecionados',
        autoDetailText: 'Cada veículo é avaliado por uma equipe técnica especializada antes de ser disponibilizado para venda.',
        noTamperingTitle: 'Sem adulterações',
        noTamperingText: 'Todos os veículos possuem a estrutura original da fábrica, com chassi e motor originais.',
        carGreatConditionTitle: 'Carro com ótimas condições',
        carGreatConditionText: 'Veículos de único dono e com revisões em dia, feitas de acordo com o padrão da montadora.',
        noDocumentaryRestrictionsTitle: 'Sem restrições documentais',
        noDocumentaryRestrictionsText: 'Todos os veículos estão livres de qualquer tipo de impedimentos administrativos ou restrições.',
      },
    },
    offerNotFound: {
      title: 'Nada aqui',
      text: 'Infelizmente não encontramos resultados para a sua pesquisa.',
    },
  },
  Partner:{
    memberSince: "Membro desde "
  },
  FilterSidebar: {
    title: 'Pesquisa',
    clearFilter: 'Limpar filtros',
    priceRanger: 'Faixa de preço',
    kmRanger: 'Faixa de Km',
    showMore: 'Ver mais',
    quantityText: 'nenhum item selecionado | 1 item selecionado |  {count} itens selecionados',
    brands: 'Marcas',
    categories: 'Categorias',
    models: 'Modelos',
    clearSelects: 'Limpar selecionados'
  },
  OfferScreen: {
    features: 'Características',
    resume: 'Resumo',
    specifications: 'Especificações',
    questionDialogText: {
      text: 'Sua pergunta foi enviada e logo será respondida.',
      sub: 'Fique de olho nas notificações!',
    },
    question: 'Perguntas',
    questionPlaceholder: 'Digite sua pergunta ou comentário aqui...',
    questionPlaceholderDisabled: 'Você precisa estar logado para fazer uma pergunta...',
    observation: 'Descrição',
    laudo: 'Laudo do veículo',
    implements: 'Implementos',
    implement: 'Implemento',
    tanks: 'Tanques',
    tank: 'Tanque',
    isMyBid: 'Lance atual feito por mim'
  },
  OfferBar: {
    buy: 'Comprar',
    buyNow: 'Compre já',
    sendPurpose: 'Faça uma Oferta',
    wantToNegotiate: 'Quer negociar?',
    buyLimit: 'Limite da franquia mensal ultrapassado',
    buyLimitText1: 'Por medida de segurança, adotamos uma franquia mensal de compras, de acordo com o perfil de cada usuário.',
    buyLimitText2: 'Se você deseja continuar comprando, entre em contato conosco.',
    bidLimit: 'Lance recusado',
    bidLimitText1: 'Seu último lance foi há poucos minutos. Próximo lance estará disponível em instantes.',
    buyLimitButton: 'OK',
    doBid: 'Dar um lance',
    sendBid: 'Dar lance',
    autoBid: 'Lance automático',
    autoBidBar: 'Configurar lance automático',
    actualBid: 'Lance atual',
    countdown: 'Tempo restante',
    backDefaultBuy: 'Ir para compra padrão',
    vehicleUnit: '{count} unidades',
    colour: 'Cor',
    fuel: 'Combustível',
    testPage: 'Pagina de teste',
    dialogPurposeText: 'No campo abaixo, você pode fazer uma proposta para o nosso time de vendas.',
    dialogPurposeValue: 'Valor da proposta',
    dialogBidInfo: {
      title: 'Como funciona o lance atual?',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet ultrices tellus. Nunc ornare eleifend tortor, nec consectetur leo porttitor eget. Curabitur pretium turpis sed tortor aliquam, id efficitur lacus vehicula. Vestibulum placerat erat ligula. Curabitur fermentum, nisl malesuada sodales egestas, nunc odio dignissim quam, ac placerat nisl justo et nunc. Donec tellus sapien, scelerisque tempus ligula sit amet, sagittis mattis arcu. Fusce nec felis in neque venenatis porta. Nulla facilisi.',
    },
    dialogAutoBIdText01: 'Informe o valor máximo que você está disposto a pagar pelo ativo.',
    dialogAutoBIdText02: 'O sistema irá cobrir todos os lances feitos, aumentando o valor do lance atual em R$ {count},00 automaticamente.',
    dialogAutoBIdText03: 'Caso o valor máximo seja atingido o sistema irá parar de dar lances automáticos.',
    dialogAutoBidMaxValue: 'Valor máximo do lance',
    isMyBid: 'Lance atual feito por mim'
  },
  Auth: {
    informMail: 'Informe seu e-mail para receber uma senha temporária.',
    login: 'Login',
    register: 'Cadastrar',
    registerCompany: 'Cadastro de pessoa jurídica',
    registerUser: 'Cadastro de pessoa física',
    notMember: 'Não é membro?',
    signup: 'Cadastre-se',
    isMember: 'Já é membro?',
    enterHere: 'Entre aqui',
    next: 'Próximo',
    previous: 'Voltar',
    userOrMail: 'Usuário / E-mail',
    cnpj: 'CNPJ (Somente Números)',
    cpf: 'CPF (Somente Números)',
    phone: 'Telefone (Somente Números)',
    password: 'Senha',
    repeatPassword: 'Repetir Senha',
    changePassword: 'Alterar senha',
    forgotPass: 'Esqueci minha senha',
    loginEnter: 'Entrar',
    loginGoogle: 'Entrar com Google',
    loginFacebook: 'Entrar com Facebook',
    loginSuccess: 'Login efetuado, seja bem vindo',
    registerSuccess: 'Seu cadastro foi efetuado com sucesso! Agora selecione seus interesses para melhor experiência!',
    requiredFieldsLogin: 'Preencha os campos para efetuar login.',
    requiredFieldsRegister: 'Preencha os campos para efetuar cadastro.',
    createNewPass: 'Crie uma nova senha',
    newPass: 'Nova Senha',
    passConfirmation: 'Confirmar',
    createPass: 'Criar senha',
    repeatPass: 'Repetir senha',
    requiredFieldsPass: 'Preencha os campos para criar uma nova senha.',
    requiredSamePass: 'As senhas devem ser iguais.',
    userNotFound: 'Usuário não encontrado.',
    requiredASmallCase: 'Senha deve conter ao menos um caractere minúsculo',
    requiredUppercase: 'Senha deve conter ao menos um caractere maiúsculo',
    requiredNumber: 'Senha deve conter ao menos um caractere numérico',
    requiredSpecialChar: 'Senha deve conter ao menos um caractere especial',
    requiredMinChar: 'Senha deve conter no mínimo 8 caracteres',
    agree: 'Eu concordo com todas as regras, incluindo os',
    registerGoogle: 'Cadastrar com Google',
    registerFacebook: 'Cadastrar com Facebook',
    failIsValid: 'Preencha os campos para efetuar cadastro.',
    failTestCpf: '{doc} não contém só números!',
    failTamanhoCpf: 'CPF deve possuir 11 caracteres.',
    failTamanhoCnpj: 'CNPJ deve possuir 14 caracteres.',
    failTestTel: 'Telefone não contém só números!',
    failIsEmailValid: 'E-Mail Inválido!',
    failTestMatricula: 'Matrícula é obrigatório!',
    failTestDivisaoEmpresa: 'Divisão/Empresa é obrigatório!',
    failTestCargo: 'Cargo é obrigatório!',
    failTestDepartamento: 'Departamento é obrigatório!',
    failTestLoginFunc: 'Login de Rede é obrigatório!',
    failSpaceInUsername: 'Não é permitido espaço no nome de usuário.',
    emailSuccess: 'E-Mail enviado com sucesso',
    passwordSuccess: 'Senha criada com sucesso!',
    passwordRequirements: 'Requisitos da senha',
    min8characters: 'No mínimo 8 caracteres',
    atLeast1Low: 'Ao menos 1 caracter minúsculo',
    atLeast1Upp: 'Ao menos 1 caracter maiúsculo',
    atLeast1Num: 'Ao menos 1 caracter numerico',
    atLeast1Esp: 'Ao menos 1 caracter especial',
    receiveMail: 'Desejo receber e-mails da Vix Seminovos com promoções.',

  },
  interests: {
    interests:'Interesses',
    checkInterests: 'Marque seus interesses abaixo para uma experiência personalizada.',
  },
  favorites: {
    favorites: 'Favoritos',
    noFavorite: 'Nenhum Favorito',
    haventAdd: 'Você ainda não adicionou nenhum item aos favoritos.',
  },
  finalization: {
    bidPlaced: 'Seu lance foi dado e ao encerrar o tempo de oferta você receberá um e-mail com o resultado.',
    purposeSubmitted: 'Sua proposta foi enviada e entrará para análise.',
    purchaseReview: 'Sua compra foi para análise e em breve iremos entrar em contato com orientações.',
    followProcess: 'É possível acompanhar o processo em',
    foward: 'Tudo Encaminhado!',
    toBack: 'Voltar ao início'
  },
  profile: {
    profile: 'Perfil',
  },
  negotiation: {
    timeLeft: 'Tempo Restante',
    bid: 'Lance',
    doBid: 'Novo lance',
    overcome: 'Lance batido',
    cancel: 'Cancelada',
    cancelAnalyze: 'Cancelamento em Análise',
    msgSolCancel: 'Solicitação de retirada enviada.\r\n Acompanhe o progresso da solicitação por e-mail.',
    msgCancel: 'Sua compra foi cancelada.',
    msgErrorCancel: 'Falha ao solicitar cancelamento.',
    msgPurpouseCancel: 'Sua proposta foi cancelada.',
    cancelReject: 'Cancelamento Rejeitado (Aguardando Retirada)',
    cancelRejectBuy: 'Cancelamento Rejeitado (Compra Finalizada)',
    cancelRejectCard: 'Cancelamento Rejeitado - Aguardando Retirada',
    cancelRejectCardBuy: 'Cancelamento Rejeitado - Compra Finalizada',
    reject: 'rejeitado',
    cancell: 'cancelado',
    withdrawal: 'retirado',
    cancelBuy: 'Cancelar Proposta/Compra',
    bidReject: 'Proposta Rejeitada',
    bidCancel: 'Lance Cancelado',
    bidWithdrawal: 'Proposta Retirada',
    negotiation: 'Negociação',
    waitingContact: 'Aguardando Contato',
    waitingLiberation: 'Aguardando Liberação de Crédito',
    waitingWithdrawal: 'Aguardando Retirada',
    checkout: 'Compra Finalizada',
    missedOffer: 'Oferta Perdida',
    purpose: 'Proposta',
    seeBids: 'Ver Lances',
    bidOf: 'Lance de',
    done: 'dado',
    offerDone: 'Oferta Encerrada',
    bidExc: 'Seu lance foi superado',
    negotiationsNotFound: {
      title: 'Nenhuma negociação',
      text: `Você ainda não fez nenhuma negociação.`,
    },
    myPurchaseNotFound: {
      title: 'Nenhuma compra',
      text: `Você ainda não comprou nenhum ativo.`,
    },
  },
  footerBar: {
    vehicles: 'Veiculos',
    clientes: 'Clientes',
    lojistas: 'Lojistas',
    empresa: 'Empresas',
    colaborador: 'Colaborador',
    pessoaFisica: 'Pessoa Física',
    sobreVIX: 'Sobre a VIX',
    VIXLogistica: 'VIX Logística',
    VIXSeminovos: 'VIX Seminovos',
    trabalheConosco: 'Trabalhe Conosco',
    contato: 'Contato',
    privacidade: 'Termos e Condições de Uso',
    politicaVendas: 'Política de Vendas',
    avisosLegais: 'Avisos Legais',
    mapaSite: 'Mapa do Site',
    textFooter: 'Funcionamento das 07h30 às 17h30 - Rodovia Governador Mário Covas, 2817 - Planalto de Carapina, Serra - ES, 29163-274',
    rights: 'Todos os direitos reservados',
    cnpj: '32.681.371/0010-63',
    contactSeller: 'Fale com um vendedor',
    portalPrivacidade: 'Portal de Privacidade',
    politicaPrivacidade: 'Política de Privacidade',
    politicaPrivacidadeParceiro: 'Política de Privacidade do Parceiro'
  },
  flux: {
    address: 'Endereço',
    addAddress:'Adicionar Endereço',
    zipCode: 'CEP',
    number:'Número',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    city: 'Cidade',
    state: 'UF',
    add: 'Adicionar',
    buyLimit: '* É permitida a compra de um determinado número de ativos por mês, de acordo com o perfil do usuário. Caso esta franquia seja ultrapassada, será necessário uma solicitação de liberação. Neste caso, as instruções serão dadas no ato da compra.',
    zipCodeNumber:'CEP não contém somente números!',
    chooseAddress: 'Necessário escolher um endereço!',
    buyActive: 'Ativo da Compra',
    negotiationType: 'Tipo de Negociação',
    automaticBid: 'Lance Automático',
    buy: 'Compra',
    yourOffer: 'Sua Oferta',
    maxBid: 'Valor máximo do lance',
    bidAmout: 'Valor do lance',
    amount:'Valor total',
    registration: 'Dados cadastrais',
    revision: 'Revisão',
    offerEnded: 'A oferta já foi finalizada!',
    offerMaxBid: 'A oferta já recebeu um lance maior!',
    needLogin: 'Você precisa estar logado! Ou criar um cadastro caso não tenha!',
    offerMaxBidPurpose: 'A oferta já recebeu um lance, não é possível fazer proposta!',
    data: 'Dados',
    cancel: 'Cancelar',
    simple: 'Retirada em Loja',
    subSimple: 'Busque seu veículo em nossa loja',
    deliveryAddress: 'Entrega no Endereço',
    subDeliveryAddress: 'A entrega estará sujeita a taxa de frete',
    freight: 'Frete',
    chooseFreight: 'Necessário escolher um frete!',
    toBack: 'Voltar'
  },
  global: {
    sendButton: 'Enviar',
    needLogin: 'Você deve estar logado ou solicitar um cadastro, caso não tenha!',
    showAlertParceiro: 'Dados do perfil que estão bloqueados só podem ser alterados na plataforma de Parceiros.',
    invalidOffer: 'Oferta já foi finalizada!',
    goToHome: 'Ir para home',
    successSendMail: 'E-mail enviado com sucesso!',
    successPassCreated: 'Senha criada com sucesso!',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    firstNameJuri: 'Nome Responsável',
    lastNameJuri: 'Sobrenome Responsável',
    mail: 'E-mail',
    phone: 'Telefone',
    clientType: 'Tipo de cliente',
    username: 'Nome de Usuário',
    fantasyName: 'Nome Fantasia',
    stateRegistration: 'Inscrição Estadual',
    exempt: 'Isento',
    description: 'Descrição',
    registration: 'Matrícula',
    companyDivision: 'Divisão/Empresa',
    role: 'Cargo',
    department: 'Departamento',
    networkLogin: 'Login de Rede',
    account: 'Tem Login de Rede?',
    serviceTerm: 'termos de serviço',
    needMail: 'Preencha o e-mail.',
    mailNotFound: 'E-mail não encontrado.',
    fail: 'Falha ao efetuar cadastro.',
    or: 'ou',
    required: 'Obrigatório.',
    maxChar: 'Max {count} caracteres',
    minChar: 'Min {count} caracteres',
    loginFail: 'Falha ao efetuar login.',
    registerFail: 'Falha ao efetuar login.',
    incorrectData: 'Dados Incorretos',
    invalidResult: 'Resultado inválido',
    register: 'Cadastro',
    continue: 'Continuar',
    contactsVIX: 'Contatos VIX Seminovos',
    linkedGoogle: 'Vinculado ao Google',
    linkedFacebook: 'Vinculado ao Facebook',
    actualPwd: 'Senha atual',
    save: 'Salvar',
    dataSaved: 'Dados Salvos com Sucesso!',
    fillCorrectly: 'Preencha os campos corretamente.',
    numbersPhone: 'Telefone não contém só números!',
    invalidNewPwd: 'Nova senha inválida',
    passwordMatch: 'A nova senha e a confirmação não batem.',
    samePassword: 'A nova senha não pode ser igual a anterior.',
    saveFail: 'Falha ao salvar.',
    unlimited: 'Ilimitado',
    birthDate: 'Data de Nascimento',
    genre: 'Sexo',
    chooseGenre: 'Escolha um Sexo',
    male: 'Masculino',
    female: 'Feminino',
    mothersName: 'Nome da mãe Completo',
    fillFields:'Preencha os campos restantes.',
    anotherValue: 'Outro valor',
    units: 'Unidades',
    editAddress: 'Editar Endereço',
    delete: 'Excluir',
    mother: 'Mãe',
    today: 'Hoje',
    yesterday: 'Ontem',
    vehicle: 'Veículo',
    vehicles: 'Veículos',
    lot: 'Lote',
    similarVechicle:'Veículos Semelhantes',
    bad: 'Ruim',
    regular: 'Normal',
    good: 'Bom',
    color: 'Cor',
    finalPlaca: 'Final Placa',
    yearModel: 'Ano / Modelo',
    gas: 'Combustível',
    year: 'Ano',
    findBy: 'Busque por {id}',
    vixAddress: 'Rod. Gov. Mário Covas, s/n - Planalto de Carapina, Serra - ES, 29161-160',
    details: 'Detalhes',
    serverError: 'Houve um erro na requisição. Por favor, contate ao administrador da plataforma.',
    previous: 'Voltar'
  },
  orderBy: {
    title: 'Ordenar por',
    MAIS_RECENTES: 'Mais recentes',
    MAIS_ANTIGOS: 'Mais antigos',
    MAIOR_PRECO: 'Maior preço',
    MENOR_PRECO: 'Menor preço',
    DISTANCIA: 'Distância'
  },
  reseller:{
    contact: 'Fale com um revendedor',
    dialogContactInfo: {
      title: 'Dados de contato',
      text: 'Usaremos esses dados para entrar em contato com você',
      userName: 'Nome',
      userNamePlaceholder: 'Digite o seu nome',
      userPhone: 'Telefone',
      userPhonePlaceholder: 'Digite o seu telefone',
      userEmail: 'Email',
      userEmailPlaceholder: 'Digite o seu e-mail',
      next: 'Próximo'
    },
    dialogSuccesInfo: {
      title: 'Solicitação de contato enviada com sucesso!',
      text: 'Recebemos sua solicitação de contato e em breve um dos nossos \n especialista vai entrar em contato com você. \n \n Nos próximos minutos você irá receber um e-mail com as instruções dos próximos passos. \n \n Caso tenha alguma dúvida, entre em contato usando um dos canais abaixo',
      contact: 'Contato',
      ok: 'OK' 
    }
  }

}



