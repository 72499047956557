<template>
  <div class="page-content">
    <v-container>
      <v-row justify="center" align="center" class="mb-10 mt-10">
        <CircleCheckedIcon/>
      </v-row>
      <v-row justify="center" class="mb-10">
        <span class="page-title">
          Seu cadastro foi realizado com sucesso
        </span>
      </v-row>
      <v-row justify="center" class="mb-10">
        <span class="page-subtitle">
          Agora você pode aproveitar todas as ofertas disponíveis.
        </span>
      </v-row>

      <v-row justify="center">
        <button class="p-button p-button-primary" @click="goToHome">{{ $t('finalization.toBack') }}</button>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CircleCheckedIcon from '../../shared/Icons/CircleCheckedIcon.vue'

export default {
  name: 'RegisterFinished',
  components: {CircleCheckedIcon},
  methods: {
    goToHome() {
      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+'/home'
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  margin: 30px auto 0 auto;
  max-width: 850px;

  .page-title {
    font: 30px 'HelveticaNeue-Medium';
    letter-spacing: 0;
    color: #60BED4;
    opacity: 1;
    text-align: center;
  }

  .page-subtitle {
    font: 20px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
    color: #2D2D2D;
    opacity: 1;
    width: 70%;
    text-align: center;
  }

  .page-info {
    font: 14px 'HelveticaNeueLTPro-Roman';
    letter-spacing: 0;
    color: #8B8B8B;
    opacity: 1;
    width: 70%;
    text-align: center;
  }
}
</style>