<template>
  <div class="step-container">
    <div class="step-row">
      <StepPoint
          v-for="(step, i) in steps"
          :key="i"
          :is-last="i === steps.length-1"
          :selected-line="i < actualStep"
          :selected-step="i <= actualStep"
          :step-text="step"
          @click="goTo(i)"
      />
    </div>
  </div>
</template>
<script>
import StepPoint from '@/screen/Flux/StepPoint'
import { mapMutations } from 'vuex'

export default {
  name: 'Steps',
  components: { StepPoint },
  props: {
    steps: {
      type: Array,
      default: () => [
        'flux.data',
        'flux.address',
        'flux.revision',
      ]
    },
    actualStep: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapMutations('fluxStore', ['SET_STEP']),
    goTo (step) {
      if (step >= this.actualStep) return

      this.SET_STEP(step)
      let path = '/fluxo?step=' + step

      this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      }).catch(() => {})
    }
  },
}
</script>
<style
    scoped
    type="text/css"
>
.circle-outside {
  background: #F5F6F7 0 0 no-repeat padding-box;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  box-shadow: -1px -1px 1px #00000014;
  border: 1px solid #CCCCCC;
}

.circle-outside.selected {
  box-shadow: 2px 2px 2px #FFFFFF;
  border: 1px solid #60BED4;
}

.circle-inside {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #60BED4 0 0 no-repeat padding-box;
}

.step-line {
  flex: 0;
  min-width: 129px;
  border: 1.5px solid #C9CDD0;
}

.step-line.selected {
  border-color: #60BED4;
}

.label {
  font: 12px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-top: 14px;
  position: absolute;
  bottom: -30px;
}

.step-row {
  display: flex;
  flex-flow: row;
  align-content: center;
}

.step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

@media screen and (max-width: 601px) {
  .step-container {
    width: 100%;
  }
}
</style>
