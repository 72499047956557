const ProfileApi = (vueResource, url) => {
  const resource = vueResource(`${url}/usrio/edit{/PATH}{/ID}{?Q*}`)

  return {
    async getUser () {
      try {
        return await resource.get()
      } catch (e) {
        return e
      }
    },
    async putUser (payload) {
      try {
        return await resource.update(payload)
      } catch (e) {
        return e
      }
    },
  }
}

export default ProfileApi