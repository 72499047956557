<template>
  <div class="resumo-container">
    <div style="flex-grow: 1">
      <div class="resumo-title">
        Resumo da compra
      </div>
      <div class="resumo-version">
        {{ oferta.title }}
      </div>
      <div style="font: 18px 'HelveticaNeueLTCom-Roman'; column-count: 6; margin-block: 1rem;">
        <div>Valor</div> <span class="info-list-detail">{{ price }}</span>
      </div>
      <ul class="info-list">
        <li>Km</li>
        <li>Ano</li>
        <li class="info-list-detail">{{ metric }}</li>
        <li class="info-list-detail">{{ veiculo.yearManufacture }}/{{ veiculo.yearModel }}</li>
        <li>Câmbio</li>
        <li>Cor</li>
        <li class="info-list-detail">{{ cambio }}</li>
        <li class="info-list-detail">{{ veiculo.cor }}</li>
        <li>Combustível</li>
        <li>Placa</li>
        <li class="info-list-detail">{{ veiculo.combustivel }}</li>
        <li class="info-list-detail">{{ plate }}</li>
      </ul>
    </div>
    <div style="font: 18px 'HelveticaNeueLTCom-Roman';">
      <div>Status Atual</div>
      <div style="color: #60BED4;">{{ statusOferta }}</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ResumoCompra",
  props: {
    oferta: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    ...mapState('authStore', ['user']),
    ...mapState('cancelamentoStore', ['ofertaInfo']),
    veiculo() {
      if (this.oferta && this.oferta.vehicles && this.oferta.vehicles.length) {
        return this.oferta.vehicles[0]
      }
      return {}
    },
    cambio() {
      if (this.veiculo && this.veiculo.typeVehiclesDto && this.veiculo.typeVehiclesDto.additionalInformation) {
        const informacoes = this.veiculo.typeVehiclesDto.additionalInformation.filter(i => i.codigoAdditionalInformation === 119)
        if (informacoes && informacoes.length) {
          return informacoes[0].value
        }
      }
      return ''
    },
    metric() {
      if (this.veiculo) {
        if (!this.veiculo.km) {
          return '-- ' + this.veiculo.tipoContador
        }
        return this.veiculo.km.float2Money('', '', '.', '') + ' ' + this.veiculo.tipoContador
      }
      return ''
    },
    price() {
      if (!this.oferta.price) return 'R$ 0.000,00*'
      return this.oferta.price.number2Money()
    },
    plate() {
      if (this.veiculo && this.veiculo.placa) {
        return `***-${this.veiculo.placa.substring(this.veiculo.placa.length - 4)}`
      }
      return ''
    },
    statusOferta() {
      if (this.ofertaInfo.winner !== 'N') {
        if(this.ofertaInfo.dataRejeicaoCancelamentoVenda && this.ofertaInfo.statusOferta === 'X') {
          return this.$t('negotiation.cancelRejectBuy')
        }
        if(this.ofertaInfo.dataRejeicaoCancelamento && this.ofertaInfo.statusOferta === 'L') {
          return this.$t('negotiation.cancelReject')
        } else if(this.ofertaInfo.idofrtacancl === 'Q') {
          return this.$t('negotiation.cancelAnalyze')
        } else if (this.ofertaInfo.dataCancelada) {
          return this.$t('negotiation.cancel')
        } else if (this.ofertaInfo.statusOferta === 'V' && this.ofertaInfo.dataNegociacao) {
          return this.$t('negotiation.negotiation')
        } else if (this.ofertaInfo.statusOferta === 'V' && !this.ofertaInfo.dataNegociacao) {
          return this.$t('negotiation.waitingContact')
        } else if (this.ofertaInfo.statusOferta === 'C') {
          return this.$t('negotiation.waitingLiberation')
        } else if (this.ofertaInfo.statusOferta === 'L') {
          return this.$t('negotiation.waitingWithdrawal')
        } else if (this.ofertaInfo.statusOferta === 'X') {
          return this.$t('negotiation.checkout')
        } else {
          return ''
        }
      } else {
        if (this.ofertaInfo.statusOferta === 'A' && this.isOvercome) {
          return this.$t('negotiation.overcome')
        } else {
          return this.$t('negotiation.missedOffer')
        }
      }
    },
    isOvercome() {
      return this.user.codigoUsuario !== this.ofertaInfo.codigoUsuarioUltimoLance;
    },
  }
}
</script>

<style scoped>
  .resumo-container {
    display: flex;
    margin-block: 1rem;
  }
  .resumo-title {
    font: 24px 'HelveticaNeue-Light';
    letter-spacing: 0;
    color: #7D7D7D;
    opacity: 1;
  }

  .resumo-version {
    font: 18px 'HelveticaNeue-Light';
    letter-spacing: 0;
    color: #414141;
    flex: 1;
    margin-block: 1rem;
    font-weight: bold;
  }

  .info-list {
    column-count: 6;
    list-style-type: none;
    padding-left: 0;
    font: 18px 'HelveticaNeue-Light';
  }

  .info-list-detail {
    font: 18px 'HelveticaNeueLTPro-Bd';
  }
</style>