const HeaderApi = (vueResource, url) => {
  const resourceHeader = vueResource(`${url}/header{/PATH}{/SUB}{/ID}{?Q*}`)
  const resourceAuth = vueResource(`${url}/auth{/PATH}{/SUB}{/ID}{?Q*}`)

  return {
    async getIdiomList (q) {
      try {
        return await resourceHeader.get({ Q: q, PATH: 'idiom' })
      } catch (e) {
        return { body: [] }
      }
    },
    async getList (q) {
      try {
        return await resourceHeader.get({ Q: q, PATH: 'catgr' })
      } catch (e) {
        return { body: [] }
      }
    },
    async refreshToken (payload) {
      try {
        return await resourceAuth.save({ PATH: 'refresh_token' }, payload)
      } catch (e) {
        return { body: null }
      }
    },
  }
}
export default HeaderApi