<template>
  <svg
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        class="a"
        d="M28.992,27.975l-3.433-3.438a5.739,5.739,0,1,0-4.584,2.285,5.653,5.653,0,0,0,3.472-1.171L27.9,29.07a.768.768,0,0,0,.556.23.8.8,0,0,0,.556-.23A.784.784,0,0,0,28.992,27.975Zm-8-2.708a4.206,4.206,0,1,1,2.973-7.182,4.107,4.107,0,0,1,1.228,2.977,4.218,4.218,0,0,1-1.228,2.977A4.129,4.129,0,0,1,20.994,25.267Z"
        transform="translate(-15.225 -15.3)"
    />
  </svg>
</template>
<script>
export default {
  name: 'SearchIcon',
}
</script>
<style scoped>
  .a {
    fill: #c9cdd0;
  }
</style>