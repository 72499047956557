import { render, staticRenderFns } from "./DownloadIcon.vue?vue&type=template&id=47674b3b&scoped=true"
import script from "./DownloadIcon.vue?vue&type=script&lang=js"
export * from "./DownloadIcon.vue?vue&type=script&lang=js"
import style0 from "./DownloadIcon.vue?vue&type=style&index=0&id=47674b3b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47674b3b",
  null
  
)

export default component.exports