<template>
  <v-row>
    <v-col class="pt-0 pb-0" style="display:flex;flex-direction:column;">
      <v-alert
          class="alertMessage"
          v-model="showAlertMessage"
          dense
          outlined
          type="warning"
      >
       Sua permissão de localização está bloqueada. Ative para ordenar por distância.
      </v-alert>
      <div
          class="relative"
          style="height: 41px;"
      >
        <span
            class="absolute offer-title"
        >
         {{ $t(title) }}
        </span>

        <span
            class="absolute offer-length"
        >
          ({{ length }})
        </span>

        <div
            v-if="showOrderBy"
            style="position: fixed; top: 0;bottom: 0;left: 0;right: 0; background: transparent;"
            @click="showOrderBy = false"
        />
        <div
            v-if="showFilterBy"
            style="position: fixed; top: 0;bottom: 0;left: 0;right: 0; background: transparent;"
            @click="showFilterBy = false"
        />


       <div
           v-if="filterBy"
           :class="{'offer-filter-by-expand': showFilterBy}"
           class="offer-filter-by"
       >
         <div
             class="align-content-center"
             style="height: 44px; padding-left: 22px; padding-right: 22px; white-space: nowrap;"
         >
          <span type="white-space: nowrap; ">
            Filtrar Por
          </span>
           <div
               class="cursor-pointer"
               style="display:flex; flex: 1;"
               @click="openFilterBy()"
           >
             <span
                 class="offer-filter cursor-pointer"
             >
               {{ clickedLocation }}
             </span>
             <i
                 class="fas fa-angle-down offer-arrow cursor-pointer"
                 style="font-size: 13px;"
             />
           </div>
         </div>

         <div
             class="order-by-list-filter"
             id="scroll"
             v-if="showFilterBy"
         >
           <p
               class="cursor-pointer"
               @click="selectFilterBy('','')"
           >Todas</p>
           <p
               class="cursor-pointer"
               v-for="(location,i) in OffersLocation"
               :key="i"
               @click="selectFilterBy(location.cidade,location.estado)"
           >{{ location.cidade + ' - ' + location.estado }}</p>
         </div>

       </div>


        <div
            v-if="orderBy"
            :class="{'offer-order-by-expand': showOrderBy}"
            class="offer-order-by"
        >
          <div
              class="align-content-center"
              style="height: 44px; padding-left: 22px; padding-right: 22px; white-space: nowrap;"
          >
           <span type="white-space: nowrap; ">
             {{ $t('orderBy.title') }}
           </span>
            <div
                class="cursor-pointer"
                style="display:flex; flex: 1;"
                @click="openOrderBy()"
            >
              <span
                  class="offer-order cursor-pointer"
              >
                {{ $t(list[orderByType]) }}
              </span>
              <i
                  class="fas fa-angle-down offer-arrow cursor-pointer"
                  style="font-size: 13px;"
              />
            </div>
          </div>

          <div
              v-if="showOrderBy"
              class="order-by-list"
          >
            <p
                class="cursor-pointer"
                @click="selectOrderBy('MAIS_RECENTES')"
            >{{ $t('orderBy.MAIS_RECENTES') }}</p>
            <!--<p
                @click="selectOrderBy('MAIS_NOVOS')"
                class="cursor-pointer"
            >{{$t('orderBy.MAIS_NOVOS')}}</p>-->
            <p
                class="cursor-pointer"
                @click="selectOrderBy('MAIS_ANTIGOS')"
            >{{ $t('orderBy.MAIS_ANTIGOS') }}</p>
            <p
                class="cursor-pointer"
                @click="selectOrderBy('MAIOR_PRECO')"
            >{{ $t('orderBy.MAIOR_PRECO') }}</p>
            <p
                class="cursor-pointer"
                @click="selectOrderBy('MENOR_PRECO')"
            >{{ $t('orderBy.MENOR_PRECO') }}</p>
            <p
                class="cursor-pointer"
                @click="selectOrderByDist()"
            >{{ $t('orderBy.DISTANCIA') }}</p>
          </div>

        </div>

      </div>
    </v-col>
  </v-row>
</template>
<script>

import {mapMutations,mapState} from 'vuex'
import { productEvents } from '@/analytics'

export default {
  name: 'OffersHeader',
  props: {
    title: {
      type: String,
      default: '***TITLE***'
    },
    length: {
      type: [String, Number],
      default: '0'
    },
    orderBy: {
      type: Boolean,
      default: true
    },
    filterBy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showOrderBy: false,
      showFilterBy: false,
      showAlertMessage: false,
      orderByType: 'MAIS_RECENTES',
      list: {
        'MAIS_RECENTES': 'orderBy.MAIS_RECENTES',
        'MAIS_NOVOS': 'orderBy.MAIS_NOVOS',
        'MAIS_ANTIGOS': 'orderBy.MAIS_ANTIGOS',
        'MAIOR_PRECO': 'orderBy.MAIOR_PRECO',
        'MENOR_PRECO': 'orderBy.MENOR_PRECO',
        'DISTANCIA': 'orderBy.DISTANCIA'
      }
    }
  },
  mounted() {
    this.SET_ORDER_BY('MAIS_RECENTES')
  },
  computed: {
    ...mapState('homeStore', [
      'filteredOffers','cidadeSelected','estadoSelected','offers'
    ]),
    OffersLocation() {
      let locations = []
      for(let i=0;i<this.filteredOffers.length;i++) {
        for(let j=0;j<this.filteredOffers[i].vehicles.length;j++) {
          locations.push({cidade:this.filteredOffers[i].vehicles[j].cidade,estado:this.filteredOffers[i].vehicles[j].estado})
        }
      }
      locations = locations
      .map(e => JSON.stringify(e))
      .reduce((acc, cur) => (acc.includes(cur) || acc.push(cur), acc), [])
      .map(e => JSON.parse(e))
      return locations
    },
    clickedLocation() {
      if(this.cidadeSelected == '') {
        return 'Localidade'
      } else {
        return this.cidadeSelected + ' - ' + this.estadoSelected
      }
    }
  },
  methods: {
    ...mapMutations('homeStore', [
      'SET_ORDER_BY','SET_LOCATION_BY','SET_CIDADE','SET_ESTADO'
    ]),
    openOrderBy() {
      this.showOrderBy = !this.showOrderBy
    },
    openFilterBy() {
      this.showFilterBy = !this.showFilterBy
    },
    selectOrderBy(orderBy) {
      this.orderByType = orderBy
      this.showOrderBy = false
      this.SET_ORDER_BY(orderBy)
      productEvents.sorted({ sorting_by: orderBy })
    },
    async selectOrderByDist() {
      this.orderByType = 'DISTANCIA'
      this.showOrderBy = false
      let coords = {latidude:'',longitude:''}
      await this.getCoords().then(resposta => {
        coords = resposta
        let R = 6371
        for(let i=0;i<this.offers.length;i++) {
          let dLat = (coords.latitude - this.offers[i].vehicles[0].latitude) * (Math.PI / 180)
          let dLon = (coords.longitude - this.offers[i].vehicles[0].longitude) * (Math.PI / 180)
          let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.offers[i].vehicles[0].latitude * (Math.PI / 180)) * Math.cos(coords.latitude * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
          let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
          this.offers[i].vehicles[0].dist = R * c
        }
      this.SET_ORDER_BY('DISTANCIA')
      })
    .catch(erro => {
      this.showAlertMessage = true
      let myTimer = window.setInterval(() => {
        this.showAlertMessage = false
        clearInterval(myTimer)
      }, 5000) 
      this.showAlertMessage = true
    });
    },
    selectFilterBy(cidade,estado) {
      this.showFilterBy = false
      this.SET_CIDADE(cidade)
      this.SET_ESTADO(estado)
      this.SET_LOCATION_BY({
        location: {
          cidade:cidade,
          estado:estado
        },
      })
    },
    getCoords() {
      return new Promise((resolve,reject) => navigator.geolocation.getCurrentPosition(function(position) {
        const latitude = JSON.stringify(position.coords.latitude);
        const longitude = JSON.stringify(position.coords.longitude);
        resolve({ latitude, longitude});
      },
       function() { reject() }
       ));
    }
  },
}
</script>
<style
    scoped
    type="text/css"
>
.offer-length {
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #C9CDD0;
  left: 26px;
  top: 34px;
}

.offer-title {
  text-align: left;
  font: 20px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #2D2D2D;
  left: 26px;
  top: 8px
}

.offer-order-by {
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;

  right: 17px;
  position: absolute;
}

.offer-order {
  text-align: right;
  color: #60BED4;
  margin-left: 5px;
  white-space: nowrap;
}

.offer-arrow {
  margin-left: 5px;
  color: #60BED4;
  font-size: 11px;
}

/*noinspection ALL*/
.offer-order-by-expand {
  z-index: 13;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
}


.offer-filter-by {
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;

  right: 240px;
  position: absolute;
}

.offer-filter {
  text-align: right;
  color: #60BED4;
  margin-left: 5px;
  white-space: nowrap;
}

.offer-filter-by-expand {
  z-index: 13;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
}

.order-by-list > p {
  margin-bottom: 10px;
}

.order-by-list {
  margin-top: 6px;
  margin-bottom: 24px;
  text-align: right;
  font: 13px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-right: 22px;
}

.order-by-list-filter {
  margin-top: 6px;
  margin-bottom: 24px;
  text-align: right;
  font: 13px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-right: 22px;
  overflow-y: auto;
  height: 145px;
}

.order-by-list-filter > p {
  margin-bottom: 10px;
  margin-right: 6px;
}

#scroll::-webkit-scrollbar
{
	width: 5px;
}

#scroll::-webkit-scrollbar-thumb
{
	background-color: #efefef;	
}

.alertMessage {
  width: 640px;
  align-self: center;
}

@media screen and (max-width: 701px), screen and (max-height: 501px) {

.offer-filter-by {
  text-align: left;
  font: 13px 'HelveticaNeueLTPro-Lt';
  letter-spacing: 0;
  color: #666666;

  right: 17px;
  top: 40px;
  position: absolute;
}

.alertMessage {
  width: 390px;
  align-self: center;
}

}

</style>
