<template>
  <svg :width="size ? size : width" :height="size ? size : height" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11"
       role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
    <path :fill="color"
          d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
  </svg>
</template>

<script>
export default {
  name: 'TimesIcon',
  props: {
    size: {
      type: Number,
      default: 42
    },
    height: {
      type: String,
      default: '42'
    },
    width: {
      type: String,
      default: '42'
    },
    color: {
      type: String,
      default: '#F64A14'
    },
  }
}
</script>

<style scoped>

</style>
