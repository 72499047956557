<template>
  <div class="contact-seller">
    <div class="contact-seller__header" @click="toggle">
      {{ $t('footerBar.contactSeller') }}

      <chevron-down :size="18" v-if="expanded"/>
      <chevron-up :size="18" v-else/>
    </div>
    <div class="contact-seller__body" v-show="expanded">
      <ul>

        <li v-for="contact in contacts" :key="contact.label">
          <a target="_blank" :href="whatsappUrl(contact.number, contact.text)">
            <whatsapp-icon :size="27" style="margin-right: .5rem"/>

            <span style="flex-grow: 2">{{ contact.label }}</span>

            <ChevronRight :size="16" />
          </a>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import WhatsappIcon from '@/shared/Icons/WhatsappIcon'
import ChevronRight from '@/shared/Icons/ChevronRight'
import ChevronUp from '@/shared/Icons/ChevronUp'
import ChevronDown from '@/shared/Icons/ChevronDown'

export default {
  name: 'ContactSeller',
  components: { WhatsappIcon, ChevronRight, ChevronUp, ChevronDown },
  data: () => ({
    expanded: false,
    contacts: [],
  }),
  mounted() {
     this.contacts = [
      {
        label: 'Automóveis e utilitários',
        number: '5527996944685',
        text: 'Ol%C3%A1!%20Estava%20navegando%20na%20sessão%20de%20automóveis%20e%20utilitários%20no%20site%20e%20gostaria%20de%20atendimento'
      },
      {
        label: 'Caminhões e implementos',
        number: '5527995041005',
        text: 'Ol%C3%A1!%20Estava%20navegando%20na%20sessão%20de%20caminhões%20e%20implementos%20no%20site%20e%20gostaria%20de%20atendimento'
      },
      {
        label: 'Máquinas e ônibus',
        number: '5527995041005',
        text: 'Ol%C3%A1!%20Estava%20navegando%20na%20sessão%20de%20máquinas%20e%20ônibus%20no%20site%20e%20gostaria%20de%20atendimento'
      },
    ]
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
    whatsappUrl(number, text) {
      return `https://api.whatsapp.com/send?phone=${number}&text=${text}`
    },
  }
}
</script>

<style lang="scss" scoped>
.contact-seller {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 425px;
  z-index: 6;
  transition: bottom 100ms ease-in-out;

  & .contact-seller__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: 19px 'HelveticaNeue-Bold';
    color: #FCFCFC;
    letter-spacing: 0;
    background: #44C554 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 32px 0 0 0;
    width: 425px;
    height: 62px;
    padding: 20px 20px 20px 30px;
    cursor: pointer;
  }

  & .contact-seller__body {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    padding-top: 20px;
    padding-bottom: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding-right: 24px !important;

      li > a {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        text-decoration: none;
        font: 18px 'HelveticaNeue-Medium';
        color: #666666;
        letter-spacing: 0;
      }

      li:not(:last-child) {
        border-bottom: 1px solid rgb(112, 112, 112, .15);
      }
    }

  }

}

@media screen and (max-width: 36rem) {
  .contact-seller {
    width: 19rem;

    & .contact-seller__header {
      width: auto;
      font: 1rem 'HelveticaNeue-Bold';
      height: 3rem;
    }

    & .contact-seller__body {
      padding: 0;

      & ul li a {
        font: .8rem 'HelveticaNeue-Medium';
      }
    }
  }
}

</style>
