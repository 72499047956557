<template>
  <div
      :class="{'filter-btn-selected ': selected}"
      :style="style"
      style="text-align: center"
      @click="emitClick()"
      class="filter-btn cursor-pointer"
  >
    <p class="ma-0">{{title}}</p>
  </div>
</template>
<script>
  export default {
    name: 'FilterBtn',
    props: {
      title: {
        type: String,
        default: ''
      },
      height: {
        type: String || Number,
        default: '30px'
      },
      width: {
        type: String || Number,
        default: '58px'
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      style () {
        return `height: ${this.getSize(this.height)}; width: ${this.getSize(this.width)};`
      }
    },
    methods: {
      getSize (size) {
        return size.toString()
          .replace(/\d/g, '') ? size : `${size}px`
      },
      emitClick () {
        this.$emit('click')
      }
    },
  }
</script>
<style
    scoped
    type="text/css"
>
  .filter-btn {
    height: 30px;
    width: 58px;
    font: 11px 'HelveticaNeueLTPro-Md';
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #F5F5F7;
    color: #666666;
    box-shadow: -2px -2px 2px #FFFFFF, 2px 2px 2px #A3B1C699;
  }

  .filter-btn.filter-btn-selected {
    background: #60BED4;
    color: #FFFFFF;
    box-shadow: 2px 2px 2px #FFFFFF, -2px -2px 2px #A3B1C699;
  }
</style>