<template>
  <div>
    <FilterSidebarDesktop
        v-if="isDesktop"
        @click="$emit('click')"
    />
    <FilterSidebarTablet
        v-if="isTablet"
        @click="$emit('click')"
    />
    <FilterSidebarMobile
        v-if="isMobile"
        @click="$emit('click')"
    />
  </div>
</template>

<script>
import FilterSidebarDesktop from '@/shared/FilterSidebar/FilterSidebarDesktop'
import FilterSidebarTablet from '@/shared/FilterSidebar/FilterSidebarTablet'
import FilterSidebarMobile from '@/shared/FilterSidebar/FilterSidebarMobile'

export default {
  name: 'FilterSidebar',
  components: { FilterSidebarMobile, FilterSidebarTablet, FilterSidebarDesktop },
  computed: {
    isDesktop () {
      return this.$vuetify.breakpoint.lgAndUp
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    isTablet () {
      return !this.$vuetify.breakpoint.lgAndUp && !this.$vuetify.breakpoint.xs
    }
  },
}
</script>

<style scoped>

</style>