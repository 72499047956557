import { render, staticRenderFns } from "./TransmissionIcon.vue?vue&type=template&id=396f1f34&scoped=true"
import script from "./TransmissionIcon.vue?vue&type=script&lang=js"
export * from "./TransmissionIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396f1f34",
  null
  
)

export default component.exports