<template>
  <div>
    <FluxFrame
        :negotiation-type="negotiationType"
        :offer="offer"
        :price-title="priceTitle"
        :is-last="isLast"
        @click="next"
    >
      <FluxPersonal
          ref="FluxPersonal"
          v-if="activeScreen === 'FluxPersonal'"
      />
      <FluxAddress
          ref="FluxAddress"
          v-if="activeScreen === 'FluxAddress'"
      />
      <FluxResume
          ref="FluxResume"
          v-if="activeScreen === 'FluxResume'"
      />
    </FluxFrame>
  </div>
</template>

<script>
import { FluxResource, getImageUrl, OfferResource } from '@/api'
import FluxFrame from '@/screen/Flux/FluxFrame'
import FluxPersonal from '@/screen/Flux/FluxPersonal'
import FluxAddress from '@/screen/Flux/FluxAddress'
import FluxResume from '@/screen/Flux/FluxResume'
import { mapMutations, mapState } from 'vuex'
import { checkoutEvents } from '../../analytics'

export default {
  name: 'FluxIndex',
  components: { FluxResume, FluxAddress, FluxPersonal, FluxFrame },
  watch: {
    stepper (newValue, oldValue) {
      this.SET_STEP(newValue || 0)
    }
  },
  computed: {
    ...mapState('fluxStore', ['step', 'offer', 'fluxType', 'bid', 'offerPrice']),
    stepper () {
      return this.$route.query.step
    },
    negotiationType () {
      switch (this.fluxType) {
        case 'PURPOSE':
          return this.$t('negotiation.negotiation')
        case 'AUTO_BID':
          return this.$t('flux.automaticBid')
        case 'BID':
          return this.$t('negotiation.bid')
        default:
          return this.$t('flux.buy')
      }
    },
    priceTitle () {
      switch (this.fluxType) {
        case 'PURPOSE':
          return this.$t('flux.yourOffer')
        case 'AUTO_BID':
          return this.$t('flux.maxBid')
        case 'BID':
          return this.$t('flux.bidAmout')
        default:
          return this.$t('flux.amount')
      }
    },
    activeScreen () {
      return this.screens[this.step]
    },
    isLast () {
      return this.screens.length - 1 === this.step
    }
  },
  data () {
    return {
      screens: [
        'FluxPersonal',
        'FluxAddress',
        'FluxResume',
      ],
    }
  },
  mounted () {
    this.init()
    if (this.step === 0) {
      checkoutEvents.started(this.offer, this.fluxType, this.bid, this.offerPrice)
    }
  },
  beforeDestroy () {
    this.SET_STEP(0)
  },
  methods: {
    ...mapMutations('fluxStore', ['SET_USER', 'SET_ADDRESSES', 'SET_OFFER', 'SET_CAN_NEXT', 'SET_STEP', 'SET_OFFER_PRICE']),
    init () {
      this.getUser()
      this.getAddress()
      let step = this.$route.query.step || 0
      this.SET_STEP(step)
      this.getPrices()
    },
    async getUser () {
      const response = await FluxResource.getUser()
      this.SET_USER(response.body)
    },
    async getAddress () {
      const response = await FluxResource.getAddress()
      this.SET_ADDRESSES(response.body)
    },
    async getPrices () {
      const response = await OfferResource.getPrice(this.offer.codigo)
      const offerPrice = response.body || {
        codigoOferta: 0,
        codigoPerfil: 0,
        valorCompreJa: 0,
        valorLeilao: 0
      }
      this.SET_OFFER_PRICE(offerPrice)
    },
    getImage (endpoint) {
      return getImageUrl(endpoint)
    },
    getImageActive (endpoint) {
      return getImageUrl(endpoint)
    },
    async next () {
      const retorno = await this.$refs[this.activeScreen].save()
      if (retorno !== 'erro' && retorno !== 'endereco') {
        const step = this.step + 1
        this.SET_STEP(step)
        let path = '/fluxo?step=' + this.step

        if (step >= this.screens.length) {
          window.scrollTo(0, 0)
          path = '/finalizar'
        }

        this.$router.push({
          path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
        }).catch((e) => {})
      }
    }
  },
}
</script>

