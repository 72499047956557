<template>
  <v-row
      v-if="offers"
      ref="grid-anchor"
      class="offer-grid-background"
  >
    <v-col
        class="pt-0 pb-0"
        v-if="offers.length > 0"
    >
      <div class="grid-aligner">
        <div class="grid-content">
          <OffersHeader
              :length="offers.length"
              :order-by="orderBy"
              :filter-by="filterBy"
              :title="title"
          />
          <v-row>
            <v-col
                class="pt-0 pb-0"
            >
              <div class="top">

                <div
                    style="display: flex; flex-wrap: nowrap;"
                >
                  <div
                      v-if="$vuetify.breakpoint.lgAndUp"
                      class="align-content-center"
                      style="width: 10px; margin-right: 17px;"
                  >
                    <ChevronLeft  class="cursor-pointer"
                                  style="visibility: hidden;" />
                  </div>

                  <div
                      ref="grid"
                      :style="gridStyle"
                  >
                    <div class="grid-card-container">
                      <div
                          v-for="(offer) in offersInShow"
                          :key="offer.codigo"
                      >
                        <OfferCard
                            v-if="offer.codigo"
                            :offer="offer"
                        />
                        <div
                            v-else
                        />
                      </div>
                    </div>
                  </div>

                  <div
                      v-if="$vuetify.breakpoint.lgAndUp"
                      class="align-content-center"
                      style="width: 10px; margin-left: 17px;"
                  >
                    <ChevronRight class="cursor-pointer"
                                  style="visibility: hidden;" />
                  </div>
                </div>

                <div
                    class="align-content-center"
                    style="margin-top: 15px;"
                >
                  <div
                      v-if="pages > 1"
                      class="columns-pagination"
                  >
                    <div
                        v-for="i in pages"
                        :key="i"
                        :class="{'selected': (i===pageSelected) }"
                        class="column-pagination cursor-pointer"
                        @click="selectPage(i)"
                    >
                      {{ i }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>

    <v-col
        v-else
        class="notfound"
    >
      <PlaceholderCarIcon/>
      <div class="notfound-title">{{ $t('HomeIndex.offerNotFound.title') }}</div>
      <div class="notfound-text">{{ $t('HomeIndex.offerNotFound.text') }}</div>
    </v-col>
  </v-row>
</template>
<script>
import OffersHeader from '../OfferHeader/OffersHeader'
import OfferCard from '../OfferCard/OfferCardIndex'
import PlaceholderCarIcon from "@/shared/Icons/PlaceholderCarIcon";
import ChevronLeft from "@/shared/Icons/ChevronLeft";
import ChevronRight from "@/shared/Icons/ChevronRight";

export default {
  name: 'OffersGrid',
  components: {ChevronRight, ChevronLeft, PlaceholderCarIcon, OfferCard, OffersHeader},
  props: {
    offers: {
      type: Array
    },
    orderBy: {
      type: Boolean,
      default: false
    },
    filterBy: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '878px'
    },
    title: {
      type: String,
      default: 'global.vehicles'
    },
  },
  data () {
    return {
      pageSelected: 1,
      myRowWidth: 0,
      cardSizeDefault: 268,
      cardMarginDefault: 30,
      timeoutId: null
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateRowWidth)
  },
  mounted () {
    window.addEventListener('resize', this.updateRowWidth)
    this.updateRowWidth()
  },
  watch: {
    width (newValue, oldValue) {
      setTimeout(this.updateRowWidth, 100)
    }
  },
  computed: {
    offerPerRow () {
      return this.myRowWidth ? Math.floor(this.myRowWidth / this.cardSizeDefault) : 3
    },
    offersInShow () {
      if (!this.offers) return []
      if (this.offers.length < this.onScreen && (this.offers.length % this.offerPerRow) > 0) {
        let length = this.offerPerRow - (this.offers.length % this.offerPerRow)
        return this.offers.concat(
            Array.apply(null, {length: length}).map(() => {
              return {}
            })
        )
      }
      let offset = (this.pageSelected - 1) * this.onScreen
      return this.offers.slice(offset, offset + this.onScreen)
    },
    pages () {
      return Math.ceil(this.offers.length / this.onScreen)
    },
    onScreen () {
      return this.myRowWidth ? this.offerPerRow * 3 : 9
    },
    gridStyle () {
      return `min-width: ${this.width}; max-width: ${this.width};`
    }
  },
  methods: {
    getStyle (i) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 'margin-right: 0;'//'margin-right: auto; margin-left: auto;'
      }

      if (i % this.offerPerRow === this.offerPerRow - 1) {
        return 'margin-right: 0;'
      } else {
        return 'margin-right: auto;'
      }
    },
    selectPage (page) {
      this.pageSelected = page
      this.toAnchor()
    },
    updateRowWidth () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
      if (!this.$refs.grid) return this.timeoutId = setTimeout(this.updateRowWidth, 100)
      this.myRowWidth = this.$refs.grid.offsetWidth
    },
    toAnchor () {
      const element = this.$refs['grid-anchor']
      const offset = 50
      const top = element.offsetTop - offset

      window.scrollTo(0, top)
    }
  }
}
</script>

<style
    scoped
    type="text/css"
>
.grid {
  display: grid;
  width: 100%;
  grid-template-rows: 348px 349px 349px;
  column-gap: 40px;
  row-gap: 30px;
  align-items: center;
  justify-items: center;
}

.columns-pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.column-pagination {
  flex: 1;
  margin-left: 5px;
  height: 22px;
  width: 22px;
  text-align: center;
  font: 18px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #8B8B8B;
}

.column-pagination:first-child {
  margin-left: 0;
}

.selected {
  background: #D8D8D8;
  box-shadow: 1px 1px 2px #FFFFFF;
  border-radius: 2px;
  color: #FFFFFF;
}

.box {
  width: 268px;
}

.offer-card {
  flex: 1;
  flex-basis: 100%;
  margin: 10px;
  width: 270px;
}

@media (max-width: 768px) {
  .offer-card {
    width: 160px;
  }
}

.offer-grid-background {
  background: #F5F5F7 0 0 no-repeat padding-box;
}

.grid-card-container {
  display: flex;
  flex-wrap: wrap;

  /*justify-content: space-evenly;*/
}

.notfound {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px 20px 80px;
}

.notfound-title {
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 20px;
}

.notfound-text {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #8B8B8B;
  margin-bottom: 8px;
  text-align: center;
}

.grid-content {
  margin-top: 20px;
  margin-bottom: 30px;
  width: fit-content;
}

.grid-aligner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.top {
  margin-top: 24px;
}

@media screen and (max-width: 701px), screen and (max-height: 501px) {

.top {
  margin-top: 55px;
}


}

</style>
