<template>
    <div class="offer-detail-card" >
      <v-row justify="space-between">
        <span class="detail-title">{{ $t('global.details') }}</span>
        <span class="cursor-pointer" @click.stop="$emit('close')"><XMarkIcon height="12" width="12"/></span>

      </v-row>

      <v-row class="mt-5 mb-5 justify-space-around">
        <addon-card :addon="mileage">
          <VelocimeterIcon/>
        </addon-card>

        <v-divider inset vertical></v-divider>

        <addon-card :addon="year">
          <year-model-icon/>
        </addon-card>

        <v-divider inset vertical></v-divider>

        <addon-card :addon="placa">
          <license-plate-icon/>
        </addon-card>
      </v-row>

      <v-row class="mb-5" v-if="hasHtmlListTagFormmat(offer.description)">
        <div class="detail-container" v-html="offer.description">
        </div>
      </v-row>

      <v-row>
        <button
          class="p-button p-button-primary buy-button"
          style="border-radius: 4px; width: 100%"
          @click.stop="goToBuy">
          {{ $t('OfferBar.buy') }}
        </button>
      </v-row>
    </div>
</template>

<script>
import XMarkIcon from '@/shared/Icons/XMarkIcon'
import AddonCard from '@/screen/Offer/AddonCard'
import YearModelIcon from '@/shared/Icons/YearModelIcon'
import CookieControl from '@/cookie-control'
import LicensePlateIcon from '@/shared/Icons/LicensePlateIcon'
import VelocimeterIcon from '@/shared/Icons/VelocimeterIcon'
import { mapMutations } from 'vuex'

const cookieControl = new CookieControl()

export default {
  name: 'OfferDetail',
  components: {XMarkIcon, AddonCard, YearModelIcon, LicensePlateIcon, VelocimeterIcon},
  props: {
    offer: {
      type: Object
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    year() {
      const vehicle = this.offer.vehicles[0]
      return {
        title: 'Ano',
        value: `${vehicle.yearManufacture}/${vehicle.yearModel}`
      }
    },
    mileage() {
      const vehicle = this.offer.vehicles[0]
      return {
        title: 'KM',
        value: vehicle.km.float2Money('', '', '.', '')
      }
    },
    placa() {
      const vehicle = this.offer.vehicles[0]
      if (!vehicle.placa) {
        return {
          title: 'Placa',
          value: ''
        }
      }
      return {
        title: 'Placa',
        value: `***-${vehicle.placa.substring(vehicle.placa.length - 4)}`
      }
    },
  },
  methods: {
    ...mapMutations('fluxStore', [
      'SET_FLUX_BUY', 'SET_OFFER'
    ]),
    goToBuy () {
      const parceiro = this.offer.parceiro

      if (!this.isUserLogin()) {
        return this.$router.push({
          path: (parceiro === undefined ? "" : "/" + parceiro) + '/oferta/' + this.offer.codigo
        }).catch(() => {})
      }

      this.SET_OFFER(this.offer)
      this.SET_FLUX_BUY()

      window.scrollTo(0, 0)
      this.$router.push({
        path: (parceiro === undefined ? "" : "/" + parceiro) + '/fluxo'
      }).catch(() => {})
    },
    isUserLogin () {
      return cookieControl.isLogin()
    },
    hasHtmlListTagFormmat(text) {
      if (!text) return false
      const starts_ul = text.startsWith('<ul>')
      const ends_ul = text.endsWith('</ul>')
      const ul_i = (text.split('<ul>').length - 1 === 1)
      const ul_f = (text.split('</ul>').length - 1 === 1)
      const li = (text.split('<li>').length - 1) === (text.split('</li>').length - 1)
      return starts_ul && ends_ul && ul_i && ul_f && li
    }
  },
}
</script>

<style lang="scss" scoped>
  .offer-detail-card {
    display: inline-block;
    width: 320px;
    min-width: 320px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
    z-index: 22;
    padding: 20px;
    top: -285px;
    cursor: default;

    &__right {
      left: calc(70% + 10px);
    }

    &__left {
      left: -25px;
      top: -90px;
    }

    .check-circle::before {
      font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f058";
    }

    .detail-title {
      font: 24px 'HelveticaNeue-Medium';
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
    }

    .buy-button {
      font: 20px 'HelveticaNeue-Medium';
      color: #2D2D2D;
    }

    .detail-caracteristics {
      font: 16px 'HelveticaNeue-Medium';
      color: #666666;

      & > li {
        margin-bottom: 10px;
      }
    }

    //&::after {
    //  border: 20px solid transparent;
    //  border-bottom-color: #FFFFFF;
    //  content: "";
    //  left: 5%;
    //  position: absolute;
    //  top: 442px;
    //  transform: rotate(180deg);
    //  box-shadow: 0 3px 6px #00000029;
    //}
  }

  .detail-container {
    font: 16px 'HelveticaNeue-Medium';
    color: #666666;
    max-height: 180px;
    overflow-y: auto;

    & ul {
      list-style-type: none !important;
    }
  }
</style>
