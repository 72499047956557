<template>
  <svg
      height="256"
      viewBox="0 0 256 256"
      width="256"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g
        id="placeholder_credit_card"
        transform="translate(-1144.514 -800)"
    >
      <g
          id="Retângulo_1009"
          data-name="Retângulo 1009"
          opacity="0"
      >
        <rect
            id="Retângulo_1033"
            data-name="Retângulo 1033"
            fill="#fff"
            height="256"
            transform="translate(1144.514 800)"
            width="256"
        />
        <rect
            id="Retângulo_1034"
            data-name="Retângulo 1034"
            fill="none"
            height="255"
            stroke="#707070"
            stroke-width="1"
            transform="translate(1145.014 800.5)"
            width="255"
        />
      </g>
      <circle
          id="Elipse_45"
          cx="92"
          cy="92"
          data-name="Elipse 45"
          fill="#e8f1fe"
          r="92"
          transform="translate(1180.515 833)"
      />
      <rect
          id="Retângulo_1035"
          data-name="Retângulo 1035"
          fill="#fff"
          height="104.835"
          transform="translate(1189.462 872.385)"
          width="165.758"
      />
      <g
          id="Grupo_2237"
          data-name="Grupo 2237"
      >
        <path
            id="Caminho_9135"
            d="M1359.8,881.176a13.068,13.068,0,0,0-13.038-13.037H1198.271a13.07,13.07,0,0,0-13.038,13.037v87.648a13.07,13.07,0,0,0,13.038,13.037h148.488a13.066,13.066,0,0,0,13.038-13.037ZM1355.079,913v55.824a8.35,8.35,0,0,1-8.32,8.32H1198.271a8.351,8.351,0,0,1-8.32-8.32V912h165.128Zm0-22.866v1H1189.951v-9.958a8.351,8.351,0,0,1,8.32-8.32h148.488a8.348,8.348,0,0,1,8.32,8.32Z"
            data-name="Caminho 9135"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9136"
            d="M1212.758,946.913a19.192,19.192,0,0,1,7.542-15.245c-.154-.063-.309-.122-.465-.18a16.438,16.438,0,1,0,0,30.849c.156-.057.311-.117.465-.179a19.392,19.392,0,0,1-1.926-1.686A19.125,19.125,0,0,1,1212.758,946.913Z"
            data-name="Caminho 9136"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9137"
            d="M1231.934,930.476a16.436,16.436,0,1,0,11.622,4.814A16.389,16.389,0,0,0,1231.934,930.476Z"
            data-name="Caminho 9137"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9138"
            d="M1342.137,943.554h-17.55a3.368,3.368,0,0,0,0,6.717h17.55a3.368,3.368,0,0,0,0-6.717Z"
            data-name="Caminho 9138"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9139"
            d="M1281,943.554h-17.551a3.368,3.368,0,0,0,0,6.717H1281a3.368,3.368,0,0,0,0-6.717Z"
            data-name="Caminho 9139"
            fill="#9fabbe"
        />
        <path
            id="Caminho_9140"
            d="M1311.567,943.554h-17.55a3.368,3.368,0,0,0,0,6.717h17.55a3.368,3.368,0,0,0,0-6.717Z"
            data-name="Caminho 9140"
            fill="#9fabbe"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'PlaceholderCreditCardIcon'
}
</script>
<style scoped>
</style>
