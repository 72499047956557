<template>
  <div
      v-if="offer"
      ref="offerScreen"
  >

    <div class="offer-grid">

      <div class="offer-gallery">
        <OfferGallery :gallery="photos"/>
      </div>

      <div class="offer-bid-bar" v-if="offer && offer.vehicles && offer.vehicles[vehicleSelectedId]">
        <PartnerOfferSideBar v-if="showParceiro" :offer="offer" :price="offerPrice"></PartnerOfferSideBar>
        <OfferSideBar v-else :increments="increments" :offer="offer" :price="offerPrice"/>
      </div>

      <div class="offer-addon-info">

        <AddonCard :addon="{ title: 'Km', value: formatKm(this.offer.vehicles[0].km)}" :backdrop="true">
          <VelocimeterIcon/>
        </AddonCard>

        <AddonCard :addon="{ title: $t('global.yearModel'), value: this.offer.vehicles[0].yearManufacture + '/' + this.offer.vehicles[0].yearModel }" :backdrop="true">
          <YearModelIcon/>
        </AddonCard>

        <AddonCard :addon="{ title: $t('global.finalPlaca'), value: '***-' + this.offer.vehicles[0].placa.substring(this.offer.vehicles[0].placa.length-4,this.offer.vehicles[0].placa.length) }" :backdrop="true">
          <LicensePlateIcon/>
        </AddonCard>

        <AddonCard
          v-if="cambio"
          :addon="cambio"
          :backdrop="true"
        >
          <TransmissionIcon/>
        </AddonCard>

        <AddonCard :addon="{ title: $t('global.color'), value: offer.vehicles[0].cor }" v-if="offer.vehicles[0].cor" :backdrop="true">
          <ColorIcon/>
        </AddonCard>

        <AddonCard :addon="{ title: $t('OfferBar.fuel'), value: offer.vehicles[0].combustivel }" v-if="offer.vehicles[0].combustivel" :backdrop="true">
          <FuelIcon/>
        </AddonCard>
        <AddonCard
          v-if="tipoDirecao"
          :addon="tipoDirecao"
          :backdrop="true"
        >
          <SteeringWheel/>
        </AddonCard>
        <AddonCard
          v-if="tracao"
          :addon="tracao"
          :backdrop="true"
        >
          <TractionIcon/>
        </AddonCard>
        <AddonCard
          v-for="(addon) in offer.vehicles[0].typeVehiclesDto.additionalInformation.filter(add => add.title !== 'Tração' && add.title !== 'Tipo de Direção' && add.title !== 'Câmbio'  && add.title !== 'Chassis Remarcado')"
          :key="addon.codigoAdditionalInformation"
          :addon="addon"
          :backdrop="true"
        >
        </AddonCard>
      </div>
    </div>

    <!-- Galeria e barra lateral -->
<!--    <div-->
<!--        style="width: 100%;  background:#F5F5F7;"-->
<!--    >-->
<!--      <div style="width:100%;">-->
<!--        <div class="gallery-sidebar" style=" height: fit-content; background:#F5F5F7; ">-->

<!--          <div-->
<!--              class="gallery-container"-->
<!--          >-->
<!--            <div>-->
<!--              <OfferGallery-->
<!--                  :gallery="photos"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--              v-if="offer && offer.vehicles && offer.vehicles[vehicleSelectedId]"-->
<!--              class="sidebar-container"-->
<!--          >-->
<!--            <OfferSideBar-->
<!--                :increments="increments"-->
<!--                :offer="offer"-->
<!--                :price="offerPrice"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="width:100%; max-width:100%; justify-items: center; background:#F5F5F7;">-->
<!--        &lt;!&ndash; ADDONCARD ICONS ADICIONAIS &ndash;&gt;-->
<!--        &lt;!&ndash; v-if="display === 'buy' && !isLote" &ndash;&gt;-->
<!--        <div-->
<!--          class="addon-grid"-->
<!--        >-->
<!--          <div>-->
<!--            <AddonCard :addon="{ title: $t('global.color'), value: offer.vehicles[0].cor }">-->
<!--              <ColorIcon/>-->
<!--            </AddonCard>-->
<!--            &lt;!&ndash;<p>Estou aqui</p>&ndash;&gt;-->
<!--          </div>-->
<!--          <div>-->
<!--            <AddonCard :addon="{ title: $t('OfferBar.fuel'), value: offer.vehicles[0].combustivel }">-->
<!--              <FuelIcon/>-->
<!--            </AddonCard>-->
<!--          </div>-->
<!--          <div>-->
<!--            <AddonCard :addon="{ title: $t('global.finalPlaca'), value: '***-' + this.offer.vehicles[0].placa.substring(this.offer.vehicles[0].placa.length-4,this.offer.vehicles[0].placa.length) }">-->
<!--              <EndLicensePlateIcon/>-->
<!--            </AddonCard>-->
<!--          </div>-->
<!--          <div>-->
<!--            <AddonCard :addon="{ title: $t('global.yearModel'), value: this.offer.vehicles[0].yearManufacture + '/' + this.offer.vehicles[0].yearModel }">-->
<!--              <YearModelIcon/>-->
<!--            </AddonCard>-->
<!--          </div>-->
<!--          <div>-->
<!--            <AddonCard :addon="{ title: 'Km', value: this.offer.vehicles[0].km.float2Money('', '', '.', '')}">-->
<!--              <FuelIcon/>-->
<!--            </AddonCard>-->
<!--          </div>-->
<!--          <div-->
<!--              v-for="(addon, i) in offer.vehicles[0].typeVehiclesDto.additionalInformation"-->
<!--              :key="i"-->
<!--          >-->
<!--            <AddonCard-->
<!--                :addon="addon"-->
<!--            >-->
<!--              <div-->
<!--                v-if="addon.title == 'Câmbio'"-->
<!--              >-->
<!--                <TransmissionIcon/>-->
<!--              </div>-->
<!--              <div-->
<!--                v-if="addon.title == 'Tipo de Direção'"-->
<!--              >-->
<!--                <EngineIcon/>-->
<!--              </div>-->
<!--              <div-->
<!--                v-if="addon.title == 'Tração'"-->
<!--              >-->
<!--                <TractionIcon/>-->
<!--              </div>-->
<!--            </AddonCard>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- Galeria e barra lateral ^^-->

    <!-- Características -->
    <div
        class="resume-container"
    >

      <!-- Tanques ^^-->
      <hr
          v-if="tanks && tanks.length > 0"
          class="offer-line"
      >

      <div
          v-if="tanks && tanks.length > 0"
          class="offer-screen-resume"
      >
        <div class="offer-group-container">
          <div class="offer-group-title">
            <div class="information-title">
              {{ $t('OfferScreen.tanks') }}
            </div>
          </div>
          <div class="offer-group-content">
            <div style="width: 100%;">
              <div
                  v-for="(tank, a) in tanks[0].tanques"
                  :key="a"
                  :style="a < tanks.length-1 ? 'margin-bottom: 50px;' : ''"
              >
                <p
                    class="optionals-group-title"
                    style="margin-top: 13px;"
                >
                  {{ $t('OfferScreen.tank') + " " + (a+1) }}
                </p>
                <div class="info-grid">
                  <div
                      class="borderxs info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Material
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ tank.material }}
                    </div>

                  </div>
                  <div
                      class="info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Capacidade
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ tank.capacidade + " L" }}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tanques ^^-->
      <!-- Implementos-->
      <hr
          v-if="implments && implments.length > 0"
          class="offer-line"
      >

      <div
          v-if="implments && implments.length > 0"
          class="offer-screen-resume"
      >
        <div class="offer-group-container">
          <div class="offer-group-title">
            <div class="information-title">
              {{ $t('OfferScreen.implements') }}
            </div>
          </div>
          <div class="offer-group-content">
            <div style="width: 100%;">
              <div
                  v-for="(implement, a) in implments[0].implementos"
                  :key="a"
                  :style="a < implments.length-1 ? 'margin-bottom: 50px;' : ''"
              >
                <p
                    class="optionals-group-title"
                    style="margin-top: 13px;"
                >
                  {{ $t('OfferScreen.implement') + " " + (a+1) }}
                </p>
                <div class="info-grid">
                  <div
                      class="border info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Tipo
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ implement.tipoImplemento }}
                    </div>

                  </div>
                  <div
                      class="border info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Fabricante
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ implement.fabricante }}
                    </div>

                  </div>
                  <div
                      class="border info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Modelo
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ implement.modelo }}
                    </div>

                  </div>
                  <div
                      class="border info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Ano de Fabricação
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ implement.anoImplemento }}
                    </div>

                  </div>
                  <div
                      class="info-card-content info-card-full-xs"
                  >
                    <div
                        class="info-title"
                    >
                      Dimensões (LxAxC)
                    </div>
                    <div
                        class="info-value"
                    >
                      {{ implement.largura + "x" + implement.altura + "x" + implement.comprimento + "(m)" }}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Implementos ^^-->


      <div
          v-if="offer.description"
          class="offer-screen-resume"
      >
        <div class="offer-group-container">
          <div class="offer-group-title">
            <div class="information-title">
              {{ $t('OfferScreen.observation') }}
            </div>
          </div>
          <div class="offer-group-content">
            <div style="width: 100%;">
              <div
                  class="info-grid"
                  style="padding-right: 25px;"
                  v-html="offer.description"
              >
<!--                {{ offer.description }}-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Caracteristicas: Observações ^^-->
      <hr
          v-if="offer.description"
          class="offer-line"
      >
      <!-- Caracteristicas: caracteristicas -->
      <div
          v-if="optionals && optionals.length > 0"
          class="offer-screen-resume"
      >
        <div class="offer-group-container">
          <div class="offer-group-title">
            <div class="information-title">
             {{ $t('OfferScreen.features') }}
            </div>
          </div>
          <div class="offer-group-content">
            <div style="width: 100%;">
              <div
                  v-for="(optional, a) in optionals"
                  :key="a"
                  :style="a < optionals.length-1 ? 'margin-bottom: 50px;' : ''"
              >
                <p
                    class="optionals-group-title"
                    style="margin-top: 13px;"
                >
                  {{ optional.title }}
                </p>
                <div class="info-grid">
                  <InfoCard
                      v-for="(item, i) in optional.items"
                      :key="i"
                      :show="isLast(i, optional.items)"
                      :title="item.name"
                      :value="getStarValue(item.stars)"
                      class="info-card-full-xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Caracteristicas: caracteristicas ^^-->
      <hr
          v-if="optionals && optionals.length > 0"
          class="offer-line"
      >

      <!-- Características ^^ -->

      <!-- Perguntas -->
      <div
          style="background: #FCFCFC;"
      >
        <v-dialog
            v-model="dialog"
            width="fit-content"
        >
          <v-card>
            <v-card-text style="padding-top: 20px;">
              <p>{{ $t('OfferScreen.questionDialogText.text') }}</p>
              <p>{{ $t('OfferScreen.questionDialogText.sub') }}</p>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="question-container">

          <div class="offer-group-container">
            <div class="offer-group-title">
              <div class="information-title">
                {{ $t('OfferScreen.question') }}
              </div>
            </div>
            <div class="offer-group-content">
              <div
                  class="grid-chat-control"
              >
              <textarea
                  ref="questionText"
                  v-model="question"
                  :disabled="!isUserLogin()"
                  :placeholder="placeholder"
                  class="chat-textarea"
              />

                <div>
                  <div
                      :class="{'button-disabled': !isUserLogin()}"
                      class="chat-send-button align-content-center"
                      style="margin: auto;"
                      @click="sendQuestion"
                  >
                    <span>{{ $t('global.sendButton') }}</span>
                  </div>
                </div>
              </div>

            </div>
            <div class="offer-group-content push-right">
              <div class="chat-grid">
                <template v-if="chat && chat.length">
                  <ChatTalk
                      v-for="(talk,i) in chat"
                      :key="i"
                      :show="i < chat.length-1 "
                      :talk="talk"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Perguntas ^^-->
    </div>

    <div
        class="align-content-center offer-screen-rows"
    >
      <div
          :class="{'mobile': $vuetify.breakpoint.mdAndDown}"
          class="offers-grid-content"
      >
        <OffersRow
            :card-size-default="320"
            :offers="offersSpotlight"
            :order-by="false"
            :filter-by="false"
            :show-header="false"
            :title="$t('global.similarVechicle')"
            :width="$vuetify.breakpoint.lgAndUp ? '1123px' : '100vw'"
        />
      </div>
    </div>

  </div>
</template>
<script>
import ChatTalk from './ChatTalk'
import InfoCard from './InfoCard'
import OfferGallery from './OfferGallery'
import OfferSideBar from './OfferSideBar'
import OffersRow from '../../shared/OffersRow/OffersRow'
import {mapMutations, mapState} from 'vuex'
import {OfferResource} from '@/api'
import CookieControl from '@/cookie-control'
import AddonCard from './AddonCard'
import FuelIcon from '@/shared/Icons/FuelIcon'
import ColorIcon from '@/shared/Icons/ColorIcon'
import LicensePlateIcon from '@/shared/Icons/LicensePlateIcon'
import TransmissionIcon from '@/shared/Icons/TransmissionIcon'
import YearModelIcon from '@/shared/Icons/YearModelIcon'
import TractionIcon from '@/shared/Icons/TractionIcon'
import SteeringWheel from '@/shared/Icons/SteeringWheel'
import VelocimeterIcon from '@/shared/Icons/VelocimeterIcon'
import PartnerOfferSideBar from '@/screen/Offer/PartnerOfferSideBar'
const cookieControl = new CookieControl()

export default {
  name: 'OfferScreen',
  components: {
    VelocimeterIcon,
    SteeringWheel,
    ChatTalk,
    InfoCard,
    OfferGallery,
    OfferSideBar,
    OffersRow,
    FuelIcon,
    AddonCard,
    ColorIcon,
    LicensePlateIcon,
    YearModelIcon,
    TransmissionIcon,
    TractionIcon,
    PartnerOfferSideBar
  },
  props: {
    offer: {
      type: Object
    },
    offerPrice: {
      type: Object,
      default: () => {
        return {
          codigoOferta: 0,
          codigoPerfil: 0,
          valorCompreJa: 0,
          valorLeilao: 0
        }
      }
    },
    photos: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      id: 0,
      selectedVehicle: 0,
      chat: [],
      specifications: {features: []},
      cost: {features: []},
      optionalsList: [],
      implementList: [],
      tankList: [],
      question: '',
      dialog: false,
      increments: [],
      timeoutId: null,
      windowWidth: 0,
      sizeTimeId: null,
      parceiro: '',
      showParceiro: false,
    }
  },
  computed: {
    ...mapState('offerStore', ['display','vehicleSelectedId', 'scrollToQuestion', 'offersSpotlight']),
    placeholder() {
      return this.isUserLogin() ? this.$t('OfferScreen.questionPlaceholder') : this.$t('OfferScreen.questionPlaceholderDisabled')
    },
    optionals() {
      return this.optionalsList[this.vehicleSelectedId]
    },
    implments() {
      return this.implementList[this.vehicleSelectedId]
    },
    tanks() {
      return this.tankList[this.vehicleSelectedId]
    },
    galleryWidth() {
      if (window.outerWidth < 701 || window.outerHeight < 501) {
        return this.windowWidth
      }
      //return this.windowWidth - 368
      return this.windowWidth - 600
    },
    isLote() {
      return this.offer.vehicles.length > 1
    },
    metric() {
      if (!this.offer.vehicles[0].km) return '--'
      return this.offer.vehicles[0].km.float2Money('', '', '.', '')
    },
    cambio() {
      return this.offer.vehicles[0].typeVehiclesDto.additionalInformation.filter(add => add.title === 'Câmbio')[0]
    },
    tipoDirecao() {
      return this.offer.vehicles[0].typeVehiclesDto.additionalInformation.filter(add => add.title === 'Tipo de Direção')[0]
    },
    tracao() {
      return this.offer.vehicles[0].typeVehiclesDto.additionalInformation.filter(add => add.title === 'Tração')[0]
    },
  },
  watch: {
    scrollToQuestion(newValue) {
      if (newValue) {
        this.goToQuestion()
        this.SET_SCROLL_QUESTION(false)
      }
    }
  },
  mounted() {
    this.id = this.offer.codigo
    this.parceiro = window.PARTNER
    this.showParceiro = this.parceiro !== undefined
    this.init()
  },
  methods: {
    ...mapMutations('offerStore', ['SET_VEHICLE_ID', 'SET_SCROLL_QUESTION', 'SET_OFFERS_SEM']),
    async init() {
      this.windowWidth = window.innerWidth > screen.width ? screen.width : window.innerWidth
      window.addEventListener('resize', () => {
        if (this.sizeTimeId) {
          clearTimeout(this.sizeTimeId)
        }
        this.sizeTimeId = setTimeout(
            () => {
              this.windowWidth = window.innerWidth > screen.width ? screen.width : window.innerWidth
            }, 500
        )
      })
      this.SET_VEHICLE_ID(0)
      //this.display = 'buy'
      await this.getOfferOptionals()
      await this.getOfferImplements()
      await this.getOfferTanks()
      await this.getChat()
      await this.getSpotlight()
      await this.getIncrements()
    },
    async getIncrements() {
      const response = await OfferResource.getIncrements(this.offer.codigo)

      this.increments = response.body.incremento.sort((a, b) => a - b)
    },
    async getSpotlight() {
      const response = await OfferResource.getSpotlight(this.offer.vehicles[this.vehicleSelectedId].codigo)
      this.SET_OFFERS_SEM(response.body.data)
    },
    async getOfferOptionals() {
      this.optionalsList = []
      for (let vehicle of this.offer.vehicles) {
        const response = await OfferResource.getOptionals(vehicle.codigo)
        this.optionalsList.push(response.body)
      }
    },
    async getOfferImplements() {
      this.implementList = []
      for (let vehicle of this.offer.vehicles) {
        const response = await OfferResource.getImplements(vehicle.codigo)
        if (response.body && response.body.length) {
          this.implementList.push(response.body)
        }
      }
    },
    async getOfferTanks() {
      this.tankList = []
      for (let vehicle of this.offer.vehicles) {
        const response = await OfferResource.getTanks(vehicle.codigo)
        if (response.body && response.body.length) {
          this.tankList.push(response.body)
        }
      }
    },
    async getChat() {
      const response = await OfferResource.getChat(this.offer.codigo)
      if (response.body) {
        this.chat = response.body.perguntas
      }
    },
    isUserLogin() {
      return cookieControl.isLogin()
    },
    async sendQuestion() {
      const response = await OfferResource.postChat({
        'pergunta': this.question,
        'codigoOferta': this.id,
      })

      this.question = ''

      await this.getChat()
      if (response.status === 201) {
        this.dialog = true
      }
    },
    getStarValue(value) {
      if (value === 1) return this.$t('global.bad')
      if (value === 2) return this.$t('global.regular')
      if (value === 3) return this.$t('global.good')
      return '-'
    },
    goToQuestion() {
      const element = this.$refs['questionText']
      const top = element.offsetTop
      const offsetHeaders = 200
      const to = top - offsetHeaders
      const actual = window.pageYOffset

      this.scrolling(actual, to)

    },
     scrolling(actual, to) {
      if (actual >= to) {
        return setTimeout(() => {
          const element = this.$refs['questionText']
          if (element) element.focus()
        }, 10)
      }

      if(to-200 >= actual && actual > 200){
        actual += 80
      }else{
        actual += 10
      }
      window.scrollTo(0, actual)

      setTimeout(() => {
        this.scrolling(actual, to)
      }, 5)

    },
    isLast(i, list) {
      let number = this.$vuetify.breakpoint.lgAndUp ? 2 : 1
      return i < list.length - number
    },
    formatKm(km) {
       // Check if km is a valid float representation
      if (!isNaN(parseFloat(km)) && isFinite(km)) {
       // Convert km to float and call float2Money method
        return parseFloat(km).float2Money('', '', '.', '');
      } else {
        return '';
      }
    }
  },
}
</script>
<style
    scoped
    lang="scss"
>

.offer-grid {
  display: grid;
  width: min(1366px, 100%);
  margin-inline: auto;
  grid-template-columns: 870px 472px;
  grid-template-rows: 454px 150px;
  grid-template-areas:
      "offergallery offerbidbar"
      "offeraddoninfo offeraddoninfo"
;
  background: #F5F5F7 0 0 no-repeat padding-box;

  .offer-gallery {
    grid-area: offergallery;
    height: 454px;
  }

  .offer-bid-bar {
    height: 100%;
    grid-row: span 2;
    grid-area: offerbidbar;
  }

  .offer-addon-info {
    grid-area: offeraddoninfo;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    overflow-x: auto;
    padding-block: 1.5rem;
  }
}

@media screen and (max-width: 1359px) {
  .offer-grid {
    grid-template-columns: 100%;
    grid-template-rows: 454px auto auto;
    grid-template-areas: "offergallery" "offerbidbar" "offeraddoninfo";
  }

  .offer-gallery{
    height: 339px !important;
  }

  .offer-addon-info {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .addon-container {
    width: 33.3%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .offer-grid {
    grid-template-rows: 339px auto auto;
  }
}

.v-responsive, .v-responsive .v-image{
  width:100% !important;
  max-width: 100% !important;
  height:fit-content !important;
}


.info-grid {
  display: flex;
  width: 100%;
  height: fit-content;
  /*grid-template-columns: repeat(auto-fit, 259px);*/
  flex-flow: row wrap;
  column-gap: 62px;
  /*row-gap: 20px;*/
  align-items: center;
  justify-items: center;
}

.information-title {
  text-align: left;
  font: 30px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #414141;
}

.info-title {
  text-align: left;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  flex: 1;
}

.info-value {
  text-align: right;
  font: 14px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
}

/*noinspection ALL*/
.border {
  border-bottom: 1px solid;
  border-bottom-color: RGBA(139, 139, 139, 0.4);
}

.info-card-content {
  width: 259px;
  height: 36px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

p.optionals-group-title {
  font: 18px 'HelveticaNeueLTPro-Bd';
  letter-spacing: 0;
  color: #414141;
  margin-bottom: 30px;
}

.information-subtitle {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  margin-top: 8px;
}

.chat-send-button {
  background: #CDD43F 0 0 no-repeat padding-box;
  box-shadow: -1px -1px 2px #FFFFFFB0, 1px 1px 2px #000000C2;
  border-radius: 23px;
  opacity: 1;
  text-align: center;
  font: 16px 'HelveticaNeueLTPro-Md';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 126px;
  height: 46px;
  margin-bottom: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chat-send-button > span {
  display: block;
  line-height: 1;
}

textarea.chat-textarea {
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #2D2D2D;
  width: 400px;
  height: 63px;
  background: #F5F6F7 0 0 no-repeat padding-box;
  box-shadow: -2px -2px 2px #00000014;
  border: 1px solid #ECECEC;
  border-radius: 6px;
  resize: none;
  padding: 10px;
}

textarea.chat-textarea::placeholder {
  color: #8B8B8B;
}

.grid-chat-control {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  grid-template-columns: auto 126px;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding-left: 47px;
}


.chat-grid {
  display: flex;
  flex-flow: column nowrap;
  width: 600px;
  /*grid-template-columns: repeat(auto-fit, auto);*/
  column-gap: 62px;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  min-width: 270px;
}

.offer-line {
  width: 100%;
  margin-bottom: 50px;
  height: 1px;
  background-color: #C9CDD066;
  border: none;
}

/*noinspection ALL*/
.button-disabled {
  color: #2d2d2d !important;
  background: #cdd43f 0 0 no-repeat padding-box !important;
  opacity: 0.3;
  cursor: default;
}

.gallery-sidebar {
  display: flex;
  width: 100%;
}

.gallery-sidebar .gallery-container {
  width:100%;
  height: fit-content;
  background:#F5F5F7;
  flex: 1;
}

.gallery-container  div{
  width:100%;
}

.gallery-container div .relative{
  width: 100%!important;
}

.sidebar-container {
  flex: 1;
  z-index: 21;
  /*max-width: 368px;*/
  max-width: 30%;
  align-content: center;
  justify-content: center;
}

.resume-container {
  padding-top: 48px;
  background: #FCFCFC;
}

.obs-group-title {
  text-align: left;
  font: 14px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
}


.offer-screen-resume {
  width: 870px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.offer-group-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap
}

.offer-group-title {
  min-width: 259px;
  margin-bottom: 30px;
}

.offer-group-content {
  flex: 1;
}

.question-container {
  width: 870px;
  margin-left: auto;
  margin-right: auto;
}

.offers-grid-content {
  justify-content: center;
  flex: 0;
  max-width: fit-content;
}

.offers-grid-content.mobile {
  flex: 1;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-top: 15px;
}

.offer-screen-rows {
  max-width: 100%;
  padding: 28px 30px 40px;
  background: #F5F5F7;
  z-index: 2;
  position: relative;
}

.push-right {
  display: flex;
  justify-content: flex-end;
}

.addon-grid {
        display: flex;
        flex-wrap: wrap;
        grid-template-rows: repeat(1, 89px);
        grid-template-columns: repeat(8, 8%);
        column-gap: 80px;
        row-gap: 24px;
        align-items: center;
        justify-items: center;
        padding-bottom: 30px;
        margin-left: 2%;
}

.addon-grid div{
  width: auto;
}

@media screen and (max-width: 1279px) {

  .resume-container {
    padding-left: 197px;
  }

  .question-container {
    width: 100%;
  }

  .grid-chat-control {
    padding-left: 0;
    justify-content: flex-start;
  }

  .offer-screen-resume {
    width: 100%;
  }

  .chat-grid {
    width: 100%;
  }

  .addon-grid {
    padding-right: 5%;
  }
}

@media screen and (max-width: 760px) {

  .grid-chat-control {
    flex-wrap: wrap;
    padding-right: 20px;
  }

  textarea.chat-textarea {
    margin-bottom: 20px;
    min-width: 100%;
  }

  .addon-grid {
    padding-left: 10%;
  }
}

@media screen and (max-width: 701px), screen and (max-height: 501px) {
  .resume-container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .grid-chat-control {
    padding-right: 0;
  }

  .offer-group-container {
    flex-flow: column;
  }

.borderxs {
  border-bottom: 1px solid;
  border-bottom-color: RGBA(139, 139, 139, 0.4);
}

  .gallery-sidebar {
    flex-flow: column;
  }

  .sidebar-container {
    min-width: 100%;
  }

  .info-card-full-xs {
    width: 100%;
  }

  .offer-group-title {
    flex: 1;
  }

  textarea.chat-textarea {
    width: 100%;
    min-width: 230px;
    flex: 1;
  }

  .chat-grid {
    max-width: 100%;
  }

  .addon-grid {
    padding-left: 10%;
  }
}

@media screen and (min-width: 1360px) {

  .offer-addon-info{
    margin-top: 225px; 
    height: 130px;
  }

  .resume-container{
    margin-top: 225px;
  }
}

</style>
