<template>
  <div
      v-if="showingMenu"
      class="side-menu-screen"
  > 
    <div
        class="side-menu"
        @click="(e)=> {e.preventDefault(); e.stopPropagation()}"
    >

      <div
          class="side-menu-header"
      >
     
        <div
            class="side-menu-header-back"
        >
         <!-- <div @click="close">
            <BackArrowIcon
                :show-circle="false"
            />
          </div> -->

        </div>

        <div
            class="side-menu-header-logo"
        >
          <div
              @click="goToLink()"
          >
            <VixLogo/>
          </div>
        </div>
        <div
            class="side-menu-header-back cursor-pointer"  @click="close"      
        >  
          <span   @click="close"
            class="cursor-pointer side-menu-header-close"> x 
          </span>  

          <!-- <XMarkIcon 
             class="cursor-pointer"
            height="9"
            width="9"       
           />-->

      </div>
      </div>

      <div
          class="side-menu-content"
      >
        <div
            class="side-menu-item"
            style="margin-bottom: 5px;"
        >
        <!-- 
          <div class="menu-filter">
            <div
                style="height: 40px; padding: 5px; width: 100%"
            >
              <div
                  class="input-header-search-container"
              >
                <div
                    class="input-header-search-icon align-content-center cursor-pointer"
                >
                  <SearchIcon/>
                </div>
                <input
                    type="search"
                    ref="searchInput"
                    v-model.trim="searchTerm"
                    class="input-header-search"
                    style="border: none;"
                    @keypress.enter="onChangeTerm"
                />
                <div
                    class="input-header-filter-icon align-content-center cursor-pointer"
                    style="display: none;"
                >

                  <FilterIcon
                      :show-circle="false"
                      fill=""
                  />
                </div>
              </div>
            </div>
          </div>-->
        </div>

        <div class="side-menu-content-box">

          <div
              v-for="(category, i) in categories"
              :key="i"
              class="side-menu-item"
              @click="goToLink(category.codigo)"
          >
            <!--                :selected="category.codigo.toString() === categorySelected"-->
            <ItemCategory
                :category="category"
                style="margin-left: 0;"  
                :selected="category.codigo.toString() === categorySelected"         
            />
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import ItemCategory from '@/shared/HeaderBar/ItemCategory'
import VixLogo from '@/shared/Icons/VixLogo'
import { HeaderResource } from '@/api'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'SideMenu',
  components: {ItemCategory, VixLogo},
  computed: {
    ...mapState('headerStore', ['showSideMenu']),
    ...mapState('homeStore', ['showFilterMobile']),
    showingFilterMobile: {
      get () {
        return this.showFilterMobile
      },
      set (v) {
        this.SET_SHOW_FILTER_MOBILE(v)
      }
    },
    showingMenu: {
      get () {
        return this.showSideMenu
      },
      set (v) {
        this.SET_SHOW_SIDE_MENU(v)
      }
    },
  },
  data () {
    return {
      categories: [],
      categorySelected: 0,
      searchTerm: '',
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('headerStore', ['SET_SHOW_SIDE_MENU', 'SET_HIDE_HEADER']),
    ...mapMutations('homeStore', [
      'SET_TERM',
      'SET_ANCHOR',
      'CLEAR_FILTER',
      'SET_SKIP_CLEAR_FILTER',
      'SET_SHOW_FILTER_MOBILE'
    ]),
    async init () {
      this.showingFilterMobile = false
      const responseHeaders = await HeaderResource.getList()
      this.categories = responseHeaders.body.slice(0, 7)
      this.categorySelected = this.$route.params.category
      this.searchTerm = ''
    },
    close () {
      this.showingMenu = false
    },
    onChangeTerm () {
      if (!this.searchTerm) {
        return setTimeout(this.onChangeTerm, 500)
      }
      this.SET_TERM(this.searchTerm)
      if (this.searchTerm) {
        if (this.$route.path.indexOf('/home') === -1) this.goToLink(this.categorySelected)
        this.SET_ANCHOR(true)
      }
      this.close()
    },
    async openFilter () {
      if (this.$router.history.current.fullPath.indexOf('/home') === -1) {
        await this.goToLink()
      }
      this.SET_HIDE_HEADER(true)
      this.showingFilterMobile = true
      this.close()
    },
    async goToLink (link) {
      this.SET_SKIP_CLEAR_FILTER(false)
      this.CLEAR_FILTER()
      const path = link ? '/home/' + link : '/home'
      window.scrollTo(0, 0)
      this.close()
      await this.$router.push({
        path: (window.PARTNER===undefined?"":"/"+window.PARTNER)+path
      })
    },
  },
}
</script>
<style scoped>

/*noinspection CssUnusedSymbol*/

a {
  opacity: 0;
}


.header-user-dropdown > * {
  cursor: pointer;
}

.side-menu-screen {
  background: rgba(0, 0, 0, 0.6) 0 0 no-repeat padding-box;
  height: 100vh;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 24;
}

.side-menu {
  background: #FFFFFF 0 0 no-repeat padding-box;
  min-width: 80%;
  height: 100%;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  /*bottom: 0;*/
  z-index: 24;
  display: flex;
  flex-flow: column;
  align-items: left;
  padding-left: 0px;
  padding-right: 0px;
  opacity: 1;
}

.side-menu-item {
  width: 100%;
  cursor: pointer;
}

.side-menu-header {
  height: fit-content;
  display: flex;
  align-items: right;
  justify-content: center;
  width: 100%;
  flex-flow: row;
  margin-bottom: 28px;
  margin-top: 14px;
}

.side-menu-header-back {
  width: 32px;
}

.side-menu-header-logo {
  flex: 1;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.side-menu-title {
  margin-top: 8px;
  font: 13px 'HelveticaNeueLTPro-Cn';
  font-weight: bold;
  letter-spacing: 1px;
  color: #4A5A11;
}

.menu-filter {
  font: 12px 'HelveticaNeueLTPro-Roman';
  color: #666666;
  height: 40px;
  padding: 8px 5px 8px 5px;
  column-gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input-header-search-container {
  position: relative;
  padding: 0 0 0 0;
  background: #F5F5F7 0 0 no-repeat padding-box;
  direction: ltr;
  width: 100%;
  margin: 0 auto;
}

.input-header-search-icon {
  position: absolute;
  top: 8px;
  left: 5px;
  width: 24px;
  height: 24px;
}

.input-header-filter-icon {
  position: absolute;
  top: 8px;
  right: 5px;
  width: 24px;
  height: 24px;
}

.input-header-search {
  padding-left: 30px;
  margin: 0;
  font: 13px 'HelveticaNeueLTPro-Roman';
  color: #414141;
  letter-spacing: 0;
  opacity: 1;
  border: 1px solid #d4e2f5;
  height: 38px;
  width: 100%;
  border-radius: 5px;
}

.side-menu-content {
  width: 402px;
  display: flex;
  flex-flow: column nowrap;
  align-items: left;
  padding-bottom: 10px;
  padding-left: 0px;
}

.side-menu-content-box {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0px;
  padding-bottom: 10px;
  justify-content: space-between;
  flex: 1;
  max-height: 500px;
}

.side-menu-header-close {
  text-align: left;
  font: 21px 'HelveticaNeueLTPro-Roman';
  letter-spacing: 0;
  color: #666666;
  width: 8.75px;
  height: 8.75px;
}

@media screen and (max-width: 601px) {
  .side-menu-content {
    width: 100%;
  }
}
</style>
